import React, { useEffect, useState } from 'react';
import { Table, Pagination, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import './Tables.scss';
import './UserLogs.table.scss';
import { matchIssuesTableColumns } from '../../constants/tableColumns';
import { Notification } from '../../utilities';
import { getMatchIssuesByUser } from '../../graphql/queryExecuters';
import { getAllUsersBaseData } from '../../redux/actionCreators/auth.actions';

const MatchIssuesTable = (propsData) => {

    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const { allUsersList } = useSelector(state => state.users);

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(searchParams.get('index') ? Number(searchParams.get('index')) : 1);
    const [searchText, setSearchText] = useState(searchParams.get('search') ? searchParams.get('search') : '');
    const [sortType, setSortType] = useState('DESC');
    const [user, setUser] = useState('');
    const [rowCount, setRowCount] = useState(propsData?.totalRecords);
    const [matchIssues, setMatchIssues] = useState([]);
    const [userData, setUserData] = useState([{ value: 'all', label: 'All Users' }]);

    useEffect(() => {
        getMatchIssuesDataTrigger();
    }, [user, sortType, propsData?.reload]); //eslint-disable-line

    useEffect(() => {
        getAllUserDataTrigger();
    }, [allUsersList]); //eslint-disable-line

    useEffect(() => {
        checkPageIndexChange();
    }, [searchParams]); //eslint-disable-line

    const getMatchIssuesDataTrigger = async (pageNumber = null) => {
        try {
            setLoading(true);
            let matchIssuesData = [];
            const page = pageNumber || pageindex;

            matchIssuesData = await getMatchIssuesByUser(user, searchText, page - 1, sortType);
            const value = matchIssuesData.dataCount; 
            onSearchInput(value);
            let alignedMatIssues = [];
            if(matchIssuesData.data){
                alignedMatIssues = matchIssuesData.data.map((item) => {
                    const issueDetails = item.ticketDetails ? JSON.parse(item.ticketDetails) : {};
                    return {
                        id: item.id,
                        skuId: item.skuId,
                        petcoProductLink: issueDetails.petcoUrl ? issueDetails.petcoUrl : '-',
                        competitorProductLink: issueDetails.competitorUrl ? issueDetails.competitorUrl : '-',
                        description: issueDetails.description ? issueDetails.description : '-',
                        createdBy: item.createdBy,
                        createdAt: item.createdAt ? dayjs.tz(item.createdAt).format('MM/DD/YYYY HH:mm:ss') : '-'
                    }
                })
            }

            if (matchIssuesData.dataCount < (page - 1) * 10) {
                setPageindex(1);
                searchParams.set('index', 1);
                setSearchParams(searchParams);
            }
            setMatchIssues(alignedMatIssues);
            setRowCount(matchIssuesData.dataCount);
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting match issues. Please try again later.'
            );
            setLoading(false);
        }
    };

    const getAllUserDataTrigger = async () => {
        try {
            if (allUsersList.length > 0) {
                setUserData([
                    { value: 'all', label: 'All Users' },
                    ...allUsersList.map((user) => ({ value: user.id, label: user.name }))
                ]);
            } else {
                dispatch(getAllUsersBaseData());
            }
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting user Data. Please try again later.'
            );
        }
    };

    const checkPageIndexChange = () => {
        const index = searchParams.get('index') || 1;
        if (index) {
            const indexToBeSwitch = Number(index);
            if (indexToBeSwitch !== pageindex) {
                setPageindex(indexToBeSwitch);
                getMatchIssuesDataTrigger(indexToBeSwitch);
            }
        }
    }
    
    const onSearchInput = (value) => {
        if(value < (pageindex - 1) * 10) {
            setPageindex(1);
            searchParams.set('index', 1);
            setSearchParams(searchParams);
        }
    }
    
    const handlePageChange = (event) => {
        setPageindex(event);
        searchParams.set('index', event);
        setSearchParams(searchParams);
        getMatchIssuesDataTrigger(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter')  {
                getMatchIssuesDataTrigger();
                searchParams.set('search', searchText);
                setSearchParams(searchParams);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : '';
        setSortType(sort);
    };

    return (
        <div className="table-container">
            <div className="inlineContainer">
                <Input
                    className="petco-Data-Search"
                    id="match-issues-search"
                    prefix={<SearchOutlined />}
                    value={searchText}
                    onKeyDown={onPressEnterKeyHandler}
                    onChange={handleSearchChange}
                    placeholder="Search for SKU"
                    suffix={<span className='press-enter-style'>Press enter to search</span>}
                />
                <Select
                    className="user-filter-dropdown"
                    onChange={(value) => {
                        value === 'all' ? setUser('') : setUser(value);
                    }}
                    defaultValue={'all'}
                    size="large"
                    options={userData}
                />
            </div>
            <Table
                rowKey="id"
                id="matchIssues-table"
                style={{marginBottom: '16px'}}
                dataSource={matchIssues}
                columns={matchIssuesTableColumns}
                loading={loading}
                pagination={false}
                size="middle"
                className="table-striped-rows"
                scroll={{ x: 1000 }}
                onChange={handleTableChange}
            />
            {rowCount > 10 ? <Pagination
                current={pageindex}
                className="petco-Data-pagination"
                defaultCurrent={pageindex}
                total={rowCount}
                onChange={handlePageChange}
                showSizeChanger={false}
                size="small"
            /> : <></>}
        </div>
    );
};

export default MatchIssuesTable;
