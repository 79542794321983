import React, { useEffect, useState } from 'react';
import { useNavigate , useSearchParams ,useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, encodeString } from '../../utilities';
import { Table, Input, Pagination, Spin, Checkbox, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { departmentsTableColumns } from '../../constants/tableColumns';
import { 
    getAllDeptData, 
    getDeptAtSbuData, 
    getDeptAtShopData, 
    getAllAssignDeptData,
    getAssignDeptAtSbuData, 
    getAssignDeptAtShopData
} from '../../graphql/queryExecuters';
import { DepartmentActions } from '../../redux/slices/department.slice';

import "./Tables.scss"

const DepartmentsTable = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const urlParamsObj = new URLSearchParams(location.search);
    const urlParams = urlParamsObj.toString();
    const [filterParams, setFilterParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [sortType, setSortType] = useState('ASC');
    const [rowCount, setRowCount] = useState(props.count.deptCount); 
    const [tableData, setTableData] = useState([]);
    const [pageindex, setPageindex] = useState(filterParams.get('deptIndex') ? filterParams.get('deptIndex') : 1);
    const [searchText, setSearchText] = useState(filterParams.get('deptSearch') ? filterParams.get('search') : '');
    const [reloadData, setReloadData] = useState(false);
    const [userId, setUserId] = useState('');

    const { userType, id } = useSelector(state => state.auth);
    const { level,  index, search, sort, deptList, count } = useSelector((state) => state.deptData);

    const getIndexParam = () => parseInt(filterParams.get('deptIndex')) || 1;

    useEffect(() => {
        checkSearchTextChange();
    }, [filterParams]); //eslint-disable-line

    useEffect(() => {
        if (props?.level && userType) {
            getDeptDataTrigger();
        }
    }, [pageindex, sortType, props.reload, props.level, userType]); //eslint-disable-line

    const onRowClick = (row) => {
        navigate('/sku_data/department/' + row.deptId + '_' + encodeString(row.deptDesc), { state: { searchParams: urlParams, row } });
    };

    //getting all DepartmentList
    const getDeptDataTrigger = async (searchToApply = null) => {
        try {
            setLoading(true);
            const searchValue = searchToApply !== null ? searchToApply : searchText;
            if(level === props.level && index === pageindex && search === searchValue && sort === sortType && userId === props.merchantId && !(reloadData !== props.reload && props.reload)){
                setTableData(deptList);
                setRowCount(count);
            } else {
                let deptData = [];
                if (props.level === 'all' || props.level === 'bulkConfig') {
                    if(userType === 'MERCHANT'){
                        deptData = await getAllAssignDeptData(id, getIndexParam() - 1, 10, searchValue, sortType)
                    } else {
                        deptData = await getAllDeptData(getIndexParam() - 1, 10, searchValue, sortType)
                    }
                    const value = deptData.dataCount
                    onSearchInput(value);

                } else if (props.level === 'sbu') {
                    if(userType === 'MERCHANT'){
                        deptData = await getAssignDeptAtSbuData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        deptData = await getDeptAtSbuData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = deptData.dataCount
                    onSearchInput(value);

                } else if (props.level === 'shop') {
                    if(userType === 'MERCHANT'){
                    deptData = await getAssignDeptAtShopData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        deptData = await getDeptAtShopData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = deptData.dataCount
                    onSearchInput(value);

                } else if (props.level === 'merchant') {
                    deptData = await getAllAssignDeptData(props?.merchantId, getIndexParam() - 1, 10, searchValue, sortType);
                    const value = deptData.dataCount
                    onSearchInput(value);
                }
                dispatch(DepartmentActions.setDepartmentData({ 
                    level: props.level,
                    index: pageindex, 
                    search: searchValue, 
                    sort: sortType,
                    deptData: deptData.data,
                    count: deptData.dataCount
                }));
                setTableData(deptData.data);
                setRowCount(deptData.dataCount);
                setUserId(props.merchantId);
            }
            setReloadData(props.reload);
            setLoading(false);
            props.setReload && props.setReload('department', false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting department Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const checkSearchTextChange = () => {
        const searchParamText = filterParams.get('deptSearch') || '';
        if(searchText !== searchParamText) {
            setSearchText(searchParamText);
            getDeptDataTrigger(searchParamText);
        }
    }

    const onSearchInput = (value) => {
        if (value < (getIndexParam() - 1) * 10) {
            filterParams.set('deptIndex', 1);
            filterParams.set('deptSearch', searchText);
            setFilterParams(filterParams);
        }
    }

    const handlePageChange = (event) => {
        filterParams.set('deptIndex', event);
        setFilterParams(filterParams);
        setPageindex(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleCheckboxSelection = async (checkedValues) => {
        const status = {
            value: checkedValues.target.options[0].value,
            status: checkedValues.target.checked
        }

        props.updateTagValues(status)
    };
    
    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                filterParams.set('deptIndex', 1);
                filterParams.set('deptSearch', event.target.value);
                setFilterParams(filterParams);
                setPageindex(1);
                getDeptDataTrigger();
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="table-container">
            <Input
                className="petco-Data-Search responsive-Search-Bar"
                id="dept-search"
                prefix={<SearchOutlined />}
                onKeyDown={onPressEnterKeyHandler}
                onChange={handleSearchChange}
                value={searchText}
                placeholder={searchText ? searchText : "Search for Department"}
                suffix={<span className='press-enter-style'>Press enter to search</span>}
            />
            {props.level === 'bulkConfig' ? (
                <Spin spinning={loading}>
                    {tableData.map((item) => {
                        return (
                                <li
                                    key={item.deptId}
                                    style={{
                                        color: '#001952',
                                        font: '10px',
                                        marginBottom: '10px'
                                    }}>
                                    {
                                        <Checkbox
                                            key={item.desc}
                                            checked={props.isTagChecked(item.deptDesc, item.deptId,'department')}
                                            options={[{
                                                label: item.deptDesc,
                                                value: {desc: item.deptDesc,
                                                id: item.deptId,
                                                level: 'department'
                                                }
                                            }]}
                                            onChange={handleCheckboxSelection}
                                        >{item.deptDesc}</Checkbox>
                                    }{' '}
                                   <Divider style={{margin: '15px'}} />
                                </li>
                        );
                    })}
                </Spin>
            ) : (<Table
                rowKey="deptId"
                id= "dept-table"
                dataSource={tableData}
                columns={departmentsTableColumns}
                loading={loading}
                pagination={false}
                scroll={{ x: 2000 }}
                size="middle"
                onRow={(row) => {
                    return {
                        onClick: event => {
                            props.level !== 'merchant' && onRowClick(row)
                        }
                    }
                }} 
                className="table-striped-rows clickable-table"
                rowSelection={props.level === 'merchant' ? props?.rowSelection : false}
                onChange={handleTableChange}
            />)}
            {rowCount > 10 && !loading ? <Pagination
                    className="petco-Data-pagination"
                    id="dept-bulk-filter"
                    defaultCurrent={getIndexParam()}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
        </div>
    );
};

export default DepartmentsTable;
