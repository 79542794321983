import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import './PriceConfigForBulk.form.scss';

import {
    Checkbox,
    Col,
    Row,
    Select,
    InputNumber,
    Divider,
    DatePicker,
    Typography,
    Button,
    Input,
    Space,
    Spin
} from 'antd';
import {
    priceRules,
    TAB_NAME,
    GreenBarDisplayState,
    GreenBarDisplayTypes,
    GreenBarDisplayPdpOptions,
    effectiveDataArray,
    priceModifierArray,
    manualPriceTypes,
    skuProperty,
    marginMinLimit,
    marginMaxLimit,
    fixedMinLimit,
    subRdMinLimit,
    subRdMaxLimit,
    subRdDefault
} from '../../constants/common';
import { getEligibleSKUsForMaster } from '../../graphql/queryExecuters';
import { Notification, validateSkuSearch } from '../../utilities';
import { calculateMargin, dateSkipper, getMapPriceByConfiguration, getMsrpPriceByConfiguration } from '../../utilities/helper';
import * as DisplayStrings from '../../constants/displayStrings';

const PriceConfigForBulk = ({ parentName, levelProps, handleOk, handleCancel, setStagedDataSku, editedSkuState }) => {
    const { Text } = Typography;
    const { RangePicker } = DatePicker;

    const [loading, setLoading] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [priceConfigData, setPriceConfigData] = useState(levelProps?.priceConfigData);
    const [isSubRdUpdated, setIsSubRdUpdated] = useState(false);

    const [applyAll, setApplyAll] = useState(false);

    //Helper for Date Pickers
    const [mapCalendarDate, setMapCalendarDate] = useState(null);
    const [msrpCalendarDate, setMsrpCalendarDate] = useState(null);

    // Helpers to check if fields are edited
    const [isMapEdited, setIsMapEdited] = useState(false);
    const [isMsrpEdited, setIsMsrpEdited] = useState(false);

    useEffect(() => {
        // checks if data from ConfigureSkus exists in state, if it does then use that data, if not, use Previous Data.
        let sku_id = levelProps.configId
        
        setPriceConfigData(editedSkuState[sku_id])
        
    }, []); //eslint-disable-line
    

    //SKUs for master sku dropdown (In Family Rule)
    const [filteredSKUs, setFilteredSKUs] = useState([]);

    useEffect(() => {
        if (priceConfigData?.masterSku) {
            setFilteredSKUs([priceConfigData.masterSku]);
        }
        //Set default values here
        if(!priceConfigData?.priceRule){
            setPriceConfigData({
                ...priceConfigData,
                priceRule: 'do_not_match_competitor'
            });
        }
        if(!priceConfigData?.defaultGuardMin){
            setPriceConfigData({
                ...priceConfigData,
                defaultGuardMin : 'MAP'
            });
        }
        if(!priceConfigData?.defaultGuardMax){
            setPriceConfigData({
                ...priceConfigData,
                defaultGuardMax : 'MSRP'
            });
        }
        if(!priceConfigData?.tempSubRdPrice && priceConfigData?.tempSubRdPrice !== 0){
            setPriceConfigData({
                ...priceConfigData,
                tempSubRdPrice : subRdDefault
            });
        }
    }, [priceConfigData]);

    const markdownPriceHandler = (e) => {
        setPriceConfigData({
            ...priceConfigData,
            markdownPriceEnabled: e.target.checked,
            HasSignal: true
        });
        setStagedDataSku(priceConfigData, skuProperty.markdownPriceEnabled, e.target.checked)
    };

    const basePriceHandler = (e) => {
        setPriceConfigData({
            ...priceConfigData,
            basePriceEnabled: e.target.checked,
            HasSignal: true
        });
        setStagedDataSku(priceConfigData, skuProperty.basePriceEnabled, e.target.checked)
    };

    const handlePriceRuleChange = (value) => {

        setPriceConfigData({
            ...priceConfigData,
            priceRule: value,
            HasSignal: true
        });
        setStagedDataSku(priceConfigData, skuProperty.priceRule, value)
        if(value !== 'family_rule' && value !== 'manual_price' && value!=='manual_price_with_market_tracking'){
            setPriceConfigData({
                ...priceConfigData,
                priceRule: value,
                masterSku: "",
                familyPriceModifier: "",
                familyPrice: null,
                familyPriceSuffix: "",
                manualPrice: null,
                manualPriceSuffix: "",
                manualPriceReason: "",
                HasSignal: true
            });
        }
        else{
            setPriceConfigData({
                ...priceConfigData,
                priceRule: value,
                HasSignal: true
            });
        }

        //Resetting filtered SKUs if price rule changed
        if (value === 'family_rule') {
            setFilteredSKUs([]);
        }
    };

    const handleConfigChange = async (key, value) => {
        // this function sets the data in the price config and then in configure sku's so it persists.
        setPriceConfigData({
            ...priceConfigData,
            [key] : value,
            HasSignal: true
        })
        setStagedDataSku(priceConfigData, key, value)
    }

    const onChangeValueHandler = (key, value) => {
        setPriceConfigData((prevState) => {
            const state = { ...prevState };
            state[key] = value;
            if (key === 'greenBarStatus') {
                state.greenBarPdp = null;
                state.greenBarDisplayType = null;
                if (value === 'see_price_in_cart') {
                    state.greenBarDisplayType = 'see_price_in_cart';
                }
            }
            return state;
        });
    };

    const validateData = () => {
        setSubmitClicked(true);
        //Validations for Price Rule
        if (
            !priceConfigData.priceRule ||
            priceConfigData.priceRule === null ||
            priceConfigData.priceRule === ''
        ) {
            Notification('error', 'Please enter the Price Rule');
            return false;
        }

        //Validations for manual Price
        if (
            !priceConfigData.priceRule ||
            ((priceConfigData?.priceRule === 'manual_price' ||
                priceConfigData?.priceRule === 'manual_price_with_market_tracking') &&
                (!priceConfigData?.manualPrice || !priceConfigData?.manualPriceReason))
        ) {
            Notification('error', 'Please enter the Price Rule required fields');
            return false;
        }

        //Validations for Family Rule
        if (priceConfigData?.priceRule === 'family_rule') {
            if (priceConfigData?.masterSku === priceConfigData?.skuId) {
                Notification('error', 'A SKU cannot be its own Master SKU');
                return false;
            }
            if (
                priceConfigData?.masterSku === '' ||
                priceConfigData?.masterSku === null ||
                !priceConfigData?.masterSku ||
                priceConfigData?.masterSku === priceConfigData?.skuId ||
                priceConfigData?.familyPriceModifier === '' ||
                priceConfigData?.familyPriceModifier === null ||
                !priceConfigData?.familyPriceModifier ||
                priceConfigData?.familyPrice === '' ||
                priceConfigData?.familyPrice === null ||
                !priceConfigData?.familyPrice ||
                priceConfigData?.familyPriceSuffix === '' ||
                priceConfigData?.familyPriceSuffix === null ||
                !priceConfigData?.familyPriceSuffix
            ) {
                Notification('error', 'Please enter the required highlighted fields');
                return false;
            }
        }

        //Validations for guardrail values
        if (!priceConfigData.defaultGuardMax || !priceConfigData.defaultGuardMin) {
            Notification('error', DisplayStrings.required_error);
            return false;
        }

        if (parentName === 'SKU') {
            if (
                (priceConfigData.defaultGuardMax === 'Margin' ||
                    priceConfigData.defaultGuardMax === 'Fixed') &&
                priceConfigData.defaultGuardMaxPrice === null
            ) {
                Notification('error', 'Default Guardrail Max Price should be valid');
                return false;
            }

            if (
                (priceConfigData.defaultGuardMin === 'Margin' ||
                    priceConfigData.defaultGuardMin === 'Fixed') &&
                priceConfigData.defaultGuardMinPrice === null
            ) {
                Notification('error', 'Default Guardrail Min Price should be valid');
                return false;
            }
        } else {
            if (
                priceConfigData.defaultGuardMax === 'Margin' &&
                priceConfigData.defaultGuardMaxPrice === null
            ) {
                Notification('error', 'Default Guardrail Max Price should be valid');
                return false;
            }

            if (
                priceConfigData.defaultGuardMin === 'Margin' &&
                priceConfigData.defaultGuardMinPrice === null
            ) {
                Notification('error', 'Default Guardrail Min Price should be valid');
                return false;
            }
        }

        if (
            priceConfigData.defaultGuardMax === 'Margin' &&
            (priceConfigData.defaultGuardMaxPrice < marginMinLimit ||
                priceConfigData.defaultGuardMaxPrice >= marginMaxLimit)
        ) {
            Notification(
                'error',
                'Default Guardrail Max Margin should be greater than or equal to 0'
            );
            return false;
        }

        if (
            priceConfigData.defaultGuardMin === 'Margin' &&
            (priceConfigData.defaultGuardMinPrice < marginMinLimit ||
                priceConfigData.defaultGuardMinPrice >= marginMaxLimit )
        ) {
            Notification('error', 'Default Guardrail Min Margin should be less than 100');
            return false;
        }

        //Validaton for min and max price
        if (parentName === TAB_NAME.SKU) {
            let minPrice = priceConfigData.defaultGuardMinPrice;
            //Set min price
            if (priceConfigData.defaultGuardMin === 'Margin') {
                if (priceConfigData.costPrice) {
                    minPrice =
                        priceConfigData.costPrice /
                        (1 - 0.01 * priceConfigData.defaultGuardMinPrice);
                } else {
                    Notification('error', DisplayStrings.unit_cost_error);
                    return false;
                }
            }
            if (priceConfigData.defaultGuardMin === 'MAP') {
                minPrice = getMapPriceByConfiguration(priceConfigData);
                if(!minPrice) {
                    Notification('error' , DisplayStrings.map_error);
                    return false;
                }
            }
            if (priceConfigData.defaultGuardMin === 'MSRP') {
                minPrice = getMsrpPriceByConfiguration(priceConfigData);
                if(!minPrice) {
                    Notification('error' , DisplayStrings.msrp_error);
                    return false;
                }
            }
            //Set max price
            let maxPrice = priceConfigData.defaultGuardMaxPrice;
            if (priceConfigData.defaultGuardMax === 'Margin') {
                if (priceConfigData.costPrice) {
                    maxPrice =
                        priceConfigData.costPrice /
                        (1 - 0.01 * priceConfigData.defaultGuardMaxPrice);
                } else {
                    Notification('error', DisplayStrings.unit_cost_error);
                    return false;
                }
            }
            if (priceConfigData.defaultGuardMax === 'MAP') {
                maxPrice = getMapPriceByConfiguration(priceConfigData);
                if(!maxPrice) {
                    Notification('error' , DisplayStrings.map_error);
                    return false;
                }
            }
            if (priceConfigData.defaultGuardMax === 'MSRP') {
                maxPrice = getMsrpPriceByConfiguration(priceConfigData);
                if(!maxPrice) {
                    Notification('error' , DisplayStrings.msrp_error);
                    return false;
                }
            }

            if (minPrice > maxPrice) {
                Notification('error', 'Default Guardrail Min price is greater than max price');
                return false;
            }
        } else {
            if(priceConfigData.defaultGuardMax === 'Margin' && priceConfigData.defaultGuardMin === 'Margin'){
                let minPrice = priceConfigData.defaultGuardMinPrice;
                let maxPrice = priceConfigData.defaultGuardMaxPrice;
                if(minPrice > maxPrice){
                    Notification('error' , 'Default Guardrail Min price is greater than max price');
                    return false;
                }
            }
        }

        //Validation for guardrail prices
        if (parentName === 'SKU') {
            if (
                priceConfigData.tempMapPrice &&
                priceConfigData.tempMapPrice !== priceConfigData.mapPrice
            ) {
                if (!(priceConfigData.mapStartDate && priceConfigData.mapEndDate)) {
                    Notification('error', 'Please Fill The Interval For MAP');
                    return false;
                }
            }
            if (
                priceConfigData.tempMsrpPrice &&
                priceConfigData.tempMsrpPrice !== priceConfigData.listPrice
            ) {
                if (!(priceConfigData.msrpStartDate && priceConfigData.msrpEndDate)) {
                    Notification('error', 'Please Fill The Interval For MSRP');
                    return false;
                }
            }
            if (priceConfigData.tempSubRdPrice) {
                if(priceConfigData.tempSubRdPrice < subRdMinLimit){
                    Notification('error', DisplayStrings.rd_error);
                    return false;
                }
                if(priceConfigData.tempSubRdPrice > subRdMaxLimit){
                    Notification('error', DisplayStrings.rd_error_max);
                    return false;
                }
                if(priceConfigData.tempSubRdPrice !== subRdDefault && !(priceConfigData.subRdStartDate)) {
                    Notification('error', 'Please Fill the Date For Subsequent RD Price');
                    return false;
                }
            }else {
                Notification('error', DisplayStrings.rd_error_no_data);
                return false;
            }
        }
        return true;

        // if (priceConfigData?.greenBarStatus !== 'off' &&
        //     (!priceConfigData?.greenBarPdp || !priceConfigData?.greenBarDisplayType)) {
        //     Notification('error', 'Please enter the required fields: Green bar settings');
        //     return false;
        // }
    };
    
    const updateConfig = async (priceConfigData) => {
        setLoading(true);
        const data = {
                skuId: priceConfigData.skuId,
                skuDesc: priceConfigData.skuDesc,
                markdownPriceEnabled: priceConfigData.markdownPriceEnabled,
                basePriceEnabled: priceConfigData.basePriceEnabled,
                defaultGuardMin: priceConfigData.defaultGuardMin,
                defaultGuardMinPrice:  priceConfigData.defaultGuardMin === 'MAP' ? getMapPriceByConfiguration(priceConfigData) : 
                priceConfigData.defaultGuardMin === 'MSRP' ? getMsrpPriceByConfiguration(priceConfigData) : 
                priceConfigData.defaultGuardMinPrice,
                defaultGuardMinPriceSuffix: priceConfigData.defaultGuardMinPriceSuffix,
                defaultGuardMax: priceConfigData.defaultGuardMax,
                defaultGuardMaxPrice: priceConfigData.defaultGuardMax === 'MAP' ? getMapPriceByConfiguration(priceConfigData) : 
                priceConfigData.defaultGuardMax === 'MSRP' ? getMsrpPriceByConfiguration(priceConfigData) : 
                priceConfigData.defaultGuardMaxPrice,
                defaultGuardMaxPriceSuffix: priceConfigData.defaultGuardMaxPriceSuffix,
                priceRule: priceConfigData.priceRule,
                isMaster: priceConfigData.isMaster,
                masterSku: priceConfigData.masterSku,
                familyPriceModifier: priceConfigData.familyPriceModifier,
                familyPrice: priceConfigData.familyPrice,
                familyPriceSuffix: priceConfigData.familyPriceSuffix,
                manualPrice: priceConfigData.manualPrice,
                manualPriceSuffix: priceConfigData.manualPriceSuffix,
                manualPriceReason: priceConfigData.manualPriceReason,
                tempMapPrice: priceConfigData.tempMapPrice? priceConfigData.tempMapPrice : priceConfigData.mapPrice,
                mapStartDate: priceConfigData.mapStartDate ? dayjs(priceConfigData.mapStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss'): null,
                mapEndDate: priceConfigData.mapEndDate ? dayjs(priceConfigData.mapEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss'): null,
                tempMsrpPrice: priceConfigData.tempMsrpPrice? priceConfigData.tempMsrpPrice : priceConfigData.listPrice,
                msrpStartDate: priceConfigData.msrpStartDate ? dayjs(priceConfigData.msrpStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss'): null,
                msrpEndDate: priceConfigData.msrpEndDate ? dayjs(priceConfigData.msrpEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss'): null,
                subRdPrice: isSubRdUpdated ? priceConfigData.tempSubRdPrice : priceConfigData.subRdPrice,
                subRdStartDate: priceConfigData.subRdStartDate ? dayjs(priceConfigData.subRdStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss'): null,
                subRdEndDate: priceConfigData.subRdEndDate ? dayjs(priceConfigData.subRdEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss'): null,
                greenBarStatus: "off",
                greenBarPdp: "",
                greenBarDisplayType: "",
                HasSignal: priceConfigData.HasSignal ? priceConfigData.HasSignal : false,
                lastRepricePrice: priceConfigData?.lastRepricePrice || null,
                listPrice: priceConfigData.listPrice || null,
                tempGuardMax: priceConfigData.tempGuardMax || '',
                tempGuardMaxPrice: priceConfigData.tempGuardMaxPrice || null,
                tempGuardMaxPriceSuffix: priceConfigData.tempGuardMaxPriceSuffix || '$',
                tempGuardMin: priceConfigData.tempGuardMin || '',
                tempGuardMinPrice: priceConfigData.tempGuardMinPrice || null,
                tempGuardMinPriceSuffix: priceConfigData.tempGuardMinPriceSuffix || '$',
                startTempGuardDate: priceConfigData.startTempGuardDate || '',
                endTempGuardDate: priceConfigData.endTempGuardDate || '',
                mapPrice: priceConfigData.mapPrice || null,
                costPrice: priceConfigData.costPrice || null
        }
        levelProps.callbackForEditedData(data);
        setLoading(true);
    };

    const onSearchMasterSKUHandler = async (searchText) => {
        if (validateSkuSearch(searchText)) {
            setLoading(true)
            const skuList = await getEligibleSKUsForMaster(searchText);
            const filteredSkuList = skuList.filter(sku => sku !== priceConfigData.skuId)
            setFilteredSKUs(filteredSkuList);
            setLoading(false)
        }
    };

    //Helper for setting state
    const onOpenChangeHandler = (field, open) => {
        if (open) {
            if (field === 'map') setMapCalendarDate([null, null])
            else if (field === 'msrp') setMsrpCalendarDate([null, null])
        } else {
            if (field === 'map') setMapCalendarDate(null)
            else if (field === 'msrp') setMsrpCalendarDate(null)
        }
    }

    return (
        <Spin spinning={loading}>
            <div className="priceConfigure">
                <Row className="row-1">
                    <Col span={12}>
                        <h3 className="eads-model-heading">EADS Model</h3>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Checkbox
                            className="markdownCheckbox"
                            onChange={(e) => {
                                markdownPriceHandler(e);
                            }}
                            checked={
                                parentName === TAB_NAME.SKU
                                    ? priceConfigData?.markdownPriceEnabled
                                    : (priceConfigData?.markdownPriceEnabled ||
                                      priceConfigData?.markdownPrice)
                            }>
                            Markdown Price
                        </Checkbox>
                    </Col>
                    <Col span={12}>
                        <Checkbox
                            checked={
                                parentName === TAB_NAME.SKU
                                    ? priceConfigData?.basePriceEnabled
                                    : (priceConfigData?.basePriceEnabled ||
                                      priceConfigData?.basePrice)
                            }
                            className="basePriceCheckbox"
                            onChange={(e) => {
                                basePriceHandler(e);
                            }}>
                            Base Price
                        </Checkbox>
                    </Col>
                </Row>
                <Divider />
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col span={7}>
                        <h3 className="price-rule-heading">Price Rule</h3>
                        <div
                            style={{
                                marginTop:
                                    priceConfigData.priceRule === 'family_rule' ? '8px' : '8px'
                            }}>
                            <label>Price Rule</label>
                            <br />
                            <Select
                                className="priceRuleOptions"
                                status={
                                    submitClicked &&
                                    (priceConfigData?.priceRule === null ||
                                        priceConfigData?.priceRule === '')
                                        ? 'error'
                                        : ''
                                }
                                placeholder="Select"
                                // defaultValue={priceConfigData?.priceRule}
                                onChange={(value) => {
                                    handlePriceRuleChange(value);
                                }}
                                style={{
                                    width: '100%'
                                }}
                                options={priceRules.map((rule) => ({
                                    key: rule.key,
                                    label: rule.label,
                                    value: rule.key,
                                    disabled:
                                        (priceConfigData?.isMaster && rule.key === 'family_rule') ||
                                        (parentName !== TAB_NAME.SKU &&
                                            (rule.key === 'manual_price' ||
                                                rule.key === 'manual_price_with_market_tracking' ||
                                                rule.key === 'family_rule'))
                                }))}
                                value={priceConfigData?.priceRule}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            />
                        </div>
                    </Col>
                    {parentName === TAB_NAME.SKU &&
                        priceConfigData?.priceRule === 'family_rule' && (
                            <Col span={4}>
                                <br />
                                <label>Master SKU</label>
                                <Select
                                    showSearch
                                    showArrow={false}
                                    style={{ width: '100%' }}
                                    value={priceConfigData?.masterSku}
                                    onChange={(value) => handleConfigChange(skuProperty.masterSku,value)}
                                    notFoundContent={<Spin spinning={loading}/>}
                                    onSearch={onSearchMasterSKUHandler}
                                    status={
                                        submitClicked &&
                                        (priceConfigData?.masterSku === null ||
                                            !priceConfigData?.masterSku ||
                                            priceConfigData?.masterSku === priceConfigData?.skuId)
                                            ? 'error'
                                            : ''
                                    }>
                                    {filteredSKUs.map((sku) => (
                                        <Select.Option key={sku} value={sku}>
                                            {sku}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        priceConfigData?.priceRule === 'family_rule' && (
                            <Col span={4}>
                                <br />
                                <label>Price Modifier</label>
                                <Select
                                    value={priceConfigData?.familyPriceModifier}
                                    onChange={(value) => handleConfigChange(skuProperty.familyPriceModifier, value)}
                                    style={{
                                        width: '100%'
                                    }}
                                    options={priceModifierArray}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    status={
                                        submitClicked &&
                                        (!priceConfigData?.familyPriceModifier ||
                                            priceConfigData?.familyPriceModifier === null)
                                            ? 'error'
                                            : ''
                                    }
                                />
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        priceConfigData?.priceRule === 'family_rule' && (
                            <Col span={4}>
                                <br />
                                <label>Price</label>
                                <InputNumber
                                    min={0}
                                    className='bulkConfig-inputs'
                                    style={{ width: '100%' }}
                                    value={priceConfigData?.familyPrice}
                                    onChange={(value) => handleConfigChange(skuProperty.familyPrice, value)}
                                    status={
                                        submitClicked && !priceConfigData?.familyPrice
                                            ? 'error'
                                            : ''
                                    }
                                />
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        priceConfigData?.priceRule === 'family_rule' && (
                            <Col span={2}>
                                <br />
                                <label>Unit</label>
                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    options={[
                                        {
                                            key: '$',
                                            label: '$',
                                            value: '$'
                                        },
                                        {
                                            key: '%',
                                            label: '%',
                                            value: '%'
                                        }
                                    ]}
                                    onChange={(value) => handleConfigChange(skuProperty.familyPriceSuffix, value)}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    defaultValue={priceConfigData?.familyPriceSuffix}
                                    status={
                                        submitClicked && !priceConfigData?.familyPriceSuffix
                                            ? 'error'
                                            : ''
                                    }
                                />
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        (priceConfigData?.priceRule === 'manual_price' ||
                            priceConfigData?.priceRule === 'manual_price_with_market_tracking') && (
                            <Col span={8}>
                                <br />
                                <label>Manual Price</label>
                                <InputNumber
                                    min={0}
                                    prefix="$"
                                    className='bulkConfig-inputs'
                                    style={{ width: '100%' }}
                                    value={priceConfigData?.manualPrice}
                                    onChange={(value) =>  handleConfigChange(skuProperty.manualPrice, value)}
                                    status={
                                        submitClicked && !priceConfigData?.manualPrice
                                            ? 'error'
                                            : ''
                                    }
                                />
                            </Col>
                        )}
                    {parentName === TAB_NAME.SKU &&
                        (priceConfigData?.priceRule === 'manual_price' ||
                            priceConfigData?.priceRule === 'manual_price_with_market_tracking') && (
                            <Col span={8}>
                                <br />
                                <label>Type</label>
                                <Select
                                    style={{
                                        width: '100%'
                                    }}
                                    value={priceConfigData?.manualPriceReason}
                                    onChange={(value) => handleConfigChange(skuProperty.manualPriceReason, value)}
                                    options={manualPriceTypes}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    status={
                                        submitClicked && !priceConfigData?.manualPriceReason
                                            ? 'error'
                                            : ''
                                    }
                                />
                            </Col>
                        )}
                </Row>
                {parentName === TAB_NAME.SKU && (
                    <>
                        <Divider className="custom-divider" />
                        <br />
                        <Row className="row-2">
                            <Col span={12}>
                                <Row className="row-MAPhOLIDAY">
                                    <Col span={9}>
                                        <label>MAP</label>
                                        <InputNumber
                                            min={0}
                                            prefix="$"
                                            style={{ width: '90%' }}
                                            value={
                                                priceConfigData?.tempMapPrice || (!isMapEdited ? getMapPriceByConfiguration(priceConfigData) : null )
                                            }
                                            onChange={(value) => {
                                                setIsMapEdited(true);
                                                handleConfigChange(skuProperty.tempMapPrice, value);
                                            }}
                                        />
                                    </Col>
                                    <Col span={15}>
                                        <label>Schedule MAP</label>
                                        <RangePicker
                                            placement="topLeft"
                                            onChange={(value, dateString) => {
                                                setPriceConfigData({
                                                    ...priceConfigData,
                                                    mapStartDate: dateString[0],
                                                    mapEndDate: dateString[1]
                                                })
                                            }}
                                            onCalendarChange={e=> setMapCalendarDate(e)}
                                            onOpenChange={e=> onOpenChangeHandler('map', e)}
                                            value={mapCalendarDate || [
                                                priceConfigData.mapStartDate ? dayjs(priceConfigData.mapStartDate) : null,
                                                priceConfigData.mapEndDate ? dayjs(priceConfigData.mapEndDate) : null,
                                            ]}
                                            disabledDate={current => dateSkipper(current, 14, mapCalendarDate)}
                                        />
                                    </Col>
                                </Row>
                                {priceConfigData.mapStartDate && priceConfigData.mapEndDate && (<Row>
                                <button
                                    className="cancel-schedule-button"
                                    onClick={() => {
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            tempMapPrice: null,
                                            mapStartDate: null,
                                            mapEndDate: null
                                        })
                                    }}
                                >
                                    Cancel Schedule
                                </button>
                                </Row>)}
                            </Col>

                            {/* vertical divider */}
                            <Col span={1}>
                                {' '}
                                <Divider
                                    type="vertical"
                                    style={{
                                        height: '95%'
                                    }}
                                />
                            </Col>

                            <Col span={11}>
                                <Row className="row-msrp">
                                    <Col span={9}>
                                        <label>MSRP</label>
                                        <InputNumber
                                            min={0}
                                            prefix="$"
                                            style={{ width: '90%' }}
                                            value={
                                                priceConfigData?.tempMsrpPrice || (!isMsrpEdited ? getMsrpPriceByConfiguration(priceConfigData) : null)
                                            }
                                            onChange={(value, dateString) => {
                                                setIsMsrpEdited(true);
                                                handleConfigChange(skuProperty.tempMsrpPrice,value);
                                                
                                            }}                                    
                                        />
                                    </Col>
                                    <Col span={15}>
                                        <label>Schedule MSRP</label>
                                        <RangePicker
                                            placement="topLeft"
                                            onCalendarChange={e=> setMsrpCalendarDate(e)}
                                            onOpenChange={e=> onOpenChangeHandler('msrp', e)}
                                            onChange={(value, dateString) => {
                                                setPriceConfigData({
                                                    ...priceConfigData,
                                                    msrpStartDate: dateString[0],
                                                    msrpEndDate: dateString[1]
                                                })
                                            }}
                                            value={msrpCalendarDate || [
                                                priceConfigData.msrpStartDate ? dayjs(priceConfigData.msrpStartDate) : null,
                                                priceConfigData.msrpEndDate ? dayjs(priceConfigData.msrpEndDate) : null,
                                            ]}
                                            disabledDate={current => dateSkipper(current, 14, msrpCalendarDate)}
                                        />
                                    </Col>
                                </Row>
                                {priceConfigData.msrpStartDate && priceConfigData.msrpEndDate && (<Row>
                                <button
                                    className="cancel-schedule-button"
                                    onClick={() => {
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            tempMsrpPrice: null,
                                            msrpStartDate: null,
                                            msrpEndDate: null
                                        })
                                    }}
                                >
                                    Cancel Schedule
                                </button>
                                </Row>)}
                            </Col>
                        </Row>
                        <br/>
                     <Row className="row-map">
                        <Col span={12}>
                            <Row className="row-MAPhOLIDAY">
                                    <Col span={9}>
                                        <label>Subsequent RD Price</label><br/>
                                        <InputNumber
                                            prefix="%"
                                            min={subRdMinLimit}
                                            max={subRdMaxLimit}
                                            style={{ width: '90%' }}
                                            defaultValue={priceConfigData?.subRdPrice}
                                            onChange={(value) => {
                                                setIsSubRdUpdated(true)
                                                handleConfigChange(skuProperty.tempSubRdPrice,value);
                                            }}
                                        />
                                        {
                                        priceConfigData.lastRepricePrice ?
                                        (parseFloat(priceConfigData.tempSubRdPrice) <= subRdMaxLimit && 
                                        parseFloat(priceConfigData.tempSubRdPrice) >= subRdMinLimit) ?
                                        <div>Projected RD price: 
                                            <b>${(priceConfigData.lastRepricePrice - ((priceConfigData.tempSubRdPrice/100) * priceConfigData.lastRepricePrice)).toFixed(2)}</b>
                                        </div> :
                                        <div style={{color: 'red'}}>{'Enter valid percentage'} </div>:
                                        <>{typeof priceConfigData.tempSubRdPrice === 'number' ? <div className='error-mssge' style={{color: 'red'}}>{'No recommended price'}</div> : <></>}</>
                                        }
                                    </Col>
                                    <Col span={15} >
                                        <label>Schedule Subsequent RD Price</label>
                                        <br/>
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            placement="topLeft"
                                            className='rd-date-picker'
                                            onChange={(value, dateString) =>{
                                                handleConfigChange(skuProperty.subRdStartDate,dateString)

                                            }
                                            }
                                            value={priceConfigData.subRdStartDate ? dayjs(priceConfigData.subRdStartDate) : null}
                                            disabledDate={dateSkipper}
                                        />
                                        {priceConfigData.subRdStartDate && (
                            <Row>
                                <button
                                    className="cancel-schedule-button-1"
                                    onClick={() => {
                                        setPriceConfigData({
                                            ...priceConfigData,
                                            tempSubRdPrice: null,
                                            subRdStartDate: null,
                                        })
                                    }}
                                >
                                    Cancel Schedule
                                </button>
                            </Row>
                            )}
                                    </Col>
                             </Row>
                                
                        </Col>
                    </Row>
                    </>
                )}
                <Divider className="custom-divider" />
                <Row className="row-guardRail">
                    <h3 className="default-guardrail">Default Guardrail</h3>
                </Row>
                <Row className="row-3">
                    <Col span={6}>
                        <label>Default Min</label>
                        <br />
                        <Select
                            defaultValue='MAP'
                            status={
                                submitClicked && priceConfigData?.defaultGuardMin === null
                                    ? 'error'
                                    : ''
                            }
                            value={priceConfigData?.defaultGuardMin}
                            onChange={(value) => {
                                setPriceConfigData({
                                    ...priceConfigData,
                                    defaultGuardMin: value,
                                    defaultGuardMinPriceSuffix: value === 'Margin' ? '%' : '$',
                                    defaultGuardMinPrice: (value !== 'MAP' || value !== 'MSRP') ? null : priceConfigData.defaultGuardMinPrice
                                })
                            }}
                            style={{ width: '100%' }}
                            options={effectiveDataArray.map((data) => ({
                                key: data,
                                label: data,
                                value: data
                            }))}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                        />
                    </Col>
                    <Col span={6}>
                        <label>Value</label>
                        <br />
                        <InputNumber
                            max={priceConfigData?.defaultGuardMin === 'Margin' ? marginMaxLimit : null}
                            min={
                                priceConfigData?.defaultGuardMin === 'Margin' ? marginMinLimit :
                                (parentName === TAB_NAME.SKU && priceConfigData?.defaultGuardMin === 'Fixed') ? fixedMinLimit : null}
                            step={0.1}
                            prefix={priceConfigData?.defaultGuardMin === 'Margin'? '%': '$'}
                            style={{ width: '100%' }}
                            disabled={
                                (parentName !== TAB_NAME.SKU && priceConfigData?.defaultGuardMin !== 'Margin') ||
                                (parentName === TAB_NAME.SKU && (priceConfigData?.defaultGuardMin === 'MSRP' || priceConfigData?.defaultGuardMin === 'MAP'))
                            }
                            value={
                                priceConfigData?.defaultGuardMin === 'MSRP' ? getMsrpPriceByConfiguration(priceConfigData) : 
                                priceConfigData?.defaultGuardMin === 'MAP' ? getMapPriceByConfiguration(priceConfigData) : 
                                (priceConfigData?.defaultGuardMinPrice !== null) ? priceConfigData?.defaultGuardMinPrice: ''
                            }
                            onChange={(value) => {
                                handleConfigChange(skuProperty.defaultGuardMinPrice, value)
                            }}
                            // onChange={(value) =>
                            //     setPriceConfigData({
                            //         ...priceConfigData,
                            //         defaultGuardMinPrice: value
                            //     })
                            // }
                        />
                        {(parentName === TAB_NAME.SKU && priceConfigData?.defaultGuardMin === 'Margin') ? 
                        priceConfigData.costPrice ? 
                        (parseFloat(priceConfigData.defaultGuardMinPrice) <= marginMaxLimit 
                        && parseFloat(priceConfigData.defaultGuardMinPrice) >= marginMinLimit) ?
                        <div>Projected min price: <b>${calculateMargin(priceConfigData.costPrice, priceConfigData.defaultGuardMinPrice)}</b></div> : 
                        <div style={{color: 'red'}}>{'Enter valid percentage'}</div> :
                        <div style={{color: 'red'}}>{DisplayStrings.unit_cost_error}</div> : null}
                    </Col>
                    <br />
                    <Col span={6}>
                        <label>Default Max</label>
                        <br />
                        <Select
                            // defaultValue={priceConfigData?.guardrailMaxValueType}
                            status={
                                submitClicked && priceConfigData.defaultGuardMax === null
                                    ? 'error'
                                    : ''
                            }
                            value={priceConfigData?.defaultGuardMax}
                            onChange={(value) => {
                                setPriceConfigData({
                                    ...priceConfigData,
                                    defaultGuardMax: value,
                                    defaultGuardMaxPriceSuffix: value === 'Margin' ? '%' : '$',
                                    defaultGuardMaxPrice: (value !== 'MAP' || value !== 'MSRP') ? null : priceConfigData.defaultGuardMaxPrice
                                })
                            }}
                            style={{ width: '100%' }}
                            options={effectiveDataArray.map((data) => ({
                                key: data,
                                label: data,
                                value: data
                            }))}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                0
                            }
                        />
                    </Col>
                    <Col span={5}>
                        <label>Value</label>
                        <br />
                        <InputNumber
                            max={priceConfigData?.defaultGuardMax === 'Margin' ? marginMaxLimit : null}
                            min={
                                priceConfigData?.defaultGuardMax === 'Margin' ? marginMinLimit :
                                (parentName === TAB_NAME.SKU && priceConfigData?.defaultGuardMax === 'Fixed') ?
                                fixedMinLimit : null
                            }
                            step={0.1}
                            prefix={priceConfigData?.defaultGuardMax === 'Margin' ? '%' : '$'}
                            style={{ width: '90%' }}
                            disabled={
                                parentName !== TAB_NAME.SKU ||
                                (parentName === TAB_NAME.SKU &&
                                    (priceConfigData?.defaultGuardMax === 'MSRP' ||
                                        priceConfigData?.defaultGuardMax === 'MAP'))
                            }
                            value={
                                priceConfigData?.defaultGuardMax === 'MSRP'
                                    ? getMsrpPriceByConfiguration(priceConfigData)
                                    : priceConfigData?.defaultGuardMax === 'MAP'
                                    ? getMapPriceByConfiguration(priceConfigData)
                                    : priceConfigData?.defaultGuardMaxPrice !== null &&
                                      priceConfigData?.defaultGuardMaxPrice !== -1
                                    ? priceConfigData?.defaultGuardMaxPrice
                                    : ''
                            }
                            
                            onChange={(value) => {
                                handleConfigChange(skuProperty.defaultGuardMaxPrice, value)
                            }}
                        />
                        {(parentName === TAB_NAME.SKU && priceConfigData?.defaultGuardMax === 'Margin') ? 
                        priceConfigData.costPrice ? 
                        (parseFloat(priceConfigData.defaultGuardMaxPrice) >= marginMinLimit 
                        && parseFloat(priceConfigData.defaultGuardMaxPrice) <= marginMaxLimit) ?
                        <div>Projected max price: <b>${calculateMargin(priceConfigData.costPrice, priceConfigData.defaultGuardMaxPrice)}</b></div> : 
                        <div style={{color: 'red'}}>{'Enter valid percentage '}{priceConfigData.defaultGuardMaxPrice}</div> :
                        <div style={{color: 'red'}}>{DisplayStrings.unit_cost_error}</div> : null}
                    </Col>
                    <br />
                </Row>
                <br />
                {parentName === TAB_NAME.SKU && (
                    <>
                        <Divider className="custom-divider" />
                        <Row className="row-guardRail">
                            <h3 className="default-guardrail">Green Bar Display</h3>
                        </Row>
                        <Row className="row-3">
                            <Col span={11}>
                                <Row className="row-guardRail">
                                    <Row className="row-minValue">
                                        <Col span={15}>
                                            <label>Green Bar Display State</label>
                                            <Select
                                                disabled={true}
                                                value={priceConfigData?.greenBarStatus || 'off'}
                                                onChange={(e) => {
                                                    onChangeValueHandler(skuProperty.greenBarStatus, e);
                                                }}
                                                style={{
                                                    minWidth: '160px',
                                                    width: '90%'
                                                }}>
                                                {GreenBarDisplayState.map((state) => (
                                                    <Select.Option
                                                        key={state.value}
                                                        value={state.value}>
                                                        {state.title}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        {false && priceConfigData?.greenBarStatus !== 'off' && (
                                            <Col span={9}>
                                                <label>PDP</label>
                                                <Select
                                                    value={priceConfigData?.greenBarPdp}
                                                    onChange={(e) => {
                                                        onChangeValueHandler(skuProperty.greenBarPdp, e);
                                                    }}
                                                    style={{
                                                        width: '90%'
                                                    }}>
                                                    {GreenBarDisplayPdpOptions.map((option) => (
                                                        <Select.Option
                                                            key={option.value}
                                                            value={option.value}>
                                                            {option.title}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        )}
                                    </Row>
                                    <br />
                                </Row>
                            </Col>
                            <Col span={11}>
                                <Row className="row-guardRail">
                                    {false && priceConfigData?.greenBarStatus !== 'off' && (
                                        <Row className="row-minValue">
                                            <Col span={12}>
                                                <label>In-Cart</label>
                                                <InputNumber
                                                    prefix="$"
                                                    style={{ width: '90%' }}
                                                    disabled
                                                    value={priceConfigData?.competitorMinValue}
                                                />
                                            </Col>
                                            <Col span={12}>
                                                <label>Display Type</label>
                                                {priceConfigData?.greenBarStatus ===
                                                    'see_price_in_cart' && (
                                                    <Input
                                                        className="input-global"
                                                        style={{ width: '90%' }}
                                                        disabled
                                                        value={'See Price In Cart'}
                                                    />
                                                )}
                                                {priceConfigData?.greenBarStatus ===
                                                    'automated_promotion' && (
                                                    <Select
                                                        value={priceConfigData?.greenBarDisplayType}
                                                        onChange={(e) => {
                                                            onChangeValueHandler(
                                                                skuProperty.greenBarDisplayType,
                                                                e
                                                            );
                                                        }}
                                                        style={{
                                                            width: '90%'
                                                        }}>
                                                        {GreenBarDisplayTypes[1].value.map(
                                                            (option) => (
                                                                <Select.Option
                                                                    key={option.value}
                                                                    value={option.value}>
                                                                    {option.title}
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    <br />
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
                {parentName !== TAB_NAME.SKU && (
                    <div className="scrapers-apply-config-checkbox">
                        <Text>
                            <Checkbox
                                onChange={(e) => setApplyAll(e.target.checked)}
                                checked={applyAll}>
                                {' '}
                                Apply configuration changes to individual configured SKUs
                            </Checkbox>
                        </Text>
                    </div>
                )}
                <div className="config-model-footer">
                    <Space>
                        <Button
                            className="discardBtn"
                            key="discard"
                            onClick={() => {
                                setSubmitClicked(false);
                                handleCancel(priceConfigData.skuId);
                                setApplyAll(false);
                            }}>
                            Discard Change
                        </Button>
                        <Button
                            className="updateConfigBtn"
                            // loading={loading}
                            key="submit"
                            type="primary"
                            onClick={() => {
                                const valid = validateData();
                                if (valid) {
                                    setSubmitClicked(false);
                                    updateConfig(priceConfigData);
                                    handleCancel(priceConfigData.skuId);
                                }
                            }}>
                            Update Configuration
                        </Button>
                    </Space>
                </div>
            </div>
        </Spin>
    );
};

export default PriceConfigForBulk;
