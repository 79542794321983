import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllManufData = async (index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllManufDataQuery(index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllManufacturers;
    } catch (err) {
        console.log(err);
    }
};
export const getManufDetails = async (manufId, manufDesc) => {
    try {
        const query = QueryCreators.createGetManufDataQuery(manufId, manufDesc);
        const response = await GraphQL.request(query);
        return response.getLevelDetails;
    } catch (err) {
        console.log(err);
    }
};

export const getManufAtSbuData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetManufAtSbuDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllManufacturersAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getManufAtShopData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetManufAtShopDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllManufacturersAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getManufAtDeptData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetManufAtDeptDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllManufacturersAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getManufAtClassData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetManufAtClassDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllManufacturersAtClass;
    } catch (err) {
        console.log(err);
    }
};

export const getManufAtSubclassData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetManufAtSubclassDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllManufacturersAtSubclass;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignManufactureData = async (merchantId, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignManufactureDataQuery(merchantId, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignManufacturers;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignManufAtSbuData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignManufAtSbuDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignManufacturersAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignManufAtShopData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignManufAtShopDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignManufacturersAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignManufAtDeptData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignManufAtDeptDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignManufacturersAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignManufAtClassData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignManufAtClassDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignManufacturersAtClass;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignManufAtSubclassData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignManufAtSubclassDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignManufacturersAtSubclass;
    } catch (err) {
        console.log(err);
    }
};