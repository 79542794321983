import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllShopData = async (index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllShopDataQuery(index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllShops;
    } catch (err) {
        console.log(err);
    }
};

export const getShopAtSbuData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetShopAtSbuDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllShopsAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignShopData = async (merchantId, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignShopDataQuery(merchantId, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignShops;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignShopAtSbuData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignShopAtSbuDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignShopsAtSbu;
    } catch (err) {
        console.log(err);
    }
};
export const getShopDetails = async (shopId, shopDesc) => {
    try {
        const query = QueryCreators.createGetShopDetailsQuery(shopId, shopDesc);
        const response = await GraphQL.request(query);
        return response.getLevelDetails;
    } catch (err) {
        console.log(err);
    }
};