import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllCountData = async () => {
    try {
        const query = QueryCreators.createGetAllCountQuery();
        const response = await GraphQL.request(query);
        return response.getAllCounts;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignedCountData = async (merchantId) => {
    try {
        const query = QueryCreators.createGetAllAssignedCountQuery(merchantId);
        const response = await GraphQL.request(query);
        return response.getAllAssignCounts;
    } catch (err) {
        console.log(err);
    }
};

export const getUsersCount = async () => {
    try {
        const query = QueryCreators.createGetUsersCountQuery();
        const response = await GraphQL.request(query);
        return response.getTeamsAndMerchantsCount;
    } catch (err) {
        console.log(err);
    }
};

export const getScrapersAndCompetitorsCount = async () => {
    try {
        const query = QueryCreators.createGetScraperAndCompetitorCountQuery();
        const response = await GraphQL.request(query);
        return response.getScrapersAndCompetitorsCount;
    } catch (err) {
        console.log(err);
    }
};

export const getUserAlertsCount = async () => {
    try {
        const query = QueryCreators.createGetUserAlertsCountQuery();
        const response = await GraphQL.request(query);
        return response.getSubscriptionAlertsCount;
    } catch (err) {
        console.log(err);
    }
};

export const getAlertsCountByUser = async (userId) => {
    try {
        const query = QueryCreators.createGetAlertsCountByUserQuery(userId);
        const response = await GraphQL.request(query);
        return response.getAlertsCountByUser;
    } catch (err) {
        console.log(err);
    }
};

