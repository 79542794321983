import React, { useEffect, useState } from 'react';
import { Table, Pagination, Input, Select, Button, Drawer, Tag, Tabs, Row, Col } from 'antd';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import * as getApprovals from '../../graphql/queryExecuters';
import { getAllUsersBaseData } from '../../redux/actionCreators/auth.actions';
import { all_users, engine_trigger } from '../../constants/displayStrings';
import { Notification } from '../../utilities';
import { getApprovalTableColumns } from '../../constants/tableColumns';

import './Tables.scss';
import MultiApprovalStatusModal from '../Modals/MultiApprovalStatusModal';
import CreatedByTable from './CreatedBy.table.js';
import StatusTable from './Status.table.js';
import CreatedAtTable from './CreatedAt.table';
import PriceReasonTable from './PriceReason.table';
import AcceptCodeTable from './AcceptCode.table';
import RejectCodeTable from './RejectCode.table';

const ApprovalTable = (props) => {

    const initialFiltersValue = {
        data: {
            startDate: '',
            endDate: '',
            priceReason: '',
            status: '',
            createdBy: '',
            rejectCode: '',
            approvalCode: '',
            isDiscarded: '',
        }
    }

    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    const [drawer, setDrawer] = useState(false);
    const [filterTags, setFilterTags] = useState([]);
    const [appliedFilterTags, setAppliedFilterTags] = useState([])
    const [tabindex, setTabindex] = useState('1');
    const [loading, setLoading] = useState(false);
    const [sortType, setSortType] = useState('DESC');
    const [modalOpen, setModalOpen] = useState(false)
    const [requestStatus, setRequestStatus] = useState('');
    const [changeReason, setChangeReason] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [SelectedSkuForUpdate, setSelectedSkuForUpdate] = useState([])
    const [rowCount, setRowCount] = useState(0);
    const [rowdata, setRowdata] = useState([]);
    const [repriceRequestCodes, setRepriceRequestCodes] = useState({})
    const [filters, setFilters] = useState(initialFiltersValue)
    const [createdAtDate, setCreatedAtDate] = useState([null, null])
    const [pageindex, setPageindex] = useState(
        searchParams.get('index') ? Number(searchParams.get('index')) : 1
        );
    const [searchText, setSearchText] = useState(
        searchParams.get('search') ? searchParams.get('search') : ''
        );
    const [userData, setUserData] = useState([
        { value: 'system', label: engine_trigger }
        ]);     

    const { allUsersList } = useSelector((state) => state.users);
    const user = ''
    const approvalStatus = ''
    const hasSelected = selectedRowKeys.length > 0;
    const statusOptions = [
        { label: 'All Status', value: '', level: 'status' },
        { label: 'Approved', value: 'Approved', level: 'status' },
        { label: 'Rejected', value: 'Rejected', level: 'status' },
        { label: 'Is Actionable', value: 'true', level: 'status' },
    ]
    // Allows us to only select the rejected options in the approval table when all status filter is applied
    const rowSelection = {
        selectedRowKeys,
        getCheckboxProps: (record = rowdata) => ({
            disabled: record.isDiscarded === false || record.status === 'Approved' || record.isDiscarded === null // Column configuration not to be checked
        }),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedSkuForUpdate(selectedRows);
        }
    };

    useEffect(() => {
        getAllSkuApprovalDataTrigger();
    }, [sortType, filters, user, approvalStatus, props?.totalRecords, props?.reload,props?.id,props?.userId,props?.level]); //eslint-disable-line

    useEffect(() => {
        getAllUserDataTrigger();
    }, [allUsersList]); //eslint-disable-line

    useEffect(() => {
        checkPageIndexChange();
    }, [searchParams]); //eslint-disable-line

    const getAllUserDataTrigger = async () => {
        try {
            let defaultOptions = [
                { value: 'system', label: engine_trigger },
                ...allUsersList.map((user) => ({ value: user.id, label: user.name }))
            ]

            if (allUsersList.length > 0) {
                if (props.level === 'all') setUserData(defaultOptions);
                else setUserData([{ value: 'all', label: all_users }, ...defaultOptions]);
            } else {
                dispatch(getAllUsersBaseData());
            }
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting user Data. Please try again later.'
            );
        }
    };

    //getting all sku Data
    const getAllSkuApprovalDataTrigger = async (pageNumber = null) => {
        try {
            setLoading(true);
            const queryFilters = `{
                data: {
                    startDate: "${filters.data.startDate}",
                    endDate: "${filters.data.endDate}",
                    priceReason: "${filters.data.priceReason}",
                    status: "${filters.data.status}",
                    createdBy: "${filters.data.createdBy}",
                    rejectCode: "${filters.data.rejectCode}",
                    acceptCode: "${filters.data.approvalCode}",
                    isDiscarded: "${filters.data.isDiscarded}"
                }
            }`;
            const page = pageNumber || pageindex;
            let queueData = null;
            if (props.level === 'all') {
                queueData = await getApprovals.getAllApproval(page - 1, 10, user, searchText, sortType, queryFilters, approvalStatus);
            } else if (props.level === 'sku') {
                if (props.id) {
                    queueData = await getApprovals.getSkuApproval(props.id, page - 1, 10, user, sortType, queryFilters, approvalStatus);
                }
            } else if (props.level === 'user') {
                if (props.userId) {
                    queueData = await getApprovals.getAllApproval(page - 1, 10, props.userId, searchText, sortType, queryFilters, approvalStatus);
                }
            } else {
                if (props.id && props.level) {
                    queueData = await getApprovals.getLevelApproval(props.id, props.level, page - 1, 10, user, searchText, sortType, queryFilters, approvalStatus);
                }
            }

            let repriceCodes = await getApprovals.getAllAcceptRejectReason();
            if (queueData?.dataCount < (page - 1) * 10) {
                setPageindex(1);
                if (props.level === 'all') {
                    searchParams.set('index', 1);
                    setSearchParams(searchParams);
                }
            }
            setRepriceRequestCodes(repriceCodes);
            queueData && setRowdata(queueData.data);
            queueData && setRowCount(queueData.dataCount);
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting Accept reject queue Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const checkPageIndexChange = () => {
        const index = searchParams.get('index') || 1;
        if (index) {
            const indexToBeSwitch = Number(index);
            if (indexToBeSwitch !== pageindex) {
                setPageindex(indexToBeSwitch);
                getAllSkuApprovalDataTrigger(indexToBeSwitch);
            }
        }
    }

    // Remove existing statuses from tags
    const deleteTagStatusLabel = async (previouslySelected) => {
        let deleteTag = [];
        if (previouslySelected) {
            filterTags.forEach((item, index) => {
                if (item.value.desc === previouslySelected) deleteTag.push(index)
            })
        } else {
            filterTags.forEach((item, index) => {
                if (item.value.level === 'status') deleteTag.push(index)
            })
        }
        deleteTag.sort((a, b) => b - a); // Sort deleteTag in descending order
        deleteTag.forEach((el) => filterTags.splice(el, 1))
    }
    // Uses the data from filter drawer to update filter tag state
    const updateTagValues = (levelFilteredValues) => {
        if (levelFilteredValues.checked) {
            setFilterTags([...filterTags, levelFilteredValues]);
        } else if (levelFilteredValues.checked === false) {
            const setData = filterTags.filter(
                (item) => item.value.desc !== levelFilteredValues.value.desc
            );
            setFilterTags(setData);
        }
    };

    // Generates a tag value and filter property from the created at filter tab
    const handleDateFilter = (dateData) => {
        let tempFilterTags = [...filterTags]
        if (dateData.startDate && dateData.endDate) {
            tempFilterTags = tempFilterTags.filter(item => item.value.level !== 'date-range');

            let dateTag = {
                value: {
                    level: 'date-range',
                    desc: `${dayjs(dateData.startDate).format('MM/DD/YYYY HH:mm:ss')} - ${dayjs(dateData.endDate).format('MM/DD/YYYY HH:mm:ss')}`,
                    start: dateData.startDate,
                    end: dateData.endDate,
                }
            }
            setFilterTags([...tempFilterTags, dateTag])
            setCreatedAtDate([dateData.startDate, dateData.endDate])
        }
    }

    // Check whether or not the filter options are checked
    const isChecked = (desc, id, level) => {
        return (filterTags.filter(item => 
            item.value.desc === desc && 
            item.value.id === id && 
            item.value.level === level)).length > 0
    }
    
    const handlePageChange = (event) => {
        setSelectedRowKeys([]);
        setPageindex(event);
        if (props.level === 'all') {
            searchParams.set('index', event);
            setSearchParams(searchParams);
        }
        getAllSkuApprovalDataTrigger(event);
    };

    const handleSearchChange = (event) => {
        setSelectedRowKeys([]);
        setSearchText(event.target.value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setSelectedRowKeys([]);
        let sort = sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : '';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                getAllSkuApprovalDataTrigger();
                if (props.level === 'all') {
                    searchParams.set('search', searchText);
                    setSearchParams(searchParams);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    // Control whether the drawer is open or not
    const showDrawer = (value) => {
        setTabindex('1')
        if (value === true || value === false) setDrawer(value)
        else drawer ? setDrawer(false) : setDrawer(true);
    };

    // Tracks tab changes within the filter
    const onTabChange = (key) => {
        setTabindex(key);
    };

    // Maps through the filter tags and generates a queryFilter object for the api call
    const handleApplyFilter = () => {
        setLoading(true);

        const isActionableSelected = filterTags.find((item) => item.value.desc === 'Is Actionable')
        filterTags
            .filter((item) => item.value.desc === 'Is Actionable')
            .map((item) => item.value.level = 'is_discarded')
        setFilterTags([...filterTags])

        const startDate = filterTags
            .filter((item) => item.value.level === 'date-range')
            .map((item) => `${item.value.start}`)
            .join('|');
        const endDate = filterTags
            .filter((item) => item.value.level === 'date-range')
            .map((item) => `${item.value.end}`)
            .join('|');
        const priceReason = filterTags
            .filter((item) => item.value.level === 'price-reason')
            .map((item) => `${item.value.desc}`)
            .join('|');
        const isDiscarded = filterTags
            .filter((item) => item.value.desc === 'Is Actionable')
            .map((item) => `${item.value.id}`)
            .join('|');
        let status = filterTags
            .filter((item) => item.value.level === 'status')
            .map((item) => `${item.value.id}`)
            .join('|');
        if (isActionableSelected) status = status ? `${status}, Rejected` : 'Rejected';
        const createdBy = filterTags
            .filter((item) => item.value.level === 'created-by')
            .map((item) => `${item.value.id}`)
            .join('|');
        const rejectCode = filterTags
            .filter((item) => item.value.level === 'reject-code')
            .map((item) => `${item.value.desc}`)
            .join('|');
        const approvalCode = filterTags
            .filter((item) => item.value.level === 'accept-code')
            .map((item) => `${item.value.desc}`)
            .join('|');

        setFilters({
             data: {
                ...filters.data,
                startDate: startDate,
                endDate: endDate,
                priceReason: priceReason,
                status: status,
                createdBy: createdBy,
                rejectCode: rejectCode,
                approvalCode: approvalCode,
                isDiscarded: isDiscarded
        }
        });
        filterTags.filter((item) => item.value.desc === 'Is Actionable').map((item) => item.value.level = 'status')
        setFilterTags([...filterTags])
        setAppliedFilterTags(filterTags)
        showDrawer(false);
        setLoading(false);
    };

    // handles dropdown filters
    const handleFilter = (filter) => {
        if (filter === '') {
            setFilters({
                data: {
                    ...filters.data,
                    status: filter,
                }
            })
        } else if (isNaN(filter) && filter !== 'all' && filter !== 'system') {
            setFilters({
                data: {
                    ...filters.data,
                    status: filter,
                }
            })
        } else {
            if (filter === 'all') filter = '';
            setFilters({
                data: {
                    ...filters.data,
                    createdBy: filter
                }
            })
        }
    }

    // handles the tags that are removed
    const closedTag = (tag, type) => {
        const { desc, level } = tag.value;
        let closed = []

        filterTags.forEach((tag, index) => {
            if (tag.value.level === level && tag.value.desc === desc) {
                closed.push(index)
            }
        })
        closed.forEach((item) => {
            filterTags.splice(item, 1);
        })
        let newTags = [...filterTags];

        if (tag && type !== 'appliedFilter') {
            setFilterTags(newTags)
        } else {
            setFilterTags(newTags)
            handleApplyFilter()
        }

        if (level === 'date-range') setCreatedAtDate([null, null]);
    }

    // Clears Drawer Filters
    const clearAllFilters = () => {
        setFilterTags([])
        setAppliedFilterTags([])
        setFilters(initialFiltersValue)
        setCreatedAtDate([null, null])
    };

    // Determines which bulk action was selected and opens the modal for multi approval/rejection.
    const handleAcceptReject = (event) => {
        setRequestStatus(event?.target?.innerText)
        if (event?.target?.innerText === 'Accept') {
            setRequestStatus('Approved')
            setChangeReason('Bulk SKU Alert -- Approval')
        } else {
            setRequestStatus('Rejected')
            setChangeReason('Bulk SKU Alert -- Rejection')
        }
        modalOpen ? setModalOpen(false) : setModalOpen(true)
    };

    const removeSelected = () => {
            setSelectedRowKeys([])
            setSelectedSkuForUpdate([])
    }

    const tabs = [
        {
            label: (
                <span
                    style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}
                    id="alerts-filter-table">
                    Status{' '}
                </span>
            ),
            key: '1',
            children: <StatusTable
                level={'Status'}
                options={statusOptions}
                updateTagValues={updateTagValues}
                handleCheck={isChecked}
                deleteTagStatusLabel={deleteTagStatusLabel}
                currStatusFilter={filters.data.isDiscarded ? 'Is Actionable' : filters.data.status}/>
        },
        {
            label: (
                <span
                    style={{ color: tabindex === '2' ? '#001952' : '#96999F' }}
                    id="alerts-filter-table">
                    Created At{' '}
                </span>
            ),
            key: '2',
            children: <CreatedAtTable handleDateFilter={handleDateFilter}
                                      setCreatedAtDate={setCreatedAtDate}
                                      createdAtDate={createdAtDate}
                />
        },
        {
            label: (
                <span
                    style={{ color: tabindex === '3' ? '#001952' : '#96999F' }}
                    id="alerts-filter-table">
                    Price Reason{' '}
                </span>
            ),
            key: '3',
            children: <PriceReasonTable level={'price-reason'} codes={repriceRequestCodes.priceReasons} updateTagValues={updateTagValues} handleCheck={isChecked}/>
        },
        {
            label: (
                <span
                    style={{ color: tabindex === '4' ? '#001952' : '#96999F' }}
                    id="alerts-filter-table">
                    Created By{' '}
                </span>
            ),
            key: '4',
            children: <CreatedByTable level={'Created By'} userData={userData} updateTagValues={updateTagValues} handleCheck={isChecked}/>
        },
        {
            label: (
                <span
                    style={{ color: tabindex === '5' ? '#001952' : '#96999F' }}
                    id="alerts-filter-table">
                    Reject Code{' '}
                </span>
            ),
            key: '5',
            children: <RejectCodeTable level={'reject-code'} codes={repriceRequestCodes.rejectCodes} updateTagValues={updateTagValues} handleCheck={isChecked}/>
        },
        {
            label: (
                <span
                    style={{ color: tabindex === '6' ? '#001952' : '#96999F' }}
                    id="alerts-filter-table">
                    Approval Code{' '}
                </span>
            ),
            key: '6',
            children:  <AcceptCodeTable level={'accept-code'} codes={repriceRequestCodes.acceptCodes} updateTagValues={updateTagValues} handleCheck={isChecked}/>
        }
    ];

    return (
        <div className="table-container">
            <div className="inlineContainer">
                {props.level !== 'sku' && (
                    <Input
                        id="aprroval-search"
                        className="petco-Data-Search"
                        value={searchText}
                        prefix={<SearchOutlined />}
                        onKeyDown={onPressEnterKeyHandler}
                        onChange={handleSearchChange}
                        placeholder="Search for SKU"
                        suffix={<span className="press-enter-style">Press enter to search</span>}
                        disabled={loading}
                    />
                )}
                {props.level !== 'all' ?
                    <> 
                        <Select
                        className="table-dropdown"
                        onChange={handleFilter}
                        placeholder={'All Status'}
                        size="large"
                        options={[
                            { label: 'All Status', value: '' },
                            { label: 'Approved', value: 'Approved' },
                            { label: 'Rejected', value: 'Rejected' },
                        ]}
                        disabled={loading}
                        />

                        <Select
                        className="table-dropdown"
                        onChange={handleFilter}
                        placeholder={'All Users'}
                        size="large"
                        options={userData}
                        disabled={loading}/>
                    </>
                :
                    <>
                        <div className="inline-btn filter-btn">
                            <Button 
                                type="primary"
                                onClick={showDrawer}
                                id="bulk-config-filter-btn"
                                disabled={loading}>
                                Filter <RightOutlined />
                            </Button>
                        </div>
                        <div className="inline-btn accept-btn">
                            <Button
                                key="back"
                                type="primary"
                                id="accept-reject-approval"
                                name="accept"
                                disabled={selectedRowKeys.length > 0 ? false : true}
                                onClick={handleAcceptReject}>
                                Accept
                            </Button>
                        </div>
                        <div className="inline-btn reject-btn">
                            <Button
                                key="back"
                                type="primary"
                                id="accept-reject-rejected"
                                name="reject"
                                disabled={selectedRowKeys.length > 0 ? false : true}
                                onClick={handleAcceptReject}>
                                Reject
                            </Button>
                        </div>
                    </>
                }
            </div>
            {appliedFilterTags.length > 0 ? 
            <div style={{ margin: 8 }}>
                        Applied Filters:{' '}
                        {appliedFilterTags.map((item) => {
                            return (
                                <Tag
                                key={`${item.value.id}+${item.value.desc}`}
                                closable={true}
                                style={{
                                    color: '#001952',
                                    backgroundColor: '#E9EAEC',
                                    borderRadius: '10px'
                                }}
                                onClose={() => closedTag(item, 'appliedFilter')}>
                                    {item.value.level.toUpperCase()}: {item.value.desc}
                                </Tag>
                            );
                        })}{' '}
                        {appliedFilterTags.length === 0 ? (
                            <></>
                            ) : (
                                <p
                                style={{
                                    display: 'inline-block',
                                    color: '#001952',
                                    cursor: 'pointer'
                                }}
                                >
                                <span
                                    onClick={clearAllFilters}>
                                    <u>Clear all</u>
                                </span>
                            </p>
                        )}
                    </div>
                    :
                    <></>
                    }
            <span style={{ margin: 5 }}>
                {hasSelected ? `Selected ${selectedRowKeys.length}` : ''}
            </span>
            <Table
                rowKey="id"
                id="approval-table"
                style={{ marginBottom: '16px' }}
                dataSource={rowdata}
                columns={getApprovalTableColumns(() => {
                    getAllSkuApprovalDataTrigger();
                    removeSelected();
                    props.level === 'sku' && props.handleUpdate();
                })}
                rowSelection={props.level !== 'all' ? '' : rowSelection}
                loading={loading}
                pagination={false}
                scroll={{ x: 2000 }}
                size="middle"
                className="table-striped-rows"
                onChange={handleTableChange}
            />
            {rowCount > 10 ? (
                <Pagination
                    id="aprroval-filter"
                    current={pageindex}
                    className="petco-Data-pagination"
                    defaultCurrent={pageindex}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
                />
            ) : (
                <></>
            )}
            <Drawer
                title="Filter"
                destroyOnClose={true}
                placement="right"
                onClose={showDrawer}
                open={drawer}
                width={'clamp(580px, 100%, 800px)'}
                footer={[]}>
                    <div style={{ margin: 8 }}>
                        Applied Filters:{' '}
                        {filterTags.map((item) => {
                            return (
                                <Tag
                                    key={`${item.value.id}+${item.value.desc}`}
                                    closable={true}
                                    style={{
                                        color: '#001952',
                                        backgroundColor: '#E9EAEC',
                                        borderRadius: '10px'
                                    }}
                                    onClose={() => closedTag(item)}>
                                    {item.value.level.toUpperCase()}: {item.value.desc}
                                </Tag>
                            );
                        })}{' '}
                        {filterTags.length === 0 ? (
                            <></>
                        ) : (
                            <p
                                style={{
                                    display: 'inline-block',
                                    color: '#001952',
                                    cursor: 'pointer'
                                }}
                                onClick={clearAllFilters}>
                                <span>
                                    <u>Clear all</u>
                                </span>
                            </p>
                        )}
                    </div>

                <Tabs
                    style={{
                        height: '520px'
                    }}
                    tabPosition="left"
                    animated
                    defaultActiveKey={tabindex}
                    items={tabs}
                    onChange={onTabChange}
                />
                <div className="filter-drawer-btns">
                    <Row>
                        <Col xs={14} sm={14} md={16} />
                        <Col xs={5} sm={5} md={4}>
                            <div className="close-btn">
                                <Button key="back" id="alert-table-close" onClick={showDrawer}>
                                    Close
                                </Button>
                            </div>
                        </Col>
                        <Col xs={2} sm={3}>
                            <div className="applyFilters-btn">
                                <Button
                                    type="primary"
                                    id="bulk-config-apply"
                                    onClick={() => handleApplyFilter(filterTags)}>
                                    Apply Filter
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Drawer>
            {modalOpen ? (
                <MultiApprovalStatusModal
                    selected={SelectedSkuForUpdate}
                    openModal={modalOpen}
                    requestStatus={requestStatus}
                    code={changeReason}
                    removeSelected={removeSelected}
                    callback={getAllSkuApprovalDataTrigger}
                    controlModal={handleAcceptReject}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export default ApprovalTable;
