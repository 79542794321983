export const price_rule_types = {
    match_lowest: { label: 'Match Lowest', key: 'match_lowest'},
    do_not_match_competitor:  { label: 'Do Not Match Competitor', key: 'do_not_match_competitor'},
    match_second_lowest_competitor:  { label: 'Match Second Lowest Competitor', key: 'match_second_lowest_competitor'},
    manual_price:  { label: 'Manual Price', key: 'manual_price'},
    manual_price_with_market_tracking:  { label: 'Manual Price W/ Market Tracking', key: 'manual_price_with_market_tracking'},
    family_rule:  { label: 'Family Rule', key: 'family_rule'},
};

export const priceRules = [
    {key: 'match_lowest', label: price_rule_types.match_lowest.label},
    {key: 'do_not_match_competitor', label: price_rule_types.do_not_match_competitor.label},
    {key: 'match_second_lowest_competitor', label: price_rule_types.match_second_lowest_competitor.label},
    {key: 'manual_price', label: price_rule_types.manual_price.label},
    {key: 'manual_price_with_market_tracking', label: price_rule_types.manual_price_with_market_tracking.label},
    {key: 'family_rule', label: price_rule_types.family_rule.label},
];

export const priceRuleDesc= {
    'match_lowest' : 'Match Lowest',
    'do_not_match_competitor' : 'Do Not Match Competitor',
    'match_second_lowest_competitor' : 'Match Second Lowest Competitor',
    'manual_price': 'Manual Price',
    'manual_price_with_market_tracking' : 'Manual Price W/ Market Tracking',
    'family_rule' : 'Family Rule'
};

export const TAB_NAME = {
    SBU: 'SBU',
    SHOP: 'SHOP',
    DEPARTMENT: 'DEPARTMENT',
    CLASS: 'CLASS',
    SUB_CLASS: 'SUB_CLASS',
    MANUFACTURER: 'MANUFACTURER',
    BRAND: 'BRAND',
    SKU: 'SKU',
    CONFIG_SKU: 'configSku'
}

export const LEVEL_IDS = {
    SBU: 'sbuId',
    SHOP: 'shopId',
    DEPARTMENT: 'deptId',
    CLASS: 'classId',
    SUB_CLASS: 'subclassId',
    MANUFACTURER: 'manufId',
    BRAND: 'brandId',
    SKU: 'skuId',
    CONFIG_SKU: 'skuId'
}

export const Levels = [
    {
        key: 'ALL',
        value: 'ALL',
        label: 'Show All Hierarchy'
    },
    {
        key: 'SBU',
        value: 'SBU',
        label: 'SBU'
    },
    {
        key: 'SHOP',
        value: 'SHOP',
        label: 'SHOP'
    },
    {
        key: 'DEPARTMENT',
        value: 'DEPARTMENT',
        label: 'DEPARTMENT'
    },
    {
        key: 'CLASS',
        value: 'CLASS',
        label: 'CLASS'
    },
    {
        key: 'SUBCLASS',
        value: 'SUBCLASS',
        label: 'SUBCLASS'
    },
    {
        key: 'MANUFACTURER',
        value: 'MANUFACTURER',
        label: 'MANUFACTURER'
    },
    {
        key: 'BRAND',
        value: 'BRAND',
        label: 'BRAND'
    },
    {
        key: 'SKU',
        value: 'SKU',
        label: 'SKU'
    },    
    
]

export const skuProperty = {
    basePrice: 'basePrice',
    basePriceEnabled: 'basePriceEnabled',
    buyerDesc: 'buyerDesc',
    buyerId: 'buyerId',
    classDesc: 'classDesc',
    classId: 'classId',
    costPrice: 'costPrice',
    currentPrice: 'currentPrice',
    cyCost: 'cyCost',
    cySalesUnits: 'cySalesUnits',
    defaultGuardMax: 'defaultGuardMax',
    defaultGuardMaxPrice: 'defaultGuardMaxPrice',
    defaultGuardMaxPriceSuffix: 'defaultGuardMaxPriceSuffix',
    defaultGuardMin: 'defaultGuardMin',
    defaultGuardMinPrice: 'defaultGuardMinPrice',
    defaultGuardMinPriceSuffix: 'defaultGuardMinPriceSuffix', 
    departmentDesc: 'departmentDesc',
    departmentId: 'departmentId',
    endTempGuardData: 'endTempGuardData',
    engineSelectedPriceRule: 'engineSelectedPriceRule',
    familyPrice: 'familyPrice',
    familyPriceModifier: 'familyPriceModifier',
    familyPriceSuffix: 'familyPriceSuffix',
    fullImage: 'fullImage',
    greenBarDisplayType: 'greenBarDisplayType',
    greenBarPdp: 'greenBarPdp',
    greenBarStatus: 'greenBarStatus',
    isMaster: 'isMaster',
    last90DaysMinPrice: 'last90DaysMinPrice',
    lastRepricePrice: 'lastRepricePrice',
    lastRepriceReason: 'lastRepriceReason',
    listPrice: 'listPrice',
    manualPrice: 'manualPrice',
    manualPriceReason: 'manualPriceReason',
    manualPriceSuffix: 'manualPriceSuffix',
    mapEndDate: 'mapEndDate',
    mapHoldEndDate: 'mapHoldEndDate',
    mapHoldStartDate: 'mapHoldStartDate',
    mapPrice: 'mapPrice',
    mapStartDate: 'mapStartDate',
    markdownPrice: 'markdownPrice',
    markdownPriceEnabled: 'markdownPriceEnabled',
    masterSku: 'masterSku',
    msrpEndDate: 'msrpEndDate',
    msrpStartDate: 'msrpStartDate',
    priceRule: 'priceRule',
    productUrlkeyword: 'productUrlkeyword',
    productView: 'productView',
    rdPrice: 'rdPrice',
    repriceRunStatus: 'repriceRunStatus',
    sbuDesc: 'sbuDesc',
    sbuId: 'sbuId',
    skuBrandDesc: 'skuBrandDesc',
    skuBrandId: 'skuBrandId',
    skuBuyableInd: 'skuBuyableInd',
    skuDesc: 'skuDesc',
    skuId: 'skuId',
    skuManufacturerDesc: 'skuManufacturerDesc',
    skuManufacturerId: 'skuManufacturerId',
    skuPblshInd: 'skuPblshInd',
    startTempGuardDate: 'startTempGuardDate',
    subClassDesc: 'subClassDesc',
    subClassId: 'subClassId',
    subRdEndDate: 'subRdEndDate',
    subRdStartDate: 'subRdStartDate',
    tempGuardMax: 'tempGuardMax',
    tempGuardMaxPrice: 'tempGuardMaxPrice',
    tempGuardMaxPriceSuffix: 'tempGuardMaxSuffix',
    tempGuardMin: 'tempGuardMin',
    tempGuardMinPrice: 'tempGuardMinPrice',
    tempGuardMinPriceSuffix: 'tempGuardMinPriceSuffix',
    tempMapHoldPrice: 'tempMapHoldPrice',
    tempMapPrice: 'tempMapPrice',
    tempMsrpPrice: 'tempMsrpPrice',
    tempSubRdPrice: 'tempSubRdPrice',
}

//Green Bar Display Types
export const GreenBarDisplayState = [
    {title: 'Off', value: 'off'},
    {title: 'Automated Promotion', value: 'automated_promotion'},
    {title: 'See Price In Cart', value: 'see_price_in_cart'}
]

//Green Bar Display PDP Options
export const GreenBarDisplayPdpOptions = [
    {title: 'MAP', value: 'map'},
    {title: 'MSRP', value: 'msrp'},
    {title: 'Manual Price', value: 'manual_price'}
]

//Green Bar Display Type
export const GreenBarDisplayTypes = [
    {type: 'off', value:[]},
    {
        type: 'automated_promotion', 
        value:[
            {title: '% Off', value: '%'}, 
            {title: '$ Off', value: '$'}
        ]
    },
    {type: 'see_price_in_cart', value:[{title: 'See Price In Cart', value: 'see_price_in_cart'}]}
]

export const subscriptionSuffix =[
    {
        key: '$',
        value: '$',
        label: '$'
    },
    {
        key: '%',
        value: '%',
        label: '%'
    }
]

export const manualPriceTypes = [
    {
        key: 'promotion',
        label: 'Promotion',
        value: 'promotion'
    },
    {
        key: 'margin gain',
        label: 'Margin Gain',
        value: 'margin gain'
    },
    {
        key: 'wrong attributes',
        label: 'Wrong Attributes',
        value: 'wrong attributes'
    },
    {
        key: 'system issues',
        label: 'System Issues',
        value: 'system issues'
    }
];
export const priceModifierArray = [
    {
        key: 'sub',
        label: 'Subtract (-)',
        value: '-'
    },
    {
        key: 'add',
        label: 'Addition (+)',
        value: '+'
    }
];

export const corpLabel = {
    amazon: 'Amazon',
    tractorsupply: 'Tractor Supply',
    hollywoodfeed: 'Hollywood Feed',
    kroger: 'Kroger',
    walmart: 'Walmart',
    walmartpetrx: 'WalmartPetRx',
    allivet: 'Allivet',
    petsuppliesplus: 'Pet Supplies Plus',
    target: 'Target',
    tractorsupplyrx: 'TractorSupplyRx',
    petmeds: 'PetMeds',
    petsmart: 'PetSmart',
    petsupermarket: 'Pet Supermarket',
    chewy: 'Chewy',
    bungeetech: 'BungeeTech',
    quicklizard: 'Quicklizard',
    petco: 'Petco'
};

export const oos_status = {
    'temporary unmatch': 'Temporary Unmatch',
    'permanent unmatch': 'Permanent Unmatch',
    'matched and instock': 'Matched and instock',
    'NULL': 'NULL',
    'matched but temp oos': 'Matched but perm oos',
    'matched but perm oos': 'Matched but perm oos',
    'other': 'OTHER'
}

export const effectiveDataArray = ['MAP', 'MSRP', 'Margin', 'Fixed'];

export const inviteStatus = {
    "0": "Unable to send email",
    "1": "Email sent",
    "2": "User verified"
}

export const stockTypes = [
    'In Stock',
    'Out of Stock',
    'In Store Only',
    'Back Ordered',
    'Blank',
    'Unknown'
]

export const guardrailOptions = [
    {
        value: 'MAP',
        label: 'MAP'
    },
    {
        value: 'MSRP',
        label: 'MSRP'
    },
    {
        value: 'Fixed',
        label: 'Fixed'
    },
    {
        value: 'Margin',
        label: 'Margin %'
    }
];

export const tempGuardTypes = [
    {
        value: 'MAP',
        label: 'MAP'
    },
    {
        value: 'MSRP',
        label: 'MSRP'
    },
    {
        value: 'Fixed',
        label: 'Fixed'
    },
    {
        value: 'Margin',
        label: 'Margin'
    }
];

export const merchantAccessTypes = [
    {
        value: 'VIEW',
        label: 'View'
    },
    {
        value: 'ASSISTED-EDIT',
        label: 'Assisted Edit'
    },
    {
        value: 'EDIT',
        label: 'Edit'
    }
];

export const merchantAccessDisplayTypes = {
    'VIEW': 'View',
    'ASSISTED-EDIT': 'Assisted Edit',
    'EDIT': 'Edit' 
}

export const accessTypeDescriptions = {
    'VIEW': "Merchant will have view only access.",
    'ASSISTED-EDIT': "Merchant changes will be subject to pricing team review.",
    'EDIT': "Merchants are free to make changes without any pricing team review." 
}

export const merchantAccess = {
    'VIEW': 'VIEW',
    'EDIT': 'EDIT',
    'ASSISTED-EDIT': 'ASSISTED-EDIT'
}

export const userTypes = {
    'ADMIN': 'ADMIN',
    'PETCO-USER': 'PETCO-USER',
    'MERCHANT': 'MERCHANT'
}

export const priceRequest = {
    approved: 'Approved',
    rejected: 'Rejected',
    pageSize: 9,
}

export const dataConfigDrawerWidth = "clamp(580px, 90%, 900px)";

export const repriceTime =  [{
    timeString: '12:45 AM',
    hour: 0
},
{
    timeString: '02:45 AM',
    hour: 2
},
{
    timeString: '04:45 AM',
    hour: 4
},
{
    timeString: '06:45 AM',
    hour: 6
},
{
    timeString: '08:45 AM',
    hour: 8
},
{
    timeString: '10:45 AM',
    hour: 10
},
{
    timeString: '12:45 PM',
    hour: 12
},
{
    timeString: '02:45 PM',
    hour: 14
},
{
    timeString: '04:45 PM',
    hour: 16
},
{
    timeString: '07:45 PM',
    hour: 19
},
]

export const DELAY_TIME = 3000;
export const RESCRAPE_DELAY_TIME = 10000;
export const REPRICE_DELAY_TIME = 2000;
export const MAX_SKU_UPLOAD_LIMIT = 10001;
export const MAX_CSV_UPLOAD_SIZE = 2097152; // 2MB

export const marginMinLimit = -1000.0;
export const marginMaxLimit = 99.0;
export const fixedMinLimit = 0.0;
export const subRdMinLimit = 0.0;
export const subRdMaxLimit = 50.0;
export const subRdDefault = 5.0;
export const inputMinDefault = 0.0;
export const inputMaxDefault = 1000.0;

export const allCsvColumns = ('sku_id' && 'Manual Price' && 'Market Tracking (Y or N)' && 'Type' && 'MAP' && 'MAP Start Date(mm/dd/yyyy)' && 'MAP End Date(mm/dd/yyyy)' && 'MSRP' && 'MSRP Start Date(mm/dd/yyyy)' && 'MSRP End Date(mm/dd/yyyy)' && 'SubRD(%)' && 'SubRD Start Date(mm/dd/yyyy)' && 'Temp Guardrail Min' && 'Temp Min Value' && 'Temp Guardrail Max' && 'Temp Max Value' && 'Temp Guard Start Date(mm/dd/yyyy)' && 'Temp Guard End Date(mm/dd/yyyy)')

export const globalSearchFilters = [
    { label: 'SKUs', filterString: 'sku' },
    { label: 'Departments', filterString: 'department' },
    { label: 'Brands', filterString: 'brand' },
    { label: 'Classes', filterString: 'class' },
    { label: 'Subclasses', filterString: 'subclass' },
    { label: 'Shops', filterString: 'shop' },
    { label: 'Manufacturers', filterString: 'manufacturer' },
    { label: 'SBUs', filterString: 'sbu' }
];

export const nextDayRepriceHour = 19; // 7 PM PT