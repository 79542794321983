import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';

import { RoutesList, PublicRoutes } from './constants/routes';
// import Home from './containers/Home/Home';

const AppRouter = () => (
    <Suspense fallback={() => <div>Loading...</div>}>
        <Routes>
            {/* Layout for Disable Header for particular routes */}
            <Route element={<Layout hideHeaderPaths={PublicRoutes.map((route) => route.path)} />}>
                {RoutesList.map((route, index) => (
                    <Route key={index} path={route.path} element={route.component} exact />
                ))}
                {/* <Route path="*" element={<Home />} /> */}
                {/* Need to uncomment above line to enable dashbiard route */}
                {/* Enable in routes file also */}
                <Route path="*" element={<Navigate to='/sku_data' replace/>}/>
            </Route>
        </Routes>
    </Suspense>
);

export default AppRouter;
