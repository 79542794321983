import { Table } from 'antd';
// import { useNavigate } from 'react-router-dom';

import { CompetitorsColumns } from '../../constants/tableColumns';
import "./Tables.scss"

const CompetitorsTable = ({ data, loading }) => {
    // const navigate = useNavigate();
    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     navigate('/sbuDetailsPage')

    // }
    return (
        <div className="table-container">
            <Table 
                rowKey={(record) => record.competitor}   
                id="comp-table"           
                dataSource={data} 
                columns={CompetitorsColumns} 
                style={{
                width: '100%'
                }}
                className="table-striped-rows clickable-table"
                scroll={{ x: 1000 }}
                pagination={false}
                loading={loading}
            />
        </div>
    );
};

export default CompetitorsTable;
