import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    level: '',
    index: -1, 
    search: '', 
    sku_pricing_rule: '',
    sku_publish: '',
    sort: '',
    skuList: [],
    count: 0
};

const skuSlice = createSlice({
    name: 'sku',
    initialState,
    reducers: {
        setSkuData(state, { payload }) {
            state.level = payload.level;
            state.index = payload.index;
            state.search = payload.search;
            state.sku_pricing_rule = payload.sku_pricing_rule;
            state.sku_publish = payload.sku_publish;
            state.sort = payload.sort;
            state.skuList = payload.skuData;
            state.count = payload.count;
        },

        updateSkuList(state, { payload }) {
            state.skuList = payload.skuList
        },
        
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const SkuActions = skuSlice.actions;
export const SkuReducer = skuSlice.reducer;
export default skuSlice;
