import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Tabs, Modal, Badge, Breadcrumb } from 'antd';
import dayjs from 'dayjs';
import { ArrowLeftOutlined } from '@ant-design/icons';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ScraperDataTable from '../../components/Tables/ScraperData.table';
import './ScraperDetails.scss';
import {
    updateScraperDetails,
    getAllScrapers,
    getAllCompetitors,
} from '../../graphql/queryExecuters';
import { Notification } from '../../utilities';

import { ScrapersActions } from '../../redux/slices/scrapers.slice';
import { CompetitorsActions } from '../../redux/slices/competitors.slice';
import { userTypes } from '../../constants/common';
import { getScrapersBaseData } from '../../redux/actionCreators/scrapers.actions';

const ScraperDetails = () => {
    const settingsFields = ['pdpMatching', 'incartMatching'];

    const settingsFieldsSQLMatch = {
        pdpMatching: 'pdp_matching',
        incartMatching: 'incart_matching'
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { scraper_name } = useParams();
    const { scrapers, scrapersBaseDetails } = useSelector((state) => state.scrapers);
    const { competitors } = useSelector((state)=> state.competitors);
    const { userType } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(false);
    const [scraperDetails, setScraperDetails] = useState(null);
    const [showDefaultSettingModal, setShowDefaultSettingModal] = useState(false);

    //Scraper Base details
    const [scraperBaseData, setScraperBaseData] = useState({});
    const [defaultSettings, setDefaultSettings] = useState({
        pdpMatching: false,
        incartMatching: false,
    });

    //Updating base data when base details are available
    useEffect(() => {
        const scraperData = scrapersBaseDetails.find(data => data.scraperId === String(scraper_name).toLowerCase());
        if (scraperData) {
            setScraperBaseData(scraperData);
        }
    }, [scrapersBaseDetails, scraper_name]) //eslint-disable-line

    useEffect(() => {
        getScraperDetails();
    }, [competitors, scrapers]); //eslint-disable-line

    //Redirecting user if not a valid user
    useEffect(() => {
        if (userType === userTypes.MERCHANT) {
            navigate('/');
        }
    }, [userType])//eslint-disable-line

    const props = {
        level: "scraper",
        name: scraper_name
    };

    const tabs = [
        {
            label: (
                <span> 
                    <span style={{ color: '#001952' }} id='scraped-data'>
                        Scraped Data{' '}
                    </span>
                    <Badge
                        showZero
                        overflowCount={99999999999}
                        count={scraperBaseData.totalRecords ? scraperBaseData.totalRecords : 0}
                        style={{
                            marginLeft: 8,
                            backgroundColor: '#001952',
                            color: '#FFFFFF'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: (
                <ScraperDataTable {...props}/>
            )
        }
    ];

    const getScraperDetails = async () => {
        try {
            setLoading(true);
            if (competitors?.length === 0) {
                const competitorsData = await getAllCompetitors();
                if (competitorsData) {
                    dispatch(CompetitorsActions.setCompetitors({ competitors: competitorsData.data }));
                }
            }
            if (scrapers.length === 0) {
                const scrapersData = await getAllScrapers();
                if (scrapersData) {
                    dispatch(ScrapersActions.setScrapers({ scrapers: scrapersData.data }));
                    const activeScraper = scrapersData.data.filter((scrap) => scrap.status);
                    if (activeScraper) {
                        dispatch(ScrapersActions.setActiveScrapers({ activeScrapers: activeScraper }));
                    }
                }
            }

            dispatch(getScrapersBaseData());

            const scraperData = scrapers.find((scraper) => scraper.id === String(scraper_name).toLowerCase());
            if (scraperData) {
                setScraperDetails({ ...scraperData });
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onClickStatusChangeHandler = async () => {
        try {
            setLoading(true);
            const updatedValue = scraperDetails.status ? false : true;
            const fieldsToUpdate = [{ field: 'status', value: updatedValue }];
            const response = await updateScraperDetails(scraperDetails.name, fieldsToUpdate);
            if (response.data.status === 1) {
                const newScrapersList = scrapers.map((scraper) => Object.assign({}, scraper));
                const index = newScrapersList.findIndex(
                    (scraper) => scraper.name === scraperDetails.name
                );
                if (index > -1) {
                    newScrapersList[index].status = updatedValue;
                    dispatch(ScrapersActions.setScrapers({ scrapers: newScrapersList }));
                    setScraperDetails((prevState) => {
                        const state = { ...prevState };
                        state.status = updatedValue;
                        return state;
                    });
                }
                const scrapersData = await getAllScrapers();
                const activeScraper = scrapersData.data.filter((scrap) => scrap.status);
                if (activeScraper) {
                    dispatch(ScrapersActions.setActiveScrapers({ activeScrapers: activeScraper }));
                }
            }
            Notification(response.data.status === 1 ? 'success' : 'error', response.data.message);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onClickShowSettingsModalHandler = () => {
        setShowDefaultSettingModal(true);
        setDefaultSettings({
            pdpMatching: scraperDetails.pdpMatching ? true : false,
            incartMatching: scraperDetails.incartMatching ? true : false
        });
    };

    const onChangeSettingsHandler = (key, value) => {
        setDefaultSettings((prevState) => {
            const state = { ...prevState };
            state[key] = value;
            return state;
        });
    };

    const onClickApplyChangesHandler = async () => {
        try {
            setShowDefaultSettingModal(false);
            setLoading(true);
            //Checking changes
            const changedFields = {};
            for (let field of settingsFields) {
                if (defaultSettings[field] !== !!scraperDetails[field]) {
                    changedFields[field] = defaultSettings[field];
                }
            }

            if (JSON.stringify(changedFields) !== '{}') {
                const fieldsToUpdate = settingsFields.map((field) => ({
                    field: settingsFieldsSQLMatch[field],
                    value: defaultSettings[field]
                }));
                const response = await updateScraperDetails(scraperDetails.name, fieldsToUpdate);
                if (response.data.status === 1) {
                    const newScrapersList = scrapers.map((scraper) => Object.assign({}, scraper));
                    const index = newScrapersList.findIndex(
                        (scraper) => scraper.name === scraperDetails.name
                    );
                    if (index > -1) {
                        for (let field of settingsFields) {
                            newScrapersList[index][field] = defaultSettings[field];
                        }
                        dispatch(ScrapersActions.setScrapers({ scrapers: newScrapersList }));
                        setScraperDetails((prevState) => {
                            const state = { ...prevState };
                            for (let field of settingsFields) {
                                state[field] = defaultSettings[field];
                            }
                            return state;
                        });
                    }
                }
                Notification(
                    response.data.status === 1 ? 'success' : 'error',
                    response.data.message
                );
                setLoading(false);
            } else {
                Notification('warning', 'Nothing changed to save.');
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    return (
        <>
            <ContentWrapper>
                <div className="scraper-details-container">
                    <div className="scraper-base-details">
                        <div className="breadcrumn-container">
                            <Breadcrumb 
                            items = {[
                                {
                                    title: (
                                        <div style={{ display: 'inline-flex'}}>
                                        <div style={{color: '#001952', cursor:'pointer'}} onClick={() => {navigate('/scrapers_and_competitors/scrapers');}}>
                                            <ArrowLeftOutlined/>
                                        </div>
                                        <div style={{color: '#96999F', marginLeft: 10}}>Scrapers</div>
                                    </div>
                                    )

                                },
                                {
                                    title: scraperDetails?.name,
                                    style:{color: '#001952'}
                                }
                            ]}
                            />
                            
                        </div>
                        <div className="name-and-actions">
                            <div className="name-container">{scraperDetails?.name}</div>
                            {userType === userTypes.ADMIN && <div className="actions-container">
                                <Button
                                    type={`${scraperDetails?.status ? 'default' : 'primary'}`}
                                    id='scraper-status'
                                    onClick={onClickStatusChangeHandler}>{`${
                                    scraperDetails?.status ? 'Pause' : 'Activate'
                                } Scraper`}</Button>
                                <Button
                                    type="primary"
                                    id='default-settings'
                                    onClick={onClickShowSettingsModalHandler}>
                                    Default Settings
                                </Button>
                            </div>}
                        </div>
                        <div className="detail-container">
                            <div className="detail">
                                <div className="key" style={{
                                    fontSize: '12px',
                                    width: '200px'
                                }}>Last Scraped Date</div>
                                <div className="value">
                                    {scraperBaseData?.lastScrapedDate
                                        ? dayjs.tz(scraperBaseData.lastScrapedDate).format(
                                                'MM/DD/YYYY HH:mm:ss'
                                            )
                                        : '-'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">Scraper Status</div>
                                <div className="value">
                                    {scraperDetails?.status ? 'Active' : 'Disabled'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">PDP Matching</div>
                                <div className="value">
                                    {scraperDetails?.pdpMatching ? 'Active' : 'Disabled'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">In-cart Matching</div>
                                <div className="value">
                                    {scraperDetails?.incartMatching ? 'Active' : 'Disabled'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">Competitors Scraped</div>
                                <div className="value">{scraperBaseData?.competitorScraped ? scraperBaseData.competitorScraped : '-'}</div>
                            </div>
                            <div className="detail">
                                <div className="key">SKU Scraped</div>
                                <div className="value">{scraperBaseData?.skuScraped ? scraperBaseData.skuScraped : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="scraper-table-container">
                        <Tabs animated defaultActiveKey={'1'} items={tabs}/>
                    </div>
                </div>
            </ContentWrapper>
            <Modal
                open={showDefaultSettingModal}
                footer={null}
                title="Default Scraper Settings"
                onCancel={() => {
                    setShowDefaultSettingModal(false);
                }}>
                <div className="default-setting-modal-container">
                    <div className="setting">
                        <Checkbox
                            id='in-cart-checkbox'
                            checked={defaultSettings.incartMatching}
                            onChange={(e) => {
                                onChangeSettingsHandler('incartMatching', e.target.checked);
                            }}>
                            In-cart matching
                        </Checkbox>
                        <Checkbox
                            id='pdp-checkbox'
                            checked={defaultSettings.pdpMatching}
                            style={{ marginLeft: '48px' }}
                            onChange={(e) => {
                                onChangeSettingsHandler('pdpMatching', e.target.checked);
                            }}>
                            PDP
                        </Checkbox>                        
                    </div>
                    <div className="actions">
                        <Button
                            id='close-scraper-settings'
                            type="default"
                            onClick={() => {
                                setShowDefaultSettingModal(false);
                            }}>
                            Close
                        </Button>
                        <Button id='apply-scraper-settings' type="primary" onClick={onClickApplyChangesHandler} loading={loading}>
                            Apply Settings
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ScraperDetails;
