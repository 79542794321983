import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    level: '',
    index: -1, 
    search: '', 
    sort: '',
    sbuList: [],
    count: 0
};

const sbuSlice = createSlice({
    name: 'sbu',
    initialState,
    reducers: {
        //Setting SBU state
        setSbuData(state, { payload }) {
            state.level = payload.level;
            state.index = payload.index;
            state.search = payload.search;
            state.sort = payload.sort;
            state.sbuList = payload.sbuData;
            state.count = payload.count;
        },

        updateSbuList(state, { payload }) {
            state.sbuList = payload.sbuList
        },

        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const SbuActions = sbuSlice.actions;
export const SbuReducer = sbuSlice.reducer;
export default sbuSlice;
