import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

//Get all Scrapers Data
export const createGetAllScrapersQuery = () => {
    try {
        return gql`
            query {
                getAllScrapers {
                    data {
                        id
                        name
                        lastScrapedDate
                        competitorScraped
                        brandScraped
                        skuScraped
                        status
                        pdpMatching
                        incartMatching
                        expiryDate
                        competitors
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get Scraper Data by Name
export const createGetScraperByNameQuery = (scraperName) => {
    try {
        return gql`
            query {
                getScraperByName(data: { data: { scraper_name: "${scraperName}" } }) {
                    name
                    lastScrapedDate
                    competitorScraped
                    brandScraped
                    skuScraped
                    status
                    pdpMatching
                    incartMatching
                    expiryDate
                    competitors
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get Scraper SKU Hiostorical Data by Page size
export const createGetScraperSKUQuery = (page = 0, limit = 10, searchText = '', competitor = '', stockType = '', period = '', sortType = 'DESC') => {
    try {
        return gql`
            query {
                getSkusList(page: ${page}, limit: ${limit}, search: ${prepareString(searchText)}, competitor: "${competitor}", stockType: "${stockType}", period: "${period}", sortType: "${sortType}") {
                    totalCount
                    dataCount
                    data {
                        id
                        skuId
                        name
                        dayId
                        price
                        inCartPrice
                        partNumber
                        url
                        inventoryStatus
                        matchType
                        competitor
                        scraper
                        offerDate
                        availability
                        thirdParty
                        amazonFba
                        fbw
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get Scraper SKU Recent Data by Page size
export const createGetRecentScraperSKUQuery = (
    page = 1,
    limit = 10,
    searchText = '',
    competitors = [],
    stockType = '',
    period = 'today'
) => {
    try {
        return gql`
            query {
                getRecentSkusList(page: ${page}, limit: ${limit}, search: ${prepareString(searchText)}, competitors: ${prepareString(competitors)}, 
                stockType: "${stockType}", period: "${period}") {
                    totalCount
                    dataCount
                    data {
                        id
                        skuId
                        name
                        competitor
                        dayId
                        price
                        inCartPrice
                        partNumber
                        url
                        inventoryStatus
                        matchType
                        scraper
                        offerDate
                        availability
                        thirdParty
                        amazonFba
                        fbw
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Update Scraper Details
export const createUpdateScraperQuery = (scraperName, fieldsToUpdate) => {
    try {
        // Fields to update should be array of "{field: "FieldName", value: "valueToUpdate"}"
        return gql`
            mutation {
                updateScraperDetails(
                    data: {
                        data: {
                            scraper_name: "${scraperName}",
                            fields_to_update: [${fieldsToUpdate
                                .map(
                                    (updation) =>
                                        `{field: "${updation.field}", value: ${
                                            typeof updation.value === 'string' || typeof updation.value === 'boolean'
                                                ? `"${updation.value}"`
                                                : updation.value
                                        }}`
                                )
                                .join(', ')}]
                        }
                    }
                ) {
                    data
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};


//Re-scrape query
export const createUpdateScraperTriggerQuery = (configId, configdesc, configLevel, competitors, expiryHour) => {
    configdesc = prepareString(configdesc)
    try {
        return gql`
        mutation {
            updateScraperTriggerConfig (configId: "${configId}", configDesc: ${configdesc}, configLevel: "${configLevel}", competitor: "${competitors}", scrapeExpiryHour: "${expiryHour}")
        }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSKUScraperQuery = (sku_id, index, size, filter) => {
    try {
        return gql`
            query {
                getSkuScraperList(skuId: "${sku_id}", index: ${index}, size: ${size}, filter:${filter}) {
                    data {
                        id
                        skuId
                        name
                        competitor
                        dayId
                        price
                        inCartPrice
                        partNumber
                        url
                        inventoryStatus
                        matchType
                        scraper
                        offerDate
                        availability
                        thirdParty
                        amazonFba
                        fbw
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get base details for all scrapers
export const createGetAllScrapersBaseDataQuery = () => {
    try {
        return gql`
            query {
                getScrapersBaseData {
                    scraperId
                    competitorScraped
                    skuScraped
                    lastScrapedDate
                    totalRecords
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};
