import { Table } from 'antd';
import { scrapersTableColumns } from '../../constants/tableColumns';
import "./Tables.scss"

const ScrapersTable = ({ data, onClickRow }) => {
    return (
        <div className="table-container">
            <Table
                onRow={(row, index) => {
                    return {
                        onClick: () => {
                            onClickRow(row, index);
                        }
                    };
                }}
                rowKey="name"
                id="scrapers-table"
                dataSource={data}
                columns={scrapersTableColumns}
                className="table-striped-rows clickable-table"
                pagination={false}
                scroll={{ x: 2000 }}
                size="middle"
            />
        </div>
    );
};

export default ScrapersTable;
