import { gql } from 'graphql-request';

export const createGetAllCountQuery = () => {
    try {
        return gql`
            query {
                getAllCounts {
                    sbuCount
                    shopCount
                    deptCount
                    classCount
                    subclassCount
                    manufCount
                    brandCount
                    skuCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignedCountQuery = (merchantId) => {
    try {
        return gql`
            query {
                getAllAssignCounts(merchantId: "${merchantId}") {
                    sbuCount
                    shopCount
                    deptCount
                    classCount
                    subclassCount
                    manufCount
                    brandCount
                    skuCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetUsersCountQuery = (merchantId) => {
    try {
        return gql`
            query {
                getTeamsAndMerchantsCount {
                    teamCount
                    merchantCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetScraperAndCompetitorCountQuery = (merchantId) => {
    try {
        return gql`
            query {
                getScrapersAndCompetitorsCount {
                    scraperCount
                    competitorCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetUserAlertsCountQuery = () => {
    try {
        return gql`
            query {
                getSubscriptionAlertsCount {
                    subscriptionCount
                    approvalCount
                    alertCount
                    matchIssuesCount
                    unpricedSkusCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAlertsCountByUserQuery = (userId) => {
    try {
        return gql`
            query {
                getAlertsCountByUser(userId: "${userId}") {
                    subscriptionCount
                    logCount
                    alertCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};
