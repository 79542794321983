import { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Button, Divider, Dropdown, Modal, Select, Spin } from 'antd';
import { ArrowLeftOutlined, CloseOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, Tabs, Badge } from 'antd';

import './MerchantDetails.scss';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import SBUTable from '../../components/Tables/SBU.table';
import ShopTable from '../../components/Tables/Shop.table';
import ClassTable from '../../components/Tables/Class.table';
import DepartmentsTable from '../../components/Tables/Departments.table';
import SubclassTable from '../../components/Tables/Subclass.table';
import ManufacturerTable from '../../components/Tables/Manufacturer.table';
import BrandsTable from '../../components/Tables/Brands.table';
import SKUTable from '../../components/Tables/SKU.table';
import MerchantTableWrapper from '../../components/MerchantTableWrapper/MerchantTableWrapper';
import { useSelector } from 'react-redux';

import { getAllSbuData, getAllShopData, getAllDeptData, getAllClassData, getAllSubclassData, getAllManufData, getAllBrandsData, getAllSkuData, updateMerchantAssignlevelConfigData, getUserById, deleteMerchantAssignlevelConfigData } from '../../graphql/queryExecuters';
import { useLocation, useNavigate, useParams, useNavigationType } from 'react-router-dom';
import { Notification, prepareString, validatePetcoEmail } from '../../utilities';
import { getAllAssignedCountData, updateUser } from '../../graphql/queryExecuters';
import { accessTypeDescriptions, merchantAccessDisplayTypes, merchantAccessTypes, userTypes } from '../../constants/common';
import {
    assignment_deletion_error, assignment_deletion_success,
    assignment_item_missing_error, brand_data_error, class_data_error,
    count_list_error,
    data_assignment_error,
    data_assignment_success, department_data_error,
    generic_email_error,
    generic_name_error, manufacturer_data_error,
    sbu_data_error, shop_data_error, sku_data_error, subclass_data_error,
    user_update_success,
    user_update_warning
} from "../../constants/displayStrings";

const MerchantDetails = () => {

    const nullState = {
        name: '',
        email: '',
        accessLevel: merchantAccessTypes[0].value
    };

    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { state, pathname } = location;
    const navigationType = useNavigationType();

    const [showModalFor, setShowModalFor] = useState('');
    const [selectedLevelData, setSelectedLevelData] = useState([]);
    const [currentLevelSelected, setCurrentLevelSelected] = useState(null);
    const [showSelected, setShowSelected] = useState([]);
    const [merchantData, setMerchantData] = useState(nullState);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [loadingReferenceData, setLoadingReferenceData] = useState(false);
    const [allCountList, setAllCountList] = useState({
        sbuCount: state?.row?.sbuCount || 0,
        shopCount: state?.row?.shopCount || 0,
        deptCount: state?.row?.deptCount || 0,
        classCount: state?.row?.classCount || 0,
        subclassCount: state?.row?.subclassCount || 0,
        manufCount: state?.row?.manufCount || 0,
        brandCount: state?.row?.brandCount || 0,
        skuCount: state?.row?.skuCount || 0
    });
    const [isCreating, setIsCreating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [tabindex, setTabindex] = useState('1');
    const [currentLevel, setCurrentLevel] = useState('sbu');
    //searching for a sku
    const [searchedtext,setSearchedText] = useState('');

    //State for local list (For selection)
    const [sbusData, setSbusData] = useState([]);
    const [shopsData, setShopsData] = useState([]);
    const [departmentsData, setDepartmentsData] = useState([]);
    const [classesData, setClassesData] = useState([]);
    const [subClassesData, setSubClassesData] = useState([]);
    const [manufacturersData, setManufacturersData] = useState([]);
    const [brandsData, setBrandsData] = useState([]);
    const [skusData, setSkusData] = useState([]);

    //Helper for reload data after adding/updating level data
    const [reloadData, setReloadData] = useState({
        sbu: false,
        shop: false,
        department: false,
        class: false,
        subclass: false,
        manufacturer: false,
        brand: false,
        sku: false
    })

    const [select, setSelect] = useState({
        selectedRowKeys: [],
        selectedRowData: []
    });

    useEffect(() => {
        if(!state?.row){
            getAllCountTrigger();
        }
    }, []); //eslint-disable-line

    const getAllCountTrigger = async () => {
        try {
            const allCountData = await getAllAssignedCountData(id);
            setAllCountList(allCountData);
            for (const key in allCountData) {
                if (state?.row.hasOwnProperty(key)) {
                    state.row[key] = allCountData[key];
                }
            }
            navigate(pathname, { state: { searchParams: state.searchParams, row: state.row}, replace: true });
        } catch (err) {
            console.log(err);
            Notification('error', count_list_error);
        }
    };

    const [loading, setLoading] = useState(false);

    const [userDetails, setUserDetails] = useState({
        name: state?.row?.name || '',
        email: state?.row?.email || '',
        userType: state?.row?.userType || '',
        createdBy: state?.row?.createdBy || '',
        accessRole: state?.row?.accessRole || '',
        invitationStatus: state?.row?.invitationStatus || '',
        accessLevel: state?.row?.editAccess || merchantAccessTypes[0].value,
    });

    const { userType } = useSelector(state => state.auth);

    useEffect(() => {
        if (userType === 'MERCHANT') {
            navigate('/');
        }
        if (!state) {
            getUserDetailsTrigger(id);
        }
    }, [id, userType]) //eslint-disable-line

    useEffect(() => {
        modalForFunction()
    }, [showModalFor]); //eslint-disable-line

    //trigger searching for sku

    useEffect(() => {
        if(showModalFor?.levelType === 'sku'){
            getSkuDataTrigger()
        }
    }, [searchedtext]); //eslint-disable-line
    //modal opened for which level function
    const modalForFunction = () => {
        if (sbusData?.length === 0 && showModalFor?.levelType === 'sbu') {
            getSbuDataTrigger();
        } else if (shopsData?.length === 0 && showModalFor?.levelType === 'shop') {
            getShopDataTrigger();
        } else if (departmentsData?.length === 0 && showModalFor?.levelType === 'department') {
            getDeptDataTrigger();
        } else if (classesData?.length === 0 && showModalFor?.levelType === 'class') {
            getClassDataTrigger();
        } else if (subClassesData?.length === 0 && showModalFor?.levelType === 'subclass') {
            getSubClassDataTrigger();
        } else if (manufacturersData?.length === 0 && showModalFor?.levelType === 'manufacturer') {
            getManufacturerDataTrigger();
        } else if (brandsData?.length === 0 && showModalFor?.levelType === 'brand') {
            getBrandDataTrigger();
        } else if (skusData?.length === 0 && showModalFor?.levelType === 'sku') {
            getSkuDataTrigger();
        }
    }

    const getUserDetailsTrigger = async id => {
        try {
            setLoading(true);
            const res = await getUserById(id);
            if (res.__typename === 'Error') {
                Notification('error', res.message);
            } else {
                setUserDetails(prevState => {
                    const state = { ...prevState };
                    state.name = res.name;
                    state.email = res.email;
                    state.userType = res.userType;
                    state.accessRole = res.accessRole;
                    state.createdBy = res.createdBy;
                    state.invitationStatus = res.invitationStatus;
                    state.accessLevel =  Object.keys(merchantAccessDisplayTypes).includes(res.editAccess) ? res.editAccess : merchantAccessTypes[0].value;
                    return state;
                })
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getSbuDataTrigger = async () => {
        try {
            setLoadingReferenceData(true);
            let sbuData = [];
            sbuData = await getAllSbuData(0, 100000, '','ASC');
            setSbusData(sbuData.data);
            setLoadingReferenceData(false);
        } catch (err) {
            console.log(err);
            Notification('error', sbu_data_error);
            setLoadingReferenceData(false);
        }
    };

    const getShopDataTrigger = async () => {
        try {
            let shopData = [];
            shopData = await getAllShopData(0, 100000, '','ASC');
            setShopsData(shopData.data);
        } catch (err) {
            console.log(err);
            Notification('error', shop_data_error);
        }
    };

    const getDeptDataTrigger = async () => {
        try {
            let deptData = [];
            deptData = await getAllDeptData(0, 100000, '','ASC');
            setDepartmentsData(deptData.data);
        } catch (err) {
            console.log(err);
            Notification('error', department_data_error);
        }
    };

    const getClassDataTrigger = async () => {
        try {
            let classData = [];
            classData = await getAllClassData(0, 100000, '','ASC');
            setClassesData(classData.data);
        } catch (err) {
            console.log(err);
            Notification('error', class_data_error);
        }
    };

    const getSubClassDataTrigger = async () => {
        try {
            let subclassData = [];
            subclassData = await getAllSubclassData(0, 100000, '','ASC');
            setSubClassesData(subclassData.data);
        } catch (err) {
            console.log(err);
            Notification('error', subclass_data_error);
        }
    };

    const getManufacturerDataTrigger = async () => {
        try {
            let manufData = [];
            manufData = await getAllManufData(0, 100000, '','ASC');
            setManufacturersData(manufData.data);
        } catch (err) {
            console.log(err);
            Notification('error', manufacturer_data_error);
        }
    };

    const getBrandDataTrigger = async () => {
        try {
            let brandsData = [];
            brandsData = await getAllBrandsData(0, 100000, '','ASC');
            setBrandsData(brandsData.data);
        } catch (err) {
            console.log(err);
            Notification('error', brand_data_error);
        }
    };
    const filters = {
        data: {
            sbu: "",
            shop: "",
            department: "",
            class: "",
            subclass: "",
            manufacturer: "",
            brand: "",
            sku: "",
            publish: "",
            pricing_rule: ""
        }
    };
    const getSkuDataTrigger = async () => {
        try {
            setLoadingReferenceData(true);
            let skuData = [];
            const filter = `{
                data : {
                    sbu: "${filters.data.sbu}",
                    shop: "${filters.data.shop}",
                    department: "${filters.data.department}",
                    class: "${filters.data.class}",
                    subclass: "${filters.data.subclass}",
                    manufacturer: "${filters.data.manufacturer}",
                    brand: "${filters.data.brand}",
                    sku: "${filters.data.sku}",
                    publish: "${filters.data.publish}",
                    pricing_rule: "${filters.data.pricing_rule}",

                }
            }`
            skuData = await getAllSkuData(0, 1000, searchedtext,filter,'ASC');
            setSkusData(skuData.data);
            setLoadingReferenceData(false);
        } catch (err) {
            console.log(err);
            Notification('error', sku_data_error);
            setLoadingReferenceData(false);
        }
    };
    const { selectedRowKeys } = select;
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, data) => {
            setSelect({
                ...select,
                selectedRowKeys: selectedRowKeys,
                selectedRowData: data
            });
        }
    };

    let newProps = {
        level: 'merchant',
        id: '',
        count: allCountList,
        merchantId: id,
        rowSelection: rowSelection
    };

    const handleSearchChange = (value, label) => {
        const selectedvalue =
        {
            levelId: value,
            levelType: showModalFor.levelType,
            levelName: label.label,
        };
        setCurrentLevelSelected(selectedvalue);
    };

    const addSbuHandler = () => {
        if (currentLevelSelected && !selectedLevelData.some(a => a.levelId === currentLevelSelected?.levelId)) {
            const combinedData = [...selectedLevelData, { ...currentLevelSelected }]
            setSelectedLevelData(combinedData);
            setShowSelected(combinedData);
            setCurrentLevelSelected(null);
        }
    };

    const onClickAddHandler = (tableFor) => {
        setShowModalFor({ levelType: tableFor });
    };

    const onClickRemoveHandler = async () => {
        try {
            setIsDeleting(true);
            const levelIds = ['sbuId', 'shopId', 'deptId', 'classId', 'subclassId', 'manufId', 'brandId', 'skuId'];
            const descriptions = ['sbuDesc', 'shopDesc', 'deptDesc', 'classDesc', 'subclassDesc', 'manufDesc', 'brandDesc', 'skuDesc']
            const tabNumber = +tabindex - 1;
            let levelText = select?.selectedRowData?.map((level) => {
                return `{
                levelId: ${prepareString(level?.[levelIds[tabNumber]])},
                levelType: "${currentLevel}",
                levelName: ${prepareString(level?.[descriptions[tabNumber]])}
            }`;
            });
            const response = await deleteMerchantAssignlevelConfigData(sendmerchantData.merchantId, levelText.join(','));
            if (response?.deleteAssignment === 'Successfully deleted') {
                Notification('success', assignment_deletion_success);
                const levels = Object.keys(reloadData);
                setReloadHandler(levels[tabNumber], true);
                setSelect({
                    selectedRowData: [],
                    selectedRowKeys: []
                });
                getAllCountTrigger();
                setReloadData({
                    sbu: true,
                    shop: true,
                    department: true,
                    class: true,
                    subclass: true,
                    manufacturer: true,
                    brand: true,
                    sku: true
                });
            } else {
                Notification('error', assignment_deletion_error);
            }
            setIsDeleting(false);
        } catch (error) {
            console.log(error);
            setIsDeleting(false);
        }
    };
    const handleRemove = (e, item) => {
        let temp = showSelected;
        let data = temp.filter((o) => o !== item);
        setSelectedLevelData(data);
        setShowSelected(data);
    };

    const openModal = (e, levelType) => {
        setShowModalFor({ levelType: levelType });
    };

    const sendmerchantData = {
        merchantId: id,
        levels: showSelected
    };

    const items = [
        { label: <a onClick={(e) => openModal(e, 'sbu')}>SBU</a>, key: '01' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'shop')}>Shop</a>, key: '02' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'department')}>Department</a>, key: '03' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'class')}>Class</a>, key: '04' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'subclass')}>Subclass</a>, key: '05' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'manufacturer')}>Manufacturer</a>, key: '06' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'brand')}>Brands</a>, key: '07' }, //eslint-disable-line
        { label: <a onClick={(e) => openModal(e, 'sku')}>SKU</a>, key: '08' } //eslint-disable-line
    ];

    //Helper for setting setting reload state for levels
    const setReloadHandler = (level, levelState) => {
        setReloadData(prevState => {
            const state = {...prevState}
            state[level] = levelState;
            return state;
        });
    }

    const tabs = [
        {
            label: (
                <span id='merchant-sbu-tab'>                
                  <span style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}>SBU{' '}</span>
                    <Badge
                        showZero
                        className="sbu-count-badge"
                        count={allCountList?.sbuCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: (
                <MerchantTableWrapper
                    tableFor="sbu"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <SBUTable reload = {reloadData.sbu} setReload={setReloadHandler} {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-shop-tab'>
                    <span style={{ color: tabindex === '2' ? '#001952' : '#96999F' }}>Shop{' '}</span>
                    <Badge
                        showZero
                        className="shop-count-badge"
                        count={allCountList?.shopCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                            color: tabindex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '2',
            children: (
                <MerchantTableWrapper
                    tableFor="shop"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <ShopTable reload = {reloadData.shop} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-dept-tab'>
                    <span style={{ color: tabindex === '3' ? '#001952' : '#96999F' }}>Department{' '}</span>
                    <Badge
                        showZero
                        className="departments-count-badge"
                        count={allCountList?.deptCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                            color: tabindex === '3' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '3',
            children: (
                <MerchantTableWrapper
                    tableFor="department"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <DepartmentsTable reload = {reloadData.department} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-class-tab'>
                    <span style={{ color: tabindex === '4' ? '#001952' : '#96999F' }}>Class{' '}</span>
                    <Badge
                        showZero
                        className="class-count-badge"
                        count={allCountList?.classCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '4' ? '#001952' : '#EBF1FF',
                            color: tabindex === '4' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '4',
            children: (
                <MerchantTableWrapper
                    tableFor="class"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <ClassTable reload = {reloadData.class} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-subclass-tab'>
                    <span style={{ color: tabindex === '5' ? '#001952' : '#96999F' }}>Subclass{' '}</span>
                    <Badge
                        showZero
                        className="class-count-badge"
                        count={allCountList?.subclassCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '5' ? '#001952' : '#EBF1FF',
                            color: tabindex === '5' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '5',
            children: (
                <MerchantTableWrapper
                    tableFor="subclass"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <SubclassTable reload = {reloadData.subclass} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-manuf-tab'>
                    <span style={{ color: tabindex === '6' ? '#001952' : '#96999F' }}>Manufacturer{' '}</span>
                    <Badge
                        showZero
                        className="class-count-badge"
                        disabled={selectedRowKeys?.length === 0}
                        overflowCount={99999}
                        count={allCountList?.manufCount}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '6' ? '#001952' : '#EBF1FF',
                            color: tabindex === '6' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '6',
            children: (
                <MerchantTableWrapper
                    tableFor="manufacturer"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <ManufacturerTable reload = {reloadData.manufacturer} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-brand-tab'>
                    <span style={{ color: tabindex === '7' ? '#001952' : '#96999F' }}>Brand{' '}</span>
                    <Badge
                        showZero
                        className="brands-count-badge"
                        count={allCountList?.brandCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '7' ? '#001952' : '#EBF1FF',
                            color: tabindex === '7' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '7',
            children: (
                <MerchantTableWrapper
                    tableFor="brand"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <BrandsTable reload = {reloadData.brand} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        },
        {
            label: (
                <span id='merchant-sku-tab'>
                    <span style={{ color: tabindex === '8' ? '#001952' : '#96999F' }}>SKU{' '}</span>
                    <Badge
                        showZero
                        className="sku-count-badge"
                        count={allCountList?.skuCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '8' ? '#001952' : '#EBF1FF',
                            color: tabindex === '8' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '8',
            children: (
                <MerchantTableWrapper
                    tableFor="sku"
                    isDeleting={isDeleting}
                    disabled={selectedRowKeys?.length === 0}
                    onClickRemove={onClickRemoveHandler}
                    onClickAdd={onClickAddHandler}>
                    <SKUTable reload = {reloadData.sku} setReload={setReloadHandler}  {...newProps} />
                </MerchantTableWrapper>
            )
        }
    ];

    const onAddItemHandler = async () => {
        try {
            if (!isCreating && sendmerchantData.levels.length > 0) {
                setIsCreating(true);
                let levelText = sendmerchantData.levels.map((level) => {
                    return `{
                    levelId: ${prepareString(level.levelId)},
                    levelType: "${level.levelType}",
                    levelName: ${prepareString(level.levelName)}
                }`;
                });
                if(sendmerchantData.levels[0].levelType === 'sbu'){
                    let isAssignmentfailed = false;
                    for(const text in levelText){
                        const response = await updateMerchantAssignlevelConfigData(sendmerchantData.merchantId, levelText[text]);
                        if (response !== 'update successfully') {
                            isAssignmentfailed = true;
                        }
                    }
                    if(isAssignmentfailed){
                        Notification('warning', data_assignment_error);
                        setIsCreating(false); 
                    } else {
                        Notification('success', data_assignment_success);
                        //Re-triggering get data
                        setReloadHandler(showModalFor?.levelType, true);
            
                        setShowModalFor('');
                        setShowSelected([]);
                        setSelectedLevelData([]);
                        setIsCreating(false); 
                        getAllCountTrigger();
                        setReloadData({
                            sbu: true,
                            shop: true,
                            department: true,
                            class: true,
                            subclass: true,
                            manufacturer: true,
                            brand: true,
                            sku: true
                        });
                        setSearchedText('')
                    }
                } else {
                    const response = await updateMerchantAssignlevelConfigData(sendmerchantData.merchantId, levelText.join(','));
                    if (response === 'repeated sku') {
                        Notification('warning', data_assignment_error);
                        setIsCreating(false); 
                    } else {
                        Notification('success', data_assignment_success);
                        //Re-triggering get data
                        setReloadHandler(showModalFor?.levelType, true);
            
                        setShowModalFor('');
                        setShowSelected([]);
                        setSelectedLevelData([]);
                        setIsCreating(false); 
                        getAllCountTrigger();
                        setReloadData({
                            sbu: true,
                            shop: true,
                            department: true,
                            class: true,
                            subclass: true,
                            manufacturer: true,
                            brand: true,
                            sku: true
                        });
                        setSearchedText('')
                    }
                }
            } else {
                Notification('warning', assignment_item_missing_error);
            }
        } catch (error) {
            console.log(error);
            setIsCreating(false);
        }
    };

    const searchList = useMemo(() => {
        if (showModalFor.levelType === 'sbu') {
            const data = sbusData?.map((item) => {
                return {
                    key: item.sbuId,
                    value: item.sbuId,
                    label: item.sbuDesc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'shop') {
            const data = shopsData?.map((item) => {
                return {
                    key: item.shopId,
                    value: item.shopId,
                    label: item.shopDesc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'department') {
            const data = departmentsData?.map((item) => {
                return {
                    key: item.deptId,
                    value: item.deptId,
                    label: item.deptDesc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'class') {
            const data = classesData?.map((item) => {
                return {
                    key: item.classId,
                    value: item.classId,
                    label: item.classDesc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'subclass') {
            const data = subClassesData?.map((item) => {
                return {
                    key: item.subclassId,
                    value: item.subclassId,
                    label: item.subclassDesc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'manufacturer') {
            const data = manufacturersData?.map((item) => {
                return {
                    key: `${item.manufId} ${item.manufDesc}`,
                    value: `${item.manufId}_${item.manufDesc}`,
                    label: item.manufDesc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'brand') {
            const data = brandsData?.map((item) => {
                return {
                    key: `${item.brandId} ${item.brandDesc}`,
                    value: `${item.brandId}_${item.brandDesc}`,
                    label: item.brandDesc
                };
            });
            return data;
        } else if (showModalFor.levelType === 'sku') {
            const data = skusData?.map((item) => {
                return {
                    key: item.skuId,
                    value: item.skuId,
                    label: item.skuDesc
                };
            });
            return data;
        }
    }, [
        showModalFor, 
        sbusData, 
        shopsData, 
        departmentsData, 
        classesData, 
        subClassesData, 
        manufacturersData, 
        brandsData, 
        skusData
    ]);

    const onClickEditUserHandler = () => {
        setMerchantData(Object.assign({}, { ...userDetails }))
        setShowEditUserModal(true);
    }

    const onCancelModalHandler = () => {
        setShowEditUserModal(false);
    }

    const onChangeValueHandler = (field, value) => {
        try {
            setMerchantData((prevState) => {
                const state = { ...prevState };
                state[field] = value;
                return state;
            });
        } catch (err) {
            console.log(err);
        }
    };

    const onUpdateMerchantHandler = async () => {
        try {
            const merchnatName = merchantData.name.trim()
            const merchantEmail = merchantData.email.trim()
            if(!merchnatName){
                Notification('error', generic_name_error);
                return;
            }
            
            const isValidEmail = validatePetcoEmail(merchantEmail);
            if (!isValidEmail) {
                Notification('error', generic_email_error);
                return;
            }
            let data = [];
            if(merchnatName !== userDetails.name ){
                data.push({ field: 'name', value: merchnatName, })
            }
            if(merchantEmail.toLowerCase() !== userDetails.email.toLowerCase() ){
                data.push({ field: 'email', value: merchantEmail.toLowerCase() })
            }
            if(merchantData.accessLevel !== userDetails.accessLevel ){
                data.push({ field: 'edit_access', value: merchantData.accessLevel })
            }
            if(data.length > 0) {
                const res = await updateUser(id, data);
                if (res.__typename === 'Error') {
                    Notification('error', res.message);
                } else {
                    setUserDetails({ ...userDetails, ...merchantData });
                    setShowEditUserModal(false);
                    navigate(pathname, { state: { row: {...userDetails, ...merchantData} }, replace: true });
                    Notification('success', user_update_success);
                }
            } else {
                Notification('warning', user_update_warning);
                return;
            }
        } catch (err) {
            console.log(err);
        }
    }

    const onTabChange = (key) => {
        setSelect({
            selectedRowKeys: [],
            selectedRowData: []
        });
        setTabindex(key);
        if (key === '1') {
            setCurrentLevel('sbu');
        } else if (key === '2') {
            setCurrentLevel('shop');
        } else if (key === '3') {
            setCurrentLevel('department');
        } else if (key === '4') {
            setCurrentLevel('class');
        } else if (key === '5') {
            setCurrentLevel('subclass');
        } else if (key === '6') {
            setCurrentLevel('manufacturer');
        } else if (key === '7') {
            setCurrentLevel('brand');
        } else if (key === '8') {
            setCurrentLevel('sku');
        }
    };

    const onBreadClickHandler = () => {
        if (navigationType === 'PUSH') navigate(-1 ,{state:{path:location.pathname , searchParams : state.searchParams}, replace: true});
        navigate('/teams_and_merchants/merchants');
    }

    function formatTitle(input) {
        if (input === 'sku' || input === 'sbu') {
            return input.toUpperCase();
        }
        return input?.charAt(0)?.toUpperCase() + input?.slice(1);
    }

    return (
        <Spin spinning={loading}>
            <ContentWrapper>
                <div className="merchant-details-container">
                    <div className='breadcrumb-container'>
                        <Breadcrumb
                        items={[
                            {
                                title:(
                                    <div style={{ display: 'inline-flex'}}>
                                    <div style={{color: '#001952', cursor:'pointer'}} onClick={onBreadClickHandler}>
                                        <ArrowLeftOutlined/>
                                    </div>
                                    <div style={{color: '#96999F', marginLeft: 10}}>Merchant</div>
                                </div>
                                )
                            },
                            {
                                title: userDetails.name,
                                style:{ color: '#001952' }
                            }
                        ]}
                        />
                       
                    </div>
                    <div className="user-base-details">
                        <div className="name-and-actions">
                            <div className="name-container">{userDetails.name}</div>
                            <div className="actions-container">
                                {userType === userTypes.ADMIN && <Button style={{ marginRight: '16px' }} id='merchant-edit-btn' type='default' onClick={onClickEditUserHandler}>Edit/Update</Button>}
                                <Dropdown menu={{ items }} trigger={['click']}>
                                    <Button type='primary' id='merchant-assign-btn' className='assignData-btn'>
                                        + Assign Data <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="detail-container">
                            <div className="detail">
                                <div className="key">Access Type</div>
                                <div className="value">{merchantAccessDisplayTypes[userDetails.accessLevel]}</div>
                            </div>
                            <div className="detail">
                                <div className="key">Email Address</div>
                                <div className="value">{userDetails.email}</div>
                            </div>
                        </div>
                    </div>
                    <div className="user-table-container">
                        <Tabs defaultActiveKey="1" items={tabs} onChange={onTabChange} />
                    </div>
                </div>
                <Modal
                    open={showModalFor}
                    title={`Add ${formatTitle(showModalFor?.levelType)}`}
                    okText="Add Selected"
                    okButtonProps={{
                        className: "blue-btn",
                        loading: isCreating
                    }}
                    cancelButtonProps={{
                        disabled: isCreating
                    }}
                    cancelText="Close"
                    destroyOnClose={true}
                    onCancel={() => {
                        setShowModalFor('');
                        setSelectedLevelData([]);
                        setShowSelected([]);
                        setCurrentLevelSelected(null);
                    }}
                    onOk={onAddItemHandler}>
                    <div className="add-merchant-items-modal">
                        <div className="search-container">
                            <Select
                                className="selectLevel"
                                id='merchant-select-hierarchy'
                                showSearch
                                style={{
                                    width: '70%',
                                    color: 'white'
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option && option?.label && option?.label.toLowerCase().includes(input.toLowerCase()))
                                }                                                              
                                options={searchList}
                                onSearch={(value) => setSearchedText(value)}
                                onChange={(value, label) => handleSearchChange(value, label)}
                                value={currentLevelSelected?.levelId || null}
                                loading={loadingReferenceData}
                                // disabled={loadingReferenceData}
                            />
                            <Button type='primary' icon={<PlusOutlined />} id='merchant-add-btn' onClick={addSbuHandler}>
                                Add
                            </Button>
                        </div>
                        <div className="selected-items">
                            <div className="title">{`Selected ${formatTitle(showModalFor?.levelType)}'s`}</div>
                            <Divider style={{ marginBottom: 8 }} />
                            <div className="selected-items-scrollable">
                                {showSelected &&
                                    showSelected.map((item) => (
                                        <li key={item.levelId}>
                                            <p
                                                style={{
                                                    color: '#001952',
                                                    font: '10px',
                                                    margin: 0
                                                }}>
                                                {item.levelName}{' '}
                                                <CloseOutlined
                                                    style={{ float: 'right', marginRight: 16 }}
                                                    onClick={(e) => handleRemove(e, item)}
                                                />
                                            </p>{' '}
                                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                                        </li>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={showEditUserModal}
                    title="Edit Merchant"
                    okText="Update"
                    cancelText="Cancel"
                    onCancel={onCancelModalHandler}
                    onOk={onUpdateMerchantHandler}>
                    <div className="pricing-engine-form">
                        <div className="input-container">
                            <div className="label" style={{ marginTop: 32 }} >Merchant Name</div>
                            <div>
                                <Input
                                    onChange={(e) => {
                                        onChangeValueHandler('name', e.target.value);
                                    }}
                                    value={merchantData.name}
                                    id='merchant-edit-name'
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="label">Merchant Email Address</div>
                            <div>
                                <Input
                                    value={merchantData.email}
                                    id='merchant-edit-email'
                                    onChange={(e) => {
                                        onChangeValueHandler('email', e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="label">Access level</div>
                            <div>
                                <Select
                                    placeholder="Select Access Level"
                                    id='merchant-edit-access'
                                    style={{
                                        width: '100%'
                                    }}
                                    value={merchantData.accessLevel}
                                    options={(merchantAccessTypes)?.map((item) => ({
                                        value: item.value,
                                        label: item.label
                                    }))}
                                    onChange={(value) => {
                                        onChangeValueHandler('accessLevel', value);
                                    }}
                                />
                                <div className="label">{accessTypeDescriptions[merchantData.accessLevel]}</div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </ContentWrapper>
        </Spin>
    );
};

export default MerchantDetails;
