import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    level: '',
    index: -1, 
    search: '', 
    sort: '',
    manufList: [],
    count: 0
};

const manufSlice = createSlice({
    name: 'manufacture',
    initialState,
    reducers: {
        setManufData(state, { payload }) {
            state.level = payload.level;
            state.index = payload.index;
            state.search = payload.search;
            state.sort = payload.sort;
            state.manufList = payload.manufData;
            state.count = payload.count;
        },

        updateManufList(state, { payload }) {
            state.manufList = payload.manufList
        },

        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const ManufActions = manufSlice.actions;
export const ManufReducer = manufSlice.reducer;
export default manufSlice;
