import { GraphQLClient } from 'graphql-request';
import { authMiddleware } from '../middlewares/auth.middleware';
import { GRAPHQL_URL} from '../constants/url'

let GraphQL = new GraphQLClient(GRAPHQL_URL, {
    requestMiddleware: authMiddleware
});

//Creating new connection when change in token
window.addEventListener('storage', () => {
    GraphQL = new GraphQLClient(GRAPHQL_URL, {
        requestMiddleware: authMiddleware
    });
})

export default GraphQL;