import { gql } from 'graphql-request';

//Login query creator
export const getActiveScrapers = () => {
    try {
        return gql`
            query {
                getAllScrapers {
                    data {
                        name
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};
