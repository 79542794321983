import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeId: '',
    activeDesc: ''
};

const activeIdSlice = createSlice({
    name: 'activeId',
    initialState,
    reducers: {
        setActiveId(state, { payload }) {
            state.activeId = payload.activeId;
        },
        setActiveName(state, { payload }) {
            state.activeDesc = payload.activeName;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const ActiveIdActions = activeIdSlice.actions;
export const ActiveIdReducer = activeIdSlice.reducer;
export default activeIdSlice;
