import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

//Get all Brands Data
export const createGetAllBrandsDataQuery = (index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllBrands(index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetBrandsDataByIdQuery =  (brandId, brandDesc) => {
    try {
        return gql`
            query {
                getLevelDetails(level: "brand", levelId: "${brandId}", levelDesc: "${brandDesc}") {
                    levelId
                    levelDesc
                    skuCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetBrandAtSbuDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllBrandsAtSbu(sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetBrandAtShopDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllBrandsAtShop(shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetBrandAtDeptDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllBrandsAtDept(deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetBrandAtClassDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllBrandsAtClass(classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetBrandAtSubclassDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllBrandsAtSubclass(subclassId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetBrandAtManufDataQuery = (id, desc, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllBrandsAtManufacturer(manufactureId:"${id}", manufactureDesc:"${desc}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignBrandDataQuery = (merchantId, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignBrands(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignBrandAtSbuDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignBrandsAtSbu(merchantId: "${merchantId}", sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignBrandAtShopDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignBrandsAtShop(merchantId: "${merchantId}", shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignBrandAtDeptDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignBrandsAtDept(merchantId: "${merchantId}", deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignBrandAtClassDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignBrandsAtClass(merchantId: "${merchantId}", classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignBrandAtSubclassDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignBrandsAtSubclass(merchantId: "${merchantId}", subclassId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignBrandAtManufDataQuery = (merchantId, id, desc, index, size, search, sortType) => {
    desc = prepareString(desc);
    try {
        return gql`
            query {
                getAllAssignBrandsAtManufacturer(merchantId: "${merchantId}", manufactureId:"${id}", manufactureDesc:${desc}, index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        brandId
                        brandDesc
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};