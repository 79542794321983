import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate , useNavigationType} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Tabs } from 'antd';
import './PetcoData.scss';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import BrandsTable from '../../components/Tables/Brands.table';
import DepartmentsTable from '../../components/Tables/Departments.table';
import SBUTable from '../../components/Tables/SBU.table';
import SKUTable from '../../components/Tables/SKU.table';
import ClassTable from '../../components/Tables/Class.table';
import ShopTable from '../../components/Tables/Shop.table';
import SubclassTable from '../../components/Tables/Subclass.table';
import ManufacturerTable from '../../components/Tables/Manufacturer.table';

import { Notification } from '../../utilities';
import { swapKeyValue } from '../../utilities/helper';
import { getAllAssignedCountData, getAllCountData } from '../../graphql/queryExecuters';
import { AllCountActions } from '../../redux/slices/allCount.slice';
import { userTypes } from '../../constants/common';
import {count_list_error} from "../../constants/displayStrings";

const PetcoData = (props) => {
    const location = useLocation();
    //const { state } = location;
    const navigationType = useNavigationType();
    const dispatch = useDispatch();
    const navigate = useNavigate();    
    const { allCountList } = useSelector((state) => state.allCount);

    const { userType, id } = useSelector(state => state.auth);

    const [tabindex, setTabindex] = useState(props.tabindex);

    const pathsToIndices = {
      '/sku_data/sbu' : 1,
      '/sku_data/shop' : 2,
      '/sku_data/department' : 3,
      '/sku_data/class' : 4,
      '/sku_data/subclass' : 5,
      '/sku_data/manufacturer' : 6,
      '/sku_data/brand' : 7,
      '/sku_data/sku' : 8
    }

    const indicesToPaths = swapKeyValue(pathsToIndices)

    useEffect(() => {
      const newTabIndex = pathsToIndices[location.pathname] || '1'
      if (tabindex !== newTabIndex) {
        onTabChange(String(newTabIndex))
      }
    }, [location]); //eslint-disable-line

    useEffect(() => {
        if (allCountList.length === 0) {
            getAllCountTrigger();
        }
    }, [tabindex, userType]); //eslint-disable-line

    // On reload page  ink bar may break. To fix that, below useeffect will help
    useEffect(() => {
        const newTabIndex = String(pathsToIndices[location.pathname] || '1')
        if (newTabIndex !== tabindex) {
          onTabChange(tabindex);
        }

        if (navigationType === 'POP' && location?.search === '?') {
          navigate(location?.pathname)
        }

        if (location?.search === '?') {
          navigate(location?.pathname)
        }
    }, [tabindex]); //eslint-disable-line 

    const getAllCountTrigger = async () => {
        try {
            let allCountData = null;
            if(userType) {
                if (userType === userTypes.MERCHANT) {
                    allCountData = await getAllAssignedCountData(id);
                } else {
                    allCountData = await getAllCountData();
                }
                if (allCountData) {
                    dispatch(AllCountActions.setAllCountData({ allCountData: allCountData }));
                }
            }
        } catch (err) {
            console.log(err);
            Notification('error', count_list_error);
        }
    };

    const onTabChange = (key) => {
        setTabindex(key);
        const pathToRoute = indicesToPaths[key] || "/sku_data/sku"
        if (location.pathname !== pathToRoute) {
          navigate({pathname: pathToRoute , search: location.search})
        }
    };
    
    const newProps = {
        level: 'all',
        count: allCountList,
        merchantId: ''
    }

    const tabs = [
        {
            label: (
                <span>                  
                  <span style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}>SBU{' '}</span>
                  <Badge
                        showZero
                        className="sbu-count-badge"
                        count={allCountList?.sbuCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
              ),
            key: '1',
            id: 'sbu-tab',
            children: <SBUTable {...newProps} />
        },
        {
            label: (
                <span>
                    <span style={{ color: tabindex === '2' ? '#001952' : '#96999F' }}>Shop{' '}</span>
                    <Badge
                        showZero
                        className="shop-count-badge"
                        count={allCountList?.shopCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                            color: tabindex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '2',
            id: 'shop-tab',
            children: <ShopTable {...newProps} />
        },
        {
            label: (
                <span>
                    <span style={{ color: tabindex === '3' ? '#001952' : '#96999F' }}>Department{' '}</span>
                    <Badge
                        showZero
                        className="departments-count-badge"
                        count={allCountList?.deptCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                            color: tabindex === '3' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '3',
            id: 'dept-tab',
            children: <DepartmentsTable {...newProps} />
        },
        {
            label: (
                <span>
                    <span style={{ color: tabindex === '4' ? '#001952' : '#96999F' }}>Class{' '}</span>
                    <Badge
                        showZero
                        className="class-count-badge"
                        count={allCountList?.classCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '4' ? '#001952' : '#EBF1FF',
                            color: tabindex === '4' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '4',
            id: 'class-tab',
            children: <ClassTable {...newProps} />
        },
        {
            label: (
                <span>
                    <span style={{ color: tabindex === '5' ? '#001952' : '#96999F' }}>Subclass{' '}</span>
                    <Badge
                        showZero
                        className="class-count-badge"
                        count={allCountList?.subclassCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '5' ? '#001952' : '#EBF1FF',
                            color: tabindex === '5' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '5',
            id: 'subclass-tab',
            children: <SubclassTable {...newProps} />
        },
        {
            label: (
                <span>
                    <span style={{ color: tabindex === '6' ? '#001952' : '#96999F' }}>Manufacturer{' '}</span>
                    <Badge
                        showZero
                        className="class-count-badge"
                        overflowCount={99999}
                        count={allCountList?.manufCount}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '6' ? '#001952' : '#EBF1FF',
                            color: tabindex === '6' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '6',
            id: 'manuf-tab',
            children: <ManufacturerTable {...newProps} />
        },
        {
            label: (
                <span>
                    <span style={{ color: tabindex === '7' ? '#001952' : '#96999F' }}>Brand{' '}</span>
                    <Badge
                        showZero
                        className="brands-count-badge"
                        count={allCountList?.brandCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '7' ? '#001952' : '#EBF1FF',
                            color: tabindex === '7' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '7',
            id: 'brand-tab',
            children: <BrandsTable {...newProps} />
        },
        {
            label: (
                <span>
                    <span style={{ color: tabindex === '8' ? '#001952' : '#96999F' }}>SKU{' '}</span>
                    <Badge
                        showZero
                        className="sku-count-badge"
                        count={allCountList?.skuCount}
                        overflowCount={500000}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '8' ? '#001952' : '#EBF1FF',
                            color: tabindex === '8' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '8',
            id: 'sku-tab',
            children: <SKUTable {...newProps} />
        }
    ];

    return (
        <ContentWrapper>
            <div className="petco-data-container">
                <Tabs animated activeKey={tabindex} defaultActiveKey={tabindex} items={tabs} onChange={onTabChange} />
            </div>
        </ContentWrapper>
    );
};

export default PetcoData;
