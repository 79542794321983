import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

//Login query creator
export const createLoginQuery = (username, password) => {
    try {
        return gql`
        mutation {
            login (username: "${username}", password:"${password}") {
                __typename
                ...on LoginDetails {
                    id
                    name
                    email
                    userType
                    accessRole
                    createdBy
                    editAccess
                    accessToken
                    refreshToken
                }
                ... on Error {
                    status
                    message
                }
            }
          }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const forgotPasswordQuery = (email) => {
    try {
        return gql`
        mutation {
            resetPassword (email: "${email}") {
                __typename
                ...on defaultMessage {
                    message
                }
                ... on Error {
                    status
                    message
                }
            }
          }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const updatePasswordQuery = (key, newpassword) => {
    try {
        return gql`
        mutation {
            updatePassword (key: "${key}", newpassword: "${newpassword}") {
                __typename
                ...on defaultMessage {
                    message
                }
                ... on Error {
                    status
                    message
                }
            }
          }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Add User
export const createAddUserQuery = (name, username, userType, accessRole, accesslevel = null) => {
    try {
        return gql`
        mutation {
            addUser (name: "${name}", email: "${username}", userType: "${userType}", accessRole: "${accessRole}" editAccess: "${accesslevel ? accesslevel : ''}") {
                __typename
                ...on Error {
                    status
                    message
                }
                ...on defaultMessage {
                    message
                }
            }
        }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateUserQuery = (userId, fieldsToUpdate) => {
    return gql`
        mutation {
            updateUserInfo (userId: ${userId}, 
            fieldsToUpdate: [${fieldsToUpdate
                .map(
                    (updation) =>
                        `{field: "${updation.field}", value: ${
                            typeof updation.value === 'string'
                                ? `"${updation.value}"`
                                : updation.value
                        }}`
                )
                .join(', ')}]) 
            {
                __typename
                ...on Error {
                    status
                    message
                }
                ...on defaultMessage {
                    message
                }
            }
        }
        `;
};

export const createGetCurrentUserQuery = () => {
    return gql`
        query {
            getCurrentUser {
                __typename
                ... on UserDetails {
                    id
                    name
                    email
                    userType
                    accessRole
                    createdBy
                    editAccess
                }
            }
        }
    `;
};

export const createGetUsersListQuery = (userType, pageNo, pageSize, searchText, sortType = 'ASC') => {
    return gql`
    query {
        getUsersList(userParam: "${userType}", pageno: ${pageNo}, pagesize: ${pageSize}, search: ${prepareString(searchText)}, sortType: "${sortType}") {
            __typename
            ...on userInfo {
                count
                user {
                    id
                    email
                    name
                    createdBy
                    createdAt
                    updatedBy
                    updatedAt
                    invitationStatus
                    editAccess
                    lastLogin
                    isActive
                    sbuCount
                    shopCount
                    deptCount
                    classCount
                    subclassCount
                    manufCount
                    brandCount
                    skuCount
                    accessRole
                    userType
                }
            }
        }
      }
    `;
};

export const createGetUserByIdQuery = id => {
    return gql`
    query {
        getUserById(id: ${id}) {
            __typename
            ...on UserDetails {
                id
                name
                email
                userType
                accessRole
                createdBy
                editAccess
                invitationStatus
                lastLogin
            }
            ...on Error {
                status
                message
            }
        }
    }
    `;
};

export const createResetTokenQuery = refreshToken => {
    try {
        return gql`
        mutation {
            createAccessToken (refreshToken: "${refreshToken}") {
                __typename
                ...on LoginDetails {
                    accessToken
                    refreshToken
                }
                ... on Error {
                    status
                    message
                }
            }
          }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createValidateAccessTokenQuery = token => {
    try {
        return gql`
        mutation {
            validateAccessToken (token: "${token}") {
                status
                id
                name
                email
                userType
                accessRole
                createdBy
                editAccess
                accessToken
                refreshToken
            }
          }
        `;
    } catch (err) {
        console.log(err);
    }
};
