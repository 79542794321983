import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getlevelLogsCount = async (configid, configlevel) => {
    try {
        const query = QueryCreators.createGetLevelLogsCountQuery(configid, configlevel);
        const response = await GraphQL.request(query);
        return response.getLevelLogsCount;
    } catch (err) {
        console.log(err);
    }
};

export const getSKUScraperLogsCount = async (configid) => {
    try {
        const query = QueryCreators.createGetSKUScraperLogsCountQuery(configid);
        const response = await GraphQL.request(query);
        return response.getSkuScraperCount;
    } catch (err) {
        console.log(err);
    }
};

export const getChildSKUCount = async (configid) => {
    try {
        const query = QueryCreators.createGetChildSKUCountQuery(configid);
        const response = await GraphQL.request(query);
        return response.getChildSkuCount;
    } catch (err) {
        console.log(err);
    }
};

export const getlevelLogsData = async (configid, configlevel,index, size) => {
    try {
        const query = QueryCreators.createGetLevelLogsQuery(configid, configlevel,index, size);
        const response = await GraphQL.request(query);
        return response.getLogsByLevel;
    } catch (err) {
        console.log(err);
    }
};

export const getEngineLogsData = async (configid, configlevel,index, size) => {
    try {
        const query = QueryCreators.createGetEngineLogsQuery(configid, configlevel,index, size);
        const response = await GraphQL.request(query);
        return response.getLogsByReprice;
    } catch (err) {
        console.log(err);
    }
};

export const getUserLogsCount = async (userid) => {
    try {
        const query = QueryCreators.createGetUserLogsCountQuery(userid);
        const response = await GraphQL.request(query);
        return response.getLogsByUser;
    } catch (err) {
        console.log(err);
    }
};

export const getUserLogs = async (userId, eventType, configType, index, size, searchText) => {
    try {
        const query = QueryCreators.createGetUserLogsQuery(userId, size, index, eventType, configType, searchText);
        const response = await GraphQL.request(query);
        return response.getLogsByUser;
    } catch (err) {
        console.log(err);
    }
};

export const getLogFiltersByUser = async (userId) => {
    try {
        const query = QueryCreators.createGetLogFiltersQuery(userId);
        const response = await GraphQL.request(query);
        return response.getLogFiltersByUser;
    } catch (err) {
        console.log(err);
    }
};