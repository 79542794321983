import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation , useNavigationType, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TAB_NAME, merchantAccess, priceRuleDesc, userTypes, dataConfigDrawerWidth, DELAY_TIME} from '../../constants/common';
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Row,
    Tag,
    Typography,
    Drawer,
    Tabs,
    Badge,
    Divider,
    Spin
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import CompetitorsTable from '../../components/Tables/Competitors.table';
import PriceConfigure from '../../components/Forms/PriceConfigure.form';
import ScrapersCompetitors from '../../components/Forms/ScrapersCompetitors.form';
import BrandsTable from '../../components/Tables/Brands.table';
import SKUTable from '../../components/Tables/SKU.table';
import OverrideModal from '../../components/Modals/OverrideModal';
import './LevelDetails.scss';
import { useDispatch } from 'react-redux';

import {
    getCompConfigData,
    getAllCompetitors,
    getAllScrapers,
    getPricelevelConfigData,
    getTempGuardrailData,
    getlevelLogsCount,
    checkMerchantAccess,
    getManufDetails
} from '../../graphql/queryExecuters';
import { ScrapersActions } from '../../redux/slices/scrapers.slice';
import { CompetitorsActions } from '../../redux/slices/competitors.slice';
import { Notification, capitalizeFirstLetter, decodeString } from '../../utilities';
import ApprovalTable from '../../components/Tables/Approval.table';
import LevelConfigLogs from '../../components/Tables/levelConfigLogs.table';
import {
    competitor_data_error,
    log_count_data_error,
    price_config_data_error,
    temp_guardrail_data_error,
    bulk_config_for_merchant_hierarchy_tooltip
} from "../../constants/displayStrings";
import Barricade from '../../components/Barricade/Barricade';

const ManufacturerDetails = () => {
    const level = 'manufacturer';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tabindex, setTabindex] = useState('1');
    const location = useLocation();
    const { uid } = useParams();
    const { state } = location;
    const navigationType = useNavigationType();
    const [historyIndex, setHistoryIndex] = useState(0);

    const { Text } = Typography;
    const { competitors } = useSelector((state) => state.competitors);
    const { activeScrapers } = useSelector((state) => state.scrapers);
    const { userType, editAccess, id } = useSelector(state => state.auth);

    const [configDataModal, setConfigDataModal] = useState(false);
    const [loading, setLoading] = useState(false);

    //Temp Guardrail Loader
    const [tempGuardrailLoading, setTempGuardrailLoading] = useState(false);

    const [configData, setConfigData] = useState({});
    const [compData, setCompData] = useState({});
    const [guardData, setGuardData] = useState({});
    const [logsCounts, setLogsCounts] = useState({});

    const [reloadPriceConfig, setReloadPriceConfig] = useState(false);
    const [reloadCompConfig, setReloadCompConfig] = useState(false);
    const [reloadTempConfig, setReloadTempConfig] = useState(false);
    const [reloadLog, setReloadLog] = useState(false);
    const [manufDetails, setManufDetails] = useState({});


    //Helper for Show Page for Merchant
    const [isMerchantHasAccess, setMerchantAccess] = useState(null);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    useEffect(() => {
        if (navigationType === 'PUSH') {
            setHistoryIndex(current => current - 1);
        } else if (navigationType === 'POP') {
            setHistoryIndex(current => Math.min(current + 1, -1));
        }
    }, [location.search, location.pathname]); //eslint-disable-line

    useEffect(() => {
        getSBUDetailsTrigger();
    }, []); //eslint-disable-line
    useEffect(() => {
        if (isMerchantHasAccess && JSON.stringify(manufDetails) !== '{}') getConfigDataTrigger();
    }, [reloadPriceConfig, isMerchantHasAccess, manufDetails]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess && JSON.stringify(manufDetails) !== '{}') getCompConfigDataTrigger();
    }, [reloadCompConfig, isMerchantHasAccess, manufDetails]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess && JSON.stringify(manufDetails) !== '{}') getLogsCounts();
    }, [reloadLog, isMerchantHasAccess, manufDetails]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess && JSON.stringify(manufDetails) !== '{}') getTempGuardrailDataTrigger();
    }, [reloadTempConfig, isMerchantHasAccess, manufDetails]); //eslint-disable-line

    useEffect(() => {
        checkMerchantAccessTrigger();
    }, [userType, id]); // eslint-disable-line

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getSBUDetailsTrigger = async () => {
        try {
            if (state?.row) {
                if (Object.keys(state.row).length > 2) {
                    setManufDetails(state.row);
                    return;
                }
            }
            if (uid) {
                setLoading(true);
                const splitIndex = uid.indexOf('_');
                const manufId = uid.substring(0, splitIndex);
                const manufDesc = decodeString(uid.substring(splitIndex + 1));
                const manufData = await getManufDetails(manufId, manufDesc);
                setManufDetails({...manufData, manufId: manufData.levelId, manufDesc: manufData.levelDesc});
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            console.log(err)
        }
    }

    const checkMerchantAccessTrigger = async () => {
        try {
            if (userType && id) {
                if (userType !== userTypes.MERCHANT) {
                    setMerchantAccess(true);
                } else {
                    if (isMerchantHasAccess === null) {
                        const isAccessGranted = await checkMerchantAccess(id, level, manufDetails.manufId || uid.split('_')[0], manufDetails.manufDesc || uid.split('_')[1]);
                        setMerchantAccess(isAccessGranted);
                    }
                }
            }
        }  catch (err) {
            console.log('Error - Error while getting merchant access', err);
        }
    }

    const getConfigDataTrigger = async () => {
        try {
            let configData = await getPricelevelConfigData(manufDetails.manufId, manufDetails.manufDesc, level);
            setConfigData(configData);
        } catch (err) {
            console.log(err);
            Notification('error', price_config_data_error);
        }
    };

    const getCompConfigDataTrigger = async () => {
        try {
                setLoading(true)
                if (competitors?.length === 0) {
                    const competitorsData = await getAllCompetitors();
                    if (competitorsData) {
                        dispatch(CompetitorsActions.setCompetitors({ competitors: competitorsData.data }));
                    }
                }
                if (activeScrapers.length === 0) {
                    const scrapersData = await getAllScrapers();
                    const activeScraper = scrapersData.data.filter((scrap) => scrap.status);
                    if (activeScraper) {
                        dispatch(ScrapersActions.setActiveScrapers({ activeScrapers: activeScraper }));
                    }
                }
                let detailData = await getCompConfigData(manufDetails.manufId, manufDetails.manufDesc, level);
                let activeCompdata = [];
                if (detailData && detailData.activeComp) {
                    activeCompdata = detailData.activeComp.map((comp, index) => {
                        let temp = {
                            index: index,
                            competitor: comp.competitor,
                            priority: comp.scraper,
                            adderText: comp.adderText,
                            adderValue: comp.adderValue,
                            incart: comp.incartMatch,
                            pdp: comp.pdpMatch,
                            lastScrapedDate: comp.lastScrapedDate,
                            scrapeExpiration: comp.scrapeExpiryHour
                        };
                        return temp;
                    });
                }
                let activeScraperdata = [];
                if (detailData && detailData.activeScraper) {
                    activeScraperdata = detailData.activeScraper.map((scraper, index) => {
                        let temp = {
                            name: scraper.scraper,
                            isActive: scraper.isActive
                        };
                        return temp;
                    });
                }
                let lastScrapedata = {};
                if (detailData && detailData.activeScraper) {
                    detailData.activeScraper.forEach((scraper) => {
                        if (scraper.scraper === 'bungeetech') {
                            lastScrapedata = {
                                competitor: scraper.competitor ? scraper.competitor : 'All',
                                lastScrapedDate: scraper.lastScrapedDate ? scraper.lastScrapedDate : '-',
                                scrapeExpiryHour: scraper.scrapeExpiryHour ? scraper.scrapeExpiryHour : '-',
                                scrapeRunStatus: scraper.scrapeRunStatus ? 'Active' : 'Disable',
                                scrapeError: scraper.scrapeError
                            };
                        }
                    });
                }
                const uniqueScrapersData = activeScraperdata.filter((item) => item.isActive===true)
                setCompData({
                    activeCompetitor: activeCompdata,
                    activeScraper: uniqueScrapersData,
                    lastScrape: lastScrapedata
                });
                setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err);
            Notification('error', competitor_data_error);
        }
    };

    const getTempGuardrailDataTrigger = async () => {
        try {
            setTempGuardrailLoading(true);
            const tempGuardrailData = await getTempGuardrailData(manufDetails.manufId, manufDetails.manufDesc, level);
            setGuardData(tempGuardrailData);
            setTempGuardrailLoading(false);
        } catch (err) {
            setTempGuardrailLoading(false);
            console.log(err);
            Notification('error', temp_guardrail_data_error);
        }
    };

    const getLogsCounts = async () => {
        try {
            const logsCount = await getlevelLogsCount(manufDetails.manufId, level);
            setLogsCounts(logsCount)
        } catch (err) {
            console.log(err);
            Notification('error', log_count_data_error);
        }
    }

    const handleConfigDataUpdate = async (data) => {
        await delay(DELAY_TIME);
        setReloadPriceConfig(!reloadPriceConfig);
        setReloadLog(!reloadLog);
    }

    const handleCompConfigDataUpdate = async (scraperData, compData) => {
        await delay(DELAY_TIME);
        setReloadPriceConfig(!reloadPriceConfig);
        setReloadCompConfig(!reloadCompConfig);
        setReloadLog(!reloadLog);
    }

    const handleTempGuardDataUpdate = async () => {
        await delay(DELAY_TIME);
        setReloadTempConfig(!reloadTempConfig);
        setReloadLog(!reloadLog);
    }

    const hideConfigDataModal = () => {
        setConfigDataModal(false);
    };

    const showConfigDataModal = () => {
        setConfigDataModal(true);
    };

    const onTabChange = (key) => {
        setTabindex(key);
    };

    const onBreadClick = () => {
        // if (navigationType === 'PUSH') navigate(-1 ,{state:{path:location.pathname , searchParams : state.searchParams}, replace: true});
        if (navigationType === 'PUSH') navigate(historyIndex ,{state: {path:location.pathname , searchParams : state.searchParams}});
        else navigate('/sku_data/manufacturer');
    };

    let newprops = {
        level: level,
        id: manufDetails.manufId,
        count: manufDetails,
        desc: manufDetails.manufDesc,
    };

    //tabs inside configureDataModal Form
    const configureModalTabs = [
        {
            label: 'Price Configure',
            key: '1',
            children: (
                <PriceConfigure
                    parentName={TAB_NAME.MANUFACTURER}
                    levelProps={{
                        configId: manufDetails.manufId,
                        configdesc: manufDetails.manufDesc,
                        configLevel: level,
                        priceConfigData: configData
                    }}
                    handleCancel={hideConfigDataModal}
                    handleOk={handleConfigDataUpdate}
                />
            )
        },
        {
            label: 'Scrapers and Competitors Configure',
            key: '2',
            children: (
                <ScrapersCompetitors
                    parentName={TAB_NAME.MANUFACTURER}
                    levelProps={{
                        configId: manufDetails.manufId,
                        configdesc: manufDetails.manufDesc,
                        configLevel: level,
                        activeScraper: compData.activeScraper,
                        activeCompetitor: compData.activeCompetitor,
                        globalScraper: activeScrapers,
                        globalCompetitor: competitors,
                    }}
                    handleCancel={hideConfigDataModal}
                    handleOk={handleCompConfigDataUpdate}
                />
            )
        }
    ];

    const tabs = [{
        label: (
            <span id='manuf-details-approvalTab'>
                Accept/Reject{' '}
                <Badge
                    showZero
                    className="Accept/Reject-count-badge"
                    count={logsCounts.approvalCount}
                    overflowCount={99999}
                    style={{
                        backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                        color: tabindex === '1' ? '#FFFFFF' : '#001952'
                    }}
                />
            </span>
        ),
        key: '1',
        children: <ApprovalTable {...newprops} totalRecords={logsCounts.approvalCount}/>
    },
    {
        label: (
            <span id='manuf-details-levelConfigTab'>
                Manufacturer Configuration Logs{' '}
                <Badge
                    showZero
                    className="configurationLogs-count-badge"
                    count={logsCounts.logCount}
                    overflowCount={99999}
                    style={{
                        marginLeft: 8,
                        backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                        color: tabindex === '2' ? '#FFFFFF' : '#001952'
                    }}
                />
            </span>
        ),
        key: '2',
        children: <LevelConfigLogs {...newprops} totalRecords={logsCounts.logCount}/>
    },
    {
        label: (
            <span id='manuf-details-brandsTab'>
                {' '}
                Brand{' '}
                <Badge
                    showZero
                    className="brands-count-badge"
                    count={manufDetails.brandCount}
                    overflowCount={99999}
                    style={{
                        backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                        color: tabindex === '3' ? '#FFFFFF' : '#001952'
                    }}
                />
            </span>
        ),
        key: '3',
        children: <BrandsTable {...newprops} />
    },
    {
        label: (
            <span id='manuf-details-skuTab'>
                {' '}
                SKU{' '}
                <Badge
                    showZero
                    className="sku-count-badge"
                    count={manufDetails.skuCount}
                    overflowCount={99999}
                    style={{
                        backgroundColor: tabindex === '4' ? '#001952' : '#EBF1FF',
                        color: tabindex === '4' ? '#FFFFFF' : '#001952'
                    }}
                />
            </span>
        ),
        key: '4',
        children: <SKUTable {...newprops} />
    }
    ];

    return (
        <ContentWrapper>
            {isMerchantHasAccess === false ? <Barricade /> : <>
            <div className="manuf-details-container">
                <Breadcrumb 

                items={[
                    {
                        title: (
                            <div style={{ display: 'inline-flex'}}>
                            <div style={{color: '#001952', cursor:'pointer'}} onClick={onBreadClick}>
                                <ArrowLeftOutlined/>
                            </div>
                            <div style={{color: '#96999F', marginLeft: 10}}>Manufacturers</div>
                        </div>
                        )
                    },
                    {
                        title: manufDetails.manufDesc,
                        style: { color: '#001952' }
                    }
                ]}
                />
                    
            </div>
            <div
                className="sbu-details-title"
                style={{
                    display: 'flex',
                }}>
                <p style={{ marginTop: 14 }}>{manufDetails.manufDesc}</p>
            </div>
            <div className="sbu-details-configureSBU">
                <Card>
                    <Spin spinning={loading}>
                        <Row>
                            <Col span={4}>
                                <p className="sbu-details-configureSBU-title">Configure Manufacturer</p>
                            </Col>
                            <Col span={12} />
                            <Col span={8}>
                                <div className="configureSBU-btns">
                                    {!(userType === userTypes.MERCHANT && editAccess === merchantAccess.VIEW) && <Button
                                        type='primary'
                                        id='manuf-details-config-btn'
                                        className="sbu-details-configureSBU-data-points-btn"
                                        onClick={showConfigDataModal}
                                        disabled={userType === userTypes.MERCHANT}
                                        title={userType === userTypes.MERCHANT ? bulk_config_for_merchant_hierarchy_tooltip : ''}>
                                        Configure
                                    </Button>}
                                    <Drawer
                                        open={configDataModal}
                                        onClose={hideConfigDataModal}
                                        title="Data Configuration"
                                        onCancel={hideConfigDataModal}
                                        width={dataConfigDrawerWidth}
                                        footer={[]}>
                                        <Tabs defaultActiveKey="1" items={configureModalTabs} />
                                    </Drawer>
                                </div>
                            </Col>
                        </Row>
                        <Row className="configureSBU-EADS-guardrail-cards">
                            {/* divider format below */}
                            <Col span={7} className="eads-model">
                                <Text className='sbu-sub-title'>EADS Model</Text>
                                <Row>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Markdown price</p>
                                        <p>
                                            {configData && configData.markdownPrice
                                                ? 'ON'
                                                : 'OFF'}
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Base Price</p>
                                        <p>
                                            {configData && configData.basePrice
                                                ? 'ON'
                                                : 'OFF'}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={1}>
                                {' '}
                                <Divider
                                    type="vertical"
                                    style={{
                                        height: '95%'
                                    }}
                                />
                            </Col>
                            <Col span={7} className="price-rule">
                                <Text className='sbu-sub-title'>Price Rule</Text>
                                <Row>
                                    <Col span={22}>
                                        <p className="whiteSmokeText">Price Rule</p>
                                        <p>
                                            {configData && configData.priceRule
                                                ? priceRuleDesc[configData.priceRule]
                                                : priceRuleDesc['do_not_match_competitor']}
                                        </p>
                                    </Col>
                                    <Col span={2} />
                                </Row>
                            </Col>
                            <Col span={1}>
                                {' '}
                                <Divider
                                    type="vertical"
                                    style={{
                                        height: '95%'
                                    }}
                                />
                            </Col>

                            <Col span={8} className="default-guardrail">
                                <Text className='sbu-sub-title'>Default Guardrail</Text>
                                <Row>
                                    <Col span={8}>
                                        <p className="whiteSmokeText">Default Min</p>
                                        <p>
                                            {configData &&
                                                configData.defaultGuardMin
                                                ? configData.defaultGuardMin
                                                : 'MAP'}{' '}
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <p className="whiteSmokeText">Default Max</p>
                                        <p>
                                            {configData &&
                                                configData.defaultGuardMax
                                                ? configData.defaultGuardMax
                                                : 'MSRP'}{' '}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                    <Divider />
                        <Row className="configureSBU-priceRule-scraper-cards">
                            <Col span={12} className="scrapers">
                                <Text className='sbu-sub-title'>Scrapers</Text>
                                <Row>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Active Scrapers</p>
                                        <div style={{ marginTop: '-12px', marginLeft: '-2px' }}>
                                            {(compData.activeScraper && compData.activeScraper.length > 0) ?
                                                compData.activeScraper.map((scraper) => {
                                                    return (
                                                        <Tag
                                                            color="#ECF2FF"
                                                            key={scraper.name}
                                                            className="scraperTag">
                                                            {capitalizeFirstLetter(scraper.name)}
                                                        </Tag>
                                                    );
                                                }) : 
                                                <div style={{ marginLeft: '20px' }}>{'---'}</div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    <Divider />
                    <br />
                    <Row className="configureSBU-competitorsList-card">
                        <Col span={24}>
                            <Text
                                style={{
                                    fontWeight: 400,
                                    fontSize: '18px',
                                    lineHeight: '21px'
                                }}>
                                Enabled Competitors
                            </Text>
                            <br />
                            <br />
                            <CompetitorsTable
                                className="competitors-table"
                                loading={loading}
                                data={
                                    compData.activeCompetitor &&
                                    compData.activeCompetitor.map((comp) => {
                                        let temp = {
                                            competitor: comp.competitor,
                                            priority: comp.priority,
                                            adder:
                                                (comp.adderText.includes('Add') ? '+' : '-') +
                                                comp.adderValue +
                                                (comp.adderText.includes('$') ? '$' : '%'),
                                            incart: comp.incart ? 'On' : 'Off',
                                            pdp: comp.pdp ? 'On' : 'Off',
                                            lastScrapedDate: comp.lastScrapedDate,
                                            scrapeExpiration: comp.scrapeExpiryHour
                                                ? comp.scrapeExpiryHour
                                                : '-'
                                        };
                                        return temp;
                                    })
                                }
                            />
                        </Col>
                    </Row>
                </Card>
            </div>
            <div className="sbu-temporaryGuardRail-rescrape-cards">
                <Row>
                    <Col span={12}>
                        <Spin spinning={tempGuardrailLoading}>
                            <Card className="sbu-temporaryGuardrail-card">
                                    <Row className="row-1">
                                        <Col span={16}>
                                            <h2>Temporary Guardrail</h2>
                                        </Col>
                                        <Col span={8}>
                                            <OverrideModal
                                                levelProps={{
                                                    configId: manufDetails.manufId,
                                                    configdesc: manufDetails.manufDesc,
                                                    configLevel: level,
                                                    tempGuardData: guardData
                                                }}
                                                callback={() => {
                                                    handleTempGuardDataUpdate();
                                                }}
                                            />
                                        </Col>
                                    </Row>
    
                                    <Row className="row-2">
                                        <Col className="whiteSmokeText" span={12}>
                                            <p>Status</p>
                                            <p>
                                                {guardData && (dayjs.tz(guardData.startTempGuardDate) <= dayjs.tz()) && (dayjs.tz(guardData.endTempGuardDate) >= dayjs.tz()) ? 'Active' : 'Disabled'}
                                            </p>
                                        </Col>
    
                                        <Col className="whiteSmokeText" span={12}>
                                            <p>Temporary Max</p>
                                            <p>
                                                {guardData &&
                                                    guardData.tempGuardMax
                                                    ? guardData.tempGuardMax
                                                    : '-'}{' '}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="row-3">
                                        <Col className="whiteSmokeText" span={12}>
                                            <p>Expiry Date</p>
                                            <p>
                                                {guardData &&
                                                    guardData.startTempGuardDate
                                                    ? dayjs.tz(guardData.startTempGuardDate).format('MM/DD/YYYY')
                                                    : ''}{' '}
                                                -{' '}
                                                {guardData &&
                                                    guardData.endTempGuardDate
                                                    ? dayjs.tz(guardData.endTempGuardDate).format('MM/DD/YYYY')
                                                    : ''}
                                            </p>
                                        </Col>
                                        <Col className="whiteSmokeText" span={12}>
                                            <p>Temporary Min</p>
                                            <p>
                                                {guardData &&
                                                    guardData.tempGuardMin
                                                    ? guardData.tempGuardMin
                                                    : '-'}{' '}
                                            </p>
                                        </Col>
                                    </Row>
                            </Card>
                        </Spin>
                    </Col>
                </Row>
            </div>
            <br />
            <br />
            <Tabs className="level-tables-wrapper" defaultActiveKey="1" items={tabs} onTabClick={onTabChange} />
            </>}
        </ContentWrapper>
    );
};

export default ManufacturerDetails;
