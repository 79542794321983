import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getlevelAlerts = async (skuId, index, size, sortType) => {
    try {
        const query = QueryCreators.createGetLevelAlertsQuery(skuId, index, size, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAlertsBySku;
    } catch (err) {
        console.log(err);
    }
};

export const getUserAlerts = async (userId, index, size, searchText, sortType) => {
    try {
        const query = QueryCreators.createGetUserAlertsQuery(userId, index, size, searchText, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAlertsByUser;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAlerts = async (userId, index, size, searchText, sortType) => {
    try {
        const query = QueryCreators.createGetAllAlertsQuery(userId, index, size, searchText, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAlerts;
    } catch (err) {
        console.log(err);
    }
};

export const getLiveAlertsCount = async () => {
    try {
        const query = QueryCreators.createGetLiveAlertsCountQuery();
        const response = await GraphQL.request(query);
        return response.getUnreadAlerts;
    } catch (err) {
        console.log(err);
    }
}

export const updateLiveAlertsCount = async () => {
    try {
        const query = QueryCreators.createUpdateLiveAlertsCountQuery();
        const response = await GraphQL.request(query);
        return response.markUnreadAlert;
    } catch (err) {
        console.log(err);
    }
}

export const createUnmatchTicket = async (skuId, ticketDetails) => {
    try {
        const query = QueryCreators.createUnmatchTicketQuery(skuId, ticketDetails);
        const response = await GraphQL.request(query);
        return response.createUnmatchTicket;
    } catch (err) {
        console.log(err);
    }
}
