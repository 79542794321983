import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';

import '../../containers/Login/Login.scss';
const Barricade = () => {

    const navigate = useNavigate();
    return (
        <div className="login-page-container">
            <div className="frame-75">
                <Result
                    status="warning"
                    title="Not Authorized"
                    subTitle="You are not authorized to see this page since your are not assigned."
                    extra={
                        <Button type="primary" key="console" onClick={() => {navigate('/')}}>
                          Go Home
                        </Button>
                      }
                />
            </div>
        </div>
    );
};
export default Barricade;
