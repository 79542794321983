import { gql } from 'graphql-request';

export const createUpdateAssignMerchantLevelConfigQuery = (merchantid, levelsdata) => {

    try {
        return gql`
            mutation {
                createAssignment(
                merchantId: "${merchantid}",  
                levels: [
                      ${levelsdata}
                  ]
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const deleteMerchantLevelConfigQuery = (merchantid, levelsdata) => {

    try {
        return gql`
            mutation {
                deleteAssignment(
                merchantId: "${merchantid}",  
                levels: [
                      ${levelsdata}
                  ]
                )
              }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const createCheckMerchantAccessQuery = (merchantId, levelType, levelId, levelDesc = '') => {
    try {
        return gql`
        query {
            checkMerchantAccess (merchantId: "${merchantId}", levelType: "${levelType}", levelId: "${levelId}", levelDesc: "${levelDesc}")
        }
        `;
    } catch (err) {
        console.log(err);
    }
}