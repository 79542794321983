import React, {useEffect, useState} from "react";
import { Pagination, Checkbox, Spin, Divider } from 'antd';

import './Tables.scss'
import { priceRequest } from "../../constants/common";

const CreatedByTable = ({userData, level, handleCheck, updateTagValues}) => {
    const [pageIndex, setPageindex] = useState(1);
    const [rowCount, setRowCount] = useState(0)
    const pageSize = priceRequest.pageSize
    const loading = false

    useEffect(() => {
        setRowCount(userData.length)
    }, [pageIndex, userData.length])

    useEffect(() => {
        setPageindex(1);
    }, [userData, pageSize]);

    const getDataForCurrentPage = () => {
        const startIndex = (pageIndex -1 ) * pageSize;
        const endIndex = startIndex + pageSize;
        return userData.slice(startIndex, endIndex)
    }

    const handleCheckboxSelection = async (checkedValues) => {
        const status = {
            value: checkedValues.target.options[0].value,
            checked: checkedValues.target.checked
        }
        updateTagValues(status)
    };

    const paginatedData = getDataForCurrentPage();
    
    return (
        <div className="table-container">
                <Spin spinning={loading}>
                    {paginatedData.map((user) => {
                        return (
                            <li
                                key={user.label + user.value}
                                style={{
                                    color: '#001952',
                                    font: '10px',
                                    marginBottom: '10px'
                                }}>
                                {
                                    <Checkbox
                                        key={user.desc}
                                        checked={handleCheck(user.label, user.value, 'created-by')}
                                        options={[
                                            {
                                                label: user.label,
                                                value: {
                                                    desc: user.label,
                                                    id: user.value,
                                                    level: 'created-by'
                                                }
                                            }
                                        ]}
                                        onChange={handleCheckboxSelection}>
                                        {user.label}
                                    </Checkbox>
                                }{' '}
                                <Divider style={{margin: '15px'}} />
                            </li>
                        );
                    })}
                </Spin>
            {rowCount > 10 ? <Pagination
                    className="petco-Data-pagination"
                    id="createdBy-alerts-filter"
                    defaultCurrent={pageIndex}
                    defaultPageSize={pageSize}
                    total={rowCount}
                    onChange={(event) => {setPageindex(event)}}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
        </div>
    )
}

export default CreatedByTable;