import { ScrapersActions } from '../slices/scrapers.slice';
import * as QueryExecuters from '../../graphql/queryExecuters';

//Action creator for getting scrapers base details like sku scraped, competitors
export const getScrapersBaseData = () => {
    return async (dispatch, getState) => {
        try {
            const { scrapersBaseDetails } = getState().scrapers;
            if (!scrapersBaseDetails.length) {
                const scrapersBaseData = await QueryExecuters.getAllScrapersBaseData();
                if (scrapersBaseData) {
                    dispatch(ScrapersActions.setScrapersBaseDetails({scrapersBaseDetails: scrapersBaseData}));
                }
            }
        } catch (err) {
            console.log('Error - Scraper Actions - Getting Scraper Data Error - ', err);
        }
    };
};