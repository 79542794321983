import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';

import ReScrape from '../Forms/ReScrape.form';

import './CompTableReScrapeModal.scss';
import { ScrapperStackIcon } from '../../constants/tableColumns';
import { updateCompetitorTrigger } from '../../graphql/queryExecuters';
import { Notification } from '../../utilities';
import { merchantAccess, userTypes } from '../../constants/common';

const CompTableReScrapeModal = ({configId, configdesc, configLevel, scraper, competitor, disableReScraperButton, callback}) => {

    const nullState = {
        competitors: [competitor],
        expiryHour: null,
        allCompetitors: false
    }

    const [formData, setFormData] = useState({...nullState});

    const [disableAction, setDisableAction] = useState(disableReScraperButton);

    const [compTableReScrapeModal, setCompTableReScrapeModal] = useState(false);

    const {userType, editAccess} = useSelector(state => state.auth);

    const handleReScrapeCancel = () => {
        setCompTableReScrapeModal(false);
    };
    const handleReScrapeModal = () => {
        setFormData({...nullState});
        setCompTableReScrapeModal(true);
    };

    const onChangeValueHandler = (field, value) => {
        try {
            setFormData(prevState => {
                const state = {...prevState};
                state[field] = value;
                return state;
            })
        } catch (err) {
            console.log(err);
        }
    }

    const onSubmitFormHandler = () => {
        try {
            if(formData.allCompetitors || formData.competitors.length > 0) {
                const expiry = formData.expiryHour ? formData.expiryHour.format('HH:mm') : '';
                updateCompetitorTrigger(configId, configdesc, configLevel, competitor, scraper, expiry);
                setDisableAction(true)
                setCompTableReScrapeModal(false);
                callback();
                Notification('success', 'Re-scrape successfully triggered.');
            } else {
                Notification('warning', 'Please select atleast one competitor.');
            }
        } catch (err) {
            console.log(err);
            Notification('error', 'Something went wrong while intiating re-scraping');
        }
    }

    return (
        <>
            {!(userType === userTypes.MERCHANT && editAccess === merchantAccess.VIEW) ? <Button
                size="small"
                onClick={handleReScrapeModal}
                loading={disableAction}
                disabled={disableAction}
                style={{
                    border: 'none',
                    background: '#F5EDCE',
                    color: 'black',
                }}
                icon={<ScrapperStackIcon />}
            >
                {disableAction ? 'Re Scraping' : 'Re Scrape'}
            </Button> : '---'}
            <Modal
                open={compTableReScrapeModal}
                title="Trigger Re-Scrape"
                onCancel={handleReScrapeCancel}
                width={600}
                footer={[
                    <Button className="closeBtn" key="close" onClick={handleReScrapeCancel}>
                        Close
                    </Button>,
                    <Button className="tiggerRescrapeBtn" key="triggerRescrape" type="primary" onClick={onSubmitFormHandler}>
                        Trigger Re-Scrape
                    </Button>
                ]}>
                {/* rescrape form inside modal */}
                <ReScrape modalFor="competitor" formData={formData} onChange={onChangeValueHandler}/>
            </Modal>
        </>
    );
};

export default CompTableReScrapeModal;
