import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    subscriptions: []
};

const subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        //Setting logs state
        setSubscriptionsData(state, { payload }) {
            state.subscriptions = payload.subscriptionsData;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const SubscriptionsActions = subscriptionSlice.actions;
export const SubscriptionsReducer = subscriptionSlice.reducer;
export default subscriptionSlice;