import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    index: -1, 
    search: '', 
    sort: '',
    merchantsList: [],
    merchantCount: 0
};

const merchantsSlice = createSlice({
    name: 'merchants',
    initialState,
    reducers: {
        setMerchants(state, { payload }) {
            state.index = payload.index;
            state.search = payload.search;
            state.sort = payload.sort;
            state.merchantsList = payload.merchantsList;
            state.merchantCount = payload.merchantCount;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const merchantsActions = merchantsSlice.actions;
export const merchantsReducer = merchantsSlice.reducer;
export default merchantsSlice;
