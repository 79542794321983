import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';

import '../../containers/Login/Login.scss';

const ErrorPage = () => {

    const navigate = useNavigate();

    return (
        <div className="login-page-container">
            <div className="frame-75">
                <Result
                    status="500"
                    title="500"
                    subTitle="Something Went Wrong. Please try again later."
                    extra={
                        <Button type="primary" key="console" onClick={() => {navigate('/')}}>
                          Go Home
                        </Button>
                    }
                />
            </div>
        </div>
    );
};
export default ErrorPage;
