import { useState, useEffect } from 'react';
import { Badge, Spin, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import Competitors from '../Competitors/Competitors';
import Scrapers from '../Scrapers/Scrapers';
import { ScrapersActions } from '../../redux/slices/scrapers.slice';
import {
    getAllCompetitors,
    getAllScrapers
} from '../../graphql/queryExecuters';
import { CompetitorsActions } from '../../redux/slices/competitors.slice';
import { Notification } from '../../utilities';
import { useNavigate } from 'react-router-dom';
import { userTypes } from '../../constants/common';

const ScrapersAndCompetitors = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Helper for loader
    const [loading, setLoading] = useState(false);

    const [tabIndex, setTabIndex] = useState(props.tabindex);

    const { scrapers } = useSelector((state) => state.scrapers);

    const { competitors } = useSelector((state) => state.competitors);

    const { userType } = useSelector(state => state.auth);

    const onTabChange = (key) => {
        setTabIndex(key);
        if(key==='1'){
            navigate('/scrapers_and_competitors/scrapers');
        }
        else if(key==='2'){
            navigate('/scrapers_and_competitors/competitors');
        }
        else{
            navigate('/scrapers_and_competitors/scrapers');
        }
    };

    const tabs = [
        {
            label: (
                <span>
                    <span style={{ color: tabIndex === '1' ? '#001952' : '#96999F' }}>
                        Scrapers{' '}
                    </span>
                    <Badge
                        showZero
                        overflowCount={10000000}
                        count={scrapers?.length}
                        style={{
                            backgroundColor: tabIndex === '1' ? '#001952' : '#EBF1FF',
                            color: tabIndex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: <Scrapers />
        },
        {
            label: (
                <span>
                    <span style={{ color: tabIndex === '2' ? '#001952' : '#96999F' }}>
                        Competitors{' '}
                    </span>
                    <Badge
                        showZero
                        overflowCount={10000000}
                        count={competitors?.length}
                        style={{
                            backgroundColor: tabIndex === '2' ? '#001952' : '#EBF1FF',
                            color: tabIndex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '2',
            children: <Competitors />
        }
    ];

    //Redirecting user if not a valid user
    useEffect(() => {
        if (userType === userTypes.MERCHANT) {
            navigate('/');
        }
    }, [userType]) //eslint-disable-line

    //Get all scrapers and COmpetitors initator
    useEffect(() => {
        getAllScrapersandCompetitorsTrigger();
    }, []); //eslint-disable-line

    //Getting all scrapers and competitors
    const getAllScrapersandCompetitorsTrigger = async () => {
        try {
            setLoading(true);
            if (competitors?.length === 0) {
                const competitorsData = await getAllCompetitors();
                if (competitorsData) {
                    dispatch(CompetitorsActions.setCompetitors({ competitors: competitorsData.data }));
                }
            }
            if (scrapers?.length === 0) {
                const scrapersData = await getAllScrapers();
                if (scrapersData) {
                    dispatch(ScrapersActions.setScrapers({ scrapers: scrapersData.data }));
                }
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting scrapers and competitors. Please try again later.'
            );
            setLoading(false);
        }
    };
    return (
        <Spin spinning={loading}>
            <ContentWrapper>
                <Tabs
                    animated
                    items={tabs}
                    defaultActiveKey={tabIndex}
                    onChange={onTabChange}
                />
            </ContentWrapper>
        </Spin>
    );
};

export default ScrapersAndCompetitors;
