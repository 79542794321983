import { fixedMinLimit, marginMaxLimit, marginMinLimit } from "./common";

//Notifications
export const required_error = 'Please enter the required fields.';
export const unit_cost_error = 'No base cost';
export const map_error = 'No MAP price';
export const msrp_error = 'No MSRP price';
export const rdPrice_error = 'No RD price available';
export const rd_error = 'RD percentage should be greater than or equal to 0';
export const rd_error_max = 'RD percentage should be less than or equal to 50';
export const rd_error_no_data = 'Please Fill The Price For Subsequent RD';
export const rd_error_no_date = 'Please Fill The Date For Subsequent RD Price';
export const rd_fields_missing_error = 'Please Fill The Subsequent RD Fields';
export const price_interval_missing = 'Please fill the price & interval';

//Competitor Config Notifications
export const fill_empty_fields = 'Please enter the required fields.';
export const multiple_config_error = 'multiple configurations are added for same competitor.';
export const missing_scraper_priority = 'Please select the scraper priority for all competitors.';
export const missing_adder_value = 'Please fill adder value for all competitors';
export const competitor_config_updation_failed = 'Scrapers and competitors update has failed';
export const competitor_config_updation_success = 'Scrapers and competitors data updated successfully';
export const error_getting_comp_config_data = 'Something went wrong while getting scraper Data. Please try again later.';

// Guardrail
export const default_max_guardrail_error = 'Default guardrail max price should be valid.'
export const default_min_guardrail_error = 'Default guardrail min price should be valid.'
export const min_max_guardrail_error = 'Guardrail min price is greater than guardrail max price.';
export const min_price_valid_error = 'Guardrail min price should be valid';
export const max_price_valid_error = 'Guardrail max price should be valid';
export const min_margin_price_valid_error = `Guardrail min margin should be between ${marginMinLimit}% and ${marginMaxLimit}%`
export const fixed_price_valid_error = `Guardrail fixed type should be greater than ${fixedMinLimit}`
export const max_margin_price_valid_error = `Guardrail max margin should be between ${marginMinLimit}% and ${marginMaxLimit}%`
export const guardrail_update_error = 'Guardrail override failed. Please try again later.';
export const guardrail_update_success = 'Guardrail override  successful';
export const guardrail_delete_success = 'Guardrail deletion successful';
export const guardrail_delete_error = 'Guardrail deletion failed. Please try again later.';
export const interval_error = 'Please provide a start date and end date.';
export const margin_limit_error = `Temp min/max margin should be between ${marginMinLimit}% and ${marginMaxLimit}%`;

// Fetch Errors
export const sbu_data_error = 'Something went wrong while getting SBU data. Please try again later.'
export const shop_data_error = 'Something went wrong while getting Shop data. Please try again later.'
export const department_data_error = 'Something went wrong while getting Department data. Please try again later.'
export const class_data_error = 'Something went wrong while getting Class data. Please try again later.'
export const subclass_data_error = 'Something went wrong while getting Subclass data. Please try again later.'
export const manufacturer_data_error = 'Something went wrong while getting Manufacturer data. Please try again later.'
export const brand_data_error = 'Something went wrong while getting Brand data. Please try again later.'
export const sku_data_error = 'Something went wrong while getting SKU data. Please try again later.';
export const competitor_data_error = 'Something went wrong while getting Competitor data. Please try again later.';
export const alert_logs_data_error = 'Something went wrong while getting Alert logs. Please try again later.'
export const log_count_data_error = 'Something went wrong while getting Log Count. Please try again later.'
export const reprice_status_error = 'Something went wrong while getting Reprice Queue status. Please try again later.'
export const guardrail_data_error = 'Something went wrong while getting Guardrail data. Please try again later.';
export const temp_guardrail_data_error = 'Something went wrong while getting Temp Guardrail data. Please try again later.';

export const subscription_data_error = 'Something went wrong while getting Subscription data. Please try again later.';
export const price_config_data_error = 'Something went wrong while getting Price Configuration data. Please try again later.';
export const comp_data_error = 'Something went wrong while getting Competitor data. Please try again later.';
export const scraper_data_error = 'Something went wrong while getting Scraper data. Please try again later.';
export  const count_list_error = 'Something went wrong while getting Count List. Please try again later.'
export const sku_id_not_found = 'SkuId Not Found'

//Labels
export const competitors = 'Competitor';
export const adder = 'Adder';
export const scraper_priority = 'Scraper Priority';
export const incart = 'InCart';
export const pdp = 'PDP';
export const temp_min = 'Temporary Min';
export const temp_max = 'Temporary Max';
export const value = 'Value';
export const interval_text = 'Start Date - End Date';
export const project_min_price = 'Projected min price: ';
export const project_max_price = 'Projected max price: ';
export const close = 'Close';
export const override_guardrail = 'Override Guardrail';
export const remove_guardrail = 'Remove Override';
export const match_issues = 'Match Issues';
export const no_price_skus = 'SKUs w/o Recommended Price';
export const petco_product_link = 'Product Link';
export const competitor_product_link = 'Competitor Product Link';
export const description = 'Description';
export const created_by = 'Created By';
export const created_at = 'Created At';
export const updated_by = 'Updated By';
export const updated_at = 'Updated At';
export const sku_duplication = 'SKU Duplication';
export const engine_trigger = 'Engine Trigger';
export const all_users = 'All Users';

//info/Warning
export const guardrail_info = 'Only the modified values will be temporarily overridden for the specific duration';
export const override_info = 'Apply configuration changes to preconfigured SKUs';
export const margin_warning = 'Enter valid percentage';
export const create_price_alert_warning_1 = 'Please enter any one Trigger Value.';
export const create_price_alert_warning_2 = 'Please select an expiry date to a create Price Alert.';
export const sku_duplication_warning = 'Some of the SKUs have multiple entries.';
export const map_price_required = 'MAP price is required';
export const msrp_price_required = 'MSRP price is required';
export const temp_guard_min_max_warning = 'Temporary Guardrail Min greater than Max';
export const default_guard_min_max_warning = 'Default Guardrail Min greater than Max';

// SKU
export const price_info = 'Modified prices will be temporarily overridden for the specific duration';
export const sku_upload_success = 'SKUs uploaded successfully';
export const competitor_creation_success = 'Competitor created successfully';
export const competitor_creation_error = 'Unable to create competitor. Please try again later.';
export const recommended_price_empty= 'Please check logs'
export const re_price_in_queue = 'Reprice request in Queue';
export const re_price_in_process = 'Reprice request in Progress';
export const manual_price = 'Manual Price';
export const price_reason = 'Price Reason';

// Generic
export const update_failed_generic = 'Something went wrong while update the data. Please try again later.';
export const update_success_generic = 'Updated successfully';
export const highlighted_fields_error = 'Please enter the required highlighted fields';

// Bulk Configuration
export const bulk_configuration_update_error = 'Bulk Configuration failed For some SKU Ids. Please try again later.';
export const bulk_configuration_update_success = 'Bulk Configuration Applied successfully';

// Login
export const generic_name_error = 'Please provide a proper name.'
export const login_error = 'Unable to login. Please try again later.';
export const password_error = 'Please provide a proper password.';
export const password_change_success = 'Password changed successfully. Please try logging in again.'
export const confirmation_password_error = 'Password and confirmation password do not match.'
export const hampered_url_error = 'The URL has been hampered.'
export const generic_email_error = 'Please provide a proper email.';
export const petco_email_error = 'Please provide a proper \'@petco.com\' email.'
export const unregistered_email_id_error = 'Email ID is not registered.';
export const email_sent_success = 'Email sent successfully';
export const petco_merchant_email_error = 'Merchant emails must belong to the \'@petco.com\' domain.'
export const merchant_properties_error = 'Merchant name and email are mandatory fields.'
export const invitation_success = 'Invitation sent successfully'

//Reset / Create Password
export const password_reset_success = 'Your password has been reset successfully.';
export const password_create_success = 'Your password has been created successfully.';

// Data Assignment
export const data_assignment_error = "Data has been previously assigned. Please remove it and try again.";
export const data_assignment_success = 'Data assignment completed successfully';
export const assignment_item_missing_error = 'Please add at least one item to assign data.'
export const assignment_deletion_success = 'Assignment successfully deleted'
export const assignment_deletion_error = 'Something went wrong while deleting the assignment data. Please try again later.'

// User
export const users_fetch_error = 'Something went wrong while getting users. Please try again later.'
export const user_update_success = 'User updated successfully'
export const user_update_warning = 'No Changes in User details'
export const user_details_error = 'Error while getting user details. Please try again later.'
export const invalid_token_error = 'Invalid token error.'
export const invalid_user_error = 'Invalid user.'

// Subscription
export const subscription_update_success = 'Subscription updated successfully'
export const unsubscribed_successfully = 'Unsubscribed successfully'
export const alert_subscription_error = 'Failed to subscribe to this alert. Please try again later.'

// Competitors
export const default_adder_update_success = 'Default adder updated successfully'
export const adder_update_error = 'Something went wrong while updating the adder.'
export const proper_adder_error = 'Please select the proper adder to update.'
export const adder_value_error = 'Please enter the proper adder value.'
export const  competitor_name_empty = 'Competitor name cannot be empty.'
export const  competitor_with_name_exist = 'Competitor with this name already exists.'
export const  scraper_name_empty = 'Scraper name cannot be empty.'

// Price Request 
export const price_update_error = 'Something went wrong while updating price request Data. Please try again later.'
export const price_update_success = 'Price request status updated successfully'

// Reprice Status warning

export const repriceWarningStatus = 'For upcoming intraday sync, cutoff time to finish submitting your changes is showTime PT. Changes made after this time will be processed during the next run.'
export const nextDayRepriceWarningStatus = 'After 7 PM PT, repricing process will use next day’s SKU configuration.'

//User selected price rule messages
export const match_lowest_message = 'User selected price rule: Match Lowest Competitor.'
export const match_second_lowest_message = 'User selected price rule: Match Second Lowest Competitor.'
export const manual_price_with_market_tracking = 'User selected price rule: Manual Price W/ Market Tracking'

// tooltips
export const bulk_config_for_merchant_hierarchy_tooltip = 'Use Bulk Configuration to make hierarchy-level configuration changes'