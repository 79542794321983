import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllClassData = async (index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllClassDataQuery(index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllClasses;
    } catch (err) {
        console.log(err);
    }
};

export const getClassDetails = async (classId, classDesc) => {
    try {
        const query = QueryCreators.createGetClassDetailsQuery(classId, classDesc);
        const response = await GraphQL.request(query);
        return response.getLevelDetails;
    } catch (err) {
        console.log(err);
    }
};

export const getClassAtSbuData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetClassAtSbuDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllClassesAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getClassAtShopData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetClassAtShopDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllClassesAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getClassAtDeptData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetClassAtDeptDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllClassesAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignClassData = async (merchantId, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignClassDataQuery(merchantId, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignClasses;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignClassAtSbuData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignClassAtSbuDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignClassesAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignClassAtShopData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignClassAtShopDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignClassesAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignClassAtDeptData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignClassAtDeptDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignClassesAtDept;
    } catch (err) {
        console.log(err);
    }
};