import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

export const createGetAllSkuDataQuery = (index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllSkus(index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSkuDataForSearchQuery = (index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllSkus(index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};
export const createGetSkuAtSbuDataQuery = (id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllSkusAtSbu(sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSkuAtShopDataQuery = (id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllSkusAtShop(shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSkuAtDeptDataQuery = (id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllSkusAtDept(deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSkuAtClassDataQuery = (id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllSkusAtClass(classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSkuAtSubclassDataQuery = (id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllSkusAtSubclass(subclassId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSkuAtManufDataQuery = (id, desc, index, size, search, filters, sortType) => {
    desc = prepareString(desc);
    try {
        return gql`
            query {
                getAllSkusAtManufacturer(manufactureId:"${id}", manufactureDesc:${desc}, index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSkuAtBrandDataQuery = (id, desc, index, size, search, filters, sortType) => {
    desc = prepareString(desc);
    try {
        return gql`
            query {
                getAllSkusAtBrand(brandId:"${id}", brandDesc: ${desc}, index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSkuDetailsQuery = (id) => {
    try {
        return gql`
            query {
                getSkuDetailsById(skuId:"${id}") {
                    ...on SKUDetails{
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                        manualPrice
                        manualPriceSuffix
                        manualPriceReason
                        masterSku
                        familyPriceModifier
                        familyPrice
                        familyPriceSuffix
                        tempMsrpPrice
                        msrpStartDate
                        msrpEndDate
                        tempMapPrice
                        mapStartDate
                        mapEndDate
                        tempSubRdPrice
                        subRdStartDate
                        subRdEndDate
                        tempMapHoldPrice
                        mapHoldStartDate
                        mapHoldEndDate
                        priceRule
                        isMaster
                        repriceRunStatus
                        engineSelectedPriceRule
                        greenBarStatus
                        greenBarPdp
                        greenBarDisplayType
                        tempGuardMin
                        tempGuardMinPrice
                        tempGuardMinPriceSuffix
                        tempGuardMax
                        tempGuardMaxPrice
                        tempGuardMaxPriceSuffix
                        startTempGuardDate
                        endTempGuardDate
                        lastRepricePrice
                        lastRepriceReason
            
        }
        ...on Error
        {
          status
          message
        }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignSkuDataQuery = (merchantId, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSkus(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}"){
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSkuAtSbuDataQuery = (merchantId, id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSkusAtSbu(merchantId: "${merchantId}", sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSkuAtShopDataQuery = (merchantId, id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSkusAtShop(merchantId: "${merchantId}", shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSkuAtDeptDataQuery = (merchantId, id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSkusAtDept(merchantId: "${merchantId}", deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSkuAtClassDataQuery = (merchantId, id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSkusAtClass(merchantId: "${merchantId}", classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSkuAtSubclassDataQuery = (merchantId, id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSkusAtSubclass(merchantId: "${merchantId}", subclassId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSkuAtManufDataQuery = (merchantId, id, desc, index, size, search, filters, sortType) => {
    desc = prepareString(desc);
    try {
        return gql`
            query {
                getAllAssignSkusAtManufacturer(merchantId: "${merchantId}", manufactureId:"${id}", manufactureDesc:${desc}, index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSkuAtBrandDataQuery = (merchantId, id, desc, index, size, search, filters, sortType) => {
    desc = prepareString(desc);
    try {
        return gql`
            query {
                getAllAssignSkusAtBrand(merchantId: "${merchantId}", brandId:"${id}", brandDesc:${desc}, index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSKUGraphDataQuery = (id, duration, competitors) => {
    try {
        return gql`
            query {
                getSkuGraphData(skuId: "${id}", duration: "${duration}", competitors: "${competitors}"){
                    data {
                        dayId
                        price
                        competitor
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const createBulkAddSKUDataQuery = (skuIds) => {
    try {
        return gql`
        mutation {
            getBulkSkuList (skuIds: ${prepareString(skuIds)}) {
                skuData {
                    skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                }
                unmatchedSkuData {
                    skuId
                }
                dataCount
            }
        }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const createGetAllChildSkuDataQuery = (id, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllChildSkus(skuId: "${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, ${filters && `filter: ${filters},`} sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        basePriceEnabled
                        markdownPriceEnabled
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetEligibleSKUForMasterQuery = (searchText) => {
    try {
        return gql`
        query {
            getEligibleSkuForMaster(searchText: ${prepareString(searchText)})
        }
        `;
    } catch (err) {
        console.log(err);
    }
}

export const createGetAllShopNameDataQuery = () => {
    try {
        return gql`
            query {
                getAllShops(index: 0, size: 100) {
                    data {
                        shopId
                        shopDesc
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllDeptNameDataQuery = () => {
    try {
        return gql`
            query {
                getAllDepts(index: 0, size: 100) {
                    data {
                        deptId
                        deptDesc
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetUnPricedSKUsList = (page = 0, size = 10, search = "", sortType = 'ASC') => {
    try {
        return gql`
            query {
                getUnpricedSkuList(pageIndex: ${page}, limit: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuId
                        buyerId
                        departmentId
                        classId
                        subClassId
                        skuManufacturerId
                        skuBrandId
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                        skuBuyableInd
                        skuPblshInd
                        fullImage
                        productUrlkeyword
                        cySalesUnits
                        cyCost
                        basePrice
                        costPrice
                        markdownPrice
                        productView
                        rdPrice
                        listPrice
                        mapPrice
                        last90DaysMinPrice
                        currentPrice
                        lastRepriceError
                    }
                    dataCount
                }
            }
        `
    } catch (err) {
        console.log(err);
    }
}

export const createGetAllSkuDataBulkQuery = (index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllSkusBulk(index: ${index}, size: ${size}, search: ${prepareString(search)},filter: ${filters}, sortType: "${sortType}") {
                    data {
                        skuId
                        skuDesc
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignSkuDataBulkQuery = (merchantId, index, size, search, filters, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSkusBulk(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, filter: ${filters}, sortType: "${sortType}"){
                    data {
                        skuId
                        skuDesc
                        sbuDesc
                        buyerDesc
                        departmentDesc
                        classDesc
                        subClassDesc
                        skuManufacturerDesc
                        skuBrandDesc
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetRepriceStatusQuery = skuId => {
    try {
        return gql`
            query {
                getRePriceStatusBySku(skuId: "${skuId}")
            }
        `;
    } catch (err) {
        console.log(err);
    }
};