import { Switch } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

import { Notification } from "../../utilities";
import * as QueryExecuters from '../../graphql/queryExecuters';
import { userTypes } from "../../constants/common";


const UserStateTrigger = ({ userData }) => {

    const [loading, setLoading] = useState(false);

    const [isActive, setIsActive] = useState(userData?.isActive ? true : false);

    const { id, userType } = useSelector(state => state.auth);

    //on change value handler
    const onChangeValueHandler = async value => {
        try {
            setLoading(true);
            const fieldsToUpdate = [{
                field: 'is_active',
                value: value
            }]
            const response = await QueryExecuters.updateUser(userData.id, fieldsToUpdate);
            if(response.__typename === "defaultMessage") {
                setIsActive(value);
                Notification('success', `User ${value ? 'Activated' : 'Deactivated'} Successfully.`);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification('error', 'Something Went Wrong While Update User Status. Please Try Again Later.');
            setLoading(false);
        }
    }

    return <Switch
        loading={loading}
        disabled={String(id) === String(userData.id) || userType !== userTypes.ADMIN}
        size='large'  
        checked={isActive} 
        onChange={onChangeValueHandler}
    ></Switch>;
};

export default UserStateTrigger;
