import * as Patterns from '../constants/patterns';

//Email Validator
export const validateEmail = (email) => {
    const regEx = new RegExp(Patterns.EMAIL_PATTERN);
    return regEx.test(email);
};

//Petco Email Validator
export const validatePetcoEmail = (email) => {
    const regEx = new RegExp(Patterns.PETCO_EMAIL_PATTERN);
    return regEx.test(email);
};

//Password Validator
export const validatePassword = (password) => {
    const regEx = new RegExp(Patterns.PASSWORD_MATCH_PATTERN);
    return regEx.test(password);
};

export const validateSkuSearch = (search) => {
    if (search === '') return true
    return (Patterns.NUMBER_PATTERN).test(search)
}

//URL Validator
export const validateURL = url => {
    const regEx = new RegExp(Patterns.URL_VALIDATION_PATTERN);
    return regEx.test(url);
};

//Validator for checking number inside a string greater than 0

export const positiveNumberValidator = (num) => {
    const regEx = new RegExp(Patterns.POSITIVE_NUMBER_PATTERN);
    return regEx.test(num)
}

//validator for checking negative as well as positive number in string

export const numberValidator = (num) => {
    const regEx = new RegExp(Patterns.NEGATIVE_NUMBER_PATTERN);
    return regEx.test(num)
}