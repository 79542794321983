import React, { useEffect, useState } from 'react';
import {useNavigate, useLocation, useParams, useNavigationType, Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GreenBarDisplayPdpOptions, GreenBarDisplayState, TAB_NAME, subscriptionSuffix, priceRuleDesc, price_rule_types, corpLabel, userTypes, merchantAccess, dataConfigDrawerWidth, RESCRAPE_DELAY_TIME, REPRICE_DELAY_TIME } from '../../constants/common';
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Row,
    Tag,
    Typography,
    Drawer,
    Tabs,
    Badge,
    Image,
    Divider,
    Modal,
    Select,
    InputNumber,
    DatePicker,
    Spin,
    Tooltip
} from 'antd';
import { ArrowLeftOutlined, BellOutlined, CheckSquareOutlined, WarningOutlined, LoadingOutlined, InfoCircleOutlined  } from '@ant-design/icons';
import dayjs from 'dayjs';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import CompetitorsRescrapeTable from '../../components/Tables/CompetitorsWithRescrape.table';
import SkuScraperTable from '../../components/Tables/SkuScraper.table';
import { ScrapperStackIcon } from '../../constants/tableColumns';
import PriceConfigure from '../../components/Forms/PriceConfigure.form';
import ScrapersCompetitors from '../../components/Forms/ScrapersCompetitors.form';
import ReScrapeModal from '../../components/Modals/ReScrapeModal';
import OverrideModal from '../../components/Modals/OverrideModal';

import './SKUDetails.scss';
import { useDispatch } from 'react-redux';
import {
    getSKUDetails,
    getCompConfigAtSkuData,
    getAllCompetitors,
    getAllScrapers,
    getlevelLogsCount,
    updateSubAlertData,
    getSkuSubscriptionData,
    unSubAlerts,
    checkMerchantAccess,
    getSKUScraperLogsCount,
    getChildSKUCount,
    getRepriceStatusBySku
} from '../../graphql/queryExecuters';
import { ScrapersActions } from '../../redux/slices/scrapers.slice';
import { CompetitorsActions } from '../../redux/slices/competitors.slice';

import defImage from '../../assets/images/svg/NoImage.svg';
import MatchIssuesModal from '../../components/Modals/MatchIssuesModal';
import { Notification } from '../../utilities';
import LevelConfigLogs from '../../components/Tables/levelConfigLogs.table';
import ApprovalTable from '../../components/Tables/Approval.table';
import SKUTable from '../../components/Tables/SKU.table';
import SKUGraph from '../../components/Graph/SKUGraph';
import Barricade from '../../components/Barricade/Barricade';
import * as DisplayStrings from '../../constants/displayStrings';

import { dateSkipper, encodeString , compareLog, getMsrpPriceByConfiguration, getMapPriceByConfiguration, capitalizeFirstLetter } from '../../utilities/helper';

const SKUDetails = () => {
    const level = 'sku';
    const { uid } = useParams();
    const location = useLocation();
    const navigationType = useNavigationType();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tabindex, setTabindex] = useState('0');
    const { state } = useLocation();
    const [historyIndex, setHistoryIndex] = useState(0);

    const { Text } = Typography;
    const { RangePicker } = DatePicker;

    const { competitors } = useSelector((state) => state.competitors);
    const { activeScrapers } = useSelector((state) => state.scrapers);
    const { userType, editAccess, id } = useSelector(state => state.auth);

    const [configDataModal, setConfigDataModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [configLoader, setConfigLoader] = useState(false);
    const [alertLoader, setAlertLoader] = useState(false); 

    const [submitClicked, setSubmitClicked] = useState(false);
    
    const [reloadPriceConfig, setReloadPriceConfig] = useState(false);
    const [reloadCompConfig, setReloadCompConfig] = useState(false);
    const [reloadSubConfig, setReloadSubConfig] = useState(false);
    const [reloadLog, setReloadLog] = useState(false);
    const [reloadScrapeLog, setReloadScrapeLog] = useState(false);
    const [reloadRepriceStatus, setReloadRepriceStatus] = useState(false);

    const [skuDetails, setSkuDetailData] = useState(state?.row || {});
    const [imageUrl, setImageUrl] = useState(defImage);
    const [compData, setCompData] = useState({});
    const [priceAlertCardData,setPriceAlertCardData] = useState(null);
    const [logsCounts, setLogsCounts] = useState({});
    const [scrapeCount, setScrapeCount] = useState(0);
    const [childSkuCount, setChildSkuCount] = useState(0);

    //Helpers to store MAP/MSRP Price
    const [mapPrice, setMapPrice] = useState(null);
    const [msrpPrice, setMsrpPrice] = useState(null);

    const [skuDesc, setSkuDesc] = useState(state?.row?.skuDesc || '');

    const [repriceStatus, setRepriceStatus] = useState(2);

    //Helper for Show Page for Merchant
    const [isMerchantHasAccess, setMerchantAccess] = useState(null);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    //setting default data to send for subscription
    const [subscriptionInputData, setSubscriptionInputData] = useState({
        lessThanPrice: null,
        lessThanPriceSuffix: '$',
        greaterThanPrice: null,
        greaterThanPriceSuffix: '$',
        subStartDate: '',
        subEndDate: ''
    });

    useEffect(() => {
        if (navigationType === 'PUSH') {
            setHistoryIndex(current => current - 1);
        } else if (navigationType === 'POP') {
            setHistoryIndex(current => Math.min(current + 1, -1));
        }
    }, [location.search, location.pathname]); //eslint-disable-line
    
    useEffect(() => {
        if (isMerchantHasAccess) getConfigDataTrigger();
    }, [reloadPriceConfig, uid, isMerchantHasAccess]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess ) getCompConfigDataTrigger();
    }, [reloadCompConfig, uid, skuDetails.skuBrandId, skuDetails.skuBrandDesc, isMerchantHasAccess]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess) getRepriceStatus();
    }, [reloadRepriceStatus, uid, isMerchantHasAccess]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess) getLogsCounts();
    }, [reloadLog, uid, isMerchantHasAccess]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess) getScraperLogsCounts();
    }, [reloadScrapeLog, uid, isMerchantHasAccess]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess) getChildLogsCounts();
    }, [uid, isMerchantHasAccess]); //eslint-disable-line

    useEffect(() => {
        if (isMerchantHasAccess) getAlertSubscriptionDataTrigger();
    }, [reloadSubConfig, uid, isMerchantHasAccess]); //eslint-disable-line

    useEffect(() => {
        checkMerchantAccessTrigger();
    }, [userType, id]); // eslint-disable-line

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [uid]);

    const getConfigDataTrigger = async () => {
        try {
            setConfigLoader(true);
            let skuData = await getSKUDetails(uid);
            if(skuData.status === 404){
                Notification('error', DisplayStrings.sku_id_not_found);
            } else {
                skuData && setSkuDetailData(skuData);
                skuData.skuDesc && setSkuDesc(skuData.skuDesc);
                skuData.fullImage ? setImageUrl(skuData.fullImage) : setImageUrl(defImage);
                setMapPrice(getMapPriceByConfiguration(skuData));
                setMsrpPrice(getMsrpPriceByConfiguration(skuData));
            }
            setConfigLoader(false);
        } catch (err) {
            console.log(err);
            setConfigLoader(false);
            Notification('error', DisplayStrings.price_config_data_error);
        }
    };

    const getCompConfigDataTrigger = async () => {
        try {
                setLoading(true)
                if (competitors?.length === 0) {
                    const competitorsData = await getAllCompetitors();
                    if (competitorsData) {
                        dispatch(CompetitorsActions.setCompetitors({ competitors: competitorsData.data }));
                    }
                }
                if (activeScrapers.length === 0) {
                    const scrapersData = await getAllScrapers();
                    const activeScraper = scrapersData.data.filter((scrap) => scrap.status);
                    if (activeScraper) {
                        dispatch(ScrapersActions.setActiveScrapers({ activeScrapers: activeScraper }));
                    }
                }
                let detailData = await getCompConfigAtSkuData(uid , skuDetails.skuBrandId || '' , skuDetails.skuBrandDesc || '');
                let activeCompdata = [];
                if (detailData && detailData.activeComp) {
                    activeCompdata = detailData.activeComp.map((comp, index) => {
                        let temp = {
                            index: index,
                            competitor: comp.competitor,
                            priority: comp.scraper,
                            adderText: comp.adderText,
                            adderValue: comp.adderValue,
                            incart: comp.incartMatch,
                            pdp: comp.pdpMatch,
                            lastScrapedDate: comp.lastScrapedDate,
                            scrapeExpiration: comp.scrapeExpiryHour,
                            scrapeRunStatus: comp.scrapeRunStatus,
                            pdpPrice: comp.pdpPrice,
                            inCartPrice: comp.inCartPrice,
                            exactPdpPrice: comp.exactPdpPrice,
                            exactInCartPrice: comp.exactInCartPrice,
                            coupon: comp.coupon,
                            unmatchedOosReliability: comp.unmatchedOosReliability,
                        };
                        return temp;
                    });
                }
                let lowComp = null;
                if(activeCompdata.length > 0){
                    let tempComps = activeCompdata.filter(item => {
                        return item.pdpPrice !== null && 
                        (item.unmatchedOosReliability === null ||
                        item.unmatchedOosReliability === 'other' || 
                        item.unmatchedOosReliability.toLowerCase() === 'matched and instock');
                    });
                    if(tempComps.length > 0){
                        let sortComp = tempComps.sort((a, b) => a.pdpPrice - b.pdpPrice);
                        lowComp = sortComp[0];
                    }
                }
                
                let activeScraperdata = [];
                if (detailData && detailData.activeScraper) {
                    activeScraperdata = detailData.activeScraper.map((scraper, index) => {
                        let temp = {
                            name: scraper.scraper,
                            isActive: scraper.isActive
                        };
                        return temp;
                    });
                }
                let lastScrapedata = {};
                if (detailData && detailData.activeScraper) {
                    detailData.activeScraper.forEach((scraper) => {
                        if (scraper.scraper === 'bungeetech') {
                            lastScrapedata = {
                                competitor: scraper.competitor && scraper.competitor !== 'all' ? scraper.competitor : 'All',
                                lastScrapedDate: scraper.lastScrapedDate,
                                scrapeExpiryHour: scraper.scrapeExpiryHour ? scraper.scrapeExpiryHour : '-',
                                scrapeRunStatus: scraper.scrapeRunStatus ? 'Active' : 'Disable',
                                scrapeError: scraper.scrapeError
                            };
                        }
                    });
                }
                const uniqueScrapersData = activeScraperdata.filter((item) => item.isActive===true)
                setCompData({
                    activeCompetitor: activeCompdata,
                    activeScraper: uniqueScrapersData,
                    lastScrape: lastScrapedata,
                    lowComp: lowComp
                });
                setLoading(false)
        } catch (err) {
            setLoading(false)
            console.log(err);
            Notification('error', DisplayStrings.competitor_data_error);
        }
    };

    const getRepriceStatus = async () => {
        try {
            const status = await getRepriceStatusBySku(uid);
            setRepriceStatus(status);
            //get status every 2 sec until reprice complete
            if(status !== 2){
                handleRepriceStatus();
            }
            if(status === 2){
                if (JSON.stringify(skuDetails) !== '{}') {
                    setReloadPriceConfig(!reloadPriceConfig);
                    setReloadLog(!reloadLog);
                }
            }
        } catch (err) {
            console.log(err);
            Notification('error', DisplayStrings.reprice_status_error);
        }
    }

    const getLogsCounts = async () => {
        try {
            const logsCount = await getlevelLogsCount(uid, level);
            setLogsCounts(logsCount)
        } catch (err) {
            console.log(err);
            Notification('error', DisplayStrings.log_count_data_error);
        }
    }

    const getScraperLogsCounts = async () => {
        try {
            const count = await getSKUScraperLogsCount(uid);
            setScrapeCount(count.scrapeCount)
        } catch (err) {
            console.log(err);
            Notification('error', DisplayStrings.log_count_data_error);
        }
    }

    const getChildLogsCounts = async () => {
        try {
            const count = await getChildSKUCount(uid);
            setChildSkuCount(count.childSkuCount)
        } catch (err) {
            console.log(err);
            Notification('error', DisplayStrings.log_count_data_error);
        }
    }

    const getAlertSubscriptionDataTrigger = async () => {
        try {
            setAlertLoader(true);
            const subscriptionAlertData = await getSkuSubscriptionData(uid);
            parseSubscriptionData(subscriptionAlertData);
            setAlertLoader(false);
        } catch (err) {
            console.log(err);
            setAlertLoader(false);
            Notification('error', DisplayStrings.subscription_data_error);
        }
    };

    const checkMerchantAccessTrigger = async () => {
        try {
            if (userType && id) {
                if (userType !== userTypes.MERCHANT) {
                    setMerchantAccess(true);
                } else {
                    if (isMerchantHasAccess === null) {
                        const isAccessGranted = await checkMerchantAccess(id, level, uid);
                        setMerchantAccess(isAccessGranted);
                    }
                }
            }
        }  catch (err) {
            console.log('Error - Error while getting merchant access', err);
        }
    }

    const handleConfigDataUpdate = async (data) => {
        setReloadRepriceStatus(!reloadRepriceStatus);
        setReloadPriceConfig(!reloadPriceConfig);
        setReloadLog(!reloadLog);
    }

    const handleCompConfigDataUpdate = async (scraperData, compData) => {
        setReloadRepriceStatus(!reloadRepriceStatus);
        setReloadPriceConfig(!reloadPriceConfig);
        setReloadCompConfig(!reloadCompConfig);
        setReloadLog(!reloadLog);
    }

    const handleGuardConfigDataUpdate = async () => {
        setReloadRepriceStatus(!reloadRepriceStatus);
        setReloadPriceConfig(!reloadPriceConfig);
        setReloadLog(!reloadLog);
    }

    const onTriggerReScrapeDetailsHandler = async () => {
        await delay(RESCRAPE_DELAY_TIME);
        setReloadRepriceStatus(!reloadRepriceStatus);
        setReloadCompConfig(!reloadCompConfig);
        setReloadScrapeLog(!reloadScrapeLog);
        setReloadLog(!reloadLog);
    }

    const handleRepriceStatus = async () => {
        await delay(REPRICE_DELAY_TIME);
        setReloadRepriceStatus(!reloadRepriceStatus);
    }

    const hideConfigDataModal = () => {
        setConfigDataModal(false);
    };

    const showConfigDataModal = () => {
        setConfigDataModal(true);
    };

    const onTabChange = (key) => {
        setTabindex(key);
    };

    const onBreadClick = () => {
        // if (navigationType === 'PUSH') navigate(-1 ,{state:{path:location.pathname , searchParams : state.searchParams , level : level}, replace: true});
        if (navigationType === 'PUSH') navigate(historyIndex ,{state: {path:location.pathname , searchParams : state.searchParams}});
        else navigate('/sku_data/sku')
    };

    //tabs inside configureDataModal Form
    const configureModalTabs = [
        {
            label: 'Price Configure',
            key: '1',
            children: (
                <PriceConfigure
                    parentName={TAB_NAME.SKU}
                    levelProps={{
                        configId: uid,
                        configdesc: skuDesc,
                        configLevel: level,
                        priceConfigData: skuDetails,
                    }}
                    handleCancel={hideConfigDataModal}
                    handleOk={handleConfigDataUpdate}
                />
            )
        },
        {
            label: 'Scrapers and Competitors Configure',
            key: '2',
            children: (
                <ScrapersCompetitors
                    parentName={TAB_NAME.SKU}
                    levelProps={{
                        configId: uid,
                        configdesc: skuDesc,
                        configLevel: level,
                        activeScraper: compData.activeScraper,
                        activeCompetitor: compData.activeCompetitor,
                        globalScraper: activeScrapers,
                        globalCompetitor: competitors,
                    }}
                    handleCancel={hideConfigDataModal}
                    handleOk={handleCompConfigDataUpdate}
                />
            )
        }
    ];

    let newprops = {
        level: level,
        id: uid,
        count: childSkuCount || 0,
        handleUpdate: handleConfigDataUpdate
    };

    let scraperprops = {
        level: level,
        id: uid,
        count: scrapeCount,
    };

    let skutabs = [
        {
            label: (
                <span id='sku-details-levelConfigTab'>
                    {' '}
                    Configuration Price Log{' '}
                    <Badge
                        showZero
                        count={logsCounts?.logCount || 0}
                        style={{
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: <LevelConfigLogs {...newprops} totalRecords={logsCounts.logCount}/>
        },
        {
            label: (
                <span id='sku-details-priceLevelConfigTab'>
                    {' '}
                    Price Engine change Log{' '}
                    <Badge
                        showZero
                        count={logsCounts?.priceLogCount || 0}
                        style={{
                            backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                            color: tabindex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '2',
            children: <LevelConfigLogs {...newprops} type={'engine'} reload={repriceStatus} totalRecords={logsCounts.priceLogCount}/>
        },
        {
            label: (
                <span id='sku-details-skuScrapperTab'>
                    {' '}
                    Scrape Logs{' '}
                    <Badge
                        showZero
                        count={scrapeCount || 0}
                        style={{
                            backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                            color: tabindex === '3' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '3',
            children: <SkuScraperTable {...scraperprops}/>
        },
        {
            label: (
                <span id='sku-details-approvalTab'>
                    {' '}
                    Accept/Reject{' '}
                    <Badge
                        showZero
                        count={logsCounts?.approvalCount || 0}
                        style={{
                            backgroundColor: tabindex === '4' ? '#001952' : '#EBF1FF',
                            color: tabindex === '4' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '4',
            children: <ApprovalTable {...newprops} totalRecords={logsCounts.approvalCount} reload={repriceStatus}/>
        },
        skuDetails?.isMaster ? { label: (
                    <span id='sku-details-skuTab'>
                        {' '}
                        Child SKU{' '}
                        <Badge
                            showZero
                            count={childSkuCount || 0}
                            style={{
                                backgroundColor: tabindex === '5' ? '#001952' : '#EBF1FF',
                                color: tabindex === '5' ? '#FFFFFF' : '#001952'
                            }}
                        />
                    </span>
                ),
                key: '5',
                children: <SKUTable {...newprops}/>
        } : {}
    ];

    const handleAlertCancel = () => {
        setAlertModal(false);
        setSubmitClicked(false);
    };

    const handleAlertModal = () => {
        setAlertModal(true);
        setSubscriptionInputData({
            lessThanPrice: priceAlertCardData?.lessThanPrice ? priceAlertCardData.lessThanPrice: null,
            lessThanPriceSuffix: priceAlertCardData?.lessThanPriceSuffix ? priceAlertCardData.lessThanPriceSuffix: '$',
            greaterThanPrice: priceAlertCardData?.greaterThanPrice ? priceAlertCardData.greaterThanPrice: null,
            greaterThanPriceSuffix: priceAlertCardData?.greaterThanPriceSuffix ? priceAlertCardData.greaterThanPriceSuffix: '$',
            subStartDate: priceAlertCardData?.subStartDate ? priceAlertCardData.subStartDate: null,
            subEndDate: priceAlertCardData?.subEndDate ? priceAlertCardData.subEndDate: null
        });
    };

    //on press of apply button of alerts modal
    const ApplyAlert = async (subscriptionInputData) => {
        setSubmitClicked(true);
        if ( !subscriptionInputData.lessThanPrice && !subscriptionInputData.greaterThanPrice ) {
            Notification('error', DisplayStrings.create_price_alert_warning_1);
            return;
        }

        //Validationg for date
        if ( !subscriptionInputData.subStartDate || !subscriptionInputData.subEndDate ) {
            Notification('error', DisplayStrings.create_price_alert_warning_2);
            return;
        }

        let subscriptionData = `{
                lessThanPrice: ${subscriptionInputData.lessThanPrice},
                lessThanPriceSuffix: "${subscriptionInputData.lessThanPriceSuffix}",
                greaterThanPrice: ${subscriptionInputData.greaterThanPrice},
                greaterThanPriceSuffix: "${subscriptionInputData.greaterThanPriceSuffix}",
                subStartDate: "${subscriptionInputData.subStartDate ? dayjs.tz(subscriptionInputData.subStartDate).format('YYYY-MM-DD'): ''}",
                subEndDate: "${subscriptionInputData.subEndDate ? dayjs.tz(subscriptionInputData.subEndDate).format('YYYY-MM-DD'):  ''}"
            }`;
        try {
            setAlertLoader(true);
            const logData = compareLog('default', priceAlertCardData, subscriptionInputData, level, uid, skuDesc)
            const response = await updateSubAlertData(newprops.id, subscriptionData, logData);
            setAlertLoader(false);
            if (response.message === 'Error Updating Subscription') {
                Notification('error', DisplayStrings.alert_subscription_error);
            }
            if (response.message === 'Subscription Updated Successfully') {
                Notification('success', DisplayStrings.subscription_update_success);
            }
        } catch (e) {
            console.log(e);
            Notification('error', DisplayStrings.alert_subscription_error);
        }
        setAlertModal(false);
        setReloadSubConfig(!reloadSubConfig);
        setReloadLog(!reloadLog);
    };

    const parseSubscriptionData = (subscriptionAlertData) => {
        setPriceAlertCardData(subscriptionAlertData && subscriptionAlertData.data? subscriptionAlertData.data[0] : null);
        
    };

    //function for unsubscribing alerts
    const unsubscribeAlert = async (uid) => {
        try {
            setAlertLoader(true);
            const response = await unSubAlerts(uid);
            setAlertLoader(false);
            if (response.message === 'Subscription Deletion Failed') {
                Notification('error', DisplayStrings.alert_subscription_error);
            }
            if (response.message === 'Subscription Deleted') {
                Notification('success', DisplayStrings.unsubscribed_successfully);
                // callback()
            }
        } catch (e) {
            console.log(e);
            Notification('error', DisplayStrings.alert_subscription_error);
        }

        // }

        setAlertModal(false);
        setReloadSubConfig(!reloadSubConfig);
        setReloadLog(!reloadLog);
    };

    return (
        <ContentWrapper>
            {isMerchantHasAccess === false ? <Barricade /> : <>
                <div className="sku-details-container">
                    <Breadcrumb 
                    items = {[
                        {
                            title :(
                                <div style={{ display: 'inline-flex'}}>
                                <div style={{color: '#001952', cursor:'pointer'}} onClick={onBreadClick}>
                                    <ArrowLeftOutlined/>
                                </div>
                                <div style={{color: '#96999F', marginLeft: 10}}>SKUs</div>
                            </div>

                            )
                        },
                        {
                            title: skuDesc,
                            style:{color: '#001952'}
                        }
                    ]}
                    />
                </div>
                <div>
                <Spin spinning={configLoader}>
                <Row
                    className="row-skuTitle"
                    style={{
                        display: 'flex'
                    }}>
                    <Col span={4}>
                        <div className="sku-details-title">
                            <div style={{ marginTop: 10 }}>{uid}</div>
                            <div style={{ marginLeft: 18 }}>{skuDetails.length === 0 ? '' : (skuDetails.skuBuyableInd && skuDetails.skuPblshInd ? <Tag color="#87d068">Published</Tag> : <Tag color="#D83A56">Not Published</Tag>)}</div>
                            {repriceStatus !== 2 && <div><Tag color="orange"><LoadingOutlined /> {repriceStatus === 0 ? DisplayStrings.re_price_in_queue : DisplayStrings.re_price_in_process}</Tag></div>}
                        </div>
                    </Col>
                    <Col span={12} />
                    <Col span={8}>
                        <div className="sku-alert-productLink-btns">
                            <Button
                                className="sku-createAlert-btn"
                                id='sku-details-create-btn'
                                icon={<BellOutlined />}
                                onClick={handleAlertModal}>
                                Create
                            </Button>
                            <Modal
                                open={alertModal}
                                title="Create Price Alerts"
                                onCancel={handleAlertCancel}
                                className="alert-modal"
                                width={650}
                                footer={[]}
                            >
                                <div className="override-form">
                                        <Row className="row-1">
                                            <Col span={12} className="override-temporaryValue">
                                                <div className="override-main-label">
                                                    Trigger Value
                                                </div>
                                                <Row className="override-tempMin">
                                                    <Col span={16}>
                                                        <label>Sale Price Less than</label>
                                                        <InputNumber
                                                            value={subscriptionInputData.lessThanPrice}
                                                            min={0.0}
                                                            id='sale-price-gtvalue'
                                                            style={{ width: '193px', height: '46px' }}
                                                            onChange={(value) => {
                                                                setSubscriptionInputData({
                                                                    ...subscriptionInputData,
                                                                    lessThanPrice: value
                                                                });
                                                            }}
                                                            status={
                                                                submitClicked && !subscriptionInputData?.lessThanPrice ? 'error' : ''
                                                            }
                                                        />
                                                    </Col>
                                                    <Col span={3}>
                                                        <br/>
                                                        <Select
                                                            id='sale-price-gtType'
                                                            value={subscriptionInputData.lessThanPriceSuffix}
                                                            onChange={(value) => {
                                                                setSubscriptionInputData({
                                                                    ...subscriptionInputData,
                                                                    lessThanPriceSuffix: value
                                                                });
                                                            }}
                                                            style={{ width: '75px', height: '46px', marginRight: '12px' }}
                                                            options={subscriptionSuffix}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.props.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >=
                                                                0
                                                            }
                                                            status={
                                                                submitClicked && !subscriptionInputData?.lessThanPriceSuffix ? 'error' : ''
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row className="override-tempMax">
                                                    <Col span={16}>
                                                        <label>Sale Price Greater than</label>
                                                        <InputNumber
                                                        id='sale-price-ltvalue'
                                                        value={subscriptionInputData.greaterThanPrice}
                                                            min={0.0}
                                                            style={{ width: '193px', height: '46px' }}
                                                            onChange={(value) => {
                                                                setSubscriptionInputData({
                                                                    ...subscriptionInputData,
                                                                    greaterThanPrice: value
                                                                });
                                                            }}
                                                            status={
                                                                submitClicked && !subscriptionInputData?.greaterThanPrice ? 'error' : ''
                                                            }
                                                        />
                                                    </Col>
                                                    <Col span={3}>
                                                        <br/>
                                                        <Select
                                                        id='sale-price-ltType'
                                                        value={subscriptionInputData.greaterThanPriceSuffix}
                                                            onChange={(value) => {
                                                                setSubscriptionInputData({
                                                                    ...subscriptionInputData,
                                                                    greaterThanPriceSuffix: value
                                                                });
                                                            }}
                                                            style={{ width: '75px', height: 46, marginRight: '12px' }}
                                                            options={subscriptionSuffix}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.props.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >=
                                                                0
                                                            }
                                                            status={
                                                                submitClicked && !subscriptionInputData?.greaterThanPriceSuffix ? 'error' : ''
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={12} className="override-Date">
                                                <div className="override-main-label">
                                                    Expiry Date
                                                </div>
                                                <label>Start Date - End Date</label>
                                                <RangePicker
                                                    id='create-price-date'
                                                    value={subscriptionInputData?.subStartDate ? [dayjs.tz(subscriptionInputData?.subStartDate), dayjs.tz(subscriptionInputData?.subEndDate)]: []}
                                                    placement="topLeft"
                                                    onChange={(value) => {
                                                        setSubscriptionInputData({
                                                            ...subscriptionInputData,
                                                            subStartDate:
                                                                value ? value[0].format(
                                                                    'YYYY/MM/DD HH:mm:ss'
                                                                ) : null,
                                                            subEndDate:
                                                                value ? value[1].format(
                                                                    'YYYY/MM/DD HH:mm:ss'
                                                                ) : null
                                                        });
                                                    }}
                                                    disabledDate={dateSkipper}
                                                    style={{ width: '100%', height: '46px' }}
                                                    status={
                                                        submitClicked && (!subscriptionInputData?.subStartDate || !subscriptionInputData?.subEndDate) ? 'error' : ''
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <div className="price-alert-modal-footer-button">
                                            <Button
                                                className="action-styles"
                                                key="close"
                                                id='create-price-close'
                                                onClick={handleAlertCancel}
                                                >
                                                Close
                                            </Button>
                                            <Button
                                                type="text"
                                                id='create-price-remove'
                                                className={priceAlertCardData ? 'removeBtn action-styles': 'removeBtnDisabled action-styles'}
                                                // onClick={handleAlertCancel}
                                                disabled={priceAlertCardData ? false : true}
                                                onClick={() => unsubscribeAlert(uid)}>
                                                Remove
                                            </Button>
                                            <Button
                                                loading={alertLoader}
                                                id='create-price-apply'
                                                className="action-styles"
                                                key="triggerRescrape"
                                                type="primary"
                                                onClick={() => ApplyAlert(subscriptionInputData)}>
                                                Apply Changes
                                            </Button>
                                        </div>
                                </div>
                            </Modal>
                            <Button 
                                icon={<CheckSquareOutlined />} 
                                id='sku-details-productLink'
                                className="sku-productLink-btn" 
                                onClick={() => {
                                    if(skuDetails.productUrlkeyword) {
                                        window.open(`https://www.petco.com/shop/en/petcostore/product/${skuDetails.productUrlkeyword}`, '_blank').focus();
                                    } else {
                                        window.open(`https://www.petco.com/shop/en/petcostore`, '_blank').focus();
                                    }
                                }}
                            >
                                Product Link
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row
                    className="row-sku-detailed-description"
                    style={{
                        width: '100%'
                    }}>
                    <Col span={3} className="sku-image-wrapper">
                        <div className="productImage">
                            <Image
                                width={100}
                                src={imageUrl}
                                onError={(event) => {
                                    event.target.src = defImage;
                                    event.onerror = null;
                                }}
                                onClick={() => {
                                    if(skuDetails.productUrlkeyword) {
                                        window.open(`https://www.petco.com/shop/en/petcostore/product/${skuDetails.productUrlkeyword}`, '_blank').focus();
                                    } else {
                                        window.open(`https://www.petco.com/shop/en/petcostore`, '_blank').focus();
                                    }
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={20}>
                        <div className='box'>
                            <div className='box-item'>
                                <p className="whiteSmokeText">SKU Description</p>
                                <p>{skuDesc}</p>
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Recommended Price</p>
                                <p>
                                    {skuDetails.lastRepricePrice ? 
                                    `$ ${skuDetails?.lastRepricePrice?.toFixed(2)}` : 
                                    '---'}
                                </p>
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Recommended Price Reasons</p>
                                <p>
                                    {!skuDetails.lastRepricePrice ? DisplayStrings.recommended_price_empty
                                        : skuDetails.lastRepriceReason
                                        ? skuDetails.lastRepriceReason
                                        : DisplayStrings.recommended_price_empty}
                                </p>
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Current Price</p>
                                <p>
                                    {skuDetails.currentPrice
                                        ? `$ ${skuDetails?.currentPrice?.toFixed(2)}`
                                        : '---'}
                                </p>
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Brand</p>
                                {skuDetails.skuBrandId ? <Link 
                                        to={'/sku_data/brand/' + skuDetails.skuBrandId + '_' + encodeString(skuDetails.skuBrandDesc)} 
                                        state={{ row: {
                                            brandId:skuDetails.skuBrandId,
                                            brandDesc:skuDetails.skuBrandDesc,
                                        }}}
                                    >
                                        <u className='link'>{skuDetails.skuBrandDesc}</u>
                                    </Link> :
                                <p>{'---'}</p>}
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Class</p>
                                {skuDetails.classId ? <Link 
                                to={'/sku_data/class/' + skuDetails.classId + '_' + encodeString(skuDetails.classDesc)}
                                state={{ row: {
                                    classId:skuDetails.classId,
                                    classDesc:skuDetails.classDesc,
                                }}}
                            >
                                <u className='link'>{skuDetails.classDesc}</u>
                            </Link>:
                                <p>{'---'}</p>}
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Sub-Class</p>
                                {skuDetails.subClassId ? <Link 
                                    to={'/sku_data/subclass/' + skuDetails.subClassId + '_' + encodeString(skuDetails.subClassDesc)}
                                    state={{ row: {
                                        subCassId:skuDetails.subClassId,
                                        subClassDesc:skuDetails.subClassDesc,
                                    }}}
                                >
                                    <u className='link'>{skuDetails.subClassDesc}</u>
                                </Link>:
                                <p>{'---'}</p>}
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">SBU</p>
                                {skuDetails.sbuId ? <Link 
                                    to={'/sku_data/sbu/' + skuDetails.sbuId + '_' + encodeString(skuDetails.sbuDesc)}
                                    state={{ row: {
                                        sbuId:skuDetails.sbuId,
                                        sbuDesc:skuDetails.sbuDesc,
                                    }}}
                                >
                                    <u className='link'>{skuDetails.sbuDesc}</u>
                                </Link>:
                                <p>{'---'}</p>}
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Shop</p>
                                {skuDetails.buyerId ? <Link 
                                    to={'/sku_data/shop/' + skuDetails.buyerId + '_' + encodeString(skuDetails.buyerDesc)}
                                    state={{ row: {
                                        shopId:skuDetails.buyerId,
                                        shopDesc:skuDetails.buyerDesc,
                                    }}}
                                >
                                    <u className='link'>{skuDetails.buyerDesc}</u>
                                </Link>:
                                <p>{'---'}</p>}
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Department</p>
                                {skuDetails.departmentId ? <Link 
                                    to={'/sku_data/department/' + skuDetails.departmentId + '_' + encodeString(skuDetails.departmentDesc)}
                                    state={{ row: {
                                        deptId:skuDetails.departmentId,
                                        deptDesc:skuDetails.departmentDesc,
                                    }}}
                                >
                                    <u className='link'>{skuDetails.departmentDesc}</u>
                                </Link>:
                                <p>{'---'}</p>}
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Manufacturer</p>
                                {skuDetails.skuManufacturerId ? <Link 
                                    to={'/sku_data/manufacturer/' + skuDetails.skuManufacturerId + '_' + encodeString(skuDetails.skuManufacturerDesc)}
                                    state={{ row: {
                                        manufId:skuDetails.skuManufacturerId,
                                        manufDesc:skuDetails.skuManufacturerDesc,
                                    }}}
                                >
                                    <u className='link'>{skuDetails.skuManufacturerDesc}</u>
                                </Link>:
                                <p>{'---'}</p>}
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Lowest Price w/ Competitor</p>
                                <p>
                                    {compData.lowComp ? `$${compData.lowComp.pdpPrice.toFixed(2)}  w/ ${corpLabel[compData.lowComp.competitor]}`
                                        : '---'}
                                </p>
                            </div>
                            <div className='box-item'>
                                <p className="whiteSmokeText">Base Price</p>
                                <p>
                                    {skuDetails.basePrice
                                        ? `$ ${skuDetails?.basePrice?.toFixed(2)}`
                                        : '---'}
                                </p>
                            </div>
                            <div className='box-item'>
                            <p className="whiteSmokeText">Markdown price</p>
                                <p>
                                    {skuDetails.markdownPrice
                                        ? `$${skuDetails.markdownPrice.toFixed(2)}`
                                        : '---'}
                                </p>
                            </div>
                            <div className='box-item'>
                            <p className="whiteSmokeText">MAP</p>
                            <p>{
                                //eslint-disable-next-line
                                skuDetails.mapPrice ? `$${skuDetails.mapPrice.toFixed(2)}` : '---'} 
                                {
                                    skuDetails.mapStartDate && 
                                    skuDetails.mapEndDate && 
                                    mapPrice &&
                                    dayjs.tz().isBetween(dayjs.tz(skuDetails.mapStartDate), dayjs.tz(skuDetails.mapEndDate)) && 
                                    <Tooltip title={`Temporary MAP $${mapPrice.toFixed(2)} will be applied until ${dayjs.tz(skuDetails.mapEndDate).format('MM/DD/YYYY')}`}>
                                        <InfoCircleOutlined style={{marginLeft: '4px'}}/>
                                    </Tooltip>
                            }</p>
                            </div> 
                            <div className='box-item'>
                            <p className="whiteSmokeText">MSRP</p>
                            <p>{
                                //eslint-disable-next-line
                                skuDetails.listPrice ? `$${skuDetails.listPrice.toFixed(2)}` : '---'} 
                                {
                                    skuDetails.msrpStartDate && 
                                    skuDetails.msrpEndDate && 
                                    msrpPrice &&
                                    dayjs.tz().isBetween(dayjs.tz(skuDetails.msrpStartDate), dayjs.tz(skuDetails.msrpEndDate)) && 
                                    <Tooltip title={`Temporary MSRP $${msrpPrice.toFixed(2)} will be applied until ${dayjs.tz(skuDetails.msrpEndDate).format('MM/DD/YYYY')}`}>
                                        <InfoCircleOutlined style={{marginLeft: '4px'}}/>
                                    </Tooltip>
                            }</p>
                            </div> 
                            <div className='box-item'>
                            <p className="whiteSmokeText">RD price</p>
                                <p>{skuDetails.rdPrice ? `$${skuDetails.rdPrice.toFixed(2)}` : '---'}</p>
                            </div> 
                            <div className='box-item'>
                            <p className="whiteSmokeText">Base cost</p>
                                <p>{skuDetails.costPrice ? `$${skuDetails.costPrice.toFixed(2)}` : '---'}</p>
                            </div> 
                            <div className='box-item'>
                            <p className="whiteSmokeText">Sales(Last 30 days)</p>
                                <p>
                                    {skuDetails.cyCost
                                        ? `$${skuDetails.cyCost.toFixed(2)}`
                                        : '---'}
                                </p>
                            </div> 
                            <div className='box-item'>
                            <p className="whiteSmokeText">Units(Last 30 days)</p>
                                <p>
                                    {skuDetails.cySalesUnits
                                        ? skuDetails.cySalesUnits
                                        : '---'}
                                </p>
                            </div> 
                            <div className='box-item'>
                            <p className="whiteSmokeText">Page Views(Last 30 days)</p>
                                <p>
                                    {skuDetails.productView
                                        ? skuDetails.productView
                                        : '---'}
                                </p>
                            </div>
                            <div className='box-item'>
                            <p className="whiteSmokeText">Publish Status</p>
                                <p>{skuDetails.skuPblshInd ? 'Published' : 'Not Published'}</p>
                            </div> 
                            <div className='box-item'>
                            <p className="whiteSmokeText">Buyable Status</p>
                                <p>{skuDetails.skuBuyableInd ? 'Buyable' : 'Not Buyable'}</p>
                            </div>
                            <div></div>
                        </div>
                    </Col>
                </Row>
                </Spin>
                </div>
                <br />
                <div className="sku-details-configureSKU">
                    <Card className="sku-details-configureSKU-card">
                        <Spin spinning={configLoader}>
                        <Row className="row-1">
                            <Col span={4}>
                                <p className="sku-details-configureSKU-title">Configure SKU</p>
                            </Col>
                            <Col span={12} />
                            <Col span={8}>
                                <div className="configureSKU-btns">
                                    <MatchIssuesModal skuId={uid}/>
                                    {!(userType === userTypes.MERCHANT && editAccess === merchantAccess.VIEW) && <Button
                                        type='primary'
                                        id='sku-details-configure'
                                        className="sku-details-configureSKU-data-points-btn"
                                        onClick={showConfigDataModal}
                                        >
                                        Configure
                                    </Button>}
                                    {/* modal for configure data  */}
                                    <Drawer
                                        open={configDataModal}
                                        onClose={hideConfigDataModal}
                                        title="SKU Configuration"
                                        onCancel={hideConfigDataModal}
                                        width={dataConfigDrawerWidth}
                                        footer={[]}>
                                        <Tabs defaultActiveKey="1" items={configureModalTabs} />
                                    </Drawer>
                                </div>
                            </Col>
                        </Row>
                        <Row className="row-2">
                            <Col span={7} className="eads-model">
                                <Text className='sku-sub-title'>EADS Model</Text>
                                <Row>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Markdown price</p>
                                        <p>
                                            {skuDetails &&
                                            skuDetails.markdownPriceEnabled
                                                ? 'ON'
                                                : 'OFF'}
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Base Price</p>
                                        <p>
                                            {skuDetails &&
                                            skuDetails.basePriceEnabled
                                                ? 'ON'
                                                : 'OFF'}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={1}>
                                {' '}
                                <Divider
                                    type="vertical"
                                    style={{
                                        height: '95%'
                                    }}
                                />
                            </Col>
                            <Col span={7} className="configureSBU-priceRule-scraper-cards">
                                <div className="card-title">
                                    <Text className='sku-sub-title'>Price Rule</Text>
                                    <div style={{margin: "0px 5px"}}>{skuDetails.isMaster ? <Tag color="#D83A56">Master</Tag> : ''}</div>
                                    <div style={{margin: "0px 5px"}}> {
                                        skuDetails && skuDetails.priceRule ? 
                                        skuDetails && skuDetails.engineSelectedPriceRule && skuDetails.engineSelectedPriceRule !== 'None'? 
                                            <>
                                                <Tag color="#6D87C3">Engine Selected</Tag>
                                                <Tooltip title={
                                                    skuDetails.priceRule === price_rule_types.match_lowest.key ? DisplayStrings.match_lowest_message :
                                                    skuDetails.priceRule === price_rule_types.match_second_lowest_competitor.key ? DisplayStrings.match_second_lowest_message :
                                                    skuDetails.priceRule === price_rule_types.manual_price_with_market_tracking.key ? `${DisplayStrings.manual_price_with_market_tracking}, ${DisplayStrings.manual_price}: $${skuDetails.manualPrice}, ${DisplayStrings.price_reason}: ${capitalizeFirstLetter(skuDetails.manualPriceReason)}.` : ''
                                                }>
                                                    <InfoCircleOutlined style={{marginLeft: '4px'}}/>
                                                </Tooltip>
                                            </> : 
                                            <Tag color="#6D87C3">User Selected</Tag>
                                        : ''}
                                    </div>
                                </div>
                                <Row>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Price rule</p>
                                        <p>
                                            {skuDetails &&
                                            (skuDetails.engineSelectedPriceRule && skuDetails.engineSelectedPriceRule !== 'None')?
                                            priceRuleDesc[skuDetails.engineSelectedPriceRule]
                                            : skuDetails.priceRule
                                                ? priceRuleDesc[skuDetails.priceRule]
                                                : priceRuleDesc['do_not_match_competitor']}
                                        </p>
                                    </Col>
                                    { skuDetails.priceRule === price_rule_types.family_rule.key && !skuDetails.engineSelectedPriceRule && (<Col span={6}>
                                        <p className="whiteSmokeText">Master SKU</p>
                                        {skuDetails && skuDetails.masterSku!=='' ? 
                                         <Link to={'/sku_data/sku/' + skuDetails.masterSku}style={{ cursor: 'pointer'}}><u>{skuDetails.masterSku}</u></Link>:
                                        <p>{'---'}</p>}

                                        
                                    </Col>)}
                                    { skuDetails.priceRule === price_rule_types.family_rule.key && !skuDetails.engineSelectedPriceRule && (<Col span={6}>
                                        <p className="whiteSmokeText">Price Change</p>
                                        <p>{skuDetails?.familyPriceModifier}{skuDetails?.familyPriceSuffix}{skuDetails?.familyPrice && skuDetails?.familyPrice.toFixed(2)}
                                        </p>
                                    </Col>)}
                                    {(skuDetails.priceRule === price_rule_types.manual_price.key || skuDetails.priceRule === price_rule_types.manual_price_with_market_tracking.key) && !skuDetails.engineSelectedPriceRule && (<Col span={6}>
                                        <p className="whiteSmokeText">Price</p>
                                        <p>
                                            {skuDetails &&
                                            skuDetails.manualPrice ?
                                            `$${skuDetails.manualPrice}`
                                            : '-'}
                                        </p>
                                    </Col>)}
                                    {(skuDetails.priceRule === price_rule_types.manual_price.key || skuDetails.priceRule === price_rule_types.manual_price_with_market_tracking.key) && !skuDetails.engineSelectedPriceRule && (<Col span={6}>
                                        <p className="whiteSmokeText">Type</p>
                                        <p >
                                            {skuDetails &&
                                            skuDetails.manualPriceReason ?
                                            capitalizeFirstLetter(skuDetails.manualPriceReason)
                                            : '-'}
                                        </p>
                                    </Col>)}

                                </Row>
                            </Col>
                            <Col span={1}>
                                {' '}
                                <Divider
                                    type="vertical"
                                    style={{
                                        height: '95%'
                                    }}
                                />
                            </Col>

                            <Col span={8} className="configureSBU-priceRule-scraper-cards">
                                <Text className='sku-sub-title'>Default Guardrail</Text>
                                <Row>
                                    <Col span={8}>
                                        <p className="whiteSmokeText">Status</p>
                                        <p>Active</p>
                                    </Col>
                                    <Col span={8}>
                                        <p className="whiteSmokeText">Default Min</p>
                                        <p>
                                            {skuDetails &&
                                            skuDetails.defaultGuardMin
                                                ? skuDetails.defaultGuardMin
                                                : 'MAP'}{' '}
                                            {skuDetails &&  
                                            (skuDetails.defaultGuardMinPrice || mapPrice || msrpPrice) ? 
                                            `${
                                                (skuDetails?.defaultGuardMin === 'MSRP' && msrpPrice) ||
                                                (skuDetails?.defaultGuardMin === 'MAP' && mapPrice) ||
                                                skuDetails?.defaultGuardMin === 'Fixed' ? '$' : ''
                                            } 
                                            ${
                                                skuDetails?.defaultGuardMin === 'MSRP'
                                                ? msrpPrice || ''
                                                : skuDetails?.defaultGuardMin === 'MAP'
                                                ? mapPrice || ''
                                                : (skuDetails?.defaultGuardMinPrice !== null)
                                                ? skuDetails?.defaultGuardMinPrice : ''
                                            }
                                             ${skuDetails.defaultGuardMinPriceSuffix === '%' ? '%': ''}`
                                                : ''
                                            }
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <p className="whiteSmokeText">Default Max</p>
                                        <p>
                                            {skuDetails &&
                                            skuDetails.defaultGuardMax
                                                ? skuDetails.defaultGuardMax
                                                : 'MSRP'}{' '}
                                            {skuDetails &&
                                            (skuDetails.defaultGuardMaxPrice || mapPrice || msrpPrice) ? 
                                            `${
                                                (skuDetails?.defaultGuardMax === 'MSRP' && msrpPrice) ||
                                                (skuDetails?.defaultGuardMax === 'MAP' && mapPrice) ||
                                                skuDetails?.defaultGuardMax === 'Fixed' ? '$' : ''
                                            } 
                                             ${
                                                skuDetails?.defaultGuardMax === 'MSRP'
                                                ? msrpPrice || ''
                                                : skuDetails?.defaultGuardMax === 'MAP'
                                                ? mapPrice || ''
                                                : (skuDetails?.defaultGuardMaxPrice !== null)
                                                ? skuDetails?.defaultGuardMaxPrice : ''
                                            }
                                             ${skuDetails.defaultGuardMaxPriceSuffix === '%' ? '%': ''}`
                                                : ''
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row> 
                            <Text className='sku-sub-title'>Price Modifiers</Text><br />
                            <Row
                            className="price-modifier-warning">
                            <Text>
                            <WarningOutlined  style={{margin: 8}}/>  MSRP/MAP derived from RPM. Only modify values here under urgent circumstances if there is inaccurate info or data is delayed.
                            </Text>
                        </Row>
                        </Row><br/> 
                        <Row className="configureSBU-priceRule-scraper-cards">
                            <Col span={12} className="eads-model">
                                
                                <Row>
                                    <Col span={8}>
                                        <p className="whiteSmokeText">MAP</p>
                                        <p>
                                            {(skuDetails?.tempMapPrice) ? `$ ${skuDetails?.tempMapPrice.toFixed(2)}` : 
                                            (skuDetails?.mapPrice) ? `$ ${skuDetails?.mapPrice.toFixed(2)}` : `---`}
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Schedule MAP</p>
                                        <p>
                                            {skuDetails && 
                                                (skuDetails.mapStartDate && skuDetails.mapEndDate)
                                                ? `${dayjs.tz(skuDetails.mapStartDate).format('MM/DD/YYYY')} - ${dayjs.tz(skuDetails.mapEndDate).format('MM/DD/YYYY')}`
                                                : 'Not Scheduled'}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={8}>
                                        <p className="whiteSmokeText">Subsequent RD Price</p>
                                        <p>
                                            {(skuDetails?.lastRepricePrice) && (typeof skuDetails?.tempSubRdPrice === 'number') ?
                                            `$ ${(skuDetails?.lastRepricePrice - ((skuDetails.tempSubRdPrice/100) * skuDetails.lastRepricePrice)).toFixed(2)}` :
                                            skuDetails?.lastRepricePrice ? 
                                            `$ ${(skuDetails?.lastRepricePrice - ((5/100) * skuDetails.lastRepricePrice)).toFixed(2)}` :
                                            `---`}
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Schedule Subsequent RD Price</p>
                                        <p>
                                            {skuDetails && 
                                                (skuDetails.subRdStartDate)
                                                ? `${dayjs.tz(skuDetails.subRdStartDate).format('MM/DD/YYYY')}`
                                                : 'Not Scheduled'}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={1}>
                                {' '}
                                <Divider
                                    type="vertical"
                                    style={{
                                        height: '95%'
                                    }}
                                />
                            </Col>
                            <Col span={10} className="configureSBU-priceRule-scraper-cards">
                                <Row >
                                    <Col span={6}>
                                        <p className="whiteSmokeText">MSRP</p>
                                        <p>
                                            {(skuDetails?.tempMsrpPrice) ? `$ ${skuDetails?.tempMsrpPrice.toFixed(2)}` : 
                                            (skuDetails?.listPrice) ? `$ ${skuDetails?.listPrice.toFixed(2)}` : `---`}
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Schedule MSRP</p>
                                        <p>
                                            {skuDetails && 
                                                (skuDetails.msrpStartDate && skuDetails.msrpEndDate)
                                                ? `${dayjs.tz(skuDetails.msrpStartDate).format('MM/DD/YYYY')} - ${dayjs.tz(skuDetails.msrpEndDate).format('MM/DD/YYYY')}`
                                                : 'Not Scheduled'}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <Row className="configureSBU-priceRule-scraper-cards">
                            <Col span={12} className="eads-model">
                                <Text className='sku-sub-title'>Green Bar Display</Text>
                                <Row>
                                    <Col span={6}>
                                        <p className="whiteSmokeText">State</p>
                                        <p>
                                            {skuDetails && 
                                                skuDetails.greenBarStatus
                                                ? GreenBarDisplayState.find(item => item.value === skuDetails.greenBarStatus)?.title
                                                : 'OFF'}
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <p className="whiteSmokeText">PDP</p>
                                        <p>
                                            {skuDetails && 
                                                skuDetails.greenBarPdp
                                                ? GreenBarDisplayPdpOptions.find(item => item.value === skuDetails.greenBarPdp)?.title
                                                : '---'}
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <p className="whiteSmokeText">In-Cart</p>
                                        <p>
                                            {`---`}
                                        </p>
                                    </Col>
                                    <Col span={6}>
                                        <p className="whiteSmokeText">Display Type</p>
                                        <p>
                                            {skuDetails && 
                                                skuDetails.greenBarDisplayType === '$' ? '$ Off' :
                                                skuDetails.greenBarDisplayType === '%' ? '% Off' :
                                                skuDetails.greenBarDisplayType === 'see_price_in_cart' ? 'See Price In-Cart' : '---'
                                            }
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={1}>
                                {' '}
                                <Divider
                                    type="vertical"
                                    style={{
                                        height: '95%'
                                    }}
                                />
                            </Col>
                            <Col span={10} className="configureSBU-priceRule-scraper-cards">
                                <Text className='sku-sub-title'>Scrapers</Text>
                                <Row>
                                    <Col span={12}>
                                        <p className="whiteSmokeText">Active Scrapers</p>
                                        <div style={{ marginTop: '-12px', marginLeft: '-2px' }}>
                                            {compData.activeScraper &&
                                                compData.activeScraper.map((scraper) => {
                                                    return (
                                                        <Tag
                                                            color="#ECF2FF"
                                                            key={scraper.name}
                                                            className="scraperTag">
                                                            {capitalizeFirstLetter(scraper.name)}
                                                        </Tag>
                                                    );
                                                })}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Divider />
                        <br />
                        <Row className="configureSKU-competitorsList-card">
                            <Col span={24}>
                                {/* <Card className="configureSBU-competitorsTable-card"> */}
                                <Text
                                    style={{
                                        fontWeight: 500,
                                        fontSize: '18px',
                                        lineHeight: '21px'
                                    }}>
                                    Enabled Competitors
                                </Text>
                                <br />
                                <br />
                                <CompetitorsRescrapeTable
                                    configId={uid}
                                    configdesc={skuDesc}
                                    configLevel={'sku'}
                                    className="competitors-table"
                                    loading={loading}
                                    callback={onTriggerReScrapeDetailsHandler}
                                    data={
                                        compData.activeCompetitor &&
                                        compData.activeCompetitor.map((comp) => {
                                            let temp = {
                                                competitor: comp.competitor,
                                                priority: comp.priority,
                                                adder:
                                                    (comp.adderText.includes('Add') ? '+' : '-') +
                                                    comp.adderValue +
                                                    (comp.adderText.includes('$') ? '$' : '%'),
                                                incart: comp.incart ? 'On' : 'Off',
                                                pdp: comp.pdp ? 'On' : 'Off',
                                                lastScrapedDate: comp.lastScrapedDate,
                                                scrapeExpiration: comp.scrapeExpiration
                                                    ? comp.scrapeExpiration
                                                    : '-',
                                                scrapeRunStatus: comp.scrapeRunStatus 
                                                    ? comp.scrapeRunStatus
                                                    : false,
                                                pdpPrice: comp.pdpPrice ? comp.pdpPrice : '-',
                                                inCartPrice: comp.inCartPrice ? comp.inCartPrice : '-',
                                                exactPdpPrice: comp.exactPdpPrice ? comp.exactPdpPrice : '-',
                                                exactInCartPrice: comp.exactInCartPrice ? comp.exactInCartPrice : '-',
                                                coupon: comp.coupon ? comp.coupon : '-',
                                                unmatchedOosReliability: comp.unmatchedOosReliability,
                                            };
                                            return temp;
                                        })
                                    }
                                />
                                {/* </Card> */}
                            </Col>
                        </Row>
                        </Spin>
                    </Card>
                    <div className="sku-priceAlert-temporaryGuardRail-rescrape-cards">
                        <Row>
                            <Col xs={24} lg={10} xl={8}>
                                <Card className="sku-temporaryGuardrail-card">
                                    <Spin spinning={alertLoader}>
                                        <Row className="row-1">
                                            <Col span={8}>
                                                <h2>Price Alert</h2>
                                            </Col>
                                            <Col span={8} />
                                            <Col span={8}>
                                                <div className="price-alert-btn">
                                                    <Button
                                                        disabled={!priceAlertCardData}
                                                        id='price-alert-edit-btn'
                                                        icon={<BellOutlined />}
                                                        className="edit-remove-btn"
                                                        onClick={handleAlertModal}>
                                                        Edit/Remove Alert
                                                    </Button>
                                                </div>
                                                {/* <OverrideModal /> */}
                                            </Col>
                                        </Row>

                                        <Row className="row-2">
                                            <Col className="whiteSmokeText" span={12}>
                                                <p>Status</p>
                                                <p>
                                                    {priceAlertCardData && priceAlertCardData.subStartDate && priceAlertCardData.subEndDate && 
                                                    (dayjs.tz(priceAlertCardData.subStartDate) <= dayjs.tz()) && (dayjs.tz(priceAlertCardData.subEndDate) >= dayjs.tz()) ? 'Active' : 'Disabled'}
                                                </p>
                                            </Col>
                                            <Col className="whiteSmokeText" span={12}>
                                                <p>Start Date - End Date</p>
                                                <p>
                                                    {priceAlertCardData && priceAlertCardData.subStartDate && priceAlertCardData.subEndDate ?
                                                    `${dayjs.tz(priceAlertCardData.subStartDate).format('MM/DD/YYYY')} - ${dayjs.tz(priceAlertCardData.subEndDate).format('MM/DD/YYYY')}`: '-'}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="row-3">
                                            <Col className="whiteSmokeText" span={12}>
                                                <p>Less Than Value</p>
                                                <p>
                                                    {priceAlertCardData && priceAlertCardData.lessThanPrice &&
                                                        priceAlertCardData.lessThanPriceSuffix === '$'
                                                    ? priceAlertCardData.lessThanPriceSuffix
                                                    : ''}
                                                    {priceAlertCardData &&
                                                    priceAlertCardData.lessThanPrice
                                                    ? priceAlertCardData.lessThanPrice.toFixed(2)
                                                    : '-'}
                                                    {priceAlertCardData && priceAlertCardData.lessThanPrice &&
                                                        priceAlertCardData.lessThanPriceSuffix === '%'
                                                    ? priceAlertCardData.lessThanPriceSuffix
                                                    : ''}
                                                </p>
                                            </Col>
                                            <Col className="whiteSmokeText" span={12}>
                                                <p>Greater Than Value</p>
                                                <p>
                                                    {priceAlertCardData && priceAlertCardData.greaterThanPrice &&
                                                        priceAlertCardData.greaterThanPriceSuffix === '$'
                                                    ? priceAlertCardData.greaterThanPriceSuffix
                                                    : ''}
                                                    {priceAlertCardData &&
                                                        priceAlertCardData.greaterThanPrice
                                                    ? priceAlertCardData.greaterThanPrice.toFixed(2)
                                                    : '-'}
                                                    {priceAlertCardData && priceAlertCardData.greaterThanPrice &&
                                                        priceAlertCardData.greaterThanPriceSuffix === '%'
                                                    ? priceAlertCardData.greaterThanPriceSuffix
                                                    : ''}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Spin>
                                </Card>
                            </Col>
                            <Col xs={24} lg={10} xl={8}>
                                <Card className="sku-temporaryGuardrail-card">
                                    <Spin spinning={configLoader}>
                                        <Row className="row-1" style={{ display: '-ms-flexbox' }}>
                                            <Col span={16}>
                                                <h2>Temporary Guardrail</h2>
                                            </Col>
                                            <Col span={8}>
                                                <OverrideModal
                                                    levelProps={{
                                                        configId: uid,
                                                        configdesc: skuDesc,
                                                        configLevel: level,
                                                        tempGuardData: skuDetails
                                                    }}
                                                    callback={handleGuardConfigDataUpdate}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="row-2">
                                            <Col className="whiteSmokeText" span={12}>
                                                <p>Status</p>
                                                <p>
                                                    {skuDetails && (dayjs.tz(skuDetails.startTempGuardDate) <= dayjs.tz()) && (dayjs.tz(skuDetails.endTempGuardDate) >= dayjs.tz()) ? 'Active' : 'Disabled'}
                                                </p>
                                            </Col>

                                            <Col className="whiteSmokeText" span={12}>
                                                <p>Temporary Max</p>
                                                <p>
                                                    {skuDetails && 
                                                    skuDetails.tempGuardMax
                                                        ? skuDetails.tempGuardMax
                                                        : '-'}{' '}
                                                    {skuDetails && 
                                                    skuDetails.tempGuardMaxPriceSuffix &&
                                                    typeof skuDetails.tempGuardMaxPrice === 'number'
                                                    ? `${skuDetails.tempGuardMaxPriceSuffix === '$' ? '$' : ''} 
                                                    ${
                                                        skuDetails.tempGuardMax === 'MAP' ? getMapPriceByConfiguration(skuDetails) || '-': 
                                                        skuDetails.tempGuardMax === 'MSRP' ? getMsrpPriceByConfiguration(skuDetails) || '-': 
                                                        skuDetails.tempGuardMaxPrice
                                                    }
                                                    ${skuDetails.tempGuardMaxPriceSuffix === '%' ? '%' : ''}`
                                                    : ''}
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="row-3">
                                            <Col className="whiteSmokeText" span={12}>
                                                <p>Expiry Date</p>
                                                <p>
                                                    {skuDetails && 
                                                    skuDetails.startTempGuardDate
                                                        ? dayjs.tz(skuDetails.startTempGuardDate).format('MM/DD/YYYY')
                                                        : ''}{' '}
                                                    -{' '}
                                                    {skuDetails && 
                                                    skuDetails.endTempGuardDate
                                                        ? dayjs.tz(skuDetails.endTempGuardDate).format('MM/DD/YYYY')
                                                        : ''}
                                                </p>
                                            </Col>
                                            <Col className="whiteSmokeText" span={12}>
                                                <p>Temporary Min</p>
                                                <p>
                                                    {skuDetails && 
                                                    skuDetails.tempGuardMin
                                                        ? skuDetails.tempGuardMin
                                                        : '-'}{' '}
                                                    {skuDetails && 
                                                    typeof skuDetails.tempGuardMinPrice === 'number' &&
                                                    skuDetails.tempGuardMinPriceSuffix
                                                    ? `${skuDetails.tempGuardMinPriceSuffix === '$' ? '$' : ''} 
                                                    ${
                                                        skuDetails.tempGuardMin === 'MAP' ? getMapPriceByConfiguration(skuDetails) || '': 
                                                        skuDetails.tempGuardMin === 'MSRP' ? getMsrpPriceByConfiguration(skuDetails) || '': 
                                                        skuDetails.tempGuardMinPrice
                                                    }
                                                    ${skuDetails.tempGuardMinPriceSuffix === '%' ? '%' : ''}`
                                                    : ''}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Spin>
                                </Card>
                            </Col>
                            <Col xs={24} lg={10} xl={8}>
                                <Card className="sku-rescrape-card" >
                                    <Spin spinning={loading}>
                                    <Row className="row-1">
                                        <Col span={16}>
                                            <h2>Re-Scrape Data</h2>
                                        </Col>
                                        <Col span={8}>
                                            <ReScrapeModal
                                                activeCompetitors={compData.activeCompetitor}
                                                configId={uid}
                                                configdesc={skuDesc}
                                                configLevel={'sku'}
                                                disableReScraperButton={compData.lastScrape &&
                                                compData.lastScrape.scrapeRunStatus
                                                    ? compData.lastScrape.scrapeRunStatus
                                                    : false}
                                                onOk={onTriggerReScrapeDetailsHandler}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="row-2">
                                        <Col className="whiteSmokeText" span={12}>
                                            <p>Status</p>
                                            <p>
                                                {compData.lastScrape &&
                                                compData.lastScrape.scrapeRunStatus
                                                    ? compData.lastScrape.scrapeRunStatus
                                                    : 'Disabled'}
                                            </p>
                                        </Col>
                                        <Col className="whiteSmokeText" span={12}>
                                            <p>Competitors</p>
                                            <p>
                                                {compData.lastScrape &&
                                                compData.lastScrape.competitor
                                                    ? compData.lastScrape.competitor
                                                    : 'All'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="row-3">
                                        <Col className="whiteSmokeText" span={12}>
                                            <p>Expiry Hour</p>
                                            <p>
                                                {compData.lastScrape &&
                                                compData.lastScrape.scrapeExpiryHour
                                                    ? compData.lastScrape.scrapeExpiryHour
                                                    : '-'}
                                            </p>
                                        </Col>
                                        <Col className="whiteSmokeText" span={12}>
                                            <p>Last Re-Scraped Date</p>
                                            <p>
                                                {compData.lastScrape &&
                                                compData.lastScrape.lastScrapedDate
                                                    ? dayjs.tz(compData.lastScrape.lastScrapedDate).format('MM/DD/YYYY')
                                                    : '-'}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row
                                        className="row-4"
                                        style={{
                                            backgroundColor: '#EBF1FF',
                                            height: '25px',
                                            borderRadius: '1px'
                                        }}>
                                        <Col span={22}>
                                            <Text
                                            // style={{
                                            //     display: 'flex',
                                            //     position: 'sticky'}}
                                            >
                                                <ScrapperStackIcon /> Processing re-scraping data
                                                for all competitors
                                            </Text>
                                        </Col>
                                    </Row>
                                    </Spin>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <br />
                    <Row className="graphRow">
                        <Card
                            style={{
                                width: '2000px',
                                minHeight: '200px'
                            }}>
                            <SKUGraph 
                                {...{
                                    skuId: uid
                                }}
                            />
                        </Card>
                    </Row>
                    <br />
                    <br />
                    <Tabs defaultActiveKey="0" className="level-tables-wrapper" items={skutabs} onChange={onTabChange} />
                </div>
            </>}
        </ContentWrapper>
    );
};

export default SKUDetails;
