import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    priceApprovals: []
};

const approvalsSlice = createSlice({
    name: 'approvals',
    initialState,
    reducers: {
        //Setting Approval state
        setApprovalsData(state, { payload }) {
            state.priceApprovals = payload.priceApprovals;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const ApprovalsActions = approvalsSlice.actions;
export const ApprovalsReducer = approvalsSlice.reducer;
export default approvalsSlice;
