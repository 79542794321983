import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    petcoUsersList: [],
    merchantsList: [],
    allUsersList: []
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setPetcoUsers(state, { payload }) {
            state.petcoUsersList = payload.petcoUsersList;
        },
        setMerchants(state, { payload }) {
            state.merchantsList = payload.merchantsList;
        },
        setAllUsers(state, { payload }) {
            state.allUsersList = payload.allUsersList;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const UsersActions = usersSlice.actions;
export const UsersReducer = usersSlice.reducer;
export default usersSlice;
