import { CompetitorsActions } from '../slices/competitors.slice';
import * as QueryExecuters from '../../graphql/queryExecuters';

//Action creator for get all scrapers base details
export const getCompetitorsBaseData = () => {
    return async (dispatch, getState) => {
        try {
            const { competitorsBaseDetails } = getState().competitors;
            if (!competitorsBaseDetails.length) {
                const competitorsBaseDetails = await QueryExecuters.getAllCompetitorsBaseData();
                if (competitorsBaseDetails) {
                    dispatch(CompetitorsActions.setCompetitorsBaseDetails({competitorsBaseDetails}));
                }
            }
        } catch (err) {
            console.log('Error - Check Authorization Action Error - ', err);
        }
    };
};