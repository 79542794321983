import { Button } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import './MerchantTableWrapper.scss';
import { capitalizeFirstLetter } from '../../utilities';

const MerchantTableWrapper = ({ tableFor, children, onClickAdd, onClickRemove, disabled, isDeleting }) => {

    return (
        <div className="merchant-table-wrapper-container">
            <div className="actions-container">
                <div className="search">

                </div>
                <div className="action-buttons">
                    <Button icon={<DeleteOutlined />} onClick={onClickRemove} disabled={disabled || isDeleting} loading={isDeleting}>
                        Remove
                    </Button>
                    <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        className='blue-btn'
                        onClick={() => {
                            onClickAdd(tableFor);
                        }}>{`Assign ${tableFor === 'sbu' || tableFor === 'sku' ? tableFor.toUpperCase() : capitalizeFirstLetter(tableFor)}`}</Button>
                </div>
            </div>
            <div className="table-container" style={{ marginTop: -60 }}>{children}</div>
        </div>
    );
};

export default MerchantTableWrapper;
