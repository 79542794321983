import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllSubclassData = async (index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllSubclassDataQuery(index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSubclasses;
    } catch (err) {
        console.log(err);
    }
};
export const getSubclassDetails = async (subclassId, subclassDesc) => {
    try {
        const query = QueryCreators.createGetSubclassDetailsQuery(subclassId, subclassDesc);
        const response = await GraphQL.request(query);
        return response.getLevelDetails;
    } catch (err) {
        console.log(err);
    }
};

export const getSubclassAtSbuData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetSubclassAtSbuDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSubclassesAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getSubclassAtShopData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetSubclassAtShopDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSubclassesAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getSubclassAtDeptData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetSubclassAtDeptDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSubclassesAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getSubclassAtClassData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetSubclassAtClassDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSubclassesAtClass;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignSubClassData = async (merchantId, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignSubClassDataQuery(merchantId, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSubclasses;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSubclassAtSbuData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSubclassAtSbuDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSubclassesAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSubclassAtShopData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSubclassAtShopDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSubclassesAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSubclassAtDeptData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSubclassAtDeptDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSubclassesAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSubclassAtClassData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSubclassAtClassDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSubclassesAtClass;
    } catch (err) {
        console.log(err);
    }
};
