import { useState } from 'react';
import { Button, Input, Table, Upload, Progress, Spin } from 'antd';
import {
    CloseOutlined,
    EditOutlined,
    InboxOutlined,
    ReloadOutlined,
    SaveOutlined
} from '@ant-design/icons';
import Papaparse from 'papaparse';
import { filesize } from 'filesize';
import * as _ from 'lodash';

import './BulkUpload.scss';
import { Notification, genericPSTConverter, numberValidator, positiveNumberValidator } from '../../utilities';
import { bulkAddSkuData } from '../../graphql/queryExecuters';
import { Link } from 'react-router-dom';
import { 
    MAX_CSV_UPLOAD_SIZE, 
    MAX_SKU_UPLOAD_LIMIT, 
    manualPriceTypes, 
    tempGuardTypes, 
    allCsvColumns, 
    marginMinLimit,
    marginMaxLimit,
    fixedMinLimit,
    subRdMinLimit,
    subRdMaxLimit
} from '../../constants/common';
import { margin_limit_error, sku_duplication, sku_duplication_warning } from '../../constants/displayStrings';
import dayjs from 'dayjs';

const BulkUpload = ({ onClose, onSubmit }) => {
    //Dragger Configuration
    const draggerConfig = {
        name: 'sku',
        maxCount: 1,
        beforeUpload: (file) => {
            const isCSV = file.type === 'text/csv';
            if (!isCSV) {
                Notification('warning', 'Please upload only CSV files.');
            }

            const isSizeExceed = MAX_CSV_UPLOAD_SIZE <= file.size;
            if (isSizeExceed) {
                Notification(
                    'warning',
                    'Please Upload A Proper File.',
                    'Maximum File Size Can Be 2 MB.'
                );
            }
            return (isCSV && !isSizeExceed) || Upload.LIST_IGNORE;
        }
    };

    const { Dragger } = Upload;

    //Helper for loader state
    const [loading, setLoading] = useState(false);

    //Reference for selected csv file
    const [selectedFile, setSelectedFile] = useState(null);

    //Gathered SKU Data
    const [skuData, setSkuData] = useState([]);

    //SKUs don't have data
    const [unMatchedSku, setUnMatchedSku] = useState([]);

    //Search text based result
    const [filtertedSkuList, setFiltertedSkuList] = useState([]);

    const [searchText, setSearchText] = useState(null);

    //Selected SKUs to perform operations
    const [selectedSkuList, setSelectedSkuList] = useState([]);
    //helper for storing the processed CSV data
    const [parsedCsvData, setParsedCsvData] = useState([]);

    const [skuIdBeforeEditing, setSkuIdBeforeEditing] = useState('');

    //storing valid sku Csv Data
    const [uploadedCsvData, setUploadedCsvData] = useState([]);

    //helper for checking if csv is valid or not
    const [isCsvValid,setIsCsvValid] = useState(true)

    const columns = [
        {
            title: 'SKU Id Error',
            key: 'skuId',
            width: 200,
            render: (_, data) =>
                data.editing ? (
                    <div>
                        <Input
                            autoFocus
                            style={{ width: '170px' }}
                            type="number"
                            value={data.skuId}
                            onChange={(e) => {
                                onChangeValueHandler(e.target.value);
                            }}
                        />
                    </div>
                ) : (
                    <div>{data.skuId}</div>
                )
        },
        {
            title: 'Validation Errors',
            key: 'errors',
            width: 400,
            render: (_, data) => (
                <ol>
                    {data?.errors?.map((item) => {
                        return <li key={item}>{item}</li>;
                    })}
                </ol>
            )
        },
        {
            title: 'Actions',
            fixed: 'right',
            render: (_, data) => (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div
                        onClick={() => {
                            onClickEditHandler(data);
                        }}
                        style={{ marginRight: '16px', cursor: 'pointer' }}>
                        {data.editing ? (
                            <div>
                                <SaveOutlined /> Save
                            </div>
                        ) : data?.errors?.[0] === 'Incorrect SkuId' ? (
                            <div>
                                <EditOutlined /> Edit
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            onClickRemoveSKUHandler(data);
                        }}>
                        <CloseOutlined /> Remove
                    </div>
                </div>
            )
        }
    ];
    const onSelectChange = (value) => {
        setSelectedSkuList(value);
    };

    //Closing Drawer
    const onClickCloseModalHandler = () => {
        setSelectedFile(null);
        setFiltertedSkuList([]);
        setSearchText(null);
        setSelectedSkuList([]);
        setSkuData([]);
        onClose();
    };

    //Final data submission handler
    const onSubmitDataHandler = () => {
        onSubmit([...skuData], uploadedCsvData);
        setSelectedFile(null);
        setFiltertedSkuList([]);
        setSearchText(null);
        setSelectedSkuList([]);
        setSkuData([]);
    };

    //CSV Processor
    const processCSV = async (file) => {
        Papaparse.parse(file.file, {
            header: true,
            delimiter: '',
            skipEmptyLines: true,
            complete: (res) => {
                const skusWithMultipleEntries = [];
                //Checking entries of sku
                const entries = _.countBy(res.data.map(skuData => skuData.sku_id));
                Object.keys(entries).forEach(skuId => {
                    if (entries[skuId] > 1) skusWithMultipleEntries.push(skuId);
                });
                if (skusWithMultipleEntries.length) {
                    if (skusWithMultipleEntries.length > 5) {
                        Notification('warning', sku_duplication, sku_duplication_warning);
                    } else {
                        Notification('warning', sku_duplication, `SKU with ID ${skusWithMultipleEntries.join(', ')} have multiple entries.`);
                    }
                } else {

                    const trimmedData = res.data.map(skuData => ({
                        'MAP': skuData['MAP'].trim(),
                        'MAP End Date(mm/dd/yyyy)': skuData['MAP End Date(mm/dd/yyyy)'].trim(),
                        'MAP Start Date(mm/dd/yyyy)': skuData['MAP Start Date(mm/dd/yyyy)'].trim(),
                        'MSRP': skuData['MSRP'].trim(),
                        'MSRP End Date(mm/dd/yyyy)': skuData['MSRP End Date(mm/dd/yyyy)'].trim(),
                        'MSRP Start Date(mm/dd/yyyy)': skuData['MSRP Start Date(mm/dd/yyyy)'].trim(),
                        'Manual Price': skuData['Manual Price'].trim(),
                        'Market Tracking (Y or N)': skuData['Market Tracking (Y or N)'].trim(),
                        'SubRD Start Date(mm/dd/yyyy)': skuData['SubRD Start Date(mm/dd/yyyy)'].trim(),
                        'SubRD(%)': skuData['SubRD(%)'].trim(),
                        'Temp Guard End Date(mm/dd/yyyy)': skuData['Temp Guard End Date(mm/dd/yyyy)'].trim(),
                        'Temp Guard Start Date(mm/dd/yyyy)': skuData['Temp Guard Start Date(mm/dd/yyyy)'].trim(),
                        'Temp Guardrail Max': skuData['Temp Guardrail Max'].trim(),
                        'Temp Guardrail Min': skuData['Temp Guardrail Min'].trim(),
                        'Temp Max Value': skuData['Temp Max Value'].trim(),
                        'Temp Min Value': skuData['Temp Min Value'].trim(),
                        'Type': skuData['Type'].trim(),
                        'sku_id': skuData['sku_id'].trim()
                    }));
                    setSelectedFile(file.file);
                    setParsedCsvData(trimmedData);
                    requestSKUData(trimmedData);
                }
            }
        });
    };

    //validating the Processed CSV Data
    const validateData = (res, unmatchedSkuIds) => {
        const parsedData = res;
        if (parsedData) {
            const validatedData = parsedData.map((item) => {
                let errors = [];

                let isMAPDateStringValid,isMSRPDateStringValid, isSubRdDateStringValid,isTempGuardDateStringValid
                isMAPDateStringValid=isMSRPDateStringValid= isSubRdDateStringValid = isTempGuardDateStringValid = false

                let manual_price,map_price, msrp_price,subRd_price,temp_guard_min_price,temp_guard_max_price;
                manual_price=map_price=msrp_price=subRd_price=temp_guard_min_price=temp_guard_max_price=null

                let manualPriceMarketTrackingFlag = item['Market Tracking (Y or N)'];

                let isManualTypeValid,isTempGuardMinValid,isTempGuardMaxValid
                isManualTypeValid=isTempGuardMinValid=isTempGuardMaxValid= true

                // temp guardrail
                let tempGuardMin = item['Temp Guardrail Min'];
                let tempGuardMax = item['Temp Guardrail Max'];
                //current Date
                let currentDate = new Date();
                //converting current IST/System Date to current PST date
                const currentPST = new Date(genericPSTConverter(currentDate)).setHours(0,0,0,0);

                //array of manualtypes to check
                const manualTypes = manualPriceTypes.map((item) => item.label);
                if(!manualTypes.includes(item['Type'])){
                    isManualTypeValid=false
                }
                //checking if the types of temp guardrail is valid or not
                const guardTypes = tempGuardTypes.map((item) => item.label);
                if(!guardTypes.includes(tempGuardMin)){
                    isTempGuardMinValid=false
                }
                if(!guardTypes.includes(tempGuardMax)){
                    isTempGuardMaxValid=false
                }
                //validating manual price
                //New Validation Code
                if (
                    item['Manual Price']?.length > 0 &&
                    !positiveNumberValidator(item['Manual Price'])
                ) {
                    errors.push('manual price error');
                } else {
                    manual_price = parseFloat(item['Manual Price']);
                }

                // Manual Price Type Validation
                if(item['Type']?.length > 0 && !isManualTypeValid){
                    errors.push('Invalid Manual Price Type');
                }
                // validation for checking if any of manual price fields are empty  if any one of them is filled
                else if ((!manual_price || !item['Type'] || !item['Market Tracking (Y or N)']) && (!(!manual_price &&  !item['Type'] && !item['Market Tracking (Y or N)']))){
                    errors.push('Missing 1 or More Manual Price Entries');
                }

                // MAP  Price Validation

                if (item['MAP']?.length > 0 && !positiveNumberValidator(item['MAP'])) {
                    errors.push('MAP price error');
                } else {
                    map_price = parseFloat(item['MAP']);
                }
                //MSRP Price Validation

                if (item['MSRP'].length > 0 && !positiveNumberValidator(item['MSRP'])) {
                    errors.push('MSRP price error');
                } else {
                    msrp_price = parseFloat(item['MSRP']);
                }
                // SubRD Price Validation

                if (item['SubRD(%)'].length > 0 && !positiveNumberValidator(item['SubRD(%)'])) {
                    errors.push('SubRD price error');
                } else {
                    subRd_price = parseFloat(item['SubRD(%)']);
                    if(subRd_price > subRdMaxLimit || subRd_price < subRdMinLimit){
                        errors.push('SubRD price error');
                    }
                }

                //validating dates format and comparing start and end dates
                //MAP
                if (
                    !item['MAP Start Date(mm/dd/yyyy)'] &&
                    !item['MAP End Date(mm/dd/yyyy)'] &&
                    !item['MAP']
                ) {
                    isMAPDateStringValid = true;
                }else if (
                    item['MAP Start Date(mm/dd/yyyy)'] &&
                    item['MAP End Date(mm/dd/yyyy)'] &&
                    (!dayjs(item['MAP Start Date(mm/dd/yyyy)'], 'MM/DD/YYYY', true).isValid() ||
                        !dayjs(item['MAP End Date(mm/dd/yyyy)'], 'MM/DD/YYYY', true).isValid())
                ) {
                    errors.push(' MAP Start Or End Date Format is Invalid');
                } else if (
                    new Date(item['MAP Start Date(mm/dd/yyyy)']) >
                    new Date(item['MAP End Date(mm/dd/yyyy)'])
                ) {
                    errors.push('MAP Start Date > End Date Error');
                }
                // validation for checking if MAP given start date is prior to todays date
                else if (
                    new Date(item['MAP Start Date(mm/dd/yyyy)']).setHours(0,0,0,0) <
                    currentPST
                ) {
                    errors.push('MAP Start date < Current date Error');
                }
                // validation for checking if any of MAP fields are empty if any one of them is filled
                else if (
                   (!map_price && ((item['MAP Start Date(mm/dd/yyyy)'] ||
                    item['MAP End Date(mm/dd/yyyy)'] )))   || (map_price && ((!item['MAP Start Date(mm/dd/yyyy)'] ||
                    !item['MAP End Date(mm/dd/yyyy)'] )))
                ) {
                    errors.push('Missing 1 or More MAP Entries');
                }
                else if (
                    dayjs(item['MAP End Date(mm/dd/yyyy)'])
                        .isSameOrAfter(
                            dayjs(item['MAP Start Date(mm/dd/yyyy)']).add(14, 'days')
                        )
                ) {
                    errors.push('MAP Start and End Dates cannot be more than 14 days apart.');
                } else {
                    isMAPDateStringValid = true;
                }
                

                //MSRP
                if (
                    !item['MSRP Start Date(mm/dd/yyyy)'] &&
                    !item['MSRP End Date(mm/dd/yyyy)'] &&
                    !item['MSRP']
                ) {
                    isMSRPDateStringValid = true;
                } else if (
                    item['MSRP Start Date(mm/dd/yyyy)'] &&
                    item['MSRP End Date(mm/dd/yyyy)'] &&
                    (!dayjs(item['MSRP Start Date(mm/dd/yyyy)'], 'MM/DD/YYYY', true).isValid() ||
                        !dayjs(item['MSRP End Date(mm/dd/yyyy)'], 'MM/DD/YYYY', true).isValid())
                ) {
                    errors.push(' MSRP Start Or End Date Format is Invalid');
                } else if (
                    item['MSRP Start Date(mm/dd/yyyy)'] &&
                    item['MSRP End Date(mm/dd/yyyy)'] &&
                    new Date(item['MSRP Start Date(mm/dd/yyyy)']) >
                    new Date(item['MSRP End Date(mm/dd/yyyy)'])
                ) {
                    errors.push('MSRP Start Date > End Date Error');
                }
                // validation for checking if MSRP given start date is prior to todays date
                else if (
                    new Date(item['MSRP Start Date(mm/dd/yyyy)']).setHours(0,0,0,0) <
                    currentPST
                ) {
                    errors.push('MSRP Start date < Current date Error');
                }
                // validation for checking if any of MSRP fields are empty if any one of them is filled
                else if (
                   (!msrp_price && ((item['MSRP Start Date(mm/dd/yyyy)'] ||
                    item['MSRP End Date(mm/dd/yyyy)'] )))   || (msrp_price && ((!item['MSRP Start Date(mm/dd/yyyy)'] ||
                    !item['MSRP End Date(mm/dd/yyyy)'] )))
                ) {
                    errors.push('Missing 1 or More MSRP Entries');
                } else if (
                    dayjs(item['MSRP End Date(mm/dd/yyyy)'])
                        .isSameOrAfter(
                            dayjs.tz(item['MSRP Start Date(mm/dd/yyyy)']).add(14, 'days')
                        )
                ) {
                    errors.push('MSRP Start and End Dates cannot be more than 14 days apart.');
                } else {
                    isMSRPDateStringValid = true;
                }

                //Sub Rd Date Validation
                if (
                    !item['SubRD Start Date(mm/dd/yyyy)'] &&
                    !item['SubRD(%)'].length > 0
                ) {
                    isSubRdDateStringValid = true;
                } else if (
                    item['SubRD Start Date(mm/dd/yyyy)'] &&
                    (!dayjs(item['SubRD Start Date(mm/dd/yyyy)'], 'MM/DD/YYYY', true).isValid())
                ) {
                    errors.push(' SubRD Start Date Format is Invalid');
                } 
                 // validation for checking if SubRd given start date is prior to todays date
                else if (
                    item['SubRD Start Date(mm/dd/yyyy)'] &&
                    new Date(item['SubRD Start Date(mm/dd/yyyy)']).setHours(0,0,0,0) <
                    currentPST
                ) {
                    errors.push('SubRD Start Date < Current date');
                }
                // validation for checking if any of SubRD fields are empty if any one of them is filled
                else if (
                   (subRd_price !== subRdMinLimit && !subRd_price && (item['SubRD Start Date(mm/dd/yyyy)'])) || 
                   (subRd_price && (!item['SubRD Start Date(mm/dd/yyyy)'] ))
                ) {
                    errors.push('Missing 1 or More SubRd Entries');
                }  else {
                    isSubRdDateStringValid = true;
                }

                // Temporary guardrails validation
                // validating if correct Temp Min and Temp max type is given
                if(tempGuardMin.length > 0 && !isTempGuardMinValid){
                    errors.push('Invalid Temp Guard Min Type');
                }else if((tempGuardMin==='MAP' || tempGuardMin==='MSRP') && item['Temp Min Value'].length > 0){
                    errors.push('Temp guard Min Type MAP or MSRP cannot have Price Values');
                }
                if(item['Temp Guardrail Max'].length > 0 && !isTempGuardMaxValid){
                    errors.push('Invalid Temp Guard Max Type');
                }else if((tempGuardMax==='MAP' || tempGuardMax==='MSRP') && item['Temp Max Value'].length > 0){
                    errors.push('Temp guard Max Type MAP or MSRP cannot have Price Values');
                }
                //checking if price values entered is correct 
                if (
                    item['Temp Min Value'].length > 0 &&
                    !numberValidator(item['Temp Min Value'])
                ) {
                    errors.push('Temp Min price error');
                } else {
                    temp_guard_min_price = parseFloat(item['Temp Min Value']);   
                }
                if (
                    item['Temp Max Value'].length > 0 &&
                    !numberValidator(item['Temp Max Value'])
                ) {
                    errors.push('Temp Max price error');
                } else {
                    temp_guard_max_price = parseFloat(item['Temp Max Value']);
                }
                // checking min price < max price
                if(temp_guard_min_price> temp_guard_max_price){
                    errors.push('Temp Min price > Max Price  error');
                } else if((tempGuardMin === 'Fixed' && temp_guard_min_price <= fixedMinLimit) || 
                    (tempGuardMax === 'Fixed' && temp_guard_max_price <= fixedMinLimit)){
                    errors.push('Temp Min Fixed price and Temp Max Fixed Price should be greater than 0');
                } else if((tempGuardMin === 'Margin' && (temp_guard_min_price > marginMaxLimit || 
                    temp_guard_min_price < marginMinLimit || temp_guard_min_price === null)) || 
                    (tempGuardMax === 'Margin' && (temp_guard_max_price > marginMaxLimit || 
                    temp_guard_max_price < marginMinLimit || temp_guard_max_price === null))){
                    errors.push(margin_limit_error);
                }
                //Checking if User has entered Margin and Fixed values
                
                if(((tempGuardMin==='Margin' || tempGuardMin==='Fixed') && (temp_guard_min_price===null || isNaN(temp_guard_min_price)))){
                    errors.push('Temp Guard Min Margin/Fixed value is Mandatory');
                }
                if(((tempGuardMax==='Margin' || tempGuardMax==='Fixed') && (temp_guard_max_price===null || isNaN(temp_guard_max_price)))){
                    errors.push('Temp Guard Max Margin/Fixed value is Mandatory');
                }

                //Temp guardrail start && End date validations
                if (
                    item['Temp Guard Start Date(mm/dd/yyyy)'] &&
                    item['Temp Guard End Date(mm/dd/yyyy)'] &&
                    (!dayjs(item['Temp Guard Start Date(mm/dd/yyyy)'], 'MM/DD/YYYY', true).isValid() ||
                        !dayjs(item['Temp Guard End Date(mm/dd/yyyy)'], 'MM/DD/YYYY', true).isValid())
                ) {
                    errors.push(' Temp Guardrail Start Or End Date Format is Invalid');
                } else if (
                    new Date(item['Temp Guard Start Date(mm/dd/yyyy)']) >
                    new Date(item['Temp Guard End Date(mm/dd/yyyy)'])
                ) {
                    errors.push('Temp Guard Start Date > End Date Error');
                }
                // validation for checking if Temp guard given start date is prior to todays date
                else if (
                    new Date(item['Temp Guard Start Date(mm/dd/yyyy)']).setHours(0,0,0,0) <
                    currentPST
                ) {
                    errors.push('Temp Guard Start date < Current date Error');
                }
                //checking if any of the fields are entered all should be present
                else if((!tempGuardMin || !tempGuardMax || !item['Temp Guard Start Date(mm/dd/yyyy)'] || !item['Temp Guard End Date(mm/dd/yyyy)']) && (!(!tempGuardMin && !tempGuardMax && !item['Temp Guard Start Date(mm/dd/yyyy)'] && !item['Temp Guard End Date(mm/dd/yyyy)']))){
                    errors.push('Missing 1 or more Temp Guardrail fields');
                }
                  else {
                     isTempGuardDateStringValid = true;
                 }



                if (unmatchedSkuIds?.map((item) => item.skuId).includes(item.sku_id)) {
                    errors.unshift('Incorrect SkuId');
                }
                return {
                    skuId: item.sku_id,
                    data: {
                        skuId: item.sku_id,
                        priceRule:
                            item['Manual Price']?.length > 0
                                ? manualPriceMarketTrackingFlag === 'Y' ||
                                  manualPriceMarketTrackingFlag === 'y'
                                    ? 'manual_price_with_market_tracking'
                                    : 'manual_price'
                                : '',
                        manualPrice:
                            manual_price && !isNaN(manual_price) ? manual_price : null,
                        manualPriceReason: isManualTypeValid ? item['Type'] : '',
                        mapPrice: map_price && !isNaN(map_price) ? map_price : null,
                        mapStartDate: isMAPDateStringValid
                            ? item['MAP Start Date(mm/dd/yyyy)']
                            : '',
                        mapEndDate: isMAPDateStringValid ? item['MAP End Date(mm/dd/yyyy)'] : '',
                        msrpPrice: msrp_price && !isNaN(msrp_price) ? msrp_price : null,
                        msrpStartDate: isMSRPDateStringValid
                            ? item['MSRP Start Date(mm/dd/yyyy)']
                            : '',
                        msrpEndDate: isMSRPDateStringValid ? item['MSRP End Date(mm/dd/yyyy)'] : '',
                        subRdPrice:
                            (subRd_price || subRd_price === subRdMinLimit) && !isNaN(subRd_price) ? subRd_price : null,
                        subRdStartDate: isSubRdDateStringValid
                            ? item['SubRD Start Date(mm/dd/yyyy)']
                            : '',
                        tempGuardMin: isTempGuardMinValid? tempGuardMin : '',
                        tempGuardMinPrice: temp_guard_min_price && !isNaN(temp_guard_min_price) ? temp_guard_min_price : (tempGuardMin==='Margin' && temp_guard_min_price===0? 0 : null) ,
                        tempGuardMinPriceSuffix: tempGuardMin && tempGuardMin==='Margin'? '%' : '$',
                        tempGuardMax: isTempGuardMaxValid? tempGuardMax : '',
                        tempGuardMaxPrice: temp_guard_max_price && !isNaN(temp_guard_max_price) ? temp_guard_max_price : (tempGuardMax==='Margin' && temp_guard_max_price===0? 0 : null) ,
                        tempGuardMaxPriceSuffix: tempGuardMax && tempGuardMax==='Margin'? '%' : '$',
                        startTempGuardDate: isTempGuardDateStringValid? item['Temp Guard Start Date(mm/dd/yyyy)'] : '',
                        endTempGuardDate: isTempGuardDateStringValid? item['Temp Guard End Date(mm/dd/yyyy)'] : '', 
                    },
                    errors: errors,
                    editing: false
                };
            });
            const errSkuIds = validatedData.filter((item) => item.errors.length > 0);
            const validSkuIdsData = validatedData.filter(
                (item) => item.errors[0] !== 'Incorrect SkuId'
            );
            const prevData = uploadedCsvData.filter((item) => item.errors.length === 0);
            setUploadedCsvData([...prevData, ...validSkuIdsData]);
            setUnMatchedSku(errSkuIds);
            setSkuData((prevState) => {
                const state = [...prevState];
                errSkuIds.forEach((sku) => {
                    const index = state.findIndex((skuData) => skuData.skuId === sku.skuId);
                    if (index >= 0) {
                        state[index].error = sku.errors;
                    }
                });
                return state;
            });
            // setSkuData(validSkuIds)
            return true;
        }

        return false;
    };

    //Helper for edit sku id
    const onClickEditHandler = (data) => {
        if (data.skuId === '' && data.editing) {
            Notification('warning', 'Please Fill The Proper SKU Id.');
        } else {
            if (!data.editing) {
                setSkuIdBeforeEditing(data.skuId);
            } else {
                const parseDataIndex = parsedCsvData.findIndex(
                    (skuData) => skuData.sku_id === skuIdBeforeEditing
                );
                if (parseDataIndex >= 0) {
                    setParsedCsvData((prevState) => {
                        const state = [...prevState];
                        state[parseDataIndex].sku_id = data.skuId;
                        return state;
                    });
                }

                setSkuIdBeforeEditing('');
            }
            const list = searchText ? filtertedSkuList : unMatchedSku;
            const index = list.findIndex((skuData) => skuData.skuId === data.skuId);
            if (index >= 0) {
                list[index].editing = !data.editing;
                if (searchText) {
                    setFiltertedSkuList((prevState) => {
                        return [...list];
                    });
                } else {
                    setUnMatchedSku((prevState) => {
                        return [...list];
                    });
                }
            }
        }
    };

    //Helper for remove sku id
    const onClickRemoveSKUHandler = (data) => {
        const list = searchText ? filtertedSkuList : unMatchedSku;
        const index = list.findIndex((skuData) => skuData.skuId === data.skuId);
        if (index >= 0) {
            list.splice(index, 1);
            if (searchText) {
                setFiltertedSkuList((prevState) => {
                    return [...list];
                });
            } else {
                setUnMatchedSku((prevState) => {
                    return [...list];
                });
            }
        }
    };

    const onChangeValueHandler = (value) => {
        const list = searchText ? filtertedSkuList : unMatchedSku;
        const index = list.findIndex((skuData) => skuData.editing === true);
        if (index >= 0) {
            list[index].skuId = value;
            if (searchText) {
                setFiltertedSkuList((prevState) => {
                    return [...list];
                });
            } else {
                setUnMatchedSku((prevState) => {
                    return [...list];
                });
            }
        }
    };
    
    //Request to get all sku data
    const requestSKUData = async (skuData) => {
        try {
            setLoading(true);

            if (skuData.length > 0 && allCsvColumns in skuData[0]) {
                if (skuData.length > MAX_SKU_UPLOAD_LIMIT) {
                    Notification(
                        'warning',
                        'Maximum size exceeds.',
                        'The File Can Contains Maximum Of 1000 SKUs'
                    );
                } else {
                    const skuIds = skuData.map((skuData) => skuData.sku_id);
                    const responseList = [];
                    setIsCsvValid(true)
                    const chunkedData = _.chunk(skuIds,5000);
                    for await (let skuList of chunkedData) {
                        try{
                            const response = await bulkAddSkuData(skuList);
                            if (response === null){
                                Notification('error','Error',"Something Went Wrong While Loading CSV")   
                            }
                            responseList.push(response)
                            }
                            catch(err){
                                    console.log(err);
                                    responseList.push(err)
                                }
                    }
                    const response1 = {
                        skuData: responseList.map((item)=> item.skuData),
                        unmatchedSkuData: responseList.map((item) => item.unmatchedSkuData)
                    }
                    const response = {
                        skuData: response1.skuData.flat(1),
                        unmatchedSkuData: response1.unmatchedSkuData.flat(1)
                    }
                    if (response.skuData.length) {
                        setSkuData([...response.skuData]);
                    }
                    if (response.unmatchedSkuData.length) {
                        setUnMatchedSku(
                            response.unmatchedSkuData.map((skuData) => ({
                                skuId: skuData.skuId,
                                editing: false
                            }))
                        );
                    }
                    validateData(skuData, response.unmatchedSkuData,response.skuData);
                }
            } else {
                setIsCsvValid(false)
                Notification(
                    'error',
                    'Invalid Template/File Format',
                    'Please Provide A Proper or Latest CSV Template File To Process.'
                );
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    // Removing selected csv file handler
    const onClickCloseHandler = () => {
        setSelectedFile(null);
        setSkuData([]);
        setUnMatchedSku([]);
        setSearchText(null);
        setSelectedSkuList([]);
    };

    //Getting sku details after editing the sku ids
    const onClickGetSkuDetailsHandler = async () => {
        try {
            setLoading(true);
            const responseList = [];
            const skuIds = unMatchedSku.map((skuData) => skuData.skuId)
            const chunkedData = _.chunk(skuIds,5000);
            for await (let skuList of chunkedData) {
                try{
                    const response = await bulkAddSkuData(skuList);
                    responseList.push(response)
                    if (response === null){
                        Notification('error','Error',"Something Went Wrong While Loading CSV")
                    }
                    }
                    catch(err){
                            console.log(err);
                            responseList.push(err)
                        }
                    }
                    const response1 = {
                        skuData: responseList.map((item)=> item.skuData),
                        unmatchedSkuData: responseList.map((item) => item.unmatchedSkuData)
                    }
                    const res = {
                        skuData: response1.skuData.flat(1),
                        unmatchedSkuData: response1.unmatchedSkuData.flat(1)
                    }
            // const res = await bulkAddSkuData(unMatchedSku.map((skuData) => skuData.skuId));
            if (res.skuData.length) {
                setSkuData((prevState) => {
                    const state = [...prevState, ...res.skuData];
                    const uniqueData = _.uniqBy([...state], 'skuId');
                    return uniqueData;
                });
            }
            if (res.unmatchedSkuData.length) {
                setUnMatchedSku(
                    res.unmatchedSkuData.map((skuData) => ({
                        skuId: skuData.skuId,
                        editing: false
                    }))
                );
            } else {
                setUnMatchedSku([]);
            }
            const filteredData = [
                ...res.skuData.map((sku) => {
                    const skuParseData = parsedCsvData.find(
                        (skuData) => skuData.sku_id === sku.skuId
                    );
                    if (skuParseData) {
                        return skuParseData;
                    } else {
                        return false;
                    }
                }),
                ...res.unmatchedSkuData.map((sku) => {
                    const skuParseData = parsedCsvData.find(
                        (skuData) => skuData.sku_id === sku.skuId
                    );
                    if (skuParseData) {
                        return skuParseData;
                    } else {
                        return false;
                    }
                })
            ].filter((data) => data);
            validateData(filteredData, res.unmatchedSkuData);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onChangeSearchTextHandler = (searchText) => {
        setSearchText(searchText);
        const filtertedSKUs = unMatchedSku.filter((skuData) => skuData.skuId.includes(searchText));
        filtertedSKUs.forEach((skuData) => {
            skuData.editing = false;
        });
        setFiltertedSkuList(filtertedSKUs);
    };

    const onClickRemoveAllSKUHandler = () => {
        setUnMatchedSku([]);
        setFiltertedSkuList([]);
    };

    //Helper for removing selected skus from operations
    const onClickRemoveSelectedSKUHandler = () => {
        const list = searchText ? filtertedSkuList : unMatchedSku;
        if (selectedSkuList.length > 0) {
            selectedSkuList.forEach((skuId) => {
                const index = list.findIndex((skuData) => skuData.skuId === skuId);
                if (index >= 0) {
                    list.splice(index, 1);
                }
            });
            if (searchText) {
                setFiltertedSkuList((prevState) => {
                    return [...list];
                });
            } else {
                setUnMatchedSku((prevState) => {
                    return [...list];
                });
            }
            setSelectedSkuList([]);
        }
    };

    return (
        <Spin spinning={loading}>
            <div className="bulk-upload-container">
                {selectedFile ? (
                    <div className="sku-list-container">
                        <div>
                            <div className="file-details-container">
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className="file-name file-detail">
                                        <div className="title">File name</div>
                                        <div className="value">{selectedFile.name}</div>
                                    </div>
                                    <div className="other-details">
                                        <div className="file-detail">
                                            <div className="title">File size</div>
                                            <div className="value">
                                                {filesize(selectedFile.size, {
                                                    base: 2,
                                                    standard: 'jedec'
                                                })}
                                            </div>
                                        </div>
                                        <div className="file-detail">
                                            <div className="title">SKU Uploaded</div>
                                            <div className="value">{`${
                                                skuData.filter((sku) => !('error' in sku)).length
                                            } / ${
                                                skuData.filter((sku) => !('error' in sku)).length +
                                                unMatchedSku.length
                                            }`}</div>
                                        </div>
                                        <div className="file-detail">
                                            <div className="title">SKU Match Error</div>
                                            <div className="value error">{unMatchedSku.length}</div>
                                        </div>
                                        <div className="icon">
                                            <ReloadOutlined onClick={onClickGetSkuDetailsHandler} />
                                        </div>
                                        <div className="icon" onClick={onClickCloseHandler}>
                                            <CloseOutlined />
                                        </div>
                                    </div>
                                </div>
                                <Progress
                                    status="success"
                                    percent={
                                        (skuData.filter((sku) => !('error' in sku)).length /
                                            (skuData.filter((sku) => !('error' in sku)).length +
                                                unMatchedSku.length)) *
                                        100
                                    }
                                    showInfo={false}
                                />
                            </div>
                            <div className="sku-ids-table-container">
                                <div className="actions">
                                    <Input
                                        style={{ marginRight: '12px' }}
                                        value={searchText}
                                        placeholder="Search for SKUs"
                                        onChange={(e) => {
                                            onChangeSearchTextHandler(e.target.value);
                                        }}
                                    />
                                    <Button
                                        style={{ marginRight: '12px' }}
                                        type="primary"
                                        onClick={onClickRemoveSelectedSKUHandler}
                                        disabled={selectedSkuList.length === 0}>
                                        Remove Selected
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={onClickRemoveAllSKUHandler}
                                        disabled={unMatchedSku.length === 0}>
                                        Remove All
                                    </Button>
                                </div>
                                <div className="table">
                                    <Table
                                        rowKey="skuId"
                                        dataSource={searchText ? filtertedSkuList : unMatchedSku}
                                        pagination={
                                            unMatchedSku.length > 10
                                                ? {
                                                      position: ['', 'bottomCenter'],
                                                      showSizeChanger: false
                                                  }
                                                : false
                                        }
                                        columns={columns}
                                        size={'small'}
                                        rowSelection={{
                                            selectedRowKeys: selectedSkuList,
                                            onChange: onSelectChange
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="action-buttons">
                            <Button
                                onClick={onClickCloseModalHandler}
                                style={{ marginRight: '8px' }}>
                                Close
                            </Button>
                            <Button
                                type="primary"
                                onClick={onSubmitDataHandler}
                                disabled={unMatchedSku.length !== 0 || !isCsvValid}>
                                Upload SKUs
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div style={{ height: '95%' }}>
                        <Link to="/template.csv" target="_blank" download>
                            Download Sample CSV file
                        </Link>
                        <br />
                        <br />
                        <Dragger {...draggerConfig} customRequest={processCSV}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined style={{ color: '#001952' }} color="#001952" />
                            </p>
                            <p className="ant-upload-text">
                                Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                                Support for a single file upload. Strictly prohibit from uploading
                                other than CSV files
                            </p>
                        </Dragger>
                    </div>
                )}
            </div>
        </Spin>
    );
};

export default BulkUpload;
