import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    assignedSbu: []
};

const assignedSbuSlice = createSlice({
    name: 'assignedSbu',
    initialState,
    reducers: {
        setAssignedSbuData(state, { payload }) {
            state.assignedSbu = payload.assignedSbu;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const AssignedSbuActions = assignedSbuSlice.actions;
export const AssignedSbuReducer = assignedSbuSlice.reducer;
export default assignedSbuSlice;
