import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    level: '',
    index: -1, 
    search: '', 
    sort: '',
    shopList: [],
    count: 0
};

const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setShopData(state, { payload }) {
            state.level = payload.level;
            state.index = payload.index;
            state.search = payload.search;
            state.sort = payload.sort;
            state.shopList = payload.shopData;
            state.count = payload.count;
        },

        updateShopList(state, { payload }) {
            state.shopList = payload.shopList
        },

        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const ShopActions = shopSlice.actions;
export const ShopReducer = shopSlice.reducer;
export default shopSlice;
