import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    alerts: []
};

const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        //Setting logs state
        setAlertsData(state, { payload }) {
            state.alerts = payload.alerts;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const AlertsActions = alertsSlice.actions;
export const AlertsReducer = alertsSlice.reducer;
export default alertsSlice;