import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Spin } from "antd";
import { useDispatch } from "react-redux";

import { validateAccessToken } from "../../graphql/queryExecuters";
import { AuthActions } from "../../redux/slices";
import './SamlCallback.scss';
import { Notification } from "../../utilities";
import { invalid_token_error, invalid_user_error } from "../../constants/displayStrings";

const SamlCallback = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        initiateSamlAuth();
    }, []) //eslint-disable-line

    //Setting loader to false while moving to another page
    useEffect(() => () => {
        setLoading(false);
    })

    const initiateSamlAuth = async () => {
        try {
            const token = searchParams.get('token');
            if (token) {
                const authData = await validateAccessToken(token);
                if ('id' in authData) {
                    if (authData.id) {
                        if (authData.status === 1) {
                            localStorage.setItem('token', authData.accessToken);
                            localStorage.setItem('refresh', authData.refreshToken);
                            window.dispatchEvent(new Event("storage"));
                            const state = {
                                id: authData.id,
                                name: authData.name,
                                email: authData.email,
                                userType: authData.userType,
                                accessRole: authData.accessRole,
                                createdBy: authData.createdBy,
                                editAccess: authData.editAccess ? authData.editAccess : '',
                                accessToken: authData.accessToken
                            }
                            dispatch(AuthActions.setAuth({...state}));
                            navigate("/dashboard");
                        } else {
                            Notification('error', invalid_user_error);
                        }
                    } else {
                        Notification('error', invalid_token_error);
                    }
                } else {
                    Notification('error', invalid_token_error);
                }
            } else {
                Notification('error', invalid_token_error);
            }
        } catch (err) {
            console.log('Error - Error while initiating SAML Auth', err);
        }
    }

    return <Spin spinning={loading} tip='Handshaking with Petco ...'>
        <div className="callback-tip-container">Reauthenticating with Petco</div>
    </Spin>
}

export default SamlCallback;