import React from 'react';

import ErrorPage from '../components/ErrorPage/ErrorPage';
  
export default class ErrorBoundary extends React.Component {
    
    state = {
        hasError: false,
    };

    static getDerivedStateFromError = error => {
        return { hasError: true };
    };

    componentDidCatch = (error, info) => {
        this.setState({ error, info });
    };

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
    
        return hasError ? <ErrorPage /> : children;
    }
}