import { Divider, Tag, Spin, Space } from 'antd';

import './GlobalSearch.scss';

export const GlobalSearchDropdown = ({menu, setSearchFilters, searchFilters, dataCount, loading, isEmpty}) => {
    const handleFilterClick = (event, option) => {
        setSearchFilters(
            searchFilters.map((filter) => {
                if (filter.label === option.label && !filter.isActive) {
                    return { ...filter, isActive: !filter.isActive };
                } else {
                    return { ...filter };
                }
            })
        );
    };

    const handleFilterClose = (event, option) => {
        event.preventDefault(); // Prevents the filter tag from being removed
        setSearchFilters(
            searchFilters.map((filter) => {
                if (filter.label === option.label) {
                    return { ...filter, isActive: !filter.isActive };
                } else {
                    return { ...filter };
                }
            })
        );
    };
    return (
        <div className="global-search-dropdown">
            <span>I'm searching for...</span>
            <div className="dropdown-tag-container">
                {searchFilters.map((filter, index) => {
                    if (index < 3) {
                        return (
                            <Tag
                                key={filter.label}
                                className="dropdown-tag"
                                onClick={(event) => handleFilterClick(event, filter)}
                                checked={filter.isActive}
                                color={filter.isActive ? '#2c79b1' : 'default'}
                                closable={filter.isActive}
                                style={!filter.isActive && {}}
                                onClose={(event) => handleFilterClose(event, filter)}>
                                {filter.label}
                            </Tag>
                        );
                    }
                    else return null;
                })}
            </div>
            <div className="dropdown-tag-container">
                {searchFilters.map((filter, index) => {
                    if (index >= 3) {
                        return (
                            <Tag
                                key={filter.label}
                                className="dropdown-tag"
                                onClick={(event) => handleFilterClick(event, filter)}
                                checked={filter.isActive}
                                color={filter.isActive ? '#2c79b1' : 'default'}
                                closable={filter.isActive}
                                style={!filter.isActive && {}}
                                onClose={(event) => handleFilterClose(event, filter)}>
                                {filter.label}
                            </Tag>
                        );
                    }
                    else return null;
                })}
            </div>
            {!isEmpty && <Divider style={{ margin: '12px 0' }} />}
            {!loading && !isEmpty ? (
                <span className="results-found-span">
                    {dataCount !== 0 ? dataCount : 'No'} results found
                </span>
            ) : null}
            {loading ? (
                <Space className="global-search-loading">
                    <Spin />
                </Space>
            ) : (
                !isEmpty && dataCount !== 0 && menu
            )}
        </div>
    );
};