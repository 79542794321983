import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Notification, encodeString } from '../../utilities';
import { Table, Input, Pagination, Checkbox, Spin, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { manufactureTableColumns } from '../../constants/tableColumns';
import {
    getAllManufData,
    getManufAtSbuData,
    getManufAtClassData,
    getManufAtDeptData,
    getManufAtShopData,
    getManufAtSubclassData,
    getAllAssignManufactureData,
    getAssignManufAtSbuData,
    getAssignManufAtClassData,
    getAssignManufAtDeptData,
    getAssignManufAtShopData,
    getAssignManufAtSubclassData
} from '../../graphql/queryExecuters';
import { ManufActions } from '../../redux/slices/manufacture.slice';

import "./Tables.scss"

const ManufacturerTable = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const urlParamsObj = new URLSearchParams(location.search);
    const urlParams = urlParamsObj.toString();
    const [filterParams, setFilterParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [sortType, setSortType] = useState('ASC');
    const [tableData, setTableData] = useState([]);
    const [rowCount, setRowCount] = useState(props.count.manufCount);
    const [pageindex, setPageindex] = useState(filterParams.get('manufIndex') ? filterParams.get('manufIndex') : 1);
    const [searchText, setSearchText] = useState(filterParams.get('manufSearch') ? filterParams.get('manufSearch') : '');
    const [reloadData, setReloadData] = useState(false);
    const [userId, setUserId] = useState('');

    const { userType, id } = useSelector(state => state.auth);
    const { level,  index, search, sort, manufList, count } = useSelector((state) => state.manufData);

    const getIndexParam = () => parseInt(filterParams.get('manufIndex')) || 1;

    useEffect(() => {
        checkSearchTextChange();
    }, [filterParams]); //eslint-disable-line

    useEffect(() => {
        if (props?.level && userType) {
            getAllManufactureDataTrigger();
        }
    }, [pageindex, sortType, props.reload, props.level, userType]); //eslint-disable-line

    const onRowClick = (row) => {
        navigate('/sku_data/manufacturer/' + row.manufId + '_' + encodeString(row.manufDesc), { state: { searchParams: urlParams,
                row } });
    };

    //getting all manufature Data
    const getAllManufactureDataTrigger = async (searchToApply = null) => {
        try {
            setLoading(true);
            const searchValue = searchToApply !== null ? searchToApply : searchText;
            if(level === props.level && index === pageindex && search === searchValue && sort === sortType && userId === props.merchantId && !(reloadData !== props.reload && props.reload)){
                setTableData(manufList);
                setRowCount(count);
            } else {
                let manufData = [];
                if (props.level === 'all' || props.level === 'bulkConfig') {
                    if(userType === 'MERCHANT'){
                        manufData = await getAllAssignManufactureData(id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        manufData = await getAllManufData(getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = manufData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'sbu') {
                    if(userType === 'MERCHANT'){
                        manufData = await getAssignManufAtSbuData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        manufData = await getManufAtSbuData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = manufData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'shop') {
                    if(userType === 'MERCHANT'){
                        manufData = await getAssignManufAtShopData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        manufData = await getManufAtShopData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = manufData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'department') {
                    if(userType === 'MERCHANT'){
                        manufData = await getAssignManufAtDeptData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        manufData = await getManufAtDeptData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = manufData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'class') {
                    if(userType === 'MERCHANT'){
                        manufData = await getAssignManufAtClassData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        manufData = await getManufAtClassData(props.id, getIndexParam() - 1, 10, searchValue, sortType);  
                    }
                    const value = manufData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'subclass') {
                    if(userType === 'MERCHANT'){
                        manufData = await getAssignManufAtSubclassData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        manufData = await getManufAtSubclassData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = manufData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'merchant') {
                    manufData = await getAllAssignManufactureData(props?.merchantId, getIndexParam() - 1, 10, searchValue, sortType);
                    const value = manufData.dataCount;
                    onSearchInput(value);
                }
                dispatch(ManufActions.setManufData({ 
                    level: props.level,
                    index: pageindex, 
                    search: searchValue, 
                    sort: sortType,
                    manufData: manufData.data,
                    count: manufData.dataCount
                }));
                setTableData(manufData.data);
                setRowCount(manufData.dataCount);
                setUserId(props.merchantId);
            }
            setReloadData(props.reload);
            setLoading(false);
            props.setReload && props.setReload('manufacturer', false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting manufacture Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const checkSearchTextChange = () => {
        const searchParamText = filterParams.get('manufSearch') || '';
        if(searchText !== searchParamText) {
            setSearchText(searchParamText);
            getAllManufactureDataTrigger(searchParamText);
        }
    }

    const onSearchInput = (value) => {
        if (value < (getIndexParam() - 1) * 10) {
            filterParams.set('manufIndex', 1);
            filterParams.set('manufSearch', searchText);
            setFilterParams(filterParams);
        }
    }

    const handlePageChange = (event) => {
        filterParams.set('manufIndex', event);
        setFilterParams(filterParams);
        setPageindex(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };
    
    const handleCheckboxSelection = async (checkedValues) => {
        const status = {
            value: checkedValues.target.options[0].value,
            status: checkedValues.target.checked
        }
        props.updateTagValues(status)
    };
    
    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                filterParams.set('manufIndex', 1);
                filterParams.set('manufSearch', event.target.value);
                setFilterParams(filterParams);
                setPageindex(1);
                getAllManufactureDataTrigger();
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    return (
        <div className="table-container">
            <Input
                className="petco-Data-Search responsive-Search-Bar"
                id="manuf-search"
                prefix={<SearchOutlined />}
                onKeyDown={onPressEnterKeyHandler}
                onChange={handleSearchChange}
                value={searchText}
                placeholder={searchText ? searchText : "Search for Manufacturer"}
                suffix={<span className='press-enter-style'>Press enter to search</span>}
                disabled={loading}
            />
            {props.level === 'bulkConfig' ? (
                <Spin spinning={loading}>
                    {tableData.map((item) => {
                        return (
                                <li
                                    key={item.manufDesc}
                                    style={{
                                        color: '#001952',
                                        font: '10px',
                                        marginBottom: '10px'
                                    }}>
                                    {
                                        <Checkbox
                                            key={item.desc}
                                            checked={props.isTagChecked(item.manufDesc, item.manufId,'manufacturer')}
                                            options={[{
                                                label: item.manufDesc,
                                                value: {desc: item.manufDesc,
                                                id: item.manufId,
                                                level: 'manufacturer'
                                                }
                                            }]}
                                            onChange={handleCheckboxSelection}
                                        >{item.manufDesc}</Checkbox>
                                    }{' '}
                                    <Divider style={{margin: '15px'}} />
                                </li>
                        );
                    })}
                </Spin>
            ) :
            (<Table
                rowKey={(row) => row.manufId + row.manufDesc} 
                id= "manuf-table"
                dataSource={tableData}
                columns={manufactureTableColumns}
                loading={loading}
                pagination={false}
                scroll={{ x: 2000 }}
                size="middle"
                onRow={(row) => {
                    return {
                        onClick: (event) => {
                            props.level !== 'merchant' && onRowClick(row);
                        }
                    };
                }}
                className="table-striped-rows clickable-table"
                rowSelection={props.level === 'merchant' ? props?.rowSelection : false}
                onChange={handleTableChange}
            />
            )}
            {rowCount > 10 && !loading ? <Pagination
                    className="petco-Data-pagination"
                    id="manuf-bulk-filter"
                    defaultCurrent={getIndexParam()}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
        </div>
    );
};

export default ManufacturerTable;
