import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

//Get all class Data
export const createGetAllClassDataQuery = (index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllClasses(index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetClassDetailsQuery = (classId, classDesc) => {
    try {
        return gql`
            query {
                getLevelDetails(level: "class", levelId: "${classId}", levelDesc: "${classDesc}") {
                    levelId
                    levelDesc
                    subclassCount
                    manufCount
                    brandCount
                    skuCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get all class Data at sbu
export const createGetClassAtSbuDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllClassesAtSbu(sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get all class Data at shop
export const createGetClassAtShopDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllClassesAtShop(shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get all class Data at dept
export const createGetClassAtDeptDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllClassesAtDept(deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignClassDataQuery = (merchantId, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignClasses(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignClassAtSbuDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignClassesAtSbu(merchantId: "${merchantId}", sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get all class Data at shop
export const createGetAssignClassAtShopDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignClassesAtShop(merchantId: "${merchantId}", shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

//Get all class Data at dept
export const createGetAssignClassAtDeptDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignClassesAtDept(merchantId: "${merchantId}", deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        classId
                        classDesc
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};