import GraphQL from '../../configs/graphQL.config';

import * as QueryCreator from '../queryCreators/';

export const getAllCompetitors = async () => {
    try {
        const query = QueryCreator.createGetAllCompetitorsQuery();
        const response = await GraphQL.request(query);
        return response.getCompetitors;
    } catch (err) {
        console.log(err);
    }
};

export const getCompetitorById = async (competitorId) => {
    try {
        const query = QueryCreator.createGetCompetitorByIdQuery(competitorId);
        const response = await GraphQL.request(query);
        return response.getCompetitorById;
    } catch (err) {
        console.log(err);
    }
};

export const getCompetitorSkus = async (
    competitorId,
    page,
    limit,
    searchText,
    scrapers,
    stockType,
    period
) => {
    try {
        const query = QueryCreator.createGetCompetitorSKUQuery(
            competitorId,
            page,
            limit,
            searchText,
            scrapers,
            stockType,
            period
        );
        const response = await GraphQL.request(query);
        return response.getCompetitorSkus;
    } catch (err) {
        console.log(err);
    }
};

export const getRecentCompetitorSkus = async (
    competitorId,
    page,
    limit,
    searchText,
    scrapers,
    stockType,
    period
) => {
    try {
        const query = QueryCreator.createGetRecentCompetitorSKUQuery(
            competitorId,
            page,
            limit,
            searchText,
            scrapers,
            stockType,
            period
        );
        const response = await GraphQL.request(query);
        return response.getRecentCompetitorSkus;
    } catch (err) {
        console.log(err);
    }
};

export const updateCompetitorDetails = async (competitorId, fieldsToUpdate) => {
    try {
        const query = QueryCreator.createUpdateCompetitorQuery(competitorId, fieldsToUpdate);
        const response = await GraphQL.request(query);
        return response.updateCompetitorDetails;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuCountByCompetitors = async () => {
    try {
        const query = QueryCreator.createGetSkuCountByCompetitorQuery();
        const response = await GraphQL.request(query);
        return response.getSkusCountByCompetitor;
    } catch (err) {
        console.log(err);
    }
};

export const updateCompetitorTrigger = async (configId, configdesc, configLevel, competitor, scraper, expiryHour) => {
    try {
        const query = QueryCreator.createUpdateCompetitorTriggerQuery(configId, configdesc, configLevel, competitor, scraper, expiryHour);
        const response = await GraphQL.request(query);
        return response.updateCompetitorTriggerConfig;
    } catch (err) {
        console.log(err);
    }
};

export const getAllCompetitorsBaseData = async () => {
    try {
        const query = QueryCreator.createGetAllCompetitorsBaseDataQuery();
        const response = await GraphQL.request(query);
        return response.getCompetitorsBaseData;
    } catch (err) {
        console.log(err);
    }
};

export const addCompetitor = async (name,valueAdder,valueSubtracted,perValueAdder,perValueSubtracted,scrapers) => {
    try{
        const query = QueryCreator.createNewCompetitorQuery(name,valueAdder,valueSubtracted,perValueAdder,perValueSubtracted,scrapers);
        const response = await GraphQL.request(query);
        return response.createCompetitor;
    } catch (err) {
        console.log(err);
    }
};