import { AllCountActions } from '../slices/allCount.slice';
import * as QueryExecuters from '../../graphql/queryExecuters';

//Action creator for getting users count
export const getUsersCount = () => {
    return async (dispatch) => {
        try {
            const usersCount = await QueryExecuters.getUsersCount();
            if (usersCount) {
                dispatch(AllCountActions.setAllUserCountData({ allUserCounData: usersCount }));
            }
        } catch (err) {
            console.log('Error - Check Authorization Action Error - ', err);
        }
    };
};