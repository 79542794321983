import { useEffect, useState } from 'react';
import { Button, Input, Modal, Select, Table, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate , useSearchParams , useLocation} from 'react-router-dom';
import './Merchants.scss';
import {Notification, validatePetcoEmail} from '../../utilities';
import { addUser, getUsersList } from '../../graphql/queryExecuters';
import * as UserActions from '../../redux/actionCreators/users.actions';
import { accessTypeDescriptions, merchantAccessTypes, userTypes } from '../../constants/common';
import {
    invitation_success,
    merchant_properties_error,
    petco_merchant_email_error,
    users_fetch_error
} from "../../constants/displayStrings";
import { useDispatch, useSelector } from 'react-redux';
import { merchantsTableColumns } from '../../constants/tableColumns';

const Merchants = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const urlParams = new URLSearchParams(location.search).toString();
    const [filterParams , setFilterParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(filterParams.get('merchantIndex') ? filterParams.get('merchantIndex') : 1);
    const [searchText, setSearchText] = useState(filterParams.get('search') ? filterParams.get('search') : '');
    const [sortType, setSortType] = useState('ASC');
    const [rowCount, setRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);

    const { userType } = useSelector(state => state.auth);

    const getIndexParam = () => parseInt(filterParams.get('merchantIndex')) || 1;

    const getFilterParams = () => {
        return {
            search: filterParams.get('search') || '',
            merchantIndex: getIndexParam(),
        }
    }
    
    useEffect(() => {
        setSearchText(filterParams.get('search') || '');
        setPageindex(filterParams.get('merchantIndex') || 1);
    }, [filterParams]);

    useEffect(() => {
        getUsersListInitiator()
    }, [pageindex, sortType]); //eslint-disable-line

    //Initial values of add user part
    const nullState = {
        name: '',
        email: '',
        accessLevel: merchantAccessTypes[0].value //Setting view as default access level
    };

    //Helper for showing add user form
    const [merchantData, setMerchantData] = useState({...nullState});
    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isProperEmail, setIsProperEmail] = useState(false);

    const getUsersListInitiator = async () => {
        setLoading(true);
        const res = await getUsersList('MERCHANT', pageindex, 10, searchText, sortType);
        if (res.__typename !== 'Error') {
            setTableData(res.user);
            setRowCount(res.count);
            onSearchInput(res.count)
        } else {
            Notification('error', users_fetch_error);
        }
        setLoading(false);
    }

    const onClickRow = (row, index) => {
        try {
            navigate(`/teams_and_merchants/merchant_details/${row.id}`, { state: { searchParams: urlParams, row } });
        } catch (err) {
            console.log(err);
        }
    };
    
    const onSearchInput = (value) => {
        if (value < (getIndexParam() - 1) * 10) {
            setFilterParams({
                ...getFilterParams(),
                merchantIndex: 1,
                search : searchText
            })
        }
    }

    const onChangeValueHandler = (field, value) => {
        try {
            setMerchantData((prevState) => {
                const state = { ...prevState };
                state[field] = value;
                return state;
            });
        } catch (err) {
            console.log(err);
        }
    };

    //Helper for Close modal
    const onCancelModalHandler = () => {
        setMerchantData(nullState);
        setShowModal(false);
    };

    //Adding merchant handler
    const onAddMerchantHandler = async () => {
        try {
            setShowError(true);
            const merchantName = merchantData.name.trim()
            const merchantEmail = merchantData.email.trim()
            if (merchantName && merchantEmail) {
                const isValidEmail = validatePetcoEmail(merchantEmail);
                setIsProperEmail(isValidEmail);
                if (isValidEmail) {
                    setLoading(true);
                    const res = await addUser(merchantName, merchantEmail.toLowerCase(), 'MERCHANT', '', merchantData.accessLevel);
                    setLoading(false);
                    if (res.__typename === 'Error') {
                        Notification('error', res.message);
                    } else if (res.message === 'User Already Exist') {
                        Notification('warning', res.message);
                    } else {
                        //Getting updated users list
                        setShowModal(false);
                        setMerchantData(nullState);
                        Notification('success', invitation_success);
                        setIsProperEmail(false);
                        dispatch(UserActions.getUsersCount());
                        getUsersListInitiator();
                        setShowError(false);
                    }
                } else {
                    Notification('warning', 'Invalid Email', petco_merchant_email_error);
                }
            } else {
                Notification('warning', merchant_properties_error);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const handlePageChange = (event) => {
        setFilterParams({
            ...getFilterParams(),
            merchantIndex: event,
        })
        setPageindex(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : sorter.order === 'descend' ? 'DESC' : '';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                setFilterParams({
                    ...getFilterParams(),
                    merchantIndex: 1,
                    search: event.target.value
                })
                getUsersListInitiator()
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="table-container">
            <div className="actions">
                <Input
                    className="petco-Data-Search responsive-Search-Bar"
                    prefix={<SearchOutlined />}
                    id='merchant-search'
                    onKeyDown={onPressEnterKeyHandler}
                    onChange={handleSearchChange}
                    value={searchText}
                        placeholder={searchText ? searchText : "Search for Merchants"}
                    suffix={<span className='press-enter-style'>Press enter to search</span>}
                />
                <div className="actions-container">
                    {userType === userTypes.ADMIN && <Button
                        id='merchant-add'
                        type="primary"
                        onClick={() => {
                            setShowModal(true);
                        }}>
                        + Add Merchant
                    </Button>}
                </div>
            </div>
            <Table
                onRow={(row, index) => {
                    return {
                        onClick: () => {
                            onClickRow(row, index);
                        }
                    };
                }}
                rowKey="id"
                dataSource={tableData}
                id='merchant-table'
                columns={merchantsTableColumns}
                className="table-striped-rows clickable-table"
                scroll={{ x: 2000 }}
                pagination={false}
                size="middle"
                onChange={handleTableChange}
                loading={loading}
            />
            {rowCount > 10 ? <Pagination
                    className="petco-Data-pagination"
                    id='merchant-filter'
                    current={getIndexParam()}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
            <Modal
                confirmLoading={loading}
                open={showModal}
                title="Invite Merchant"
                okText="Send Invite"
                cancelText="Cancel"
                onCancel={onCancelModalHandler}
                onOk={onAddMerchantHandler}>
                <div className="pricing-engine-form">
                    <div className="input-container" style={{ marginTop: 32 }}>
                        <div className="label">Merchant Name</div>
                        <div>
                            <Input
                                className='input-global'
                                id='add-merchant-name'
                                onChange={(e) => {
                                    onChangeValueHandler('name', e.target.value);
                                }}
                                value={merchantData.name}
                                status={
                                    (showError && !merchantData.name) ? 'error' : ''
                                }
                            />
                        </div>
                    </div>
                    <div className="input-container">
                        <div className="label">Merchant Email Address</div>
                        <div>
                            <Input
                                className='input-global'
                                id='add-merchant-address'
                                onChange={(e) => {
                                    onChangeValueHandler('email', e.target.value);
                                }}
                                value={merchantData.email}
                                status={
                                    (showError && (!merchantData.email || !isProperEmail)) ? 'error' : ''
                                }
                            />
                        </div>
                    </div>
                    <div className="input-container">
                        <div className="label">Access level</div>
                        <div>
                            <Select
                                placeholder="Select Access Level"
                                style={{
                                    width: '100%'
                                }}
                                value={merchantData.accessLevel}
                                id='add-merchant-access'
                                options={(merchantAccessTypes)?.map((item) => ({
                                    value: item.value,
                                    label: item.label
                                }))}
                                onChange={(value) => {
                                    onChangeValueHandler('accessLevel', value);
                                }}
                            />
                            <div className="label">{accessTypeDescriptions[merchantData.accessLevel]}</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Merchants;
