import React, { useState } from 'react';
import { Button, Modal, Select, Input, Space } from 'antd';
import * as displayStrings from '../../constants/displayStrings';
import { priceRequest } from '../../constants/common';
import {
    updateApprovalStatusBulk
} from '../../graphql/queryExecuters';
import './CompTableReScrapeModal.scss';
import { Notification } from '../../utilities';

const MultiApprovalStatusModal = ({requestStatus, code, openModal, selected, controlModal, removeSelected, callback}) => {

    const { TextArea } = Input;
    const [statusCall, setStatusCall] = useState(requestStatus);
    const [changeReason, setChangeReason] = useState(code);
    const [loading, setLoading] = useState(false);

    const handleCancel = () => {
        setStatusCall(requestStatus);
        controlModal()
    };

    const handleConfirm = async () => {
        if (changeReason.includes('|')) {
            Notification('error', `Price reason may not contain '|' values.`);
            return;
        }

        setLoading(true);
        if (statusCall === priceRequest.approved) setStatusCall(priceRequest.approved)
        else setStatusCall(priceRequest.rejected)
        try {
            let response = await updateApprovalStatusBulk(selected, statusCall, changeReason);
            response.includes('successfully')
                ? Notification('success', displayStrings.price_update_success)
                : Notification(
                      'error',
                      displayStrings.price_update_error
                  );    

            setLoading(false);
            controlModal();
            removeSelected();
            callback();
        } catch(err) {
            setLoading(false);
            console.log(err);
            controlModal();
            Notification(
                'error',
                displayStrings.price_update_error
            );
        }
    };

    return (
            <Modal
                open={openModal}
                title="Price Approval"
                onCancel={handleCancel}
                width={600}
                footer={[
                    <Button className="closeBtn" key="close" onClick={handleCancel}>
                        Close
                    </Button>,
                    <Button loading={loading} className="triggerRescrapeBtn" key="triggerRescrape" type="primary" onClick={handleConfirm}>
                        Apply Changes
                    </Button>
                ]}>
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                    display: 'flex',
                    }}
                >
                    <Select
                    className="accept-reject-dropdown"
                    onChange={(value) => {
                        setStatusCall(value);
                    }}
                    value={statusCall}
                    size="large"
                    options={[
                        { label: 'Approve', value: 'Approved'},
                        { label: 'Reject', value: 'Rejected' },
                        
                    ]}
                    style={{width: '50%'}} 
                    />
                    <TextArea 
                        className="accept-reject-box"
                        rows={8.5} 
                        placeholder={`${code} -> Default Code`}
                        onChange={(e) => {
                            setChangeReason(e.target.value);
                        }}
                    />
                </Space>  
                             
            </Modal>
    );
};

export default MultiApprovalStatusModal;
