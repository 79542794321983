import { useCallback, useState } from 'react';
import { Table, Input, Button } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

import './ErrorSkuList.scss'

export function ErrorSkuList({ errorSkusList, removeSkusCallback, editSkuCallback, closeDrawerCallback }) {
    const [tableData, setTableData] = useState(errorSkusList);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [searchText, setSearchText] = useState('');

    const onClickRemoveSKUHandler = useCallback((skuId) => {
            removeSkusCallback([skuId]);
            setTableData(tableData.filter((sku) => sku.skuId !== skuId));
        }, [tableData, removeSkusCallback]
    );

    const onClickRemoveAllSKUHandler = useCallback(() => {
        removeSkusCallback(errorSkusList.map((sku) => sku.skuId));
        setTableData([]);
        closeDrawerCallback();
    }, [errorSkusList, removeSkusCallback, closeDrawerCallback]);

    const onClickRemoveSelectedSKUHandler = useCallback(() => {
        if (selectedRowKeys.length === 0) return;

        removeSkusCallback(selectedRowKeys);
        setTableData(tableData.filter((sku) => !selectedRowKeys.includes(sku.skuId)));
    }, [removeSkusCallback, selectedRowKeys, tableData]);

    const onChangeSearchTextHandler = (value) => {
        setSearchText(value);
    };

    const columns = [
        {
            title: 'SKU Id Error',
            key: 'skuId',
            width: 80,
            render: (_, data) => <div>{data.skuId}</div>
        },
        {
            title: 'Error Message',
            key: 'message',
            width: 300,
            render: (_, data) => <div>{data.messages.join(", ")}</div>
        },
        {
            title: 'Actions',
            fixed: 'right',
            width: 60,
            render: (_, data) => (
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            onClickRemoveSKUHandler(data.skuId);
                        }}>
                        <CloseOutlined /> Remove
                    </div>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            editSkuCallback(data.skuId);
                        }}>
                        <EditOutlined /> Edit
                    </div>
                </div>
            )
        }
    ];
    return (
        <div className="error-sku-table-container">
            <div className="actions">
                <Input
                    style={{ marginRight: '12px' }}
                    value={searchText}
                    placeholder="Search for SKUs"
                    onChange={(e) => {
                        onChangeSearchTextHandler(e.target.value);
                    }}
                />
                <Button
                    style={{ marginRight: '12px' }}
                    type="primary"
                    onClick={onClickRemoveSelectedSKUHandler}
                    disabled={selectedRowKeys.length === 0}>
                    Remove Selected
                </Button>
                <Button type="primary" onClick={onClickRemoveAllSKUHandler}>
                    Remove All
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={tableData.filter((sku) => sku.skuId.includes(searchText))}
                bordered
                size="small"
                pagination={false}
                rowKey="skuId"
                rowSelection={{
                    selectedRowKeys: selectedRowKeys,
                    onChange: (value) => {
                        setSelectedRowKeys(value);
                    }
                }}
            />
        </div>
    );
}
