import { Badge, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import * as UserActions from '../../redux/actionCreators/users.actions';
import Merchants from '../Merchants/Merchants';
import PetcoUsers from '../PetcoUsers/PetcoUsers';

const TeamsAndMerchants = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { userType } = useSelector(state => state.auth);
    const { allUserCountList } = useSelector((state) => state.allCount);

    //declaring index change state on tab change for changinf badge color change
    const [tabindex, setTabindex] = useState(props.tabindex);
    
    useEffect(() => {
        if(userType === 'MERCHANT') {
            navigate('/');
        }
        if (allUserCountList.length === 0) {
            getUsersCount();
        }
    }, [userType]); //eslint-disable-line

    const onTabChange = (key) => {
        setTabindex(key);
        if(key==='1'){
            navigate('/teams_and_merchants/users');
        }
        else{ 
            navigate('/teams_and_merchants/merchants');
        }
        
    };

    const getUsersCount = async () => {
        try {
            dispatch(UserActions.getUsersCount());   
        } catch (err)  {
            console.log('Error - Error while getting users count', err);
        }
    }

    const tabs = [
        {
            label: (
                <span>
                    <span style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}>Pricing Team{' '}</span>
                    <Badge
                        showZero
                        className="pricingTeam-count-badge"
                        count={allUserCountList?.teamCount || 0}
                        overflowCount={99999}
                        style={{
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: <PetcoUsers />
        },
        {
            label: (<span>
                <span style={{ color: tabindex === '2' ? '#001952' : '#96999F' }}>Merchants{' '}</span>
                <Badge
                    showZero
                    className="merchant-count-badge"
                    count={allUserCountList?.merchantCount || 0}
                    overflowCount={99999}
                    style={{
                        backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                        color: tabindex === '2' ? '#FFFFFF' : '#001952'
                    }}
                />
            </span>),
            key: '2',
            children: <Merchants />
        }
    ];
    return (
        <ContentWrapper>
            <Tabs animated items={tabs} defaultActiveKey={tabindex} onChange={onTabChange}/>
        </ContentWrapper>
    );
};

export default TeamsAndMerchants;
