import React, { useEffect, useState } from 'react';
import { Notification } from '../../utilities';
import { Table, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { levelConfigurationLogsColumns } from '../../constants/tableColumns';
import { getlevelLogsData, getEngineLogsData } from '../../graphql/queryExecuters';
import { getAllUsersBaseData } from '../../redux/actionCreators/auth.actions';
import {formatLogData} from '../../utilities/helper'


import './Tables.scss';

const LevelConfigLogs = (props) => {

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(searchParams.get(`${props.level}_config`) ? searchParams.get(`${props.level}_config`) : 1);
    const [rowCount, setRowCount] = useState(0);
    const [tableData, setTableData] = useState([]);

    const { allUsersList } = useSelector(state => state.users);

    useEffect(() => {
        getLogsDataTrigger();
    }, [pageindex, props?.totalRecords, props?.reload]); //eslint-disable-line

    useEffect(() => {
        getAllUserDataTrigger();
    }, [allUsersList]); //eslint-disable-line

    const getLogsDataTrigger = async () => {
        try {
            setLoading(true);
            let logsData = [];
            if (props.type === 'engine' ) {
                logsData = await getEngineLogsData(props.id,props.level, pageindex - 1, 10);
            } else {
                logsData = await getlevelLogsData(props.id,props.level, pageindex - 1, 10);
            }
            formatLogData(logsData.data, props.level)
            logsData && setTableData(logsData.data)
            logsData && setRowCount(logsData.dataCount);
            setLoading(false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting Logs Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const getAllUserDataTrigger = async () => {
        try {
            if (allUsersList.length === 0) {
                dispatch(getAllUsersBaseData());
            }
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting user Data. Please try again later.'
            );
        }
    };

    const handlePageChange = (event) => {
        searchParams.set(`${props.level}_config`, event);
        setSearchParams(searchParams);
        setPageindex(event);
    };

  return (
    <div className="table-container">
            <Table
                rowKey={"id"}
                id="levelConfig-table"
                dataSource={tableData}
                columns={levelConfigurationLogsColumns}
                loading={loading}
                pagination={false}
                scroll={{ x: 1000 }}
                size="middle"
                className="table-striped-rows"
                // rowSelection={props.level === 'merchant' ? props?.rowSelection : false}
            />
            {rowCount > 10 ? <Pagination
                    className="petco-Data-pagination"
                    id='levelConfig-filter'
                    defaultCurrent={pageindex}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
        </div>
  )
}

export default LevelConfigLogs