import { AutoComplete, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import './GlobalSearch.scss';
import { GlobalSearchDropdown } from './GlobalSearchDropdown';
import { getGlobalSearchResults } from '../../graphql/queryExecuters';
import { globalSearchFilters } from '../../constants/common';
import { getUrlParamString } from '../../utilities/helper';

const renderOption = (option) => {
    const dummyRender = option?.isLoading || option?.isEmpty || option?.noResults; // If true, render a dummy option
    return dummyRender
        ? {
              label: null,
              value: null,
              key: null,
              url: null
          }
        : {
              label: (
                  <Link
                      className="global-search-option"
                      to={`sku_data/${option?.hierarchyLevel}/${getUrlParamString(option?.hierarchyLevel, option?.hierarchyId, option?.hierarchyDesc)}`}
                      // Prevent link from being followed (handled in AutoComplete onSelect)
                      onClick={(e) => e.preventDefault()}>
                      <div className="option-label">
                          <span className="option-id">{option?.hierarchyId}</span>
                          <span className="option-name">{option?.hierarchyDesc}</span>
                      </div>
                      <span className="option-type">{option?.hierarchyLevel?.toUpperCase()}</span>
                  </Link>
              ),
              value: option?.hierarchyDesc,
              key: option?.hierarchyId + option?.hierarchyDesc,
              url: `sku_data/${option?.hierarchyLevel}/${getUrlParamString(option?.hierarchyLevel, option?.hierarchyId, option?.hierarchyDesc)}`
          };
};

const GlobalSearch = () => {
    const [options, setOptions] = useState([{ isEmpty: true }]); // In order to display the dropdown, there must be at least one element in the options array
    const [searchText, setSearchText] = useState('');
    const [searchFilters, setSearchFilters] = useState(globalSearchFilters);
    const [dataCount, setDataCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const getSearchResults = useCallback(async () => {
        const loadingTimeout = setTimeout(() => {
            // Clear options and show loading
            setDataCount(0);
            setOptions([renderOption({ isLoading: true })]);
            setLoading(true);
        }, 500); // 500ms delay before showing loading

        const filterStrings = searchFilters
            .filter((filter) => filter.isActive)
            .map((filter) => filter.filterString);
        const searchResults = await getGlobalSearchResults(searchText, filterStrings);

        setDataCount(searchResults?.dataCount);

        if (searchResults?.dataCount === 0) {
            setOptions([{ noResults: true }]);
        } else {
            setOptions(searchResults?.results?.map((result) => renderOption(result)));
        }

        setLoading(false);

        // Clear the loading timeout - If this is reached before the timeout, the loading spinner will not be shown
        clearTimeout(loadingTimeout);
    }, [searchFilters, searchText]);

    useEffect(() => {
        if (searchText !== '') {
            const performSearch = async () => {
                await getSearchResults();
            };
            performSearch();
        } else {
            setOptions([renderOption({ isEmpty: true })]);
            setDataCount(0);
        }
    }, [searchFilters, searchText, getSearchResults]);

    return (
        <div className="global-search-container">
            <AutoComplete
                options={options}
                className="global-search-container"
                filterOption={(inputValue, option) => {
                    if (option?.key === '') return true;
                    return (
                        option?.key?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 ||
                        option?.value === 'No results found.'
                    );
                }}
                dropdownMatchSelectWidth={true}
                dropdownRender={(menu) => {
                    return (
                        <GlobalSearchDropdown
                            menu={menu}
                            setSearchFilters={setSearchFilters}
                            searchFilters={searchFilters}
                            dataCount={dataCount}
                            loading={loading}
                            isEmpty={searchText === ''}
                        />
                    );
                }}
                onSelect={(value, option) => {
                    if (option.url !== null) navigate(option?.url);
                }}>
                <Input
                    prefix={<SearchOutlined />}
                    type="text"
                    placeholder="Search"
                    className="global-search-input"
                    allowClear={true}
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                    }}
                />
            </AutoComplete>
        </div>
    );
};

export default GlobalSearch;
