import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    level: '',
    index: -1, 
    search: '', 
    sort: '',
    petcoUsersList: [],
    petcoUsersCount: 0
};

const petcoUsersSlice = createSlice({
    name: 'petcoUsers',
    initialState,
    reducers: {
        setPetcoUsers(state, { payload }) {
            state.level = payload.level;
            state.index = payload.index;
            state.search = payload.search;
            state.sort = payload.sort;
            state.petcoUsersList = payload.petcoUsersList;
            state.petcoUsersCount = payload.petcoUsersCount;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const petcoUsersActions = petcoUsersSlice.actions;
export const petcoUsersReducer = petcoUsersSlice.reducer;
export default petcoUsersSlice;