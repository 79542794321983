export const EMAIL_PATTERN = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
export const PETCO_EMAIL_PATTERN = /^[A-Za-z0-9._%+-]+@petco\.com$/;
export const PASSWORD_MATCH_PATTERN =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const NUMBER_PATTERN = /^[0-9\b]+$/;
export const URL_VALIDATION_PATTERN = 
    /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;

export const POSITIVE_NUMBER_PATTERN = /^\d+(\.\d+)?$/
export const NEGATIVE_NUMBER_PATTERN = /^-?\d*\.?\d+$/
