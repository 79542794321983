import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allSbuList: {},
    allShopList: {}, 
    allDeptList: {}, 
    allClassList: {},
    allSubClassList: {},
    allManufList: {},
    allBrandList: {},
    alSkuList: {},
    callLevel: ''
};

const petcoDataSlice = createSlice({
    name: 'petcoData',
    initialState,
    reducers: {
        //Setting AUth state
        setPetcoData(state, { payload }) {
            state.allSbuList = payload.sbuList;
            state.allShopList = payload.shopList;
            state.allDeptList = payload.deptList;
            state.allClassList = payload.classList;
            state.allSubClassList = payload.subClassList;
            state.allManufList = payload.manufList;
            state.allBrandList = payload.brandList;
            state.alSkuList = payload.skuList;
            state.callLevel = payload.level;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const PetcoDataActions = petcoDataSlice.actions;
export const PetcoDataReducer = petcoDataSlice.reducer;
export default petcoDataSlice;
