import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedSkuList: [],
    selectedSkuCount: 0,
    selectedRowKeysList: []
};

const selectedSkuSlice = createSlice({
    name: 'selectedsku',
    initialState,
    reducers: {
        //Setting AUth state
        setSelectedSkuData(state, { payload }) {
            state.selectedSkuList = payload.selectedSkuData;
        },
        setSelectedSkuCount(state, { payload }) {
            state.selectedSkuCount = payload.selectedSkuCount;
        },
        setSelectedRowKeysData(state, { payload }) {
            state.selectedRowKeysList = payload.selectedRowKeysData;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const SelectedSkuActions = selectedSkuSlice.actions;
export const SelectedSkuReducer = selectedSkuSlice.reducer;
export default selectedSkuSlice;
