import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

export const createGetAllManufDataQuery = (index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllManufacturers(index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetManufDataQuery = (manufId, manufDesc) => {
    try {
        return gql`
        query {
            getLevelDetails(level: "manufacturer", levelId: "${manufId}", levelDesc: "${manufDesc}") {
                levelId
                levelDesc
                brandCount
                skuCount
            }
        }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetManufAtSbuDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllManufacturersAtSbu(sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetManufAtShopDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllManufacturersAtShop(shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetManufAtDeptDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllManufacturersAtDept(deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetManufAtClassDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllManufacturersAtClass(classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetManufAtSubclassDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllManufacturersAtSubclass(subclassId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignManufactureDataQuery = (merchantId, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignManufacturers(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignManufAtSbuDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignManufacturersAtSbu(merchantId: "${merchantId}", sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignManufAtShopDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignManufacturersAtShop(merchantId: "${merchantId}", shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignManufAtDeptDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignManufacturersAtDept(merchantId: "${merchantId}", deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignManufAtClassDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignManufacturersAtClass(merchantId: "${merchantId}", classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignManufAtSubclassDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignManufacturersAtSubclass(merchantId: "${merchantId}", subclassId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        manufId
                        manufDesc
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};
