import {
    BellOutlined,
    DownOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import { Space, Dropdown, Button, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import PetcoMediumLogo from '../../assets/icons/bone-biscuits.png';
import { getLiveAlertsCount, updateLiveAlertsCount } from '../../graphql/queryExecuters';
import { AlertsCountActions } from '../../redux/slices/alertCount.slice';
import { logout } from '../../redux/actionCreators/auth.actions';

import { setRouteStyles } from '../../utilities/route.utils';

import './Header.scss';
import { merchantAccess, userTypes } from '../../constants/common';
import { getStorage } from '../../utilities';
import { repriceTime, nextDayRepriceHour } from '../../constants/common';
import { repriceWarningStatus, nextDayRepriceWarningStatus } from '../../constants/displayStrings';

import GlobalSearch from '../GlobalSearch/GlobalSearch';

const Header = () => {
    const { Text } = Typography;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userType, email, editAccess } = useSelector((state) => state.auth);
    const {alertsCount} = useSelector((state) => state.alertsCount);
    
    const onClickAlertsHandler = () => {
        updateLiveAlertsCountTrigger();
        navigate('/alerts', {state: {reload: true}});
    };
    const [showTime,setShowTime] = useState('');
    useEffect(() => {
        getNextRepriceTime(repriceTime);
    }, []); //eslint-disable-line
    
    const getNextRepriceTime = (repriceTime) => {
        const myDate = new Date()
        const currentHourString = myDate.toLocaleString([], {
            timeZone: "America/Los_Angeles",
            hour: '2-digit',
            hour12: false 
        })
        const currentHour = parseInt(currentHourString)
        let index;
        if(currentHour>=repriceTime[9].hour || currentHour<repriceTime[0].hour ){
            index = 0       
        }
        else if(currentHour>=repriceTime[0].hour && currentHour<repriceTime[1].hour){
            index = 1
        }
        else if(currentHour>=repriceTime[1].hour && currentHour<repriceTime[2].hour){
            index = 2
        }
        else if(currentHour>=repriceTime[2].hour && currentHour<repriceTime[3].hour){
            index = 3
        }
        else if(currentHour>=repriceTime[3].hour && currentHour<repriceTime[4].hour){
            index = 4
        }
        else if(currentHour>=repriceTime[4].hour && currentHour<repriceTime[5].hour){
            index = 5
        }
        else if(currentHour>=repriceTime[5].hour && currentHour<repriceTime[6].hour){
            index = 6
        }
        else if(currentHour>=repriceTime[6].hour && currentHour<repriceTime[7].hour){
            index = 7
        }
        else if(currentHour>=repriceTime[7].hour && currentHour<repriceTime[8].hour){
            index = 8
        }
        else if(currentHour>=repriceTime[8].hour && currentHour<repriceTime[9].hour){
            index = 9
        }
        setShowTime(repriceTime[index].timeString)
        setREPRICE_TIME_CHECK_INTERVAL(Math.abs(repriceTime[index].hour-parseInt(currentHour))*3600000 + 2)   
        
    }

    const [reprice_TIME_CHECK_INTERVAL,setREPRICE_TIME_CHECK_INTERVAL] = useState(10800000); // 3 hours by default
    useEffect(() => {
        const interval = setInterval(() => {
            getNextRepriceTime(repriceTime);
          }, reprice_TIME_CHECK_INTERVAL);
          
          return () => clearInterval(interval);
    }, []); //eslint-disable-line

    const onClickBulkConfigHandler = () => {
        navigate('/bulk_Configurations/allSku');
    };
    
    const onLogoutHandler = () => {
        const storage = getStorage();
        dispatch(logout(storage));
        navigate('/login');
    };

    //getting bellIcon alerts Count
    const TIME_INTERVAL = 120000;
    useEffect(() => {
        const interval = setInterval(() => {
            getLiveAlertsCountTrigger();
          }, TIME_INTERVAL);
          
          return () => clearInterval(interval);
    }, []); //eslint-disable-line

    
    const getLiveAlertsCountTrigger = async () => {
        try {
            const alertsCountData = await getLiveAlertsCount();
            dispatch(
                AlertsCountActions.setAlertsCountData({ alertsCountData: alertsCountData?.count ? alertsCountData.count : 0 })
            );
            
        } catch (err) {
            console.log(err);
        }
    };
    
    const updateLiveAlertsCountTrigger = async () => {
        try {          
            let status = await updateLiveAlertsCount();
            if(status === 'success'){
                dispatch(
                    AlertsCountActions.setAlertsCountData({ alertsCountData: 0 })
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    const items = [
        {
            label: 'Logout',
            key: '0',
            onClick: onLogoutHandler
        }
    ];

    const isNextDayRepriceWindow = () => {
        const pstDate = dayjs().tz('America/Los_Angeles');
        return pstDate.hour() >= nextDayRepriceHour;
    }

    return (
        <div className='header-outline'>
        <div className="header-container">
            <div className="header-left-container">
                <div className="header-logo-container">
                    <img alt="Company Logo" src={PetcoMediumLogo} style={{ height: 40 }}/>
                </div>
                <div className="header-options-container">
                    {/* <NavLink to="/" style={setRouteStyles}>
                        <div className="header-option"><BarChartOutlined style={{ fontSize: '16px'}}/> Dashboard</div>
                    </NavLink> */}
                    <NavLink to="/sku_data" style={setRouteStyles}>
                        <div className="header-option" id='petco-data-header'>
                            SKU Data
                        </div>
                    </NavLink>
                    {userType !== 'MERCHANT' && (
                        <NavLink to="/scrapers_and_competitors" style={setRouteStyles}>
                            <div className="header-option" id='scapers-header'>
                                Scrapers
                            </div>
                        </NavLink>
                    )}
                    {userType !== 'MERCHANT' && (
                        <NavLink to="/teams_and_merchants" style={setRouteStyles}>
                            <div className="header-option" id='teams-merchants-header'>
                                Teams & Merchants
                            </div>
                        </NavLink>
                    )}
                </div>
            </div>
            {/* <div className='bulkconfig-btn' style={{
                position: 'absolute',
                right: '300px',
                borderRadius: '1px'}}>
                    <Button onClick={onClickBulkConfigHandler}>Bulk Configuration</Button>
                </div> */}
            <div className="header-right-container">
                <GlobalSearch />   
                {!(userType === userTypes.MERCHANT && editAccess === merchantAccess.VIEW) && <Button id='bulk-config-btn' style={{marginRight: '24px', height: '38px', width:'153px'}} onClick={onClickBulkConfigHandler}>Bulk Configuration</Button> }
                <div className="header-alert-container">
                    <div className="alert-icon" onClick={onClickAlertsHandler} id='alerts-header-1'>
                        <BellOutlined />
                    </div>
                    <div className="alert-count" onClick={onClickAlertsHandler} id='alerts-header-2'>
                        {alertsCount ? alertsCount : 0}
                    </div>
                </div>
                <div className="user-name" id='email-header'>
                    <Dropdown menu={{ items }} trigger={['click']}>
                            <Space>
                                <div className='email'>
                                {email}
                                </div>
                                <DownOutlined />
                            </Space>
                        
                    </Dropdown>
                </div>
            </div>
        </div>
        {/* 
        <div className='nextReprice-warning'>
                <Text className='repriceText'>
                <InfoCircleOutlined style={{ marginRight: 5 }}  />{repriceWarningStatus.replace('showTime',showTime)}
                </Text>
                {isNextDayRepriceWindow() ? <Text className="repriceText">{nextDayRepriceWarningStatus}</Text> : null}
        </div> */}
       </div>
    );
};

export default Header;
