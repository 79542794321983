import { Navigate } from 'react-router-dom';
import { getStorage } from '../../utilities';

const PrivateRoute = ({ children }) => {
    const storage = getStorage();
    const isAuthenticated = storage.getItem('token');
    return isAuthenticated ? children : <Navigate to={'/login'} />;
};

export default PrivateRoute;
