import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    scrapers: [],
    activeScrapers: [],
    scrapersBaseDetails: []
};

const scrapersSlice = createSlice({
    name: 'scrapers',
    initialState,
    reducers: {
        setScrapers(state, { payload }) {
            state.scrapers = payload.scrapers;
        },
        setActiveScrapers(state, { payload }) {
            state.activeScrapers = payload.activeScrapers;
        },
        setScrapersBaseDetails(state, { payload }) {
            state.scrapersBaseDetails = payload.scrapersBaseDetails;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const ScrapersActions = scrapersSlice.actions;
export const ScrapersReducer = scrapersSlice.reducer;
export default scrapersSlice;
