import JWT from "jwt-decode";
import { resetToken } from "../graphql/queryExecuters";
import { getCookie, getStorage } from '../utilities/helper';
import ReduxStore from '../redux/store';
import { AuthActions } from "../redux/slices";
const publicRoutes = ['login', 'createAccessToken', 'resetPassword', 'updatePassword' ];


export const authMiddleware = async request => {
    const cookieData = getCookie('rememberMe');
    const storage = getStorage();
    const token = storage.getItem('token');
    const refreshToken = storage.getItem('refresh');
    const rememberMe = cookieData || false;
    //Allowing only private routes to check token validation
    if(token && !publicRoutes.some(route => request.body.includes(route))) {
        const res = JWT(token);
        if(res.exp) {
            const expiryTime = res.exp * 1000;
            const now = new Date().getTime();
            //Check token validation
            //1. If token valid return to api call
            //2. If token invalid create new token using refresh token
            if(expiryTime > now) {
                //Token Valid

                if (res.data.email) {
                    const { email } = ReduxStore.getState().auth;
                    if (email !== res.data.email) {
                        const authDetails = {
                            id: res.data.id,
                            name: res.data.name,
                            email: res.data.email,
                            userType: res.data.user_type,
                            accessRole: res.data.access_role,
                            createdBy: res.data.created_by,
                            editAccess: res.data.edit_access ? res.data.edit_access : '',
                            accessToken: token
                        }
                        ReduxStore.dispatch(AuthActions.setAuth(authDetails));
                    }
                }

                return {
                    ...request,
                    headers: { ...request.headers, 'Authorization': `Bearer ${token}`, 'Access-Control-Allow-Origin': '*'},
                }
            } else {
                //Token invalid
                if(rememberMe && refreshToken) {
                    const res = JWT(refreshToken);
                    if(res.exp) {
                        const expiryTime = res.exp * 1000;
                        const now = new Date().getTime();
                        //Check refresh token expiry
                        //1. If token valid create new token
                        //2. If token invalid terminate session
                        if(expiryTime > now) {
                            //refresh token valid
                            const resetTokenMetaData = await resetToken(refreshToken);
                            if(resetTokenMetaData.__typename === 'Error') {
                                resetSession();
                            } else {
                                //Setting tokens in local storage and return to api call with proper token
                                storage.setItem('token', resetTokenMetaData.accessToken);
                                storage.setItem('refresh', resetTokenMetaData.refreshToken);
                                return {
                                    ...request,
                                    headers: { ...request.headers, 'Authorization': `Bearer ${resetTokenMetaData.accessToken}`, 'Access-Control-Allow-Origin': '*' },
                                }
                            }
                        } else {
                            resetSession();
                        }
                    } else {
                        resetSession();
                    }
                } else {
                    resetSession();
                }
            }
        } else {
            resetSession();
        }
    } else if (publicRoutes.some(route => request.body.includes(route))) { //If any public routes comes, No need of validation of token
        return {
            ...request,
            headers: { ...request.headers, 'Access-Control-Allow-Origin': '*' },
        }
    } else {
        resetSession();
    }
    return true
}

//Clear trace and move to login
const resetSession = () => {
    window.location.href = '/login';
    const storage = getStorage();
    storage.clear();
}