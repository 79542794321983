import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import {
    Typography,
    Tag,
    Checkbox,
    Button,
    Space,
    Modal,
    Spin
} from 'antd';

import './ScrapersCompetitors.form.scss';
import { updatelevelConfigData, updatelevelConfigAtSkuData } from '../../graphql/queryExecuters'; 
import { CompetitorConfig } from '../Forms/CompetitorConfig.form';
import { Notification } from '../../utilities';
import {LEVEL_IDS, TAB_NAME, corpLabel} from '../../constants/common';
import { compareLog } from '../../utilities';
import * as DisplatStrings from '../../constants/displayStrings';

import { BrandsActions, ClassActions, DepartmentActions, ManufActions, SbuActions, ShopActions, SkuActions, SubclassActions } from '../../redux/slices';

const { CheckableTag } = Tag;

const ScrapersCompetitors = ({ parentName, levelProps, handleOk, handleCancel }) => {

    const dispatch = useDispatch();

    //Redux States
    const { sbuList } = useSelector(state => state.sbuData);
    const { shopList } = useSelector(state => state.shopData);
    const { deptList } = useSelector(state => state.deptData);
    const { classList } = useSelector(state => state.classData);
    const { subclassList } = useSelector(state => state.subclassData);
    const { manufList } = useSelector(state => state.manufData);
    const { brandsList } = useSelector(state => state.brandsData);
    const { skuList } = useSelector(state => state.skuData);

    const { Text } = Typography;
    const [ confirm, setConfirm ] = useState(false);
    const [ loading, setLoading ] = useState(false); //eslint-disable-line
    const [ scraperConfigData, setScraperConfigData ] = useState([]);
    const [ competitorConfigData, setCompetitorConfigData ] = useState([]);
    const [ applyAll, setapplyAll ] = useState(false);
    const [ submitClicked, setSubmitClicked ] = useState(false);

    useEffect(() => {
        getScraperTrigger();
    }, [levelProps.activeCompetitor, levelProps.activeScraper]); //eslint-disable-line

    const getScraperTrigger = async () => {
        try {
            levelProps.activeScraper && setScraperConfigData(([...levelProps.activeScraper]).map((scraper) => {
                return scraper.name;
            }));
            levelProps.activeCompetitor && setCompetitorConfigData(([...levelProps.activeCompetitor]).map((comp) => {
                let temp = {
                    index: comp.index,
                    competitor: comp.competitor,
                    adderText: comp.adderText,
                    adderValue: comp.adderValue,
                    priority: comp.priority,
                    incart: comp.incart,
                    pdp: comp.pdp
                }
                return temp;
            }));
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                DisplatStrings.error_getting_comp_config_data
            );
        }
    };

    const allScraper = levelProps.globalScraper.map((scraper) => {
        let temp = {
            name: scraper.id,
            isActive: false
        }
        return temp;
    });
         
    const handleScraperTagChange = (tag, checked) => {
        //checking/highlighting the scrapers old olgic
        const nextSelectedScraperTags = checked
            ? [...scraperConfigData, tag]
            : scraperConfigData.filter((t) => t !== tag);
        setScraperConfigData(nextSelectedScraperTags);
    };

    const addCompetitor = () => {
        let currIndex = competitorConfigData.length === 0 ? -1 : Math.max(...competitorConfigData.map(o => o.index));
        let data = competitorConfigData.concat([{
            index: currIndex + 1,
            competitor: '',
            adderText: '',
            adderValue: 0,
            priority: (levelProps.activeScraper.length === 1 && levelProps.activeScraper[0].isActive )? levelProps.activeScraper[0].name : '',
            incart: true,
            pdp: true
        }]);
        setCompetitorConfigData(data);
    };

    const changeApplyAll = (e) => {
        setapplyAll(e.target.checked);
    }

    const updateConfig = async () => {
        setLoading(true);
        let scraperText = allScraper.map((scraper) => {
            return `{
                name: "${scraper.name}",
                isActive: ${scraperConfigData.includes(scraper.name) ? true : false}
            }`;
        });
        let compText = competitorConfigData.map((comp) => {
            return `{
                competitor: "${comp.competitor}",
                scraper: "${comp.priority}",
                adderText: "${comp.adderText}"
                adderValue: ${comp.adderValue}
                incartMatch: ${comp.incart}
                pdpMatch: ${comp.pdp}
            }`;
        });

        let response = null
        if(levelProps.configLevel === 'sku'){
            let logdata = compareLog('scraper', levelProps.activeCompetitor, competitorConfigData, levelProps.configLevel, levelProps.configId, levelProps.configdesc, applyAll)
            response = await updatelevelConfigAtSkuData(levelProps.configId, levelProps.configdesc, scraperText.join(','), compText.join(','),logdata);
        } else {
            let logdata = compareLog('scraper', levelProps.activeCompetitor, competitorConfigData, levelProps.configLevel, levelProps.configId, levelProps.configdesc, applyAll)
            if(levelProps.configLevel === 'sbu'){
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'shop', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'department', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'class', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'subclass', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'manufacturer', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'brand', scraperText.join(','), compText.join(','), applyAll, logdata);
            }
            if(levelProps.configLevel === 'shop'){
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'department', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'class', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'subclass', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'manufacturer', scraperText.join(','), compText.join(','), applyAll,logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'brand', scraperText.join(','), compText.join(','), applyAll, logdata);
            }
            if(levelProps.configLevel === 'department'){
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'class', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'subclass', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'manufacturer', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'brand', scraperText.join(','), compText.join(','), applyAll, logdata);
            }
            if(levelProps.configLevel === 'class'){
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'subclass', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'manufacturer', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'brand', scraperText.join(','), compText.join(','), applyAll, logdata);
            }
            if(levelProps.configLevel === 'subclass'){
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'manufacturer', scraperText.join(','), compText.join(','), applyAll, logdata);
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'brand', scraperText.join(','), compText.join(','), applyAll, logdata);
            }
            if(levelProps.configLevel === 'manufacturer'){
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'brand', scraperText.join(','), compText.join(','), applyAll, logdata);
            }
            if(levelProps.configLevel === 'brand'){
                response = await updatelevelConfigData(levelProps.configId, levelProps.configdesc, levelProps.configLevel, 'brand', scraperText.join(','), compText.join(','), applyAll, logdata);
            }
        }

        if(response==='update failed'){
            Notification(
                'error',
                DisplatStrings.competitor_config_updation_failed
            );
        }
        if(response==='successfully updated'){
            Notification(
                'success',
                DisplatStrings.competitor_config_updation_success
            );
            handleOk(scraperConfigData, competitorConfigData);
            updateLevelDataList(competitorConfigData);
        }
        setLoading(false);
        setConfirm(false);
    };

    const validateData = () => {
        setSubmitClicked(true);
        let emptyValue = competitorConfigData.filter(function (item) { return item.competitor.trim() === '' || item.adderText.trim() === '' });
        let compArr = competitorConfigData.map(function (item) { return item.competitor });
        let isDuplicate = compArr.some(function (item, idx) {
            return compArr.indexOf(item) !== idx
        });
        let isEmptyAdderValue = competitorConfigData.filter(function (item) { return typeof item.adderValue !== 'number' });

        let isScraperPriorityNotAssigned = competitorConfigData.some(config => config.priority === "");
        if (emptyValue.length > 0) {
            Notification(
                'error',
                DisplatStrings.fill_empty_fields
            );
            return false;
        }

        if (isDuplicate) {
            Notification(
                'error',
                DisplatStrings.multiple_config_error
            );
            return false;
        }

        if (isScraperPriorityNotAssigned) {
            Notification(
                'error',
                DisplatStrings.missing_scraper_priority
            );
            return false;
        }

        if (isEmptyAdderValue.length > 0) {
            Notification(
                'error',
                DisplatStrings.missing_adder_value
            );
            return false;
        }
        return true;
    }

    //Updating skulist after price config updation
    const updateLevelDataList = (competitorsData) => {
        try {
            const updatedLevelList =[...parentName === TAB_NAME.SBU ? sbuList :
                parentName === TAB_NAME.SHOP ? shopList :
                parentName === TAB_NAME.DEPARTMENT ? deptList :
                parentName === TAB_NAME.CLASS ? classList :
                parentName === TAB_NAME.SUB_CLASS ? subclassList :
                parentName === TAB_NAME.MANUFACTURER ? manufList :
                parentName === TAB_NAME.BRAND ? brandsList :
                parentName === TAB_NAME.SKU ? skuList : []]

            const levelIndex = updatedLevelList.findIndex(levelData => levelData[LEVEL_IDS[parentName]] === levelProps.configId);
            if (levelIndex >= 0) {
                const priceConfigData = {...updatedLevelList[levelIndex]}
                updatedLevelList.splice(levelIndex, 1, {...priceConfigData, activeComp: competitorsData.map(compData => compData.competitor).join(', ')});
                if (parentName === TAB_NAME.SBU) {
                    dispatch(SbuActions.updateSbuList({ sbuList: updatedLevelList }));
                } else if (parentName === TAB_NAME.SHOP) {
                    dispatch(ShopActions.updateShopList({ shopList: updatedLevelList }));
                } else if (parentName === TAB_NAME.DEPARTMENT) {
                    dispatch(DepartmentActions.updateDeptList({ deptList: updatedLevelList }));
                } else if (parentName === TAB_NAME.CLASS) {
                    dispatch(ClassActions.updateClassList({ classList: updatedLevelList }));
                } else if (parentName === TAB_NAME.SUB_CLASS) {
                    dispatch(SubclassActions.updateSubclassList({ subclassList: updatedLevelList }));
                } else if (parentName === TAB_NAME.MANUFACTURER) {
                    dispatch(ManufActions.updateManufList({ manufList: updatedLevelList }));
                } else if (parentName === TAB_NAME.BRAND) {
                    dispatch(BrandsActions.updateBrandList({  brandList: updatedLevelList }));
                } else if (parentName === TAB_NAME.SKU) {
                    dispatch(SkuActions.updateSkuList({ skuList: updatedLevelList }));
                }
            }
        } catch (err) {
            console.log('Error - Price Configure - Error while updating level', err);
        }
    }

    return (
        <Spin spinning={!levelProps.activeScraper && !levelProps.activeCompetitor}>
            <div className="scrapersCompetitors">
                <div className="scrapers">
                    <span className='scrapper-title'>Scrapers</span>
                    <br/>
                    {allScraper && allScraper.map((tag) => (
                        <CheckableTag
                        style={{
                            backgroundColor: scraperConfigData.indexOf(tag.name) > -1? '#001952' : '',
                            color: scraperConfigData.indexOf(tag.name) > -1? '#fff' :'',
                            borderColor: scraperConfigData.indexOf(tag.name) > -1? '' :'black',
                            height: 34,
                            width: 115
                        }}
                            className='scrapper-tag'
                            key={tag.name}
                            checked={scraperConfigData.indexOf(tag.name) > -1}
                            onChange={(checked) => handleScraperTagChange(tag.name, checked)}
                        >
                            <p style={{
                                marginLeft: 14,
                                marginBlock: 5,
                            }}>{corpLabel[tag.name]}</p>
                        {' '}</CheckableTag>
                    ))}
                </div>
                <div className="competitors">
                    <span className='competitors-title'>Competitors</span>
                        <div className='competitors-container'>
                        {
                            competitorConfigData && competitorConfigData.map((comp) => 
                                <CompetitorConfig submitClicked={submitClicked} {...{
                                    key: comp.index,
                                    index: comp.index,
                                    data : {
                                        competitor: comp.competitor,
                                        adderText: comp.adderText,
                                        adderValue: comp.adderValue,
                                        priority: comp.priority,
                                        incart: comp.incart,
                                        pdp: comp.pdp,
                                    },
                                    activeCompetitor: levelProps.globalCompetitor?.map((item) => item.compId),
                                    activeScraper: scraperConfigData?.map((item) => item),
                                    handleDelete: (index) => {
                                        let temp = competitorConfigData;
                                        let data = temp.filter((o) => o.index !== index);
                                        setCompetitorConfigData(data);
                                    },
                                    updateData: (data, curr_index) => {
                                        let temp = [...competitorConfigData];
                                        let index = competitorConfigData.findIndex((obj => obj.index === curr_index));
                                        temp[index].competitor = data.competitor;
                                        temp[index].adderText = data.adderText;
                                        temp[index].adderValue = data.adderValue;
                                        temp[index].priority = data.priority;
                                        temp[index].incart = data.incart;
                                        temp[index].pdp = data.pdp;
                                        setCompetitorConfigData(prevState => temp);
                                    }
                                }}/>
                            )
                        }
                    </div>
                    <div className="add-competitor-button">
                        <Button id='add-competitor-scraperComp' className='add-comp-btn' type="dashed" block onClick={addCompetitor} >
                            + Add Competitor
                        </Button>
                        <div className='scrapers-info-banner'>
                        <Text>
                            <InfoCircleOutlined />{'   '}
                            Adding Scrapers and Competitors will take time to sync sku data after applying
                            configuration
                        </Text>
                        </div>
                    </div>
                    <div className='scrapper-configure-modal-footer'>
                    {parentName !== "SKU" ? <div className='scrapers-apply-config-checkbox'>
                    <Text>
                        <Checkbox id='scraperComp-apply' checked={applyAll} onChange={changeApplyAll} >{' '}Apply configuration changes to individual configured SKUs</Checkbox>
                    </Text>
                    </div>: <br/>}
                    <div className="config-model-footer">
                        <Space>
                            <Button
                                className="discardBtn"
                                id='scraperComp-discard'
                                key="discard"
                                onClick={() => {
                                    setSubmitClicked(false);
                                    handleCancel();
                                    getScraperTrigger();
                                    setapplyAll(false);
                                }}>
                                Discard Change
                            </Button>
                            <Button
                                className="updateConfigBtn"
                                id='scraperComp-update'
                                key="submit"
                                type="primary"
                                onClick={() => {
                                    const valid = validateData();
                                    if (valid) {
                                        setSubmitClicked(false);
                                        setConfirm(true);
                                        handleCancel();
                                    }
                                }}>
                                Update Scraper Configuration
                            </Button>
                        </Space>
                    </div>
                    <Modal
                        open={confirm}
                        title="Apply Changes"
                        onCancel={() => setConfirm(false)}
                        className="override-modal"
                        width={500}
                        footer={[
                            <Button 
                                className="closeBtn"
                                id='scraperComp-overide-cancel'
                                key="close"
                                onClick={() => {
                                    setConfirm(false);
                                    getScraperTrigger();
                                }}
                            >
                                Discard Change
                            </Button>,
                            <Button 
                            loading={loading}
                            style={{ marginLeft: 24 }} 
                            className="tiggerRescrapeBtn" 
                            key="triggerRescrape" 
                            id='scraperComp-apply-ovride'
                            type="primary" 
                            onClick={() => {
                                updateConfig();
                            }}>
                                Apply Configuration
                            </Button>
                        ]}
                    >
                        <div className='apply-changes-content'>These changes will override the <br/>existing configuration, Do you wish to proceed with the selected parameters?</div>
                    </Modal>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ScrapersCompetitors;
