import React from 'react';

import { Col, Row, Select, TimePicker, Checkbox, Typography } from 'antd';
import { corpLabel } from '../../constants/common';
import { InfoCircleOutlined } from '@ant-design/icons';

const ReScrape = ({ formData, onChange, modalFor, activeCompetitors }) => {

    const { Text } = Typography;
    const format = 'HH:mm';

    return (
        <div className="reScrape-form">
            <Row className="row-1">
                <Col span={24} className="reScrape-competitors">
                    <h3>Select Competitors</h3>
                    <label>Competitors</label>
                    {(modalFor === "competitor" || formData.allCompetitors) ? <Select
                        id='recrape-form-comp'
                        value={formData.allCompetitors ? '' : corpLabel[formData.competitors]}
                        disabled
                        className="priceRuleOptions"
                        placeholder="Select Competitor"
                        onChange={e =>{onChange('competitors', e)}}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{
                            width: '100%',
                        }}
                    />  : <Select
                            value={formData.competitors}
                            mode="multiple"
                            id='recrape-form-competitor'
                            className="priceRuleOptions"
                            placeholder="Select Competitor"
                            onChange={e =>{onChange('competitors', e)}}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{
                                width: '100%',
                            }}
                >
                    {activeCompetitors?.map(competitor => <Select.Option value={competitor.competitor} key={competitor.competitor}>{corpLabel[competitor.competitor]}</Select.Option>)}
                    </Select>}
                </Col>
            </Row>
            <br />
            {modalFor === "competitor" ? '' : <>
            <Checkbox id='comp-checkbox' checked={formData.allCompetitors} onChange={e =>{onChange('allCompetitors', e.target.checked)}}> All Competitors </Checkbox>
            <br />
            <br />
            </>}
            <Row>
                <Col span={24} className="reScrape-Date">
                    <label>Expiry Hour</label>
                    <br />
                    <TimePicker
                        id='expiry-hour'
                        value={formData.expiryHour ? formData.expiryHour : null} 
                        placement="bottomLeft" 
                        onChange={(time) =>{
                            onChange('expiryHour', time)
                        }}
                        minuteStep={30}
                        format={format}
                        style={{
                            width: '100%',
                            height: 46
                        }}
                    />
                </Col>
            </Row>
            <br />
            <p style={{
                    backgroundColor: '#E3F8EC',
                    borderRadius: '1px'
            }}>
                <Text>
                    <InfoCircleOutlined style={{ margin: 8}} />{' '}
                    Triggering Re-Scrape will take time to process and sync Data
                </Text>
            </p>
        </div>
    );
};

export default ReScrape;
