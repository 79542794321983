import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allCountList: [],
    allUserCountList: [],
    publishedSkuCount: 0,
};

const allCountSlice = createSlice({
    name: 'allCount',
    initialState,
    reducers: {
        setAllCountData(state, { payload }) {
            state.allCountList = payload.allCountData;
        },
        setAllUserCountData(state, { payload }) {
            state.allUserCountList = payload.allUserCounData;
        },
        setAllPublishedSkuCountData(state, {payload}){
            state.publishedSkuCount = payload.publishedCount;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const AllCountActions = allCountSlice.actions;
export const AllCountReducer = allCountSlice.reducer;
export default allCountSlice;
