import { configureStore } from '@reduxjs/toolkit';
import { AuthReducer } from './slices/auth.slice';
import { ScrapersReducer } from './slices/scrapers.slice';
import { SbuReducer } from './slices/sbu.slice';
import { AssignedSbuReducer } from './slices/assignedSbu.slice';
import { ShopReducer } from './slices/shop.slice';
import { DepartmentReducer } from './slices/department.slice';
import { ClassReducer } from './slices/class.slice';
import { SubclassReducer } from './slices/subclass.slice';
import { ManufReducer } from './slices/manufacture.slice';
import { BrandsReducer } from './slices/brands.slice';
import { SkuReducer } from './slices/sku.slice';
import { ActiveIdReducer } from './slices/activeId.slice';
import { AllCountReducer } from './slices/allCount.slice';
import { CompetitorsReducer } from './slices/competitors.slice';
import { UsersReducer } from './slices/users.slice';
import { petcoUsersReducer } from './slices/petcoUsers.slice';
import { merchantsReducer } from './slices/merchants.slice';
import { LogsReducer } from './slices/logs.slice';
import { SubscriptionsReducer } from './slices/subscription.slice';
import { AlertsReducer } from './slices/alerts.slice';
import { SelectedSkuReducer } from './slices/selectedSkus.slice';
import { AlertsCountReducer } from './slices/alertCount.slice';
import { ApprovalsReducer } from './slices/approvals.slice';
import { PetcoDataReducer } from './slices/petcoData.slice';

const store = configureStore({
    reducer: {
        auth: AuthReducer,
        scrapers: ScrapersReducer,
        sbuData: SbuReducer,
        assignedSbuData: AssignedSbuReducer,
        shopData: ShopReducer,
        deptData: DepartmentReducer,
        classData: ClassReducer,
        subclassData: SubclassReducer,
        manufData: ManufReducer,
        brandsData: BrandsReducer,
        skuData: SkuReducer,
        activeId: ActiveIdReducer,
        allCount: AllCountReducer,
        competitors: CompetitorsReducer,
        users: UsersReducer,
        petcoUsers: petcoUsersReducer,
        merchants: merchantsReducer,
        logs: LogsReducer,
        subscriptions: SubscriptionsReducer,
        alerts: AlertsReducer,
        selectedSku: SelectedSkuReducer,
        alertsCount: AlertsCountReducer,
        approvals: ApprovalsReducer,
        petcoData: PetcoDataReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

export default store;
