import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const updateMerchantAssignlevelConfigData = async (merchantid,levelsdata) => {
    try {
        const query = QueryCreators.createUpdateAssignMerchantLevelConfigQuery(merchantid,levelsdata);
        const response = await GraphQL.request(query);
        return response.createAssignment;
    } catch (err) {
        console.log(err);
    }
};

export const deleteMerchantAssignlevelConfigData = async (merchantid,levelsdata) => {
    try {
        const query = QueryCreators.deleteMerchantLevelConfigQuery(merchantid,levelsdata);
        const response = await GraphQL.request(query);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const checkMerchantAccess = async (merchantId, levelType, levelId, levelDesc) => {
    try {
        const query = QueryCreators.createCheckMerchantAccessQuery(merchantId, levelType, levelId, levelDesc);
        const response = await GraphQL.request(query);
        return response.checkMerchantAccess;
    } catch (err) {
        console.log(err);
    }
}

