import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllSkuData = async (index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAllSkuDataQuery(index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkus;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuDataForSearch = async (index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetSkuDataForSearchQuery(index, size, search, filters, sortType);
        console.log(query);
        const response = await GraphQL.request(query);
        return response.getAllSkus;
    } catch (err) {
        console.log(err);
    }
};


export const getSkuAtSbuData = async (id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetSkuAtSbuDataQuery(id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkusAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuAtShopData = async (id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetSkuAtShopDataQuery(id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkusAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuAtDeptData = async (id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetSkuAtDeptDataQuery(id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkusAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuAtClassData = async (id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetSkuAtClassDataQuery(id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkusAtClass;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuAtSubclassData = async (id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetSkuAtSubclassDataQuery(id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkusAtSubclass;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuAtManufData = async (id, desc, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetSkuAtManufDataQuery(id, desc, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkusAtManufacturer;
    } catch (err) {
        console.log(err);
    }
};

export const getSkuAtBrandData = async (id, desc, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetSkuAtBrandDataQuery(id, desc, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkusAtBrand;
    } catch (err) {
        console.log(err);
    }
};

export const getSKUDetails = async (id) => {
    try {
        const query = QueryCreators.createGetSkuDetailsQuery(id);
        const response = await GraphQL.request(query);
        return response.getSkuDetailsById;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignSkuData = async (merchantId, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignSkuDataQuery(merchantId, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkus;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSkuAtSbuData = async (merchantId, id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSkuAtSbuDataQuery(merchantId, id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkusAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSkuAtShopData = async (merchantId, id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSkuAtShopDataQuery(merchantId, id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkusAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSkuAtDeptData = async (merchantId, id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSkuAtDeptDataQuery(merchantId, id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkusAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSkuAtClassData = async (merchantId, id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSkuAtClassDataQuery(merchantId, id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkusAtClass;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSkuAtSubclassData = async (merchantId, id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSkuAtSubclassDataQuery(merchantId, id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkusAtSubclass;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSkuAtManufData = async (merchantId, id, desc, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSkuAtManufDataQuery(merchantId, id, desc, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkusAtManufacturer;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignSkuAtBrandData = async (merchantId, id, desc, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAssignSkuAtBrandDataQuery(merchantId, id, desc, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkusAtBrand;
    } catch (err) {
        console.log(err);
    }
};

export const getSKUGraphData = async (id, duration, competitors) => {
    try {
        const query = QueryCreators.createGetSKUGraphDataQuery(id, duration, competitors);
        const response = await GraphQL.request(query);
        return response.getSkuGraphData;
    } catch (err) {
        console.log(err);
    }
};

export const bulkAddSkuData = async (skuIds) => {
    try {
        const query = QueryCreators.createBulkAddSKUDataQuery(skuIds);
        const response = await GraphQL.request(query);
        return response.getBulkSkuList;
    } catch (err) {
        console.log(err);
    }
};

export const getEligibleSKUsForMaster = async (skuIds) => {
    try {
        const query = QueryCreators.createGetEligibleSKUForMasterQuery(skuIds);
        const response = await GraphQL.request(query);
        return response.getEligibleSkuForMaster;
    } catch (err) {
        console.log(err);
    }
};

export const getSKUShopData = async () => {
    try {
        const query = QueryCreators.createGetAllShopNameDataQuery();
        const response = await GraphQL.request(query);
        return response.getAllShops;
    } catch (err) {
        console.log(err);
    }
};

export const getSKUDeptData = async () => {
    try {
        const query = QueryCreators.createGetAllDeptNameDataQuery();
        const response = await GraphQL.request(query);
        return response.getAllDepts;
    } catch (err) {
        console.log(err);
    }
};

export const getAllChildSkuData = async (id, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAllChildSkuDataQuery(id, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllChildSkus;
    } catch (err) {
        console.log(err);
    }
};

export const getUnpricedSKUList = async (index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetUnPricedSKUsList(index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getUnpricedSkuList;
    } catch (err) {
        console.log(err);
    }
};

export const getAllSkuDataBulk = async (index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAllSkuDataBulkQuery(index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSkusBulk;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignSkuDataBulk = async (merchantId, index, size, search, filters, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignSkuDataBulkQuery(merchantId, index, size, search, filters, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSkusBulk;
    } catch (err) {
        console.log(err);
    }
};

export const getRepriceStatusBySku = async skuId => {
    try {
        const query = QueryCreators.createGetRepriceStatusQuery(skuId);
        const response = await GraphQL.request(query);
        return response.getRePriceStatusBySku;
    } catch (err) {
        console.log(err);
    }
};
