import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

//Get all shop Data
export const createGetAllShopDataQuery = (index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllShops(index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        shopId
                        shopDesc
                        deptCount
                        classCount
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetShopDetailsQuery = (shopId, shopDesc) => {
    try {
        return gql`
            query {
                getLevelDetails(level: "shop", levelId: "${shopId}", levelDesc: "${shopDesc}") {
                    levelId
                    levelDesc
                    deptCount
                    classCount
                    subclassCount
                    manufCount
                    brandCount
                    skuCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetShopAtSbuDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllShopsAtSbu(sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        shopId
                        shopDesc
                        deptCount
                        classCount
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignShopDataQuery = (merchantId, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignShops(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        shopId
                        shopDesc
                        deptCount
                        classCount
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignShopAtSbuDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignShopsAtSbu(merchantId:"${merchantId}", sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        shopId
                        shopDesc
                        deptCount
                        classCount
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};