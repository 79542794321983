import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getGlobalSearchResults = async (searchString, levelFiltersApplied) => {
    try {
        const query = QueryCreators.createGetGlobalSearchResults(searchString, levelFiltersApplied);
        const response = await GraphQL.request(query);
        return response.getGlobalSearchResults;
    } catch (err) {
        console.log(err);
    }
};