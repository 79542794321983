import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { Provider as StateProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import ReduxStore from './redux/store';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = {
    token: {
        colorPrimary: '#001952',
        controlItemBgActiveHover: '#c9d6f5',
        controlItemBgActive: '#e1e8f7'
    }
}
root.render(
    <ErrorBoundary>
        <BrowserRouter>
            <StateProvider store={ReduxStore}>
                <ConfigProvider theme={theme}>
                    <App />
                </ConfigProvider>
            </StateProvider>
        </BrowserRouter>
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
