import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

//Get all Subclass Data
export const createGetAllSubclassDataQuery = (index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllSubclasses(index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSubclassDetailsQuery = (subclassId, subclassDesc) => {
    try {
        return gql`
            query {
                getLevelDetails(level: "subclass", levelId: "${subclassId}", levelDesc: "${subclassDesc}") {
                    levelId
                    levelDesc
                    manufCount
                    brandCount
                    skuCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSubclassAtSbuDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllSubclassesAtSbu(sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSubclassAtShopDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllSubclassesAtShop(shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSubclassAtDeptDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllSubclassesAtDept(deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSubclassAtClassDataQuery = (id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllSubclassesAtClass(classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignSubClassDataQuery = (merchantId, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSubclasses(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSubclassAtSbuDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSubclassesAtSbu(merchantId: "${merchantId}", sbuId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSubclassAtShopDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSubclassesAtShop(merchantId: "${merchantId}", shopId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSubclassAtDeptDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSubclassesAtDept(merchantId: "${merchantId}", deptId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAssignSubclassAtClassDataQuery = (merchantId, id, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSubclassesAtClass(merchantId: "${merchantId}", classId:"${id}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}") {
                    data {
                        subclassId
                        subclassDesc
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};