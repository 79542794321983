import GraphQL from '../../configs/graphQL.config';

import { getActiveScrapers } from '../queryCreators';

export const getAllActiveScrapers = async () => {
    try {
        const query = getActiveScrapers();
        const response = await GraphQL.request(query);
        return response.getAllScrapers;
    } catch (err) {
        console.log(err);
    }
};
