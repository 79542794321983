//Containers
import PetcoUserDetails from '../containers/PetcoUserDetails/PetcoUserDetails';
// import Home from '../containers/Home/Home';
import Login from '../containers/Login/Login';
import ResetPassword from '../containers/ResetPassword/ResetPassword';
import PetcoData from '../containers/PetcoData/PetcoData';
import ScrapersAndCompetitors from '../containers/ScrapersAndCompetitors/ScrapersAndCompetitors';
import TeamsAndMerchants from '../containers/TeamsAndMerchants/TeamsAndMerchants';
import MerchantDetails from '../containers/MerchantDetails/MerchantDetails';
import ScraperDetails from '../containers/ScraperDetails/ScraperDetails';
import Alerts from '../containers/Alerts/Alerts';
import PrivateRoute from '../components/PrivateRoute/PrivateRoute';
import CompetitorDetails from '../containers/CompetitorDetails/CompetitorDetails';

import SBUDetails from '../containers/PetcoData/SBUDetails';
import ShopDetails from '../containers/PetcoData/ShopDetails';
import DepartmentDetails from '../containers/PetcoData/DepartmentDetails';
import ClassDetails from '../containers/PetcoData/ClassDetails';
import SubclassDetails from '../containers/PetcoData/SubclassDetails';
import ManufacturerDetails from '../containers/PetcoData/ManufacturerDetails';
import BrandDetails from '../containers/PetcoData/BrandDetails';
import SKUDetails from '../containers/SKUDetails/SKUDetails';
import BulkConfig from '../containers/BulkConfigurations/BulkConfig';
import ConfigureSkus from '../containers/ConfigureSkus/ConfigureSkus';
import SamlCallback from '../containers/SamlCallback/SamlCallback';

export const PublicRoutes = [
    {
        path: '/login',
        component: <Login />
    },
    {
        path: '/reset_password',
        component: <ResetPassword />
    },
    {
        path: 'saml-auth/verify',
        component: <SamlCallback />
    },
];

export const PrivateRoutes = [
    // {
    //     path: '/',
    //     component: <Home />
    // },
    // Uncommnet above line to enable home
    {
        path: '/sku_data',
        component: <PetcoData {...{ tabindex: '1' }} />
    },
    {
        path: '/sku_data/sbu',
        component: <PetcoData {...{ tabindex: '1' }} />
    },
    {
        path: '/sku_data/shop',
        component: <PetcoData {...{ tabindex: '2' }} />
    },
    {
        path: '/sku_data/department',
        component: <PetcoData {...{ tabindex: '3' }} />
    },
    {
        path: '/sku_data/class',
        component: <PetcoData {...{ tabindex: '4' }} />
    },
    {
        path: '/sku_data/subclass',
        component: <PetcoData {...{ tabindex: '5' }} />
    },
    {
        path: '/sku_data/manufacturer',
        component: <PetcoData {...{ tabindex: '6' }} />
    },
    {
        path: '/sku_data/brand',
        component: <PetcoData {...{ tabindex: '7' }} />
    },
    {
        path: '/sku_data/sku',
        component: <PetcoData {...{ tabindex: '8' }} />
    },
    {
        path: '/scrapers_and_competitors',
        component: <ScrapersAndCompetitors {...{ tabindex: '1' }}/>
    },
    {
        path: '/scrapers_and_competitors/scrapers',
        component: <ScrapersAndCompetitors {...{ tabindex: '1' }}/>
    },
    {
        path: '/scrapers_and_competitors/competitors',
        component: <ScrapersAndCompetitors {...{ tabindex: '2' }}/>
    },
    {
        path: '/teams_and_merchants',
        component: <TeamsAndMerchants {...{ tabindex: '1' }}/>
    },
    {
        path: '/teams_and_merchants/users',
        component: <TeamsAndMerchants {...{ tabindex: '1' }}/>
    },
    {
        path: '/teams_and_merchants/merchants',
        component: <TeamsAndMerchants {...{ tabindex: '2' }}/>
    },
    {
        path: '/teams_and_merchants/user_details/:id',
        component: <PetcoUserDetails />
    },
    {
        path: '/teams_and_merchants/merchant_details/:id',
        component: <MerchantDetails />
    },
    {
        path: '/sku_data/SBU/:uid',
        component: <SBUDetails />
    },
    {
        path: '/sku_data/shop/:uid',
        component: <ShopDetails />
    },
    {
        path: '/sku_data/Department/:uid',
        component: <DepartmentDetails />
    },
    {
        path: '/sku_data/Class/:uid',
        component: <ClassDetails />
    },
    {
        path: '/sku_data/Subclass/:uid',
        component: <SubclassDetails />
    },
    {
        path: '/sku_data/Manufacturer/:uid',
        component: <ManufacturerDetails />
    },
    {
        path: '/sku_data/Brand/:uid',
        component: <BrandDetails />
    },
    {
        path: '/sku_data/sku/:uid',
        component: <SKUDetails />
    },
    {
        path: '/scrapers_and_competitors/scraper_details/:scraper_name',
        component: <ScraperDetails />
    },
    {
        path: '/scrapers_and_competitors/competitor_details/:competitor_id',
        component: <CompetitorDetails />
    },
    {
        path: '/alerts',
        component: <Alerts {...{ tabindex: '1' }}/>
    },
    {
        path: '/alerts/approvals',
        component: <Alerts {...{ tabindex: '1' }}/>
    },
    {
        path: '/alerts/subscriptions',
        component: <Alerts {...{ tabindex: '2' }}/>
    },
    {
        path: '/alerts/triggered_alerts',
        component: <Alerts {...{ tabindex: '3' }}/>
    },
    {
        path: '/alerts/match_issues',
        component: <Alerts {...{ tabindex: '4' }}/>
    },
    {
        path: '/alerts/no_price_sku',
        component: <Alerts {...{ tabindex: '5' }}/>
    },
    {
        path: '/bulk_Configurations/allSku',
        component: <BulkConfig {...{ tabindex: '1' }}/>
    },
    {
        path: '/bulk_Configurations/selectedSku',
        component: <BulkConfig {...{ tabindex: '2' }} />
    },
    {
        path: '/bulk_Configurations/selectedSku/configSku',
        component: <ConfigureSkus />
    }
];

export const RoutesList = [
    ...PrivateRoutes.map((routeData) => {
        const route = { ...routeData };
        route.component = <PrivateRoute>{routeData.component}</PrivateRoute>;
        return route;
    }),
    ...PublicRoutes
];
