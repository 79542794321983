import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

export const createGetAllSbuDataQuery = (index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllSbus(index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        sbuId
                        sbuDesc
                        shopCount
                        deptCount
                        classCount
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAssignedSbuDataQuery = (merchantId, index, size, search, sortType) => {
    try {
        return gql`
            query {
                getAllAssignSbus(merchantId: "${merchantId}", index: ${index}, size: ${size}, search: ${prepareString(search)}, sortType: "${sortType}"){
                    data {
                        sbuId
                        sbuDesc
                        shopCount
                        deptCount
                        classCount
                        subclassCount
                        manufCount
                        brandCount
                        skuCount
                        basePrice
                        markdownPrice
                        activeComp
                        priceRule
                        defaultGuardMin
                        defaultGuardMinPrice
                        defaultGuardMinPriceSuffix
                        defaultGuardMax
                        defaultGuardMaxPrice
                        defaultGuardMaxPriceSuffix
                    }
                    dataCount
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetSbuDetailsQuery = (sbuId, sbuDesc) => {
    try {
        return gql`
            query {
                getLevelDetails(level: "sbu", levelId: "${sbuId}", levelDesc: "${sbuDesc}") {
                    levelId
                    levelDesc
                    shopCount
                    deptCount
                    classCount
                    subclassCount
                    manufCount
                    brandCount
                    skuCount
                }
            }    
        `;
    } catch (err) {
        console.log(err);
    }
};