import React, {useState, useEffect} from "react";
import { Checkbox, Divider} from 'antd';

import './Tables.scss'

const StatusTable = ({ options, updateTagValues, deleteTagStatusLabel, currStatusFilter}) => {
    const [selectedCheckbox, setSelectedCheckbox] = useState(currStatusFilter || null)
    const [previouslySelected, setPreviouslySelected] = useState(null)

    const handleCheckboxSelection = async (checkedValues) => {
        let chosen = checkedValues.target.options[0]
        await deleteTagStatusLabel(previouslySelected)

        setSelectedCheckbox(chosen.value.desc)
        const status = {
            value: checkedValues.target.options[0].value,
            checked: checkedValues.target.checked
        }
        updateTagValues(status)
        setPreviouslySelected(null)
    };

    useEffect(() => {
        // clear the existing filter choice.
        if (currStatusFilter) {
            setPreviouslySelected(currStatusFilter)
        }
    }, [currStatusFilter])

    return (
        <div className="table-container"> 
            {options.map((item) => {
                return (
                    <li
                        key={item.value}
                        style={{
                            color: '#001952',
                            font: '10px',
                            marginBottom: '10px'
                        }}>                   
                            <Checkbox
                                key={item.label}
                                checked={selectedCheckbox === item.label}
                                options={[
                                    {
                                        label: item.label,
                                        value: {
                                            desc: item.label,
                                            id: item.value,
                                            level: item.level
                                        }
                                    }
                                ]}
                                onChange={handleCheckboxSelection}>
                                {item.label}
                            </Checkbox>
                            <Divider style={{margin: '15px'}} />
                    </li>
                );
            })}
        </div>
    );
}

export default StatusTable;