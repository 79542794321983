import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { boot } from './boot';
import AppRouter from './AppRouter';
import './App.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

dayjs.tz.setDefault('America/Los_Angeles');

Spin.setDefaultIndicator(
    <div>
        <LoadingOutlined
            style={{
                fontSize: '55px'
            }}
            spin
        />{' '}
    </div>
);


function App() {
    return <AppRouter />;
}

boot();

export default App;
