import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';

//Login query creator
export const createGetGlobalSearchResults = (searchString, levelFiltersApplied) => {
    try {
        return gql`
            query {
                getGlobalSearchResults(searchString: ${prepareString(searchString)}, levelFiltersApplied: [${[...levelFiltersApplied.map(level => `"${level}"`)]}]) {
                    dataCount
                    results {
                        hierarchyId
                        hierarchyDesc
                        hierarchyLevel
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};
