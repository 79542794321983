import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllSbuData = async (index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllSbuDataQuery(index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllSbus;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignedSbuData = async (merchantId, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignedSbuDataQuery(merchantId, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignSbus;
    } catch (err) {
        console.log(err);
    }
};

export const getSbuDetails = async (sbuId, sbuDesc) => {
    try {
        const query = QueryCreators.createGetSbuDetailsQuery(sbuId, sbuDesc);
        const response = await GraphQL.request(query);
        return response.getLevelDetails;
    } catch (err) {
        console.log(err);
    }
};