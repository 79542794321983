import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    level: '',
    index: -1, 
    search: '', 
    sort: '',
    classList: [],
    count: 0
};

const classSlice = createSlice({
    name: 'class',
    initialState,
    reducers: {
        setClassData(state, { payload }) {
            state.level = payload.level;
            state.index = payload.index;
            state.search = payload.search;
            state.sort = payload.sort;
            state.classList = payload.classData;
            state.count = payload.count;
        },

        updateClassList(state, { payload }) {
            state.classList = payload.classList
        },

        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const ClassActions = classSlice.actions;
export const ClassReducer = classSlice.reducer;
export default classSlice;
