import React, { useEffect, useMemo, useState } from 'react';
import {
    Col,
    Row,
    Select,
    DatePicker,
    Checkbox,
    Typography,
    InputNumber,
    Button,
    Spin
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import './Override.form.scss';
import { updateTempGuardrailData , deleteGuardrailData } from '../../graphql/queryExecuters';
import { Notification } from '../../utilities';
import { dateSkipper, getMapPriceByConfiguration, getMsrpPriceByConfiguration } from '../../utilities/helper';
import { 
    unit_cost_error, 
    map_error,
    msrp_error,
    min_max_guardrail_error,
    min_price_valid_error, 
    max_price_valid_error, 
    interval_error, 
    guardrail_update_success, 
    guardrail_update_error, 
    required_error,
    project_min_price,
    project_max_price,
    temp_min,
    temp_max,
    value,
    interval_text,
    guardrail_info,
    override_info,
    close,
    override_guardrail,
    margin_warning,
    remove_guardrail,
    guardrail_delete_success,
    guardrail_delete_error
} from '../../constants/displayStrings';

import { compareLog } from '../../utilities';
import {
    marginMinLimit,
    marginMaxLimit,
    fixedMinLimit,
    guardrailOptions
} from '../../constants/common';

const Override = ({ levelProps, callback }) => {

    const { Option } = Select;

    const [loading, setLoading] = useState(false);

    const [submitClicked, setSubmitClicked] = useState(false);

    const { RangePicker } = DatePicker;
    const { Text } = Typography;


    const [inputData, setInputData] = useState({
        applyAll: false,
        tempGuardMin: '',
        tempGuardMinPrice: 0,
        tempGuardMinPriceSuffix: '$',
        tempGuardMax: '',
        tempGuardMaxPrice: 0,
        tempGuardMaxPriceSuffix: '$',
        startTempGuardDate: '',
        endTempGuardDate: ''
    });

    const [fetchedTempGuardrailData, setFetchedTempGuardrailData] = useState({
        tempGuardMin: '',
        tempGuardMinPrice: null,
        tempGuardMax: '',
        tempGuardMaxPrice: null,
        startTempGuardDate: '',
        endTempGuardDate: ''
    });

    const hasDataForMin = useMemo(() => {
        if (
            fetchedTempGuardrailData?.tempGuardMin === '' ||
            fetchedTempGuardrailData?.tempGuardMin === undefined ||
            fetchedTempGuardrailData?.tempGuardMin === 'null' ||
            inputData?.tempGuardMin === '' ||
            inputData?.tempGuardMin === undefined ||
            inputData?.tempGuardMin === 'null'
        ) {
            return false;
        }
        return true;
    }, [fetchedTempGuardrailData, inputData]);

    const hasDataForMax = useMemo(() => {
        if (
            fetchedTempGuardrailData?.tempGuardMax === '' ||
            fetchedTempGuardrailData?.tempGuardMax === undefined ||
            fetchedTempGuardrailData?.tempGuardMax === 'null' ||
            inputData?.tempGuardMax === '' ||
            inputData?.tempGuardMax === undefined ||
            inputData?.tempGuardMax === 'null'
        ) {
            return false;
        }
        return true;
    }, [fetchedTempGuardrailData, inputData]);

    useEffect(() => {
        const data = {
            ...levelProps.tempGuardData,
            startTempGuardDate:
                levelProps?.tempGuardData?.startTempGuardDate !== 'null'
                    ? levelProps?.tempGuardData?.startTempGuardDate
                    : '',
            endTempGuardDate:
                levelProps?.tempGuardData?.endTempGuardDate !== 'null'
                    ? levelProps?.tempGuardData?.endTempGuardDate
                    : '',
            tempGuardMin:
                levelProps?.tempGuardData?.tempGuardMin !== 'null'
                    ? levelProps?.tempGuardData?.tempGuardMin
                    : '',
            tempGuardMax:
                levelProps?.tempGuardData?.tempGuardMax !== 'null'
                    ? levelProps?.tempGuardData?.tempGuardMax
                    : '',
            tempGuardMinPrice:
                levelProps?.tempGuardData?.tempGuardMin === 'MSRP'
                ? getMsrpPriceByConfiguration(levelProps?.tempGuardData)
                : levelProps?.tempGuardData?.tempGuardMin === 'MAP'
                ? getMapPriceByConfiguration(levelProps?.tempGuardData)
                : (levelProps?.tempGuardData?.tempGuardMinPrice !== null)
                ? levelProps?.tempGuardData?.tempGuardMinPrice : '',
            tempGuardMinPriceSuffix: levelProps?.tempGuardData?.tempGuardMin === 'Margin' ? '%' : '$',
            tempGuardMaxPrice:
                levelProps?.tempGuardData?.tempGuardMax === 'MSRP'
                ? getMsrpPriceByConfiguration(levelProps?.tempGuardData)
                : levelProps?.tempGuardData?.tempGuardMax === 'MAP'
                ? getMapPriceByConfiguration(levelProps?.tempGuardData)
                : (levelProps?.tempGuardData?.tempGuardMaxPrice !== null)
                ? levelProps?.tempGuardData?.tempGuardMaxPrice : '',
            tempGuardMaxPriceSuffix: levelProps?.tempGuardData?.tempGuardMax === 'Margin'
                ? '%' : '$',
        };
        setFetchedTempGuardrailData(data);
        setInputData(current => { 
            return {...current, ...data }
        });
    }, [levelProps]);
    
    const removeGuardrail = async() => {
        setSubmitClicked(true);

        try{
            setLoading(true);
            const response = await deleteGuardrailData(levelProps.configId , levelProps.configLevel , levelProps.configdesc);
            setLoading(false);
            if(response === 'deleted successfully'){
                Notification('success' , guardrail_delete_success)
            }
            if(response === 'deletion failed'){
                Notification('error' , guardrail_delete_error)
        }

        }catch(err) {
            console.log(err);
            Notification('error' , guardrail_delete_error)
        }
        levelProps.handleOk();

    };


    const OverrideGuardrail = async (inputData) => {
        setSubmitClicked(true);
        if (!inputData.tempGuardMax || !inputData.tempGuardMin) {
            Notification('error', required_error);
            return false;
        }

        //Validations for guardrail values
        if (levelProps.configLevel === 'sku') {
            if ((inputData.tempGuardMax === 'Margin' || inputData.tempGuardMax === 'Fixed') && 
                (inputData.tempGuardMaxPrice === null || !inputData.tempGuardMaxPrice)) {
                Notification('error' , max_price_valid_error);
                return false;
            }
            
            if ((inputData.tempGuardMin === 'Margin' || inputData.tempGuardMin === 'Fixed') && 
                (inputData.tempGuardMinPrice === null || !inputData.tempGuardMinPrice)) {
                Notification('error' , min_price_valid_error);
                return false;
            }
        } else {
            if (inputData.tempGuardMax === 'Margin' && inputData.tempGuardMaxPrice === null) {
                Notification('error' , max_price_valid_error);
                return false;
            }

            if (inputData.tempGuardMin === 'Margin' && inputData.tempGuardMinPrice === null) {
                Notification('error' , min_price_valid_error);
                return false;
            }
        }

        if (inputData.tempGuardMax === 'Margin' && (
            inputData.tempGuardMaxPrice < marginMinLimit || inputData.tempGuardMaxPrice > marginMaxLimit)) {
            Notification('error' , max_price_valid_error);
            return false;
        }

        if (inputData.tempGuardMin === 'Margin' && (
            inputData.tempGuardMinPrice < marginMinLimit || inputData.tempGuardMinPrice > marginMaxLimit)) {
            Notification('error' , min_price_valid_error);
            return false;
        }

        //Validaton for guardrail interval
        if(!inputData?.startTempGuardDate || !inputData?.endTempGuardDate){
            Notification('error' , interval_error);
            return false;
        }

        //Validaton for min and max price
        if (levelProps?.configLevel === 'sku') {
            let minPrice = inputData.tempGuardMinPrice;
            if(inputData.tempGuardMin === 'Margin'){
                if(levelProps?.tempGuardData?.costPrice){
                    minPrice = levelProps.tempGuardData.costPrice / (1 - (0.01 * inputData.tempGuardMinPrice));
                } else {
                    Notification('error' , unit_cost_error);
                    return false;
                }
            }
            if(inputData.tempGuardMin === 'MAP'){
                if(!minPrice){
                    Notification('error' , map_error);
                    return false;
                }
            }
            if(inputData.tempGuardMin === 'MSRP'){
                if(!minPrice){
                    Notification('error' , msrp_error);
                    return false;
                }
            }
            let maxPrice = inputData.tempGuardMaxPrice;
            if(inputData.tempGuardMax === 'Margin'){
                if(levelProps?.tempGuardData?.costPrice){
                    maxPrice = levelProps.tempGuardData.costPrice / (1 - (0.01 * inputData.tempGuardMaxPrice));
                } else {
                    Notification('error' , unit_cost_error);
                    return false;
                }
            }
            if(inputData.tempGuardMax === 'MAP'){
                if(!maxPrice){
                    Notification('error' , map_error);
                    return false;
                }
            }
            if(inputData.tempGuardMax === 'MSRP'){
                if(!maxPrice){
                    Notification('error' , msrp_error);
                    return false;
                }
            }

            if(minPrice > maxPrice){
                Notification('error' , min_max_guardrail_error);
                return false;
            }
        } else {
            if(inputData.tempGuardMin === 'Margin' && inputData.tempGuardMax === 'Margin'){
                let minPrice = inputData.tempGuardMinPrice;
                let maxPrice = inputData.tempGuardMaxPrice;
                if(minPrice > maxPrice){
                    Notification('error' , min_max_guardrail_error);
                    return false;
                }
            }
        }

        let guardData = `{
                tempGuardMin: "${inputData.tempGuardMin}",
                tempGuardMinPrice: ${inputData.tempGuardMin === 'MAP' || inputData.tempGuardMin === 'MSRP' || (inputData.tempGuardMin === 'Fixed' && levelProps.configLevel !== 'sku') ? null : inputData.tempGuardMinPrice },
                tempGuardMinPriceSuffix: ${inputData.tempGuardMin === 'MAP' || inputData.tempGuardMin === 'MSRP' || (inputData.tempGuardMin === 'Fixed' && levelProps.configLevel !== 'sku') ? null : `"${inputData.tempGuardMinPriceSuffix}"`},
                tempGuardMax: "${inputData.tempGuardMax}",
                tempGuardMaxPrice: ${inputData.tempGuardMax === 'MAP' || inputData.tempGuardMax === 'MSRP' || (inputData.tempGuardMax === 'Fixed' && levelProps.configLevel !== 'sku') ? null : inputData.tempGuardMaxPrice},
                tempGuardMaxPriceSuffix: ${inputData.tempGuardMax === 'MAP' || inputData.tempGuardMax === 'MSRP' || (inputData.tempGuardMax === 'Fixed' && levelProps.configLevel !== 'sku') ? null : `"${inputData.tempGuardMaxPriceSuffix}"`},
                startTempGuardDate: "${inputData.startTempGuardDate ? dayjs.tz(inputData.startTempGuardDate).startOf('day').format('YYYY/MM/DD HH:mm:ss') : ''}"
                endTempGuardDate: "${inputData.endTempGuardDate ? dayjs.tz(inputData.endTempGuardDate).endOf('day').format('YYYY/MM/DD HH:mm:ss') : ''}"
            }`;

        try {
            setLoading(true);
            const response = await updateTempGuardrailData(
                levelProps.configId,
                levelProps.configdesc,
                levelProps.configLevel,
                guardData,
                inputData.applyAll,
                compareLog('default',levelProps.tempGuardData , inputData, levelProps.configLevel, levelProps.configId, 
                levelProps.configdesc, inputData.applyAll)
            );
            setLoading(false);
            if (response === 'update failed') {
                Notification('error', guardrail_update_error);
            }
            if (response === 'update successfully') {
                Notification('success', guardrail_update_success);
            }
        } catch (e) {
            console.log(e);
            Notification('error', guardrail_update_success);
        }
        levelProps.handleOk();
    };
    
    const onChangeValueHandler = (field, value) => {
        try {
            const updation = {}
            if (field === 'tempGuardMin') {
                updation.tempGuardMin = value;
                if (value === 'Margin') updation.tempGuardMinPriceSuffix = '%';
                else updation.tempGuardMinPriceSuffix = '$';

                //Update price based on selection
                if(value === 'MAP' && levelProps?.tempGuardData) {
                    //Checking Price Abailability
                    updation.tempGuardMinPrice = getMapPriceByConfiguration(levelProps?.tempGuardData);
                } else if(value === 'MSRP' && levelProps?.tempGuardData) {
                    //Checking Price Abailability
                    updation.tempGuardMinPrice = getMsrpPriceByConfiguration(levelProps?.tempGuardData);
                } else {
                    updation.tempGuardMinPrice = null;
                }
            } else if (field === 'tempGuardMax') {
                updation.tempGuardMax = value;
                if (value === 'Margin') updation.tempGuardMaxPriceSuffix = '%';
                else updation.tempGuardMaxPriceSuffix = '$';

                //Update price based on selection
                if(value === 'MAP' && levelProps?.tempGuardData) {
                    //Checking Price Abailability
                    updation.tempGuardMaxPrice = getMapPriceByConfiguration(levelProps?.tempGuardData);
                } else if(value === 'MSRP' && levelProps?.tempGuardData) {
                    //Checking Price Abailability
                    updation.tempGuardMaxPrice = getMsrpPriceByConfiguration(levelProps?.tempGuardData);
                } else {
                    updation.tempGuardMaxPrice = null;
                }
            } else {
                updation[field] = value;
            }
            setInputData(prevState => ({...prevState, ...updation}));
            setFetchedTempGuardrailData(prevState => ({...prevState, ...updation}));
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="override-form">
            <Spin spinning={loading}>
                <Row className="row-1">
                    <Col xs={24} sm={11} className="override-temporaryValue">
                        <Row className="override-tempMin">
                            <Col xs={10} sm={13}>
                                <label>{temp_min}</label>
                                <Select
                                    status={
                                        submitClicked && !hasDataForMin ? 'error' : ''
                                    }
                                    value={fetchedTempGuardrailData?.tempGuardMin}
                                    onChange={(value) => {
                                        onChangeValueHandler('tempGuardMin', value);
                                    }}
                                    // value={fetchedPriceRuleData?.defaultGuardMin}
                                    style={{
                                        width: "clamp(100px,100%,135px)"
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {guardrailOptions.map(option => 
                                        <Option 
                                            disabled={levelProps.configLevel !== 'sku' && option.value === 'Fixed'} 
                                            key={option.value} 
                                            value={option.value}
                                        >
                                            {option.label}
                                        </Option>)}
                                </Select>
                            </Col>
                            <Col span={1}></Col>
                            <Col xs={8} sm={10}>
                                <label>{value}</label>
                                <InputNumber
                                    max={fetchedTempGuardrailData.tempGuardMin === 'Margin' ? marginMaxLimit : null}
                                    min={
                                        fetchedTempGuardrailData.tempGuardMin === 'Margin'? marginMinLimit : 
                                        (fetchedTempGuardrailData.tempGuardMin === 'Fixed') ? 
                                        fixedMinLimit : null
                                    }
                                    step={0.1}
                                    disabled={
                                        fetchedTempGuardrailData?.tempGuardMin === 'MAP' || 
                                        fetchedTempGuardrailData?.tempGuardMin === 'MSRP' ||
                                        (levelProps.configLevel !== 'sku' && fetchedTempGuardrailData?.tempGuardMin !== 'Margin')
                                    }
                                    prefix={fetchedTempGuardrailData?.tempGuardMin === 'Margin'? '%': '$'}
                                    value={inputData.tempGuardMinPrice}
                                    style={{ width: "clamp(100px,100%,135px)" }}
                                    onChange={(value) => {
                                        onChangeValueHandler('tempGuardMinPrice', value);
                                    }}
                                />
                                {levelProps.configLevel === 'sku' && fetchedTempGuardrailData.tempGuardMin === 'Margin' ? 
                                levelProps?.tempGuardData?.costPrice ? 
                                (parseFloat(fetchedTempGuardrailData.tempGuardMinPrice) <= marginMaxLimit 
                                && parseFloat(fetchedTempGuardrailData.tempGuardMinPrice) >= marginMinLimit) ?
                                <div>{project_min_price}
                                    <b>${(levelProps?.tempGuardData?.costPrice / (1 - (0.01 * fetchedTempGuardrailData.tempGuardMinPrice))).toFixed(2)}</b>
                                </div> : 
                                <div style={{color: 'red'}}>{margin_warning}</div> :
                                <div style={{color: 'red'}}>{unit_cost_error}</div> : null}
                            </Col>
                        </Row>
                        <br />
                        <Row className="override-tempMax">
                            <Col xs={10} sm={13}>
                                <label>{temp_max}</label>
                                <Select
                                    status={
                                        submitClicked && !hasDataForMax ? 'error' : ''
                                    }
                                    value={fetchedTempGuardrailData?.tempGuardMax}
                                    onChange={(value) => {
                                        onChangeValueHandler('tempGuardMax', value);
                                    }}
                                    style={{
                                        width: "clamp(100px,100%,135px)"
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {guardrailOptions.map(option => 
                                        <Option 
                                            disabled={levelProps.configLevel !== 'sku' && option.value === 'Fixed'} 
                                            key={option.value} 
                                            value={option.value}
                                        >
                                            {option.label}
                                        </Option>)}
                                </Select>
                            </Col>
                            <Col span={1}></Col>
                            <Col xs={8} sm={10}>
                                <label>{value}</label>
                                <InputNumber
                                    max={fetchedTempGuardrailData.tempGuardMax === 'Margin' ? marginMaxLimit : null}
                                    min={
                                         fetchedTempGuardrailData.tempGuardMax === 'Margin' ? marginMinLimit :  
                                        (fetchedTempGuardrailData.tempGuardMax === 'Fixed') ? 
                                        fixedMinLimit : null
                                    }
                                    step={0.1}
                                    disabled={
                                        fetchedTempGuardrailData?.tempGuardMax === 'MAP' || 
                                        fetchedTempGuardrailData?.tempGuardMax === 'MSRP' ||
                                        (levelProps.configLevel !== 'sku' && fetchedTempGuardrailData?.tempGuardMax !== 'Margin')
                                    }
                                    value={inputData.tempGuardMaxPrice}
                                    prefix={fetchedTempGuardrailData?.tempGuardMax === 'Margin'? '%': '$'}
                                    style={{ width: "clamp(100px,100%,135px)" }}
                                    onChange={(value) => {
                                        onChangeValueHandler('tempGuardMaxPrice', value);
                                    }}
                                />
                                {levelProps.configLevel === 'sku' && fetchedTempGuardrailData.tempGuardMax === 'Margin' ? 
                                levelProps?.tempGuardData?.costPrice ? 
                                (parseFloat(fetchedTempGuardrailData.tempGuardMaxPrice) <= marginMaxLimit 
                                && parseFloat(fetchedTempGuardrailData.tempGuardMaxPrice) >= marginMinLimit) ?
                                <div>{project_max_price}
                                    <b>${(levelProps?.tempGuardData?.costPrice / (1 - (0.01 * fetchedTempGuardrailData.tempGuardMaxPrice))).toFixed(2)}
                                    </b>
                                </div> : 
                                <div style={{color: 'red'}}>{margin_warning}</div> :
                                <div style={{color: 'red'}}>{unit_cost_error}</div> : null}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={1}></Col>
                    <Col xs={24} sm={11} className="override-Date">
                        <label>{interval_text}</label>
                        <RangePicker
                            showToday = {false}
                            value={fetchedTempGuardrailData && fetchedTempGuardrailData?.startTempGuardDate && fetchedTempGuardrailData?.endTempGuardDate ? 
                                [dayjs.tz(fetchedTempGuardrailData.startTempGuardDate), dayjs.tz(fetchedTempGuardrailData.endTempGuardDate)] : []}
                            onChange={(value) => {
                                if (value === null) {  // Clear button clicked (value is null)
                                    setInputData({
                                        ...inputData,
                                        startTempGuardDate: '',
                                        endTempGuardDate: ''
                                    });
                                    setFetchedTempGuardrailData({
                                        ...fetchedTempGuardrailData,
                                        startTempGuardDate: '',
                                        endTempGuardDate: ''
                                    });
                                    return;
                                }
                                setInputData({
                                    ...inputData,
                                    startTempGuardDate: value[0].format('YYYY/MM/DD HH:mm:ss'),
                                    endTempGuardDate: value[1].format('YYYY/MM/DD HH:mm:ss')
                                });
                                setFetchedTempGuardrailData({
                                    ...fetchedTempGuardrailData,
                                    startTempGuardDate: value[0].format('YYYY/MM/DD HH:mm:ss'),
                                    endTempGuardDate: value[1].format('YYYY/MM/DD HH:mm:ss')
                                });
                            }}
                            disabledDate={dateSkipper}
                            style={{ width: '100%' }}
                            status={submitClicked && !inputData?.startTempGuardDate && !inputData?.endTempGuardDate  ? 'error': ''}
                            
                        />
                    </Col>
                </Row>
                <br />

                <Text className="override-info-banner">
                    <InfoCircleOutlined style={{ margin: 8}}  />{guardrail_info}
                </Text>
                {levelProps.configLevel !== 'sku' && <Text className="override-do-not-apply-config-checkbox">
                    <Checkbox
                        onChange={(e) =>
                            setInputData({
                                ...inputData,
                                applyAll: e.target.checked
                            })
                        }
                        style={{ margin: 8}}
                    >{override_info}</Checkbox>
                </Text>}
                <div style={{ marginBottom: 30 }}>
                    <Row style={{
                        "alignItems":"center",
                        "justifyContent":"space-evenly"}}>
                        <Col >
                            <Button
                                className="closeBtn"
                                key="close"
                                onClick={levelProps.handleCancel}
                                style={{
                                    // left: '12%'
                                    marginTop: 10
                                }}>
                                {close}
                            </Button>
                        </Col>
                        <Col >
                            <Button
                                className="tiggerRescrapeBtn"
                                style={{
                                    // left: '15%'
                                    marginTop: 10
                                }}
                                key="triggerRescrape"
                                type="primary"
                                onClick={() => OverrideGuardrail(inputData)}>
                                {override_guardrail}
                            </Button>
                        </Col>
                        <Col >
                            <Button
                                className="closeBtn"
                                disabled={levelProps.tempGuardData?.endTempGuardDate && levelProps.tempGuardData?.startTempGuardDate ? false : true}
                                style={{
                                    // left: '18%'
                                    marginTop: 10
                                }}
                                key="remove"
                                onClick={() => removeGuardrail()}>
                                {remove_guardrail}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Spin>
        </div>
    );
};

export default Override;
