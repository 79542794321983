import React, { useEffect, useState } from 'react';
import { useNavigate , useSearchParams , useLocation} from 'react-router-dom';
import { Table, Input, Pagination, Checkbox, Spin, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Notification, encodeString } from '../../utilities';
import { sbuTableColumns } from '../../constants/tableColumns';
import { getAllSbuData, getAllAssignedSbuData } from '../../graphql/queryExecuters';
import { useDispatch, useSelector } from 'react-redux';
import { SbuActions } from '../../redux/slices/sbu.slice';

import './Tables.scss';

const SBUTable = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const urlParamsObj = new URLSearchParams(location.search);
    const urlParams = urlParamsObj.toString();
    const [filterParams , setFilterParams] = useSearchParams();

    const [loading, setLoading] = useState(false);
    const [pageindex, setPageindex] = useState(filterParams.get('sbuIndex') ? filterParams.get('sbuIndex') : 1);
    const [searchText, setSearchText] = useState(filterParams.get('sbuSearch') ? filterParams.get('sbuSearch') : '');
    const [sortType, setSortType] = useState('ASC');
    const [rowCount, setRowCount] = useState(props.count.sbuCount);
    const [tableData, setTableData] = useState([]);
    const [reloadData, setReloadData] = useState(false);

    const [userId, setUserId] = useState('');

    const { userType, id } = useSelector(state => state.auth);
    const { level,  index, search, sort, sbuList, count } = useSelector((state) => state.sbuData);

    const getIndexParam = () => parseInt(filterParams.get('sbuIndex')) || 1;

    useEffect(() => {
        checkSearchTextChange();
    }, [filterParams]); //eslint-disable-line
    
    useEffect(() => {
        if (props?.level && userType) {
            getSbuDataTrigger();
        }
    }, [pageindex, sortType, props.reload, props.level, userType]); //eslint-disable-line

    const onRowClick = (row) => {
        navigate('/sku_data/sbu/' + row.sbuId + '_' + encodeString(row.sbuDesc),  { state: { searchParams: urlParams, row } });
    };
    
    //getting all Sbu list
    const getSbuDataTrigger = async (searchToApply = null) => {
        try {   
            setLoading(true);
            const searchValue = searchToApply !== null ? searchToApply : searchText;
            if(level === props.level && index === pageindex && search === searchValue && sort === sortType && userId === props.merchantId && !(reloadData !== props.reload && props.reload)){
                setTableData(sbuList);
                setRowCount(count);
            } else {
                let sbuData = [];
                if (props.level === 'all' || props.level === 'bulkConfig') {
                    if(userType === 'MERCHANT'){
                        sbuData = await getAllAssignedSbuData(id, getIndexParam() - 1, 10, searchValue, sortType);
                    } else {
                        sbuData = await getAllSbuData(getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = sbuData.dataCount
                    onSearchInput(value)
                } else if (props.level === 'merchant') {
                    sbuData = await getAllAssignedSbuData(props?.merchantId, getIndexParam() - 1, 10, searchValue, sortType);
                    const value = sbuData.dataCount
                    onSearchInput(value)
                }
                dispatch(SbuActions.setSbuData({ 
                    level: props.level,
                    index: pageindex, 
                    search: searchValue, 
                    sort: sortType,
                    sbuData: sbuData.data,
                    count: sbuData.dataCount
                }));
                setTableData(sbuData.data);
                setRowCount(sbuData.dataCount);
                setUserId(props.merchantId);
            }
            setReloadData(props.reload);
            setLoading(false);
            props.setReload && props.setReload('sbu', false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting sbu Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const onSearchInput = (value) => {
        if (value < (getIndexParam() - 1) * 10) {
            filterParams.set('sbuIndex', 1);
            filterParams.set('sbuSearch', searchText);
            setFilterParams(filterParams);
        }
    }

    const handlePageChange = (event) => {
        filterParams.set('sbuIndex', event);
        setFilterParams(filterParams);
        setPageindex(event);
    };

    const checkSearchTextChange = () => {
        const searchParamText = filterParams.get('sbuSearch') || '';
        if(searchText !== searchParamText) {
            setSearchText(searchParamText);
            getSbuDataTrigger(searchParamText);
        }
    }

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };
    
    const handleCheckboxSelection = async (checkedValues) => {
        const status = {
            value: checkedValues.target.options[0].value,
            status: checkedValues.target.checked
        }

        props.updateTagValues(status)
    };
    const handleTableChange = (pagination, filters, sorter) => {
        let sort = (sorter.order === 'ascend') ? 'ASC' : 'DESC';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                filterParams.set('sbuIndex', 1);
                filterParams.set('sbuSearch', event.target.value);
                setFilterParams(filterParams);
                setPageindex(1);
                getSbuDataTrigger();
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="table-container">
            <Input
                className="petco-Data-Search responsive-Search-Bar"
                id="sbu-search"
                prefix={<SearchOutlined />}
                onKeyDown={onPressEnterKeyHandler}
                onChange={handleSearchChange}
                value={searchText}
                    placeholder={searchText ? searchText : "Search for SBU"}
                suffix={<span className='press-enter-style'>Press enter to search</span>}
            />
            {props.level === 'bulkConfig' ? (
                <Spin spinning={loading}>
                    {tableData.map((item) => {
                        return (
                                <li
                                    key={item.sbuId}
                                    style={{
                                        color: '#001952',
                                        font: '10px',
                                        marginBottom: '10px'
                                    }}>
                                    {
                                        <Checkbox
                                            key={item.desc}
                                            checked={props.isTagChecked(item.sbuDesc, item.sbuId,'sbu')}
                                            options={[{
                                                label: item.sbuDesc,
                                                value: {desc: item.sbuDesc,
                                                id: item.sbuId,
                                                level: 'sbu'
                                                }
                                            }]}
                                            onChange={handleCheckboxSelection}
                                        >{item.sbuDesc}</Checkbox>
                                    }{' '}
                                    <Divider style={{margin: '15px'}} />
                                </li>
                        );
                    })}
                </Spin>
            ) : (
                <Table
                    rowKey="sbuId"
                    id="sbu-table"
                    dataSource={tableData}
                    columns={sbuTableColumns}
                    loading={loading}
                    pagination={false}
                    scroll={{ x: 2000 }}
                    size="middle"
                    onRow={(row, rowIndex) => {
                        return {
                            onClick: (event) => {
                                props.level !== 'merchant' && onRowClick(row);
                            }
                        };
                    }}
                    className="table-striped-rows clickable-table"
                    rowSelection={props.level === 'merchant' ? props?.rowSelection : false}
                    onChange={handleTableChange}
                />
            )}
            {rowCount > 10 && !loading ? <Pagination
                    className="petco-Data-pagination"
                    id="sbu-bulk-filter"
                    defaultCurrent={getIndexParam()}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
        </div>
    );
};

export default SBUTable;
