import { Table } from 'antd';
// import { useNavigate } from 'react-router-dom';

import { 
    getCompetitorsWithRescrapeColumns, 
    getCompetitorsWithRescrapePriceColumns 
} from '../../constants/tableColumns';
import './Tables.scss';

const CompetitorsRescrapeTable = ({ data, configId, configdesc, configLevel, loading, callback }) => {
    return (
        <div className="table-container">
            <Table
                rowKey="competitor"
                id="compRecrape-table"
                dataSource={data}
                columns={configLevel === 'brand' ? 
                    getCompetitorsWithRescrapeColumns(configId, configdesc, configLevel, callback) :
                    getCompetitorsWithRescrapePriceColumns(configId, configdesc, configLevel, callback)
                }
                style={{
                    width: '98%'
                }}
                className="table-striped-rows"
                scroll={{ x: 2000 }}
                pagination={false}
                loading={loading}
                size="middle"
            />
        </div>
    );
};

export default CompetitorsRescrapeTable;
