import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    competitors: [],
    competitorsBaseDetails: []
};

const competitorsSlice = createSlice({
    name: 'competitors',
    initialState,
    reducers: {
        //Setting Competitor state
        setCompetitors(state, { payload }) {
            state.competitors = payload.competitors;
        },

        //Setting Competitor state
        setCompetitorsBaseDetails(state, { payload }) {
            state.competitorsBaseDetails = payload.competitorsBaseDetails;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const CompetitorsActions = competitorsSlice.actions;
export const CompetitorsReducer = competitorsSlice.reducer;
export default competitorsSlice;
