import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllBrandsData = async (index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllBrandsDataQuery(index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllBrands;
    } catch (err) {
        console.log(err);
    }
};

export const getBrandDetails = async (brandId, brandDesc) => {
    try {
        const query = QueryCreators.createGetBrandsDataByIdQuery(brandId, brandDesc);
        const response = await GraphQL.request(query);
        return response.getLevelDetails;
    } catch (err) {
        console.log(err);
    }
};

export const getBrandAtSbuData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetBrandAtSbuDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllBrandsAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getBrandAtShopData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetBrandAtShopDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllBrandsAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getBrandAtDeptData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetBrandAtDeptDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllBrandsAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getBrandAtClassData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetBrandAtClassDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllBrandsAtClass;
    } catch (err) {
        console.log(err);
    }
};

export const getBrandAtSubclassData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetBrandAtSubclassDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllBrandsAtSubclass;
    } catch (err) {
        console.log(err);
    }
};

export const getBrandAtManufData = async (id, desc, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetBrandAtManufDataQuery(id, desc, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllBrandsAtManufacturer;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignBrandData = async (merchantId, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignBrandDataQuery(merchantId, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignBrands;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignBrandAtSbuData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignBrandAtSbuDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignBrandsAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignBrandAtShopData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignBrandAtShopDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignBrandsAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignBrandAtDeptData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignBrandAtDeptDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignBrandsAtDept;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignBrandAtClassData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignBrandAtClassDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignBrandsAtClass;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignBrandAtSubclassData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignBrandAtSubclassDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignBrandsAtSubclass;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignBrandAtManufData = async (merchantId, id, desc, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignBrandAtManufDataQuery(merchantId, id, desc, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignBrandsAtManufacturer;
    } catch (err) {
        console.log(err);
    }
};