import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';

import { ScrapperStackIcon } from '../../constants/tableColumns';
import ReScrape from '../Forms/ReScrape.form';
import './ReScrapeModal.scss';
import './CompTableReScrapeModal.scss';
import { Notification } from '../../utilities';
import { updateScraperTrigger } from '../../graphql/queryExecuters';
import { merchantAccess, userTypes } from '../../constants/common';

const ReScrapeModal = ({configId, configdesc, configLevel, disableReScraperButton, activeCompetitors, onOk}) => {

    const nullState = {
        competitors: [],
        expiryHour: null,
        allCompetitors: true
    }

    const [disableAction, setDisableAction] = useState(disableReScraperButton);

    const [formData, setFormData] = useState({...nullState});

    const [reScrapeModal, setReScrapeModal] = useState(false);

    const {userType, editAccess} = useSelector(state => state.auth);
    
    const handleReScrapeCancel = () => {
        setReScrapeModal(false);
    };
    const handleReScrapeModal = () => {
        setFormData({...nullState});
        setReScrapeModal(true);
    };

    const onChangeValueHandler = (field, value) => {
        try {
            setFormData(prevState => {
                const state = {...prevState};
                state[field] = value;
                if(field === 'competitors') {
                    state.allCompetitors = false;
                }
                if(field === 'allCompetitors' && value) {
                    state.competitors = []
                }
                 return state;
            })
        } catch (err) {
            console.log(err);
        }
    }

    const onSubmitFormHandler = () => {
        try {
            if(formData.allCompetitors || formData.competitors.length > 0) {
                if (formData.allCompetitors && activeCompetitors?.length === 0) {
                    Notification('warning', 'No Competitors Are There To Re-Scrape.');
                    return;
                }
                const competitorsList = formData.allCompetitors ? 'all' : formData.competitors.join(',');
                const expiry = formData.expiryHour ? formData.expiryHour.format('HH:mm') : '';
                updateScraperTrigger(configId, configdesc, configLevel, competitorsList, expiry);
                onOk();
                setDisableAction(true);
                setReScrapeModal(false);
                Notification('success', 'Re-scrape successfully triggered.');
            } else {
                Notification('warning', 'Please select atleast one competitor.');
            }
        } catch (err) {
            console.log(err);
            Notification('error', 'Something went wrong while intiating re-scraping');
        }
    }
    return (
        <>
            <div className="sbu-rescrape-btn">

                {!(userType === userTypes.MERCHANT && editAccess === merchantAccess.VIEW) && <Button
                    loading={disableAction}
                    id='rescrape-btn'
                    disabled={disableAction || activeCompetitors?.length === 0}
                    icon={<ScrapperStackIcon style={{height: '15px'}}/>}
                    onClick={handleReScrapeModal}>
                    {disableAction ? 'Re Scraping' : 'Re Scrape'}
                </Button>}
            </div>
            <Modal
                open={reScrapeModal}
                title="Trigger Re-Scrape"
                onCancel={handleReScrapeCancel}
                width={600}
                footer={[
                    <Button id='recrape-btn' className="closeBtn" key="close" onClick={handleReScrapeCancel}>
                        Close
                    </Button>,
                    <Button id='trigger-rescrape-btn' className="tiggerRescrapeBtn" key="triggerRescrape" type="primary" onClick={onSubmitFormHandler}>
                        Trigger Re-Scrape
                    </Button>
                ]}>
                {/* rescrape form inside modal */}
                <ReScrape activeCompetitors={activeCompetitors} formData={formData} onChange={onChangeValueHandler}/>
            </Modal>
        </>
    );
};

export default ReScrapeModal;
