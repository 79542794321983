import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Row,
    Table,
    Modal,
    Spin,
    Drawer,
    Input,
    Dropdown,
    Checkbox,
    Select,
    InputNumber,
    Result,
    Typography,
    DatePicker
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import './../../components/Tables/Tables.scss';
import './ConfigureSkus.scss';
import {
    CloseCircleOutlined,
    DownOutlined,
    EyeOutlined,
    FormOutlined,
    SearchOutlined
} from '@ant-design/icons';
import defImage from '../../assets/images/svg/NoImage.svg';
import {
    getBulkSkuPriceConfigData,
    getEligibleSKUsForMaster,
    getSKUDetails
} from '../../graphql/queryExecuters';
import {
    priceRuleDesc,
    priceRules,
    price_rule_types,
    TAB_NAME,
    dataConfigDrawerWidth,
    marginMinLimit,
    marginMaxLimit,
    fixedMinLimit,
    subRdMinLimit,
    subRdMaxLimit,
    subRdDefault,
    guardrailOptions
} from '../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedSkuActions } from '../../redux/slices/selectedSkus.slice';
import { updateBulkSkuData } from '../../graphql/queryExecuters';
import { Notification } from '../../utilities';
import PriceConfigForBulk from '../../components/Forms/PriceConfigForBulk.form';
import {
    bulk_configuration_update_error,
    bulk_configuration_update_success,
    default_max_guardrail_error,
    default_min_guardrail_error,
    fixed_price_valid_error,
    highlighted_fields_error,
    interval_error,
    interval_text,
    map_error,
    margin_warning,
    max_margin_price_valid_error,
    max_price_valid_error,
    min_margin_price_valid_error,
    min_max_guardrail_error,
    min_price_valid_error,
    msrp_error,
    price_config_data_error,
    price_interval_missing,
    project_min_price,
    rd_fields_missing_error,
    required_error,
    sku_data_error,
    temp_max,
    temp_min,
    unit_cost_error,
    value,
    map_price_required,
    msrp_price_required,
    temp_guard_min_max_warning,
    default_guard_min_max_warning
} from '../../constants/displayStrings';
import dayjs from 'dayjs';
import { calculateMargin, dateSkipper, getMapPriceByConfiguration, getMsrpPriceByConfiguration, prepareString } from '../../utilities/helper';
import { effectiveDataArray,manualPriceTypes,priceModifierArray } from '../../constants/common';
import * as _ from 'lodash';
import SkuViewBox from './SkuViewBox';
import { ErrorSkuList } from '../ErrorSkuList/ErrorSkuList';

const ConfigureSkus = ({ data }) => {
    const { Paragraph, Text } = Typography;
    const { RangePicker } = DatePicker;
    const [imageUrl, setImageUrl] = useState(defImage);
    const [loading, setLoading] = useState(false);
    const [drawerLoading, setDrawerLoading] = useState(false);
    const [openResetAllModal, setOpenResetAllModal] = useState(false);
    const [skuInfoSummaryModal, setSkuInfoSummaryModal] = useState(false);
    const [viewInfomodaldata, setViewInfomodaldata] = useState({});
    const [editInfomodaldata, setEditInfomodaldata] = useState({});
    const [reload, setReload] = useState(false);
    const [configDataModal, setConfigDataModal] = useState(false);
    const navigate = useNavigate();
    const [skuDetails, setSkuDetailData] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [dataOfSkus, setDataOfSkus] = useState([]);
    //helper for tracking all the sku's which are edited
    const [allEditedSkus, setAllEditedSkus] = useState([]);
    const [isSelectedSkuMaster, setIsSelectedSkuMaster] = useState([]);
    // helper for tracking if supdate btn is clicked or not
    const [submitClicked, setSubmitClicked] = useState(false);
    const [errorSkus, setErrorSkus] = useState([]);
    const [showErrorDrawer, setShowErrorDrawer] = useState(false);

    const dispatch = useDispatch();
    const { state } = useLocation();
    const uploadedCsvData = state.uploadedCsvData;
    //drawers for all update operations
    const [showDrawerFor, setShowDrawerFor] = useState('');
    //searching in table
    const [searchText, setSearchText] = useState('');
    //getting data for selected SKU from redux store
    const { selectedSkuList } = useSelector((state) => state.selectedSku);
    const [fetchedBulkPriceConfig, setFetchedBulkPriceConfig] = useState(selectedSkuList);
    // flag variable to check if editing single sku property
    const [isEditingSingleProperty, setIsEditingSingleProperty] = useState(false);
    //SKUs for master sku dropdown (In Family Rule)
    const [filteredSKUs, setFilteredSKUs] = useState([]);
    // Staged Data
    const [editedSkuState, setEditedSkuState] = useState({});
    // helper for if user clicks back button
    const [openBackModal, setOpenBackModal] = useState(false);
    // helper to check which all pages have been visited already
    const [pagesAlreadyVisited, setPagesAlreadyVisited] = useState(new Set());
    //helper for tracking if defaultGuardrail Min value is Edited
    const [isDefaultguardMinEdited, setIsDefaultguardMinEdited] = useState(false);
    //helper for tracking if defaultGuardrail Max value is Edited
    const [isDefaultguardMaxEdited, setIsDefaultguardMaxEdited] = useState(false);


    const handleSkuInfoSummary = (record) => {
        setSkuInfoSummaryModal(true);
        setViewInfomodaldata(record);
        setReload(!reload);
    };
    const handleSkuConfigEdit = (record) => {
        setConfigDataModal(true);
        setReload(!reload);
        setEditInfomodaldata(record);
    };
    const clearSelection = () => {
        // clearing selection
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };
    //resetting all configurations to original
    const resetConfigurations = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setDataOfSkus([]);
        setAllEditedSkus([]);
        setPagesAlreadyVisited(new Set());
        setFetchedBulkPriceConfig(selectedSkuList);
        if (uploadedCsvData.length > 0) {
            const newData = selectedSkuList.map((item) => {
                const index = uploadedCsvData.findIndex((csvData) => csvData.skuId === item.skuId);
                const csvData = uploadedCsvData[index].data;
                return {
                    ...item,
                    priceRule: csvData?.priceRule?.length !== 0 ? csvData.priceRule : item?.priceRule,
                    manualPrice: csvData.manualPrice !== null ? csvData.manualPrice : item?.manualPrice || '',
                    manualPriceReason: csvData?.manualPriceReason.length !== 0? csvData.manualPriceReason : item?.manualPriceReason || '',
                    tempMapPrice: csvData.mapPrice !== null ? csvData.mapPrice : (item?.tempMapPrice || item?.mapPrice),
                    mapStartDate: csvData?.mapStartDate?.length !== 0 ? csvData.mapStartDate : '',
                    mapEndDate: csvData?.mapEndDate?.length !== 0 ? csvData.mapEndDate : '',
                    tempMsrpPrice: csvData.msrpPrice !== null ? csvData.msrpPrice : (item.tempMsrpPrice || item?.listPrice),
                    msrpStartDate: csvData?.msrpStartDate?.length > 0 ? csvData.msrpStartDate : '',
                    msrpEndDate: csvData?.msrpEndDate?.length > 0 ? csvData.msrpEndDate : '',
                    subRdPrice: csvData.subRdPrice !== null ? csvData.subRdPrice : null,
                    subRdStartDate: csvData?.subRdStartDate?.length > 0 ? csvData.subRdStartDate : '',
                    tempGuardMin: csvData?.tempGuardMin.length > 0 ? csvData?.tempGuardMin : '',
                    tempGuardMinPrice: csvData?.tempGuardMin === 'Margin' || csvData?.tempGuardMin === 'Fixed'? csvData.tempGuardMinPrice : null,
                    tempGuardMinPriceSuffix: csvData?.tempGuardMin === 'Margin' ? '%' : '$',
                    tempGuardMax: csvData?.tempGuardMax.length > 0 ? csvData?.tempGuardMax : '',
                    tempGuardMaxPrice: csvData?.tempGuardMax === 'Margin' || csvData?.tempGuardMax === 'Fixed'? csvData.tempGuardMaxPrice : null,
                    tempGuardMaxPriceSuffix: csvData?.tempGuardMax === 'Margin' ? '%' : '$',
                    startTempGuardDate: csvData?.startTempGuardDate.length > 0 ? csvData?.startTempGuardDate : '',
                    endTempGuardDate: csvData?.endTempGuardDate.length > 0 ? csvData?.endTempGuardDate : ''
                };
            });
            setFetchedBulkPriceConfig(newData);
            setAllEditedSkus(newData);
        }
    };

    //selecting All Skus
    const selectAll = () => {
        const selectedRowKeys = fetchedBulkPriceConfig.map((item) => item.skuId);
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(fetchedBulkPriceConfig);
        setDataOfSkus(fetchedBulkPriceConfig);
    };

    const onSelectChange = async (selectedKeys, selectedRows) => {
        setSelectedRowKeys(selectedKeys);
        setSelectedRows(selectedRows);
        setDataOfSkus((prevState) => {
            const previousData = prevState.length? prevState: []
            let presents = _.intersectionWith(previousData, selectedRows, _.isEqual);
            let dif = _.differenceWith(selectedRows, previousData, _.isEqual);
            if(dif.length>0){
                return presents.concat(dif)
            }
            else return presents
            
        })
        
        const isAnyMasterSku = selectedRows.filter((item) => item.isMaster === true);
        setIsSelectedSkuMaster(isAnyMasterSku);
    };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        selectedRows,
        onChange: onSelectChange
    };

    const onRowClick = (row) => {
        if (selectedRowKeys.includes(row.skuId)) {
            const newRowKeys = selectedRowKeys.filter((item) => item !== row.skuId);
            const newRowData = selectedRows.filter((item) => item.skuId !== row.skuId);
            onSelectChange(newRowKeys, newRowData);
        } else {
            const newRowkeys = [...selectedRowKeys, row.skuId];
            const newRowData = [...selectedRows, row];
            onSelectChange(newRowkeys, newRowData);
        }
    };

    const hasSelected = selectedRowKeys.length > 0;

    useEffect(() => {
        if (skuInfoSummaryModal) {
            getDetailDataTrigger();
        }
        getSelectedSkuPriceConfigDataTrigger(selectedSkuList);
    }, [reload]); //eslint-disable-line

    useEffect(() => {
        handlePageChange(1);
    }, [uploadedCsvData, pagesAlreadyVisited]); //eslint-disable-line

    const getDetailDataTrigger = async () => {
        try {
            setLoading(true);
            let skuData = [];
            skuData = await getSKUDetails(viewInfomodaldata?.skuId);
            if (skuData?.fullImage) {
                setImageUrl(skuData.fullImage);
            }
            setSkuDetailData(skuData);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            Notification('error', sku_data_error);
        }
    };

    const [showModalForError, setShowModalForError] = useState('');
    const openModal = (e, levelType, isSelectedSkuMaster) => {
        if (levelType === 'Price Rules') {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    priceRule: '',
                    familyPriceModifier: '',
                    familyPrice: null,
                    familyPriceSuffix: '',
                    manualPrice: null,
                    manualPriceReason: '',
                    HasSignal: true
                };
            });
            setDataOfSkus(newData);

            if (isSelectedSkuMaster.length !== 0) {
                setShowModalForError({ listOfMasterSkus: isSelectedSkuMaster });
            } else {
                setShowDrawerFor({ levelType: levelType });
            }
        } else if (levelType === 'EADS') {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    markdownPriceEnabled: false,
                    basePriceEnabled: false,
                    HasSignal: true
                };
            });
            setDataOfSkus(newData);
            setShowDrawerFor({ levelType: levelType });
        } else if (levelType === 'MAP Schedule') {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    tempMapPrice: null,
                    mapStartDate: null,
                    mapEndDate: null
                };
            });
            setDataOfSkus(newData);
            setShowDrawerFor({ levelType: levelType });
        } else if (levelType === 'MSRP Schedule') {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    tempMsrpPrice: null,
                    msrpStartDate: null,
                    msrpEndDate: null
                };
            });
            setDataOfSkus(newData);
            setShowDrawerFor({ levelType: levelType });
        } else if (levelType === 'SubRd Schedule') {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    subRdPrice: null,
                    subRdStartDate: null
                };
            });
            setDataOfSkus(newData);
            setShowDrawerFor({ levelType: levelType });
        } else if (levelType === 'Default Guardrail') {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                };
            });
            setDataOfSkus(newData);
            setShowDrawerFor({ levelType: levelType });
        }
        else if (levelType === 'Temporary Guardrail') {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    tempGuardMin: '',
                    tempGuardMinPrice: null,
                    tempGuardMinPriceSuffix: '',
                    tempGuardMax: '',
                    tempGuardMaxPrice: null,
                    tempGuardMaxPriceSuffix: '',
                    startTempGuardDate: null,
                    endTempGuardDate: null
                };
            });
            setDataOfSkus(newData);
            setShowDrawerFor({ levelType: levelType });
        }
         else {
            setShowDrawerFor({ levelType: levelType });
        }

        if (e?.skuId) {
            setShowDrawerFor({
                skuId: e.skuId,
                levelType: levelType,
                value: e.value,
                record: e.record
            });
        }
    };

    const items = [
        {
            //eslint-disable-line
            label: <a onClick={(e) => openModal(e, 'EADS')}>EADS Model</a>, //eslint-disable-line
            key: '01'
        },
        {
            label: (
                <a onClick={(e) => openModal(e, 'Price Rules', isSelectedSkuMaster)}>Price Rules</a> //eslint-disable-line
            ),
            key: '02'
        },
        {
            
            label: (
                <a onClick={(e) => openModal(e, 'MAP Schedule', isSelectedSkuMaster)}>MAP & Schedule </a> //eslint-disable-line
            ),
            key: '03'
        },
        {
            
            label: (
                <a onClick={(e) => openModal(e, 'MSRP Schedule', isSelectedSkuMaster)}>MSRP & Schedule</a>  //eslint-disable-line
                
            ),
            key: '04'
        },
        {
            label: (
                <a onClick={(e) => openModal(e, 'SubRd Schedule', isSelectedSkuMaster)}> Subsequent RD & Schedule</a> //eslint-disable-line
            ),
            key: '05'
        },
        {
            label: <a onClick={(e) => openModal(e, 'Default Guardrail')}>Default Guardrail</a>, //eslint-disable-line
            key: '06'
        },
        {
            label: <a onClick={(e) => openModal(e, 'Temporary Guardrail')}>Temporary Guardrail</a>, //eslint-disable-line
            key: '07'
        }
    ];

    //function for editing single sku Property

    const editSingleSkuProperty = (property, value, record) => {
        setIsEditingSingleProperty(!isEditingSingleProperty);
        let isRecordAlreadyPresent = false;
        if (property === 'Single SKU Price Rule') {
            setSelectedPriceRule(value[0]);
        }
        const newData = dataOfSkus.map((item) => {
            if (item.skuId === record.skuId) {
                isRecordAlreadyPresent = true;
                return item;
            } else {
                return item;
            }
        });
        if (!isRecordAlreadyPresent) {
            setDataOfSkus([record, ...newData]);
        }

        const editPropertyFor = {
            skuId: record.skuId,
            levelType: property,
            value: value,
            record: record
        };
        openModal(editPropertyFor, editPropertyFor.levelType);
    };

    const configureSkuColumns = [
        {
            title: 'SKU Id',
            dataIndex: 'skuId',
            key: 'skuId',
            fixed: 'left',
            width: 100,
            sorter: (a, b) => a.skuId - b.skuId
        },
        {
            title: 'Markdown',
            dataIndex: 'markdownPriceEnabled',
            key: 'markdownPriceEnabled',
            width: 100,
            render: (markdownPriceEnabled, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() => editSingleSkuProperty('Single SKU Markdown Price',markdownPriceEnabled,record)}>
                            <u>
                                {markdownPriceEnabled ? 'Active' : 'Disabled'}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'Base Price',
            dataIndex: 'basePriceEnabled',
            key: 'basePriceEnabled',
            width: 100,
            render: (basePriceEnabled, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() => editSingleSkuProperty('Single SKU Base Price',basePriceEnabled,record)
                            }>
                            <u>
                                {basePriceEnabled ? 'Active' : 'Disabled'}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'Price Rule',
            dataIndex: 'priceRule',
            key: 'priceRule',
            width: 260,
            ellipsis: true,
            render: (priceRule, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() =>
                                editSingleSkuProperty('Single SKU Price Rule',[priceRule, record['manualPrice'], record['manualPriceReason']],record)
                            }>
                            <u>
                                {' '}
                                {/*eslint-disable-line*/}
                                {priceRule && priceRule !== 'null'? priceRuleDesc[priceRule] : priceRuleDesc['do_not_match_competitor']}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'MAP',
            dataIndex: 'tempMapPrice',
            key: 'tempMapPrice',
            width: 250,
            ellipsis: true,
            render: (tempMapPrice, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() => editSingleSkuProperty('Single SKU MAP Schedule',[tempMapPrice || record.mapPrice, record['mapStartDate'], record['mapEndDate']],record)
                            }>
                            <u>
                                {' '}
                                {/*eslint-disable-line*/}
                                {(tempMapPrice || record.mapPrice)? `$${tempMapPrice || record.mapPrice} |` : ''}{' '}
                                {record['mapStartDate'] && record['mapEndDate'] && record['mapStartDate'] !== null && record['mapEndDate']? ` ${dayjs(record['mapStartDate']).format('MM/DD/YYYY')} - ${dayjs(record['mapEndDate']).format('MM/DD/YYYY')}` : ' Not Scheduled'}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'MSRP',
            dataIndex: 'tempMsrpPrice',
            key: 'tempMsrpPrice',
            width: 250,
            ellipsis: true,
            render: (tempMsrpPrice, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() =>
                                editSingleSkuProperty('Single SKU MSRP Schedule',[tempMsrpPrice || record.listPrice, record['msrpStartDate'], record['msrpEndDate']],record)
                            }>
                            <u>
                                {' '}
                                {/*eslint-disable-line*/}
                                {(tempMsrpPrice || record.listPrice)? `$${tempMsrpPrice || record.listPrice} ` : ''}{' '}
                                {record['msrpStartDate'] && record['msrpEndDate'] && record['msrpStartDate'] !== null && record['msrpEndDate']? `| ${dayjs(record['msrpStartDate']).format('MM/DD/YYYY')} - ${dayjs(record['msrpEndDate']).format('MM/DD/YYYY')}` : ' Not Scheduled'}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'SubRd',
            dataIndex: 'subRdPrice',
            key: 'subRdPrice',
            width: 200,
            ellipsis: true,
            render: (subRdPrice, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() => editSingleSkuProperty('Single SKU SubRd Schedule',[subRdPrice, record['subRdStartDate']],record)
                            }>
                            <u>
                                {' '}
                                {(subRdPrice || subRdPrice === 0) && subRdPrice !== 'null'? `${subRdPrice}% ` : `${subRdDefault}% `}{' '}
                                {record['subRdStartDate'] !== null && record['subRdStartDate']? `| ${dayjs(record['subRdStartDate']).format('MM/DD/YYYY')}` : ' Not Scheduled'}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'Default Guardrail Min',
            dataIndex: 'defaultGuardMinPrice',
            key: 'defaultGuardMinPrice',
            width: 200,
            ellipsis: true,
            render: (defaultGuardMinPrice, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() => editSingleSkuProperty('Single SKU default Guardrail Min',[defaultGuardMinPrice, record['defaultGuardMin']],record)
                            }>
                            <u> {record?.defaultGuardMin==='MAP' && (record.tempMapPrice || record.mapPrice || record.defaultGuardMinPrice) ? (getMapPriceByConfiguration(record)!==null?`$${getMapPriceByConfiguration(record)}` : '') : (record?.defaultGuardMin==='MSRP' && (record.tempMsrpPrice || record.listPrice || record.defaultGuardMinPrice)? (getMsrpPriceByConfiguration(record)!==null?`$${getMsrpPriceByConfiguration(record)}`: '') : (record?.defaultGuardMin==='Margin'? `${record?.defaultGuardMinPrice}%`: (record?.defaultGuardMinPrice? `$${record?.defaultGuardMinPrice}` : '' )) )} 
                                {' '}
                                {/*eslint-disable-line*/}
                                {record.defaultGuardMin === 'null' || !record.defaultGuardMin? 'MAP': ` ${record.defaultGuardMin}`}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'Default Guardrail Max',
            dataIndex: 'defaultGuardMaxPrice',
            key: 'defaultGuardMaxPrice',
            width: 200,
            ellipsis: true,
            render: (defaultGuardMaxPrice, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() =>
                                editSingleSkuProperty('Single SKU default Guardrail Max',[defaultGuardMaxPrice, record['defaultGuardMax']],record)
                            }>
                            <u>
                                {record?.defaultGuardMax==='MAP' && (record.tempMapPrice || record.mapPrice || record.defaultGuardMaxPrice) ? (getMapPriceByConfiguration(record)!==null?`$${getMapPriceByConfiguration(record)}` : '') : (record?.defaultGuardMax==='MSRP' && (record.tempMsrpPrice || record.listPrice || record.defaultGuardMaxPrice)? (getMsrpPriceByConfiguration(record)!==null?`$${getMsrpPriceByConfiguration(record)}`: '') : (record?.defaultGuardMax==='Margin'? `${record?.defaultGuardMaxPrice}%`: (record?.defaultGuardMaxPrice? `$${record?.defaultGuardMaxPrice}` : '' )) )}
                                {' '}
                                {/*eslint-disable-line*/}
                                {record.defaultGuardMax === 'null' || !record.defaultGuardMax? 'MSRP' : ` ${record.defaultGuardMax}`}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'Temporary Guardrail Min',
            dataIndex: 'tempGuardMin',
            key: 'tempGuardMin',
            width: 200,
            ellipsis: true,
            render: (tempGuardMin, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() => editSingleSkuProperty('Single SKU Temporary Guardrail',[tempGuardMin, record['tempGuardMinPrice']],record)
                            }>
                            <u>
                                {record?.tempGuardMin==='MAP' && (record.tempMapPrice || record.mapPrice || record.tempGuardMinPrice) ? (getMapPriceByConfiguration(record)!==null? `$${getMapPriceByConfiguration(record)}` : '') : (record?.tempGuardMin==='MSRP' && (record.tempMsrpPrice || record.listPrice || record.tempGuardMinPrice)? (getMsrpPriceByConfiguration(record)!==null? `$${getMsrpPriceByConfiguration(record)}` : '') : (record?.tempGuardMin==='Margin'? `${record?.tempGuardMinPrice}%`: (record?.tempGuardMinPrice? `$${record?.tempGuardMinPrice}` : '' )) )}
                                {' '}
                            </u>    
                                {/*eslint-disable-line*/}
                                {tempGuardMin ? <u>{`${tempGuardMin}`}</u> : '-'}
                            
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'Temporary Guardrail Max',
            dataIndex: 'tempGuardMax',
            key: 'tempGuardMax',
            width: 200,
            ellipsis: true,
            render: (tempGuardMax, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() =>editSingleSkuProperty('Single SKU Temporary Guardrail',[tempGuardMax, record['tempGuardMaxPrice']],record)
                            }>
                            <u> 
                                {record?.tempGuardMax==='MAP' && (record.tempMapPrice || record.mapPrice || record.tempGuardMaxPrice)? (getMapPriceByConfiguration(record)!==null? `$${getMapPriceByConfiguration(record)}` : '') : (record?.tempGuardMax==='MSRP' && (record.tempMsrpPrice || record.listPrice || record.tempGuardMaxPrice)? (getMsrpPriceByConfiguration(record)!==null? `$${getMsrpPriceByConfiguration(record)}` : '') : (record?.tempGuardMax==='Margin'? `${record?.tempGuardMaxPrice}%`: (record?.tempGuardMaxPrice? `$${record?.tempGuardMaxPrice}` : '' )) )}
                                {' '}
                            </u>
                                {/*eslint-disable-line*/}
                                {tempGuardMax ? <u>{`${tempGuardMax}`}</u> : '-'}
                            
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'Temporary Guardrail Schedule',
            dataIndex: 'startTempGuardDate',
            key: 'startTempGuardDate',
            width: 250,
            ellipsis: true,
            render: (startTempGuardDate, record) => (
                <>
                    {
                        <div
                            className="blueText"
                            onClick={() => editSingleSkuProperty('Single SKU Temporary Guardrail',[startTempGuardDate, record['endTempGuardDate']],record)
                            }>
                            <u>
                                {' '}
                                {/*eslint-disable-line*/}
                                {record?.startTempGuardDate && record?.endTempGuardDate? ` ${dayjs(record['startTempGuardDate']).format('MM/DD/YYYY')} - ${dayjs(record['endTempGuardDate']).format('MM/DD/YYYY')}`: ' Not Scheduled'}{' '}
                            </u>
                        </div>
                    }
                </>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 80,
            fixed: 'right',
            render: (index, record) => (
                <div key={record.skuId} style={{ marginLeft: 5 }}>
                    <a style={{ color: 'black', marginRight: '25px' }}>{' '} {/*eslint-disable-line*/}
                        <EyeOutlined color="black" onClick={() => handleSkuInfoSummary(record)} />
                    </a>
                    <a style={{ color: 'black' }}> {' '}{/*eslint-disable-line*/}
                        <FormOutlined onClick={() => handleSkuConfigEdit(record)} />
                    </a>
                </div>
            ),
            onCell: () => ({
                onClick: (event) => {
                    if (event.stopPropagation) event.stopPropagation();
                }
            })
        }
    ];

    const handleResetBtnClick = () => {
        setOpenResetAllModal(true);
    };
    const handleBackBtnClick = () => {
        setOpenBackModal(true);
    };
    const handleConfirmBack = () => {
        navigate('/bulk_Configurations/selectedSku', {
            state: { uploadedCsvData }
        });
    };
    const handleConfirmReset = () => {
        navigate('/bulk_Configurations/allSku');
        dispatch(SelectedSkuActions.setSelectedSkuCount({ selectedSkuCount: 0 }));
        dispatch(SelectedSkuActions.setSelectedRowKeysData({ selectedRowKeysData: [] }));
        dispatch(SelectedSkuActions.setSelectedSkuData({ selectedSkuData: [] }));
    };
    const handleCancel = (sku_id) => {
        setOpenResetAllModal(false);
        setOpenBackModal(false);
        setSkuInfoSummaryModal(false);
        setConfigDataModal(false);
        setShowDrawerFor('');
        setShowModalForError('');
        setIsEditingSingleProperty(false);

        setEditedSkuState((current) => {
            const copy = { ...current };
            delete copy[sku_id];
            return copy;
        });
    };

    const setStagedDataSku = (skuData, key, value) => {
        if (!editedSkuState[skuData.skuId]) {
            setEditedSkuState({
                ...editedSkuState,
                [skuData.skuId]: skuData
            });
        } else if (editedSkuState[skuData.skuId] && key) {
            skuData[key] = value;

            setEditedSkuState({
                ...editedSkuState,
                [skuData.skuId]: skuData
            });
        }
    };

    const handleConfigDataUpdate = () => {
        setConfigDataModal(false);
        setReload(!reload);
    };

    //getting the price configuration of all sku's
    useEffect(() => {
        if (selectedSkuList.length === 0) {
            navigate('/bulk_Configurations/allSku');
        }
    }, [reload]); //eslint-disable-line

    //updating seelcted SKU drawer functions and variable declarations
    
    const [selectedPriceRule, setSelectedPriceRule] = useState('' );

    //Helper for Date Pickers
    const [mapCalendarDate, setMapCalendarDate] = useState(null);
    const [msrpCalendarDate, setMsrpCalendarDate] = useState(null);

    const currentSku = dataOfSkus.find(item => item.skuId === showDrawerFor?.skuId);

    const markdownPriceHandler = (e, showDrawerFor) => {
        if (isEditingSingleProperty === false) {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    markdownPriceEnabled: e.target.checked,
                    HasSignal: true
                };
            });

            setDataOfSkus(newData);
        } else if (isEditingSingleProperty === true) {
            const newData = dataOfSkus.map((item) => {
                if (item.skuId === showDrawerFor.skuId) {
                    return {
                        ...item,
                        markdownPriceEnabled: e.target.checked,
                        HasSignal: true
                    };
                } else {
                    return item;
                }
            });

            setDataOfSkus(newData);
        }
    };

    const basePriceHandler = (e, showDrawerFor) => {
        if (isEditingSingleProperty === false) {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    basePriceEnabled: e.target.checked,
                    HasSignal: true
                };
            });
            setDataOfSkus(newData);
        } else if (isEditingSingleProperty === true) {
            const newData = dataOfSkus.map((item) => {
                if (item.skuId === showDrawerFor.skuId) {
                    return {
                        ...item,
                        basePriceEnabled: e.target.checked,
                        HasSignal: true
                    };
                } else {
                    return item;
                }
            });

            setDataOfSkus(newData);
        }
    };
    const handlePriceRuleChange = (value) => {
        if (isEditingSingleProperty === false) {
            const newData = dataOfSkus.map((item) => {
                return {
                    ...item,
                    priceRule: value,
                    HasSignal: true
                };
            });
            setDataOfSkus(newData);
        } else if (isEditingSingleProperty === true) {
            const newData = dataOfSkus.map((item) => {
                if (item.skuId === showDrawerFor.skuId) {
                    return {
                        ...item,
                        priceRule: value,
                        HasSignal: true
                    };
                } else {
                    return item;
                }
            });

            setDataOfSkus(newData);
        }

        if (value === 'family_rule') {
            setFilteredSKUs([]);
        }

        setSelectedPriceRule(value);
    };

    const getSelectedSkuPriceConfigDataTrigger = async (currentPageSkus) => {
        try {
            setLoading(true);
            const skuIdsListString = currentPageSkus.map((item) => `'${item.skuId}'`).join(',');
            let selectedPriceConfigSkusResponse = [];
            selectedPriceConfigSkusResponse = await getBulkSkuPriceConfigData(skuIdsListString);

            const matchedSkus = selectedPriceConfigSkusResponse.skuConfig;
            const unMatchedSkus = selectedPriceConfigSkusResponse.unmatchedConfig;
            let allData = [...fetchedBulkPriceConfig];
            setFetchedBulkPriceConfig((prevState) => {
                const allSku = [...prevState];
                matchedSkus.forEach((sku) => {
                    const index = allSku.findIndex((skuData) => skuData.skuId === sku.skuId);
                    const index2 = allEditedSkus?.findIndex(
                        (skuData) => skuData.skuId === sku.skuId
                    );
                    if (index >= 0) {
                        let previousData = index2 >= 0 ? allEditedSkus[index2] : allSku[index];
                        // let previousData = allEditedSkus[index2]
                        allSku[index] = {
                            ...previousData,
                            markdownPriceEnabled: 'markdownPriceEnabled' in previousData && typeof previousData.markdownPriceEnabled === 'boolean' ? previousData.markdownPriceEnabled : sku?.markdownPrice,
                            basePriceEnabled: 'basePriceEnabled' in previousData && typeof previousData.basePriceEnabled === 'boolean' ? previousData.basePriceEnabled : sku?.basePrice,
                            priceRule: 'priceRule' in previousData && previousData.priceRule? previousData.priceRule : sku?.priceRule,
                            tempMapPrice: 'tempMapPrice' in previousData && previousData.tempMapPrice && previousData.tempMapPrice!==sku.mapPrice? previousData.tempMapPrice : (sku.tempMapPrice || sku.mapPrice),
                            mapStartDate: 'mapStartDate' in previousData && previousData.mapStartDate? previousData.mapStartDate : sku?.mapStartDate,
                            mapEndDate: 'mapEndDate' in previousData && previousData.mapEndDate? previousData.mapEndDate : sku?.mapEndDate,
                            tempMsrpPrice: 'tempMsrpPrice' in previousData && previousData.tempMsrpPrice && previousData.tempMsrpPrice!==sku.listPrice ? previousData.tempMsrpPrice : (sku?.tempMsrpPrice || sku?.listPrice),
                            msrpStartDate: 'msrpStartDate' in previousData && previousData.msrpStartDate? previousData.msrpStartDate : sku?.msrpStartDate,
                            msrpEndDate: 'msrpEndDate' in previousData && previousData.msrpEndDate? previousData.msrpEndDate : sku?.msrpEndDate,
                            subRdPrice: 'subRdPrice' in previousData && (previousData.subRdPrice || previousData.subRdPrice === 0) ? previousData.subRdPrice : sku?.subRdPrice || sku?.subRdPrice === 0 ? sku.subRdPrice : subRdDefault,
                            subRdStartDate: 'subRdStartDate' in previousData && previousData.subRdStartDate? previousData.subRdStartDate : sku?.subRdStartDate,
                            defaultGuardMax: 'defaultGuardMax' in previousData && previousData.defaultGuardMax? previousData.defaultGuardMax : sku.defaultGuardMax? sku.defaultGuardMax : 'MSRP',
                            defaultGuardMaxPrice:
                                'defaultGuardMaxPrice' in previousData &&
                                (previousData.defaultGuardMaxPrice || previousData.defaultGuardMaxPrice === 0)
                                ? previousData.defaultGuardMaxPrice
                                : sku?.defaultGuardMax === 'MSRP'
                                ? (getMsrpPriceByConfiguration(sku))
                                : sku?.defaultGuardMax === 'MAP'
                                ? (getMapPriceByConfiguration(sku))
                                : sku?.defaultGuardMaxPrice,
                            defaultGuardMin: 'defaultGuardMin' in previousData && previousData.defaultGuardMin? previousData.defaultGuardMin : sku.defaultGuardMin? sku.defaultGuardMin : 'MAP',
                            defaultGuardMinPrice:
                                'defaultGuardMinPrice' in previousData &&
                                (previousData.defaultGuardMinPrice || previousData.defaultGuardMinPrice === 0)
                                ? previousData.defaultGuardMinPrice
                                : sku?.defaultGuardMin === 'MSRP'
                                ? (getMsrpPriceByConfiguration(sku))
                                : sku?.defaultGuardMin === 'MAP'
                                ? (getMapPriceByConfiguration(sku))
                                : sku?.defaultGuardMinPrice,
                            masterSku: 'masterSku' in previousData && previousData.masterSku? previousData.masterSku : sku?.masterSku,
                            familyPrice: 'familyPrice' in previousData && previousData.familyPrice? previousData.familyPrice : sku?.familyPrice,
                            familyPriceModifier: 'familyPriceModifier' in previousData && previousData.familyPriceModifier? previousData.familyPriceModifier : sku?.familyPriceModifier,
                            familyPriceSuffix: 'familyPriceSuffix' in previousData && previousData.familyPriceSuffix? previousData.familyPriceSuffix : sku?.familyPriceSuffix,
                            isMaster: 'isMaster' in previousData && previousData.isMaster? previousData.isMaster : sku?.isMaster,
                            manualPrice: 'manualPrice' in previousData && previousData.manualPrice? previousData.manualPrice : sku?.manualPrice,
                            manualPriceReason: 'manualPriceReason' in previousData && previousData.manualPriceReason? previousData.manualPriceReason : sku?.manualPriceReason,
                            manualPriceSuffix: 'manualPriceSuffix' in previousData && previousData.manualPriceSuffix? previousData.manualPriceSuffix : sku?.manualPriceSuffix,
                            lastRepricePrice: sku?.lastRepricePrice,
                            tempGuardMin: 'tempGuardMin' in previousData && previousData.tempGuardMin? previousData.tempGuardMin : sku?.tempGuardMin,
                            tempGuardMinPrice:
                                'tempGuardMinPrice' in previousData &&
                                (previousData.tempGuardMinPrice || previousData.tempGuardMinPrice === 0)
                                    ? previousData.tempGuardMinPrice
                                    : sku?.tempGuardMin === 'MSRP'
                                    ? getMsrpPriceByConfiguration(sku)
                                    : sku?.tempGuardMin === 'MAP'
                                    ? getMapPriceByConfiguration(sku)
                                    : sku?.tempGuardMinPrice,
                            tempGuardMinPriceSuffix: 'tempGuardMinPriceSuffix' in previousData && previousData.tempGuardMinPriceSuffix? previousData.tempGuardMinPriceSuffix : sku?.tempGuardMinPriceSuffix,
                            tempGuardMax: 'tempGuardMax' in previousData && previousData.tempGuardMax? previousData.tempGuardMax : sku?.tempGuardMax,
                            tempGuardMaxPrice: 
                                'tempGuardMaxPrice' in previousData &&
                                (previousData.tempGuardMaxPrice || previousData.tempGuardMaxPrice === 0)
                                ? previousData.tempGuardMaxPrice
                                : sku?.tempGuardMax === 'MSRP'
                                ? (getMsrpPriceByConfiguration(sku))
                                : sku?.tempGuardMax === 'MAP'
                                ? (getMapPriceByConfiguration(sku))
                                : sku?.tempGuardMaxPrice,
                            tempGuardMaxPriceSuffix: 'tempGuardMaxPriceSuffix' in previousData && previousData.tempGuardMaxPriceSuffix? previousData.tempGuardMaxPriceSuffix : sku?.tempGuardMaxPriceSuffix,
                            startTempGuardDate: 'startTempGuardDate' in previousData && previousData.startTempGuardDate? previousData.startTempGuardDate : sku?.startTempGuardDate,
                            endTempGuardDate: 'endTempGuardDate' in previousData && previousData.endTempGuardDate? previousData.endTempGuardDate : sku?.endTempGuardDate,
                            costPrice: sku?.costPrice? sku.costPrice : null,
                            listPrice: sku?.listPrice? sku.listPrice : null,
                            mapPrice: sku?.mapPrice? sku.mapPrice : null, 
                        };
                    }
                });
                unMatchedSkus.forEach((sku) => {
                    const index = allSku.findIndex((skuData) => skuData.skuId === sku.skuId);
                    const index2 = allEditedSkus?.findIndex(
                        (skuData) => skuData.skuId === sku.skuId
                    );
                    if (index >= 0) {
                        let previousData = index2 >= 0 ? allEditedSkus[index2] : allSku[index];

                        allSku[index] = {
                            ...previousData,
                            markdownPriceEnabled: 'markdownPriceEnabled' in previousData? previousData.markdownPriceEnabled : false,
                            basePriceEnabled: 'basePriceEnabled' in previousData? previousData.basePriceEnabled : false,
                            defaultGuardMin: 'defaultGuardMin' in previousData? previousData.defaultGuardMin : 'MAP',
                            defaultGuardMinPrice: 'defaultGuardMinPrice' in previousData? previousData.defaultGuardMinPrice : sku?.defaultGuardMin === 'MSRP'? (getMsrpPriceByConfiguration(sku)) : sku?.defaultGuardMin === 'MAP'? (getMapPriceByConfiguration(sku)) : null,
                            defaultGuardMinPriceSuffix: '$',
                            defaultGuardMax: 'defaultGuardMax' in previousData? previousData.defaultGuardMax : 'MSRP',
                            defaultGuardMaxPrice: 'defaultGuardMaxPrice' in previousData? previousData.defaultGuardMaxPrice : sku?.defaultGuardMax === 'MSRP'? (getMsrpPriceByConfiguration(sku)) : sku?.defaultGuardMax === 'MAP'? (getMapPriceByConfiguration(sku)) : null,
                            defaultGuardMaxPriceSuffix: '$',
                            priceRule: 'priceRule' in previousData? previousData.priceRule : 'do_not_match_competitor',
                            isMaster: 'isMaster' in previousData ? previousData.isMaster : false,
                            tempMsrpPrice: 'tempMsrpPrice' in previousData ? previousData.tempMsrpPrice : null,
                            msrpStartDate: 'msrpStartDate' in previousData ? previousData.msrpStartDate : null,
                            msrpEndDate: 'msrpEndDate' in previousData ? previousData.msrpEndDate : null,
                            subRdPrice: 'subRdPrice' in previousData ? previousData.subRdPrice : subRdDefault,
                            subRdStartDate: 'subRdStartDate' in previousData? previousData.subRdStartDate : null,
                            subRdEndDate: null,
                            tempMapPrice: 'tempMapPrice' in previousData ? previousData.tempMapPrice : null,
                            mapStartDate: 'mapStartDate' in previousData ? previousData.mapStartDate : null,
                            mapEndDate: 'mapEndDate' in previousData ? previousData.mapEndDate : null,
                            mapHoldPrice: null,
                            mapHoldStartDate: null,
                            mapHoldEndDate: null,
                            masterSku: 'masterSku' in previousData ? previousData.masterSku : '',
                            familyPriceModifier: 'familyPriceModifier' in previousData? previousData.familyPriceModifier : '',
                            familyPrice: 'familyPrice' in previousData ? previousData.familyPrice : null,
                            familyPriceSuffix: 'familyPriceSuffix' in previousData? previousData.familyPriceSuffix : '',
                            manualPrice: 'manualPrice' in previousData ? previousData.manualPrice : null,
                            manualPriceSuffix: '$',
                            manualPriceReason: 'manualPriceReason' in previousData? previousData.manualPriceReason : '',
                            lastRepricePrice: null,
                            tempGuardMin: 'tempGuardMin' in previousData ? previousData.tempGuardMin : '',
                            tempGuardMinPrice: 'tempGuardMinPrice' in previousData? previousData.tempGuardMinPrice : sku?.tempGuardMin === 'MSRP'? (getMsrpPriceByConfiguration(sku)) : sku?.tempGuardMin === 'MAP'? (getMapPriceByConfiguration(sku)) : null,
                            tempGuardMinPriceSuffix: 'tempGuardMinPriceSuffix' in previousData? previousData.tempGuardMinPriceSuffix : '',
                            tempGuardMax: 'tempGuardMax' in previousData ? previousData.tempGuardMax : '',
                            tempGuardMaxPrice: 'tempGuardMaxPrice' in previousData? previousData.tempGuardMaxPrice : sku?.tempGuardMax === 'MSRP'? (getMsrpPriceByConfiguration(sku)) : sku?.tempGuardMax === 'MAP'? (getMapPriceByConfiguration(sku)) : null,
                            tempGuardMaxPriceSuffix: 'tempGuardMaxPriceSuffix' in previousData? previousData.tempGuardMaxPriceSuffix : '',
                            startTempGuardDate: 'startTempGuardDate' in previousData? previousData.startTempGuardDate : null,
                            endTempGuardDate: 'endTempGuardDate' in previousData? previousData.endTempGuardDate : null,
                            costPrice: null,
                            listPrice:  null,
                            mapPrice: null, 
                        };
                    }
                });
                allData = allSku.map((item) => {
                    return {
                        ...item,
                        listPrice: item?.listPrice,
                        cyCost: item?.cyCost,
                        currentPrice: item?.currentPrice
                    }
                })
                return allSku;
            });
            if (uploadedCsvData.length > 0) {
                const newData = allData.map((item) => {
                    const index = uploadedCsvData.findIndex(
                        (csvData) => csvData.skuId === item.skuId
                    );
                    const index2 = allEditedSkus?.findIndex(
                        (skuData) => skuData.skuId === item.skuId
                    );
                    let csvData;
                    if(index2 >= 0){
                        csvData = {
                            ...allEditedSkus[index2],
                            mapPrice: allEditedSkus[index2].tempMapPrice || allEditedSkus[index2].mapPrice,
                            msrpPrice: allEditedSkus[index2].tempMsrpPrice || allEditedSkus[index2].listPrice,


                        }
                        if(!csvData.tempGuardMin && !csvData.tempGuardMax){
                            csvData={
                                ...csvData,
                                tempGuardMin: item.tempGuardMin || '',
                                tempGuardMinPrice: ((item?.tempGuardMin === 'Margin' || item?.tempGuardMin === 'Fixed') && (item.tempGuardMinPrice!==null || !isNaN(item.tempGuardMinPrice)))? item.tempGuardMinPrice : (item?.tempGuardMin === 'MAP'? (getMapPriceByConfiguration(item)) : (item?.tempGuardMin === 'MSRP'? (getMsrpPriceByConfiguration(item)) : item.tempGuardMinPrice)),
                                tempGuardMinPriceSuffix: item?.tempGuardMin === 'Margin' ? '%' : '$',
                                tempGuardMax: item?.tempGuardMax || '',
                                tempGuardMaxPrice: ((item?.tempGuardMax === 'Margin' || item?.tempGuardMax === 'Fixed') && (item.tempGuardMaxPrice!==null || !isNaN(item.tempGuardMaxPrice)))? item.tempGuardMaxPrice : (item?.tempGuardMax === 'MAP'? (getMapPriceByConfiguration(item)) : (item?.tempGuardMax === 'MSRP'? (getMsrpPriceByConfiguration(item)) : item.tempGuardMaxPrice)),
                                tempGuardMaxPriceSuffix: item?.tempGuardMax === 'Margin' ? '%' : '$',
                                startTempGuardDate: item?.startTempGuardDate || '',
                                endTempGuardDate: item?.endTempGuardDate || ''
                            }
                        }
                    }else{
                        csvData = uploadedCsvData[index].data;
                    }
                    
                    return {
                        ...item,
                        priceRule: csvData?.priceRule?.length !== 0 ? csvData.priceRule : item?.priceRule,
                        manualPrice: csvData.manualPrice !== null ? csvData.manualPrice : item?.manualPrice,
                        manualPriceReason: csvData?.manualPriceReason?.length !== 0? csvData.manualPriceReason : item?.manualPriceReason,
                        tempMapPrice: csvData.mapPrice && csvData.mapPrice!==item.mapPrice? csvData.mapPrice : (item?.tempMapPrice || item?.mapPrice),
                        mapStartDate: csvData.mapStartDate? csvData.mapStartDate : item?.mapStartDate,
                        mapEndDate: csvData.mapEndDate? csvData.mapEndDate : item?.mapEndDate,
                        tempMsrpPrice: csvData.msrpPrice && csvData.msrpPrice!==item.listPrice? csvData.msrpPrice : (item?.tempMsrpPrice || item?.listPrice),
                        msrpStartDate: csvData.msrpStartDate? csvData.msrpStartDate : item?.msrpStartDate,
                        msrpEndDate: csvData.msrpEndDate? csvData.msrpEndDate : item?.msrpEndDate,
                        subRdPrice: csvData.subRdPrice || csvData.subRdPrice === 0 ? csvData.subRdPrice : item?.subRdPrice,
                        subRdStartDate: csvData.subRdStartDate? csvData.subRdStartDate : item?.subRdStartDate,
                        tempGuardMin: csvData?.tempGuardMin?.length > 0 ? csvData?.tempGuardMin : item?.tempGuardMin,
                        tempGuardMinPrice: ((csvData?.tempGuardMin === 'Margin' || csvData?.tempGuardMin === 'Fixed') && (csvData.tempGuardMinPrice!==null || !isNaN(csvData.tempGuardMinPrice)))? csvData.tempGuardMinPrice : (csvData?.tempGuardMin === 'MAP'? (getMapPriceByConfiguration(item)) : (csvData?.tempGuardMin === 'MSRP'? (getMsrpPriceByConfiguration(item)) : item.tempGuardMinPrice)),
                        tempGuardMinPriceSuffix: csvData?.tempGuardMin === 'Margin' ? '%' : '$',
                        tempGuardMax: csvData?.tempGuardMax?.length > 0 ? csvData?.tempGuardMax : item?.tempGuardMax,
                        tempGuardMaxPrice: ((csvData?.tempGuardMax === 'Margin' || csvData?.tempGuardMax === 'Fixed') && (csvData.tempGuardMaxPrice!==null || !isNaN(csvData.tempGuardMaxPrice)))? csvData.tempGuardMaxPrice : (csvData?.tempGuardMax === 'MAP'? (getMapPriceByConfiguration(item)) : (csvData?.tempGuardMax === 'MSRP'? (getMsrpPriceByConfiguration(item)) : item.tempGuardMaxPrice)),
                        tempGuardMaxPriceSuffix: csvData?.tempGuardMax === 'Margin' ? '%' : '$',
                        startTempGuardDate: csvData?.startTempGuardDate?.length > 0? csvData?.startTempGuardDate : item?.startTempGuardDate,
                        endTempGuardDate: csvData?.endTempGuardDate?.length > 0 ? csvData?.endTempGuardDate : item?.endTempGuardDate
                    };
                });
                setFetchedBulkPriceConfig(newData);
                setAllEditedSkus(newData);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            Notification(
                'error',
                'Something went wrong while Fetching Price Configuration Data. Please try again later.'
            );
        }
    };
    
    const validateGuardrailValues = () => {
        const errorSkusList = [];
        allEditedSkus.forEach((sku) => {
            let errorMessages = [];
            const mapPrice = getMapPriceByConfiguration(sku);
            const msrpPrice = getMsrpPriceByConfiguration(sku);

            let tempMinVal = sku.tempGuardMin === 'MAP' ? mapPrice : sku.tempGuardMin==='MSRP' ? msrpPrice : sku.tempGuardMin==='Margin' ? calculateMargin(sku.costPrice, sku.tempGuardMinPrice) : sku.tempGuardMinPrice;
            let tempMaxVal = sku.tempGuardMax === 'MAP' ? mapPrice : sku.tempGuardMax==='MSRP' ? msrpPrice : sku.tempGuardMax==='Margin' ? calculateMargin(sku.costPrice, sku.tempGuardMaxPrice) : sku.tempGuardMaxPrice;

            let defaultMinVal = sku.defaultGuardMin === 'MAP' ? mapPrice : sku.defaultGuardMin==='MSRP' ? msrpPrice : sku.defaultGuardMin==='Margin' ? calculateMargin(sku.costPrice, sku.defaultGuardMinPrice) : sku.defaultGuardMinPrice;
            let defaultMaxVal = sku.defaultGuardMax === 'MAP' ? mapPrice : sku.defaultGuardMax==='MSRP' ? msrpPrice : sku.defaultGuardMax==='Margin' ? calculateMargin(sku.costPrice, sku.defaultGuardMaxPrice) : sku.defaultGuardMaxPrice;

            // Check Min/Max values
            if (tempMinVal !== null && tempMaxVal !== null && tempMinVal > tempMaxVal) {
                errorMessages.push(temp_guard_min_max_warning)
            }
            if (defaultMinVal !== null && defaultMaxVal !== null && defaultMinVal > defaultMaxVal) {
                errorMessages.push(default_guard_min_max_warning)
            }

            // Check MAP and MSRP values
            if ((sku.tempGuardMin === 'MAP' || sku.tempGuardMax === 'MAP') && mapPrice == null) {
                errorMessages.push(map_price_required);
            }
            if ((sku.tempGuardMin === 'MSRP' || sku.tempGuardMax === 'MSRP') && msrpPrice == null) {
                errorMessages.push(msrp_price_required);
            }
            if ((sku.defaultGuardMin === 'MAP' || sku.defaultGuardMax === 'MAP') && mapPrice == null) {
                errorMessages.push(map_price_required);
            }
            if ((sku.defaultGuardMin === 'MSRP' || sku.defaultGuardMax === 'MSRP') && msrpPrice == null) {
                errorMessages.push(msrp_price_required);
            }

            if (errorMessages.length > 0) {
                errorSkusList.push({ skuId: sku.skuId, messages: errorMessages });
            }
        });

        if (errorSkusList.length > 0) {
            setErrorSkus(errorSkusList);
            setShowErrorDrawer(true);
        } else {
            applyConfig(allEditedSkus);
        }
    }

    const applyConfig = async (allEditedSkus) => {
        let configData =
            allEditedSkus &&
            allEditedSkus.map((item) => {
                const desc = prepareString(item.skuDesc);

                return `{
                skuId: "${item.skuId}",
                skuDesc: ${desc},
                configData: {
                    markdownPrice: ${item.markdownPriceEnabled ? item.markdownPriceEnabled : false},
                    basePrice: ${item.basePriceEnabled ? item.basePriceEnabled : false},
                    defaultGuardMin: "${item.defaultGuardMin ? item.defaultGuardMin : 'MAP'}",
                    defaultGuardMinPrice: ${ item.defaultGuardMin==='MAP' || item.defaultGuardMin==='MSRP'? null : (typeof item.defaultGuardMinPrice === 'number' ? item.defaultGuardMinPrice : null)},
                    defaultGuardMinPriceSuffix: ${ item.defaultGuardMin==='MAP' || item.defaultGuardMin==='MSRP'? null : (item.defaultGuardMinPriceSuffix ? `"${item.defaultGuardMinPriceSuffix}"` : `"$"`)},
                    defaultGuardMax: "${item.defaultGuardMax ? item.defaultGuardMax : 'MSRP'}",
                    defaultGuardMaxPrice: ${ item.defaultGuardMax==='MAP' || item.defaultGuardMax==='MSRP'? null : (typeof item.defaultGuardMaxPrice === 'number' ? item.defaultGuardMaxPrice : null)},
                    defaultGuardMaxPriceSuffix: ${item.defaultGuardMax==='MAP' || item.defaultGuardMax==='MSRP'? null : (item.defaultGuardMaxPriceSuffix ? `"${item.defaultGuardMaxPriceSuffix}"` : `"$"`)},
                    priceRule: "${item.priceRule ? item.priceRule : 'do_not_match_competitor'}",
                    isMaster: ${item.isMaster ? item.isMaster : false},
                    msrpPrice: ${(item.tempMsrpPrice && item.msrpStartDate && item.msrpEndDate) ? (item.tempMsrpPrice) : null},
                    msrpStartDate: ${item.msrpStartDate ? `"${dayjs(item.msrpStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null},
                    msrpEndDate: ${item.msrpEndDate ? `"${dayjs(item.msrpEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null},
                    subRdPrice: ${(item?.subRdPrice || item.subRdPrice === 0) ? item.subRdPrice : null},
                    subRdStartDate: ${item.subRdStartDate ? `"${dayjs(item.subRdStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null},
                    subRdEndDate: ${null},
                    mapPrice: ${(item.tempMapPrice && item.mapStartDate && item.mapEndDate) ? (item.tempMapPrice) : null},
                    mapStartDate: ${item.mapStartDate ? `"${dayjs(item.mapStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null},
                    mapEndDate: ${item.mapEndDate ? `"${dayjs(item.mapEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null},
                    mapHoldPrice: ${item.mapHoldPrice ? item.mapHoldPrice : null},
                    mapHoldStartDate: ${item.mapHoldStartDate ? `"${dayjs(item.mapHoldStartDate).startOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null},
                    mapHoldEndDate: ${item.mapHoldEndDate ? `"${dayjs(item.mapHoldEndDate).endOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null},
                    masterSku: ${item.masterSku && item.priceRule===price_rule_types?.family_rule?.key ? `"${item.masterSku}"` : `""`},
                    familyPriceModifier: ${item.familyPriceModifier && item.priceRule===price_rule_types?.family_rule?.key? `"${item.familyPriceModifier}"` : `""`},
                    familyPrice: ${item.familyPrice && item.priceRule===price_rule_types?.family_rule?.key? item.familyPrice : null},
                    familyPriceSuffix: ${item.familyPriceSuffix && item.priceRule===price_rule_types?.family_rule?.key? `"${item.familyPriceSuffix}"` : `""`},
                    manualPrice: ${item.manualPrice && (item.priceRule===price_rule_types?.manual_price?.key || item.priceRule===price_rule_types?.manual_price_with_market_tracking?.key)? item.manualPrice : null},
                    manualPriceSuffix: ${item.manualPriceSuffix && (item.priceRule===price_rule_types?.manual_price?.key || item.priceRule===price_rule_types?.manual_price_with_market_tracking?.key)? `"${item.manualPriceSuffix}"` : `"$"`},
                    manualPriceReason: ${item.manualPriceReason && (item.priceRule===price_rule_types?.manual_price?.key || item.priceRule===price_rule_types?.manual_price_with_market_tracking?.key)? `"${item.manualPriceReason}"` : `""`},
                    tempGuardMax: ${item.tempGuardMax ? `"${item.tempGuardMax}"` : `""`},
                    tempGuardMaxPrice: ${item.tempGuardMax==='MAP' || item.tempGuardMax==='MSRP'? null : (typeof item.tempGuardMaxPrice === 'number' ? item.tempGuardMaxPrice : null)},
                    tempGuardMaxPriceSuffix: ${item.tempGuardMax==='MAP' || item.tempGuardMax==='MSRP'? null : (item.tempGuardMaxPriceSuffix ? `"${item.tempGuardMaxPriceSuffix}"` : `"$"`)},
                    tempGuardMin: ${item.tempGuardMin ? `"${item.tempGuardMin}"` : `""`},
                    tempGuardMinPrice: ${item.tempGuardMin==='MAP' || item.tempGuardMin==='MSRP'? null : (typeof item.tempGuardMinPrice === 'number' ? item.tempGuardMinPrice : null)},
                    tempGuardMinPriceSuffix: ${item.tempGuardMin==='MAP' || item.tempGuardMin==='MSRP'? null : (item.tempGuardMinPriceSuffix ? `"${item.tempGuardMinPriceSuffix}"` : `"$"`)},
                    startTempGuardDate: ${item.startTempGuardDate ? `"${dayjs(item.startTempGuardDate).startOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null},
                    endTempGuardDate: ${item.endTempGuardDate ? `"${dayjs(item.endTempGuardDate).endOf('day').format('YYYY/MM/DD HH:mm:ss')}"` : null}
                }
                hasNoSignal: ${item.HasSignal ? false : true}
            }`;
            });
        try {
            setLoading(true);
            const responseList = [];
            const chunkedData = _.chunk(configData, 2000);
            for await (let skuList of chunkedData) {
                try {
                    const response = await updateBulkSkuData(skuList);
                    responseList.push(response.message);
                } catch (err) {
                    console.log(err);
                    responseList.push(err);
                }
            }

            if (responseList.indexOf('Updation Failed') >= 0) {
                Notification('error', bulk_configuration_update_error);
            } else {
                Notification('success', bulk_configuration_update_success);
            }
        } catch (e) {
            console.log(e);
            Notification('error', price_config_data_error);
        }
        setLoading(false);
    };

    // functions for validation
    const validateData = () => {
        setSubmitClicked(true);
        //Validations for Price Rule
        if (
            showDrawerFor.levelType === 'Price Rules' &&
            (!dataOfSkus[0].priceRule || dataOfSkus[0].priceRule === null || dataOfSkus[0].priceRule === '')
        ) {
            Notification('error', 'Please enter the Price Rule');
            return false;
        }

        //Validations for manual Price
        if (
            showDrawerFor.levelType === 'Price Rules' &&
            (dataOfSkus[0]?.priceRule === 'manual_price' || dataOfSkus[0]?.priceRule === 'manual_price_with_market_tracking') && (!dataOfSkus[0]?.manualPrice || !dataOfSkus[0]?.manualPriceReason)
        ) {
            Notification('error', highlighted_fields_error);
            return false;
        }

        //Validations for Family Rule
        if (dataOfSkus[0]?.priceRule === 'family_rule') {
            const dataOfSkuIds = dataOfSkus.map((item) => item.skuId);
            if (dataOfSkuIds.includes(dataOfSkus[0]?.masterSku)) {
                Notification('error', 'A SKU cannot be its own Master SKU');
                return false;
            }
            if (
                dataOfSkus[0]?.masterSku === '' ||
                dataOfSkus[0]?.masterSku === null ||
                !dataOfSkus[0]?.masterSku ||
                dataOfSkus[0]?.masterSku === dataOfSkus[0]?.skuId ||
                dataOfSkus[0]?.familyPriceModifier === '' ||
                dataOfSkus[0]?.familyPriceModifier === null ||
                !dataOfSkus[0]?.familyPriceModifier ||
                dataOfSkus[0]?.familyPrice === '' ||
                dataOfSkus[0]?.familyPrice === null ||
                !dataOfSkus[0]?.familyPrice ||
                dataOfSkus[0]?.familyPriceSuffix === '' ||
                dataOfSkus[0]?.familyPriceSuffix === null ||
                !dataOfSkus[0]?.familyPriceSuffix
            ) {
                Notification('error', highlighted_fields_error);
                return false;
            }
        }

        //Validations for guardrail values
        if(showDrawerFor.levelType === 'Default Guardrail'){
            if (!dataOfSkus[0].defaultGuardMax && !dataOfSkus[0].defaultGuardMin) {
                Notification('error', required_error);
                return false;
            }

            if (
                (dataOfSkus[0].defaultGuardMax === 'Margin' ||
                    dataOfSkus[0].defaultGuardMax === 'Fixed') &&
                dataOfSkus[0].defaultGuardMaxPrice === null
            ) {
                Notification('error', default_max_guardrail_error);
                return false;
            }

            if (
                (dataOfSkus[0].defaultGuardMin === 'Margin' ||
                    dataOfSkus[0].defaultGuardMin === 'Fixed') &&
                dataOfSkus[0].defaultGuardMinPrice === null
            ) {
                Notification('error', default_min_guardrail_error);
                return false;
            }

             if (dataOfSkus[0].defaultGuardMax === 'Margin' && dataOfSkus[0].defaultGuardMaxPrice < marginMinLimit) {
                Notification('error', `Default Guardrail Max Margin should be greater than or equal to ${marginMinLimit}.`);
                return false;
            }
            
            if (dataOfSkus[0].defaultGuardMax === 'Margin' && dataOfSkus[0].defaultGuardMaxPrice > marginMaxLimit) {
                Notification('error', `Default Guardrail Max Margin should be less than or equal to ${marginMaxLimit}.`);
                return false;
            }
            if (dataOfSkus[0].defaultGuardMax === 'Fixed' && dataOfSkus[0].defaultGuardMaxPrice <= fixedMinLimit) {
                Notification('error', fixed_price_valid_error);
                return false;
            }
            
            if (dataOfSkus[0].defaultGuardMin === 'Margin' && dataOfSkus[0].defaultGuardMinPrice < marginMinLimit) {
                Notification('error', `Default Guardrail Min Margin should be greater than or equal to ${marginMinLimit}.`);
                return false;
            }
            
            if (dataOfSkus[0].defaultGuardMin === 'Margin' && dataOfSkus[0].defaultGuardMinPrice > marginMaxLimit) {
                Notification('error', `Default Guardrail Min Margin should be less than or equal to ${marginMaxLimit}.`);
                return false;
            }
            if (dataOfSkus[0].defaultGuardMin === 'Fixed' && dataOfSkus[0].defaultGuardMinPrice <= fixedMinLimit) {
                Notification('error', fixed_price_valid_error);
                return false;
            }
            
        }

        //validating guardrail Configuration

        if (showDrawerFor.levelType === 'MAP Schedule') {
            if (!dataOfSkus[0].tempMapPrice && !dataOfSkus[0].mapStartDate && !dataOfSkus[0].mapEndDate) {  
                Notification('error', price_interval_missing);
                    return false;
                
            }
            if (dataOfSkus[0].tempMapPrice || dataOfSkus[0].mapPrice ) {
                if (!(dataOfSkus[0].mapStartDate && dataOfSkus[0].mapEndDate)) {
                    Notification('error', 'Please Fill The Interval For MAP');
                    return false;
                }
            }
            if (dataOfSkus[0].mapStartDate && dataOfSkus[0].mapEndDate) {
                if (!dataOfSkus[0].tempMapPrice) {
                    Notification('error', 'Please Fill The MAP Price or Clear the Interval');
                    return false;
                }
            }
        }
        if (showDrawerFor.levelType === 'MSRP Schedule') {
            if (!dataOfSkus[0].tempMsrpPrice && !dataOfSkus[0].msrpStartDate && !dataOfSkus[0].msrpEndDate) {  
                Notification('error', price_interval_missing);
                    return false;
                
            }
            if (dataOfSkus[0].tempMsrpPrice && dataOfSkus[0].tempMsrpPrice !== dataOfSkus[0].listPrice) {
                if (!(dataOfSkus[0].msrpStartDate && dataOfSkus[0].msrpEndDate)) {
                    Notification('error', 'Please Fill The Interval For MSRP');
                    return false;
                }
            }
            if (dataOfSkus[0].msrpStartDate && dataOfSkus[0].msrpEndDate) {
                if (!dataOfSkus[0].tempMsrpPrice) {
                    Notification('error', 'Please Fill The MSRP Price or Clear the Interval');
                    return false;
                }
            }
        }
        if (showDrawerFor.levelType === 'SubRd Schedule') {
            if ((dataOfSkus[0].subRdPrice || dataOfSkus[0].subRdPrice===0) && dataOfSkus[0].subRdPrice !== subRdDefault) {
                if (!dataOfSkus[0].subRdStartDate) {
                    Notification('error', 'Please fill in the SubRd date.');
                    return false;
                }
            }
            if (dataOfSkus[0].subRdStartDate) {
                if (dataOfSkus[0].subRdPrice !== 0 && !dataOfSkus[0].subRdPrice) {
                    Notification('error', 'Please fill in the SubRd % or clear the date.');
                    return false;
                }
            }
            if (!dataOfSkus[0].subRdPrice && !dataOfSkus[0].subRdStartDate) {
                Notification('error', rd_fields_missing_error);
                return false;
                
            }
        }

        // Temporary Guardrail validations
        if(showDrawerFor.levelType === 'Temporary Guardrail'){
            if (!dataOfSkus[0].tempGuardMax || !dataOfSkus[0].tempGuardMin) {
                Notification('error', required_error);
                return false;
            }

            if ((dataOfSkus[0].tempGuardMax === 'Margin' && (dataOfSkus[0].tempGuardMaxPrice < marginMinLimit || dataOfSkus[0].tempGuardMaxPrice===null)) || 
                (dataOfSkus[0].tempGuardMax === 'Fixed' && (dataOfSkus[0].tempGuardMaxPrice<= fixedMinLimit || !dataOfSkus[0].tempGuardMaxPrice))) {
                Notification('error' , max_price_valid_error);
                return false;
            }
            
            if ((dataOfSkus[0].tempGuardMin === 'Margin' && (dataOfSkus[0].tempGuardMinPrice < marginMinLimit || dataOfSkus[0].tempGuardMinPrice===null )) || 
                (dataOfSkus[0].tempGuardMin === 'Fixed' && (dataOfSkus[0].tempGuardMinPrice<=fixedMinLimit || !dataOfSkus[0].tempGuardMinPrice))) {
                Notification('error' , min_price_valid_error);
                return false;
            }


            if (dataOfSkus[0].tempGuardMax === 'Margin' && (
                dataOfSkus[0].tempGuardMaxPrice < marginMinLimit || dataOfSkus[0].tempGuardMaxPrice > marginMaxLimit)) {
                Notification('error' , max_margin_price_valid_error);
                return false;
            }
    
            if (dataOfSkus[0].tempGuardMin === 'Margin' && (
                dataOfSkus[0].tempGuardMinPrice < marginMinLimit || dataOfSkus[0].tempGuardMinPrice > marginMaxLimit)) {
                Notification('error' , min_margin_price_valid_error);
                return false;
            }
            
            //Validaton for guardrail interval
            if(!dataOfSkus[0]?.startTempGuardDate || !dataOfSkus[0]?.endTempGuardDate){
                Notification('error' , interval_error);
                return false;
            }

            // guardrail min max price validation
            let minPrice = dataOfSkus[0].tempGuardMinPrice;
            let maxPrice = dataOfSkus[0].tempGuardMaxPrice;

            if((dataOfSkus[0].tempGuardMin === 'Margin' && dataOfSkus[0].tempGuardMax === 'Margin') || (dataOfSkus[0].tempGuardMin === 'Fixed' && dataOfSkus[0].tempGuardMax === 'Fixed')){
                if(minPrice > maxPrice){
                    Notification('error' , min_max_guardrail_error);
                    return false;
                }
            }
            
        }


        return true;
    };
    const validationSingleSkuEdit = (iteratedSku) => {
        setSubmitClicked(true);
        //Validations for Price Rule
        if (showDrawerFor.levelType === 'Single SKU Price Rule') {
            if (
                !iteratedSku.priceRule ||
                iteratedSku.priceRule === null ||
                iteratedSku.priceRule === ''
            ) {
                Notification('error', 'Please enter the Price Rule');
                return false;
            }

            //Validations for manual Price rule
            if (
                !iteratedSku.priceRule ||
                ((iteratedSku?.priceRule === 'manual_price' ||
                    iteratedSku?.priceRule === 'manual_price_with_market_tracking') &&
                    (!iteratedSku?.manualPrice || !iteratedSku?.manualPriceReason))
            ) {
                Notification('error', 'Please enter the Price Rule required fields');
                return false;
            }

            //Validations for Family Rule
            if (iteratedSku?.priceRule === 'family_rule') {
                if (iteratedSku?.masterSku === iteratedSku?.skuId) {
                    Notification('error', 'A SKU cannot be its own Master SKU');
                    return false;
                }
                if (
                    iteratedSku?.masterSku === '' ||
                    iteratedSku?.masterSku === null ||
                    !iteratedSku?.masterSku ||
                    iteratedSku?.masterSku === iteratedSku?.skuId ||
                    iteratedSku?.familyPriceModifier === '' ||
                    iteratedSku?.familyPriceModifier === null ||
                    !iteratedSku?.familyPriceModifier ||
                    iteratedSku?.familyPrice === '' ||
                    iteratedSku?.familyPrice === null ||
                    !iteratedSku?.familyPrice ||
                    iteratedSku?.familyPriceSuffix === '' ||
                    iteratedSku?.familyPriceSuffix === null ||
                    !iteratedSku?.familyPriceSuffix
                ) {
                    Notification('error', 'Please enter the required highlighted fields');
                    return false;
                }
            }
        } else if (
            showDrawerFor.levelType === 'Single SKU default Guardrail Min' ||
            showDrawerFor.levelType === 'Single SKU default Guardrail Max'
        ) {
            //Validations for guardrail values
            //set min price
            let minPrice = iteratedSku.defaultGuardMinPrice;
            //Set max price
            let maxPrice = iteratedSku.defaultGuardMaxPrice;
            if (showDrawerFor.levelType === 'Single SKU default Guardrail Min') {
                if (!iteratedSku.defaultGuardMin) {
                    Notification('error', required_error);
                    return false;
                }
                if (
                    (iteratedSku.defaultGuardMin === 'Margin' ||
                        iteratedSku.defaultGuardMin === 'Fixed') &&
                    iteratedSku.defaultGuardMinPrice === null
                ) {
                    Notification('error', 'Default Guardrail Min Price should be valid');
                    return false;
                }
                if (
                    iteratedSku.defaultGuardMin === 'Margin' &&
                    (iteratedSku.defaultGuardMinPrice < marginMinLimit ||
                        iteratedSku.defaultGuardMinPrice >= marginMaxLimit)
                ) {
                    Notification('error', min_margin_price_valid_error);
                    return false;
                }
                if (
                    iteratedSku.defaultGuardMin === 'Fixed' &&
                    (iteratedSku.defaultGuardMinPrice <= fixedMinLimit)
                ) {
                    Notification('error', fixed_price_valid_error);
                    return false;
                }
                //Set min price
                if (iteratedSku.defaultGuardMin === 'Margin') {
                    if (iteratedSku.costPrice) {
                        minPrice =
                            iteratedSku.costPrice / (1 - 0.01 * iteratedSku.defaultGuardMinPrice);
                    } else {
                        Notification('error', unit_cost_error);
                        return false;
                    }
                }

                //Set max price
                if (iteratedSku.defaultGuardMax === 'Margin') {
                    if (iteratedSku.costPrice) {
                        maxPrice =
                            iteratedSku.costPrice / (1 - 0.01 * iteratedSku.defaultGuardMaxPrice);
                    } else {
                        Notification('error', unit_cost_error);
                        return false;
                    }
                }

                if (iteratedSku.defaultGuardMin === 'MAP') {
                    if (iteratedSku.tempMapPrice || iteratedSku.mapPrice) {
                        minPrice = getMapPriceByConfiguration(iteratedSku);
                    } else {
                        Notification('error', map_error);
                        return false;
                    }
                }
                //set max price 
                if (iteratedSku.defaultGuardMax === 'MAP') {

                        maxPrice = getMapPriceByConfiguration(iteratedSku);
                }

                if (iteratedSku.defaultGuardMin === 'MSRP') {
                    if (iteratedSku.tempMsrpPrice || iteratedSku.listPrice) {
                        minPrice = getMsrpPriceByConfiguration(iteratedSku);
                    } else {
                        Notification('error', msrp_error);
                        return false;
                    }
                }
                //set max price 
                if (iteratedSku.defaultGuardMax === 'MSRP') { 
                    maxPrice = getMsrpPriceByConfiguration(iteratedSku);
                }
            }
            if (showDrawerFor.levelType === 'Single SKU default Guardrail Max') {
                if (!iteratedSku.defaultGuardMax) {
                    Notification('error', required_error);
                    return false;
                }
                if (
                    (iteratedSku.defaultGuardMax === 'Margin' ||
                        iteratedSku.defaultGuardMax === 'Fixed') &&
                    iteratedSku.defaultGuardMaxPrice === null
                ) {
                    Notification('error', 'Default Guardrail Max Price should be valid');
                    return false;
                }
                if (
                    iteratedSku.defaultGuardMax === 'Margin' &&
                    (iteratedSku.defaultGuardMaxPrice < marginMinLimit ||
                        iteratedSku.defaultGuardMaxPrice >= marginMaxLimit)
                ) {
                    Notification(
                        'error',
                        max_margin_price_valid_error
                    );
                    return false;
                }
                if (
                    iteratedSku.defaultGuardMax === 'Fixed' &&
                    (iteratedSku.defaultGuardMaxPrice <= fixedMinLimit)
                ) {
                    Notification(
                        'error',
                        fixed_price_valid_error
                    );
                    return false;
                }

                //Calculaitng min price based on margin
                if (iteratedSku.defaultGuardMin === 'Margin') {
                    if (iteratedSku.costPrice) {
                        minPrice =
                            iteratedSku.costPrice / (1 - 0.01 * iteratedSku.defaultGuardMinPrice);
                    } else {
                        Notification('error', unit_cost_error);
                        return false;
                    }
                }

                if (iteratedSku.defaultGuardMax === 'Margin') {
                    if (iteratedSku.costPrice) {
                        maxPrice =
                            iteratedSku.costPrice / (1 - 0.01 * iteratedSku.defaultGuardMaxPrice);
                    } else {
                        Notification('error', unit_cost_error);
                        return false;
                    }
                }
                if (iteratedSku.defaultGuardMax === 'MAP') {
                    if (iteratedSku.tempMapPrice || iteratedSku.mapPrice) {
                        maxPrice = getMapPriceByConfiguration(iteratedSku);
                    } else {
                        Notification('error', map_error);
                        return false;
                    }
                }
                //Set Min Price
                if (iteratedSku.defaultGuardMin === 'MAP') {
                   
                    minPrice = getMapPriceByConfiguration(iteratedSku);
                }
                if (iteratedSku.defaultGuardMax === 'MSRP') {
                    if (iteratedSku.tempMsrpPrice || iteratedSku.listPrice) {
                        maxPrice = getMsrpPriceByConfiguration(iteratedSku);
                    } else {
                        Notification('error', msrp_error);
                        return false;
                    }
                }
                //set Min Price
                if (iteratedSku.defaultGuardMin === 'MSRP') {
                   
                    minPrice = getMsrpPriceByConfiguration(iteratedSku); 
                }
            }

            //Validaton for min and max price

            if ((minPrice && maxPrice) && minPrice > maxPrice) {
                Notification('error', 'Default Guardrail Min price is greater than max price');
                return false;
            }
        }
        //Validation for guardrail prices
        else if (showDrawerFor.levelType === 'Single SKU MAP Schedule') {
            if (iteratedSku.tempMapPrice || iteratedSku.mapPrice) {
                if (!(iteratedSku.mapStartDate && iteratedSku.mapEndDate)) {
                    Notification('error', 'Please Fill The Interval For MAP');
                    return false;
                }
            }
            if (iteratedSku.mapStartDate && iteratedSku.mapEndDate) {
                if (!(iteratedSku.tempMapPrice)) {
                    Notification('error', 'Please Fill The Temporary MAP Price or Clear the Interval');
                    return false;
                }
            }
            if(!iteratedSku.tempMapPrice && !iteratedSku.mapStartDate && !iteratedSku.mapEndDate){
                Notification('error', price_interval_missing);
                    return false;
            }
        } else if (showDrawerFor.levelType === 'Single SKU MSRP Schedule') {
            if (iteratedSku.tempMsrpPrice && iteratedSku.tempMsrpPrice !== iteratedSku.listPrice) {
                if (!(iteratedSku.msrpStartDate && iteratedSku.msrpEndDate)) {
                    Notification('error', 'Please Fill The Interval For MSRP');
                    return false;
                }
            }
            if (iteratedSku.msrpStartDate && iteratedSku.msrpEndDate) {
                if (!(iteratedSku.tempMsrpPrice)) {
                    Notification('error', 'Please Fill The Temporary MSRP Price or Clear the Interval');
                    return false;
                }
            }
            if(!iteratedSku.tempMsrpPrice && !iteratedSku.msrpStartDate && !iteratedSku.msrpEndDate){
                Notification('error', price_interval_missing);
                    return false;
            }
        } else if (showDrawerFor.levelType === 'Single SKU SubRd Schedule') {
            if ((iteratedSku.subRdPrice || iteratedSku.subRdPrice===0) && iteratedSku.subRdPrice !== subRdDefault) {
                if (!iteratedSku.subRdStartDate) {
                    Notification('error', 'Please Fill The Subsequent RD Date');
                    return false;
                }
            }
            if (iteratedSku.subRdStartDate) {
                if (iteratedSku.subRdPrice !== 0 && !iteratedSku.subRdPrice) {
                    Notification('error', 'Please Fill The SubRd % or Clear the Date');
                    return false;
                }
            }
            if (!iteratedSku.subRdPrice && !iteratedSku.subRdStartDate) {
                Notification('error', rd_fields_missing_error);
                return false;
                
            }
        }
        // temporary guardrail validation for single sku edit
        if(showDrawerFor.levelType === 'Single SKU Temporary Guardrail'){
            if ((iteratedSku.tempGuardMax === 'Margin' || iteratedSku.tempGuardMax === 'Fixed') && 
            iteratedSku.tempGuardMaxPrice === null) {
                Notification('error' , max_price_valid_error);
                return false;
            }
            
            if ((iteratedSku.tempGuardMin === 'Margin' || iteratedSku.tempGuardMin === 'Fixed') && 
            iteratedSku.tempGuardMinPrice === null) {
                Notification('error' , min_price_valid_error);
                return false;
            }
            if (iteratedSku.tempGuardMax === 'Margin' && (
                iteratedSku.tempGuardMaxPrice < marginMinLimit || iteratedSku.tempGuardMaxPrice > marginMaxLimit)) {
                Notification('error' , max_margin_price_valid_error);
                return false;
            }
            if (iteratedSku.tempGuardMax === 'Fixed' && iteratedSku.tempGuardMaxPrice <= fixedMinLimit) {
                Notification('error' , fixed_price_valid_error);
                return false;
            }
    
            if (iteratedSku.tempGuardMin === 'Margin' && (
                iteratedSku.tempGuardMinPrice < marginMinLimit || iteratedSku.tempGuardMinPrice > marginMaxLimit)) {
                Notification('error' , min_margin_price_valid_error);
                return false;
            }
            if (iteratedSku.tempGuardMin === 'Fixed' && iteratedSku.tempGuardMinPrice <= fixedMinLimit) {
                Notification('error' , fixed_price_valid_error);
                return false;
            }

            //Validaton for guardrail interval
            if(!iteratedSku?.startTempGuardDate || !iteratedSku?.endTempGuardDate){
                Notification('error' , interval_error);
                return false;
            }
            // guardrail min max price validation
            let minPrice = iteratedSku.tempGuardMinPrice;
            let maxPrice = iteratedSku.tempGuardMaxPrice;

            if((iteratedSku.tempGuardMin === 'Margin' && iteratedSku.tempGuardMax === 'Margin') || 
               (iteratedSku.tempGuardMin === 'Fixed' && iteratedSku.tempGuardMax === 'Fixed')){
                if(minPrice > maxPrice){
                    Notification('error' , min_max_guardrail_error);
                    return false;
                }
            }
            if(iteratedSku.tempGuardMin === 'Margin'){
                if(iteratedSku?.costPrice){
                    minPrice = iteratedSku.costPrice / (1 - (0.01 * iteratedSku.tempGuardMinPrice));
                } else {
                    Notification('error' , unit_cost_error);
                    return false;
                }
            }
            if(iteratedSku.tempGuardMin === 'MAP'){
                if(iteratedSku?.tempMapPrice || iteratedSku?.mapPrice){
                    minPrice = getMapPriceByConfiguration(iteratedSku);
                } else {
                    Notification('error' , map_error);
                    return false;
                }
            }
            if(iteratedSku.tempGuardMin === 'MSRP'){
                if(iteratedSku?.tempMsrpPrice || iteratedSku?.listPrice){
                    minPrice = getMsrpPriceByConfiguration(iteratedSku);
                } else {
                    Notification('error' , msrp_error);
                    return false;
                }
            }
            
            if(iteratedSku.tempGuardMax === 'Margin'){
                if(iteratedSku?.costPrice){
                    maxPrice = iteratedSku.costPrice / (1 - (0.01 * iteratedSku.tempGuardMaxPrice));
                } else {
                    Notification('error' , unit_cost_error);
                    return false;
                }
            }
            if(iteratedSku.tempGuardMax === 'MAP'){
                if(iteratedSku?.tempMapPrice || iteratedSku?.mapPrice){
                    maxPrice = getMapPriceByConfiguration(iteratedSku);
                } else {
                    Notification('error' , map_error);
                    return false;
                }
            }
            if(iteratedSku.tempGuardMax === 'MSRP'){
                if(iteratedSku?.tempMsrpPrice || iteratedSku?.listPrice){
                    maxPrice = getMsrpPriceByConfiguration(iteratedSku);
                } else {
                    Notification('error' , msrp_error);
                    return false;
                }
            }

            if(minPrice > maxPrice){
                Notification('error' , min_max_guardrail_error);
                return false;
            }

        }

        return true;
    };

    //function for updating the data of table
    const updatedTableData = () => {
        setDrawerLoading(true);
        setFetchedBulkPriceConfig((prevState) => {
            const allSkuData = [...prevState];
            for (let i = 0; i < dataOfSkus.length; i++) {
                let index = allSkuData.findIndex((x) => x.skuId === dataOfSkus[i].skuId);
                if (index >= 0) {
                    allSkuData[index] = dataOfSkus[i];
                }
            }
            setSelectedRows(dataOfSkus);
            setSelectedRowKeys((prevState) => dataOfSkus.map((sku) => sku.skuId))
            return allSkuData;
        });
        //keeping track of which all sku's are edited
        setAllEditedSkus((prevState) => {
            const allEditedSkuData = [...prevState];
            for (let i = 0; i < dataOfSkus.length; i++) {
                let index = allEditedSkuData.findIndex((x) => x.skuId === dataOfSkus[i].skuId);
                if (index >= 0) {
                    allEditedSkuData[index] = dataOfSkus[i];
                } else {
                    allEditedSkuData.push(dataOfSkus[i]);
                }
            }
            return allEditedSkuData;
        });
        setDrawerLoading(false);
        setDataOfSkus(dataOfSkus);
        setShowDrawerFor('');
        setIsEditingSingleProperty(false);
    };

    //callbackFunction for editing single SKU
    const editSingleSkuData = (data) => {
        const skuIdsChanged = dataOfSkus.filter((item) => item.skuId !== data.skuId);
        setDataOfSkus([data, ...skuIdsChanged]);
        setFetchedBulkPriceConfig((prevState) => {
            const allSkuData = [...prevState];
            let index = allSkuData.findIndex((x) => x.skuId === data.skuId);
            if (index >= 0) {
                allSkuData[index] = data;
            }
            return allSkuData;
        });

        //keeping track of single edited SKU data edited
        setAllEditedSkus((prevState) => {
            const allEditedSkuData = [...prevState];

            let index = allEditedSkuData.findIndex((x) => x.skuId === data.skuId);
            if (index >= 0) {
                allEditedSkuData[index] = data;
            } else {
                allEditedSkuData.push(data);
            }

            return allEditedSkuData;
        });
    };

    //handling the search Input
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };
    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                if (searchText === '') {
                    setFetchedBulkPriceConfig(selectedSkuList);
                    getSelectedSkuPriceConfigDataTrigger(selectedSkuList)
                } else {
                    const searchResult = selectedSkuList.filter((item) =>
                        item.skuId.includes(searchText)
                    );
                    setFetchedBulkPriceConfig(searchResult);
                    getSelectedSkuPriceConfigDataTrigger(searchResult);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onSearchMasterSKUHandler = async (searchText) => {
        const skuList = await getEligibleSKUsForMaster(searchText);
        setFilteredSKUs(skuList);
    };

    //Helper for setting state
    const onOpenChangeHandler = (field, open) => {
        if (open) {
            if (field === 'map') setMapCalendarDate([null, null]);
            else if (field === 'msrp') setMsrpCalendarDate([null, null]);
        } else {
            if (field === 'map') setMapCalendarDate(null);
            else if (field === 'msrp') setMsrpCalendarDate(null);
        }
    };

    //function which triggers to check if the data is valid or not
    const onClickCheckValidations = (record) => {
        let validationFlag = true;
        dataOfSkus.forEach((item) => {
            if (item.skuId === record.skuId) {
                const valid = validationSingleSkuEdit(item);
                if (valid === false) {
                    validationFlag = false;
                }
            }
        });

        if (validationFlag) {
            updatedTableData();
        }
    };

    // function to get records on page change
    const handlePageChange = async (event) => {
        const pageIndexSkipper = (event - 1) * 10;
        const endingIndex =
            event === Math.ceil(fetchedBulkPriceConfig.length / 10)? pageIndexSkipper + (fetchedBulkPriceConfig.length % 10 !== 0? fetchedBulkPriceConfig.length % 10: 10) : pageIndexSkipper + 10;
        let perPageSkus = [];
        for (
            let i = pageIndexSkipper + 1;
            i <= (fetchedBulkPriceConfig.length < 10 ? fetchedBulkPriceConfig.length : endingIndex);
            i++
        ) {
            perPageSkus.push(fetchedBulkPriceConfig[i - 1]);
        }
        if(uploadedCsvData.length>0){
            perPageSkus= fetchedBulkPriceConfig;
        }
        setPagesAlreadyVisited((prevState) => {
            prevState.add(event);
            return prevState;
        });
    };
    

    const findEditedSkuIdData = (showDrawerFor) => {
        return dataOfSkus.filter(
            (item) => item.skuId === showDrawerFor.skuId)[0]
    }
    // A FUCNTION WHICH TRIGGERS IF USER CLICKS CLEAR GUARDRAIL
    const clearGuardrailConfigurations = () => {
        // clearing values of temporary guardrails first from dataOfSkus
        let newData;
        setDataOfSkus(() => {
            const emptytempData = {
                tempGuardMin: '',
                tempGuardMinPrice: null,
                tempGuardMax: '',
                tempGuardMaxPrice: null,
                startTempGuardDate: null,
                endTempGuardDate: null
                }
            if(isEditingSingleProperty){
                const editedSku = findEditedSkuIdData(showDrawerFor)
                newData = dataOfSkus.map((sku) => {
                    if(sku.skuId === editedSku.skuId){
                        return {
                            ...sku,
                            ...emptytempData
                        }
                    }
                    else{
                        return sku
                    }
                })
            }
            else{
                newData = dataOfSkus.map((sku) => {
                    return {
                        ...sku,
                        ...emptytempData
                    }
                })
            }
            return newData
        })
        
        setFetchedBulkPriceConfig((prevState) => {
            const allSkuData = [...prevState];
            for (let i = 0; i < newData.length; i++) {
                let index = allSkuData.findIndex((x) => x.skuId === dataOfSkus[i].skuId);
                if (index >= 0) {
                    allSkuData[index] = newData[i];
                }
            }
            return allSkuData;
        });
        //keeping track of which all sku's are edited
        setAllEditedSkus((prevState) => {
            const allEditedSkuData = [...prevState];
            for (let i = 0; i < newData.length; i++) {
                let index = allEditedSkuData.findIndex((x) => x.skuId === dataOfSkus[i].skuId);
                if (index >= 0) {
                    allEditedSkuData[index] = newData[i];
                } else {
                    allEditedSkuData.push(newData[i]);
                }
            }

            return allEditedSkuData;
        });
        setDataOfSkus(newData);
        setShowDrawerFor('');
        setIsEditingSingleProperty(false);
    }

    const removeSkusCallback = (skuIds) => {
        const updatedSelectedSkusList = fetchedBulkPriceConfig.filter((sku) => skuIds.indexOf(sku.skuId) === -1);
            dispatch(
                SelectedSkuActions.setSelectedSkuData({
                    selectedSkuData: updatedSelectedSkusList
                })
            );
            dispatch(
                SelectedSkuActions.setSelectedSkuCount({
                    selectedSkuCount: updatedSelectedSkusList.length
                })
            );
            setFetchedBulkPriceConfig(updatedSelectedSkusList);
            setAllEditedSkus((prevState) => prevState.filter((sku) => skuIds.indexOf(sku.skuId) === -1));
            setSelectedRows((prevState) => prevState.filter((sku) => skuIds.indexOf(sku.skuId) === -1));
            setSelectedRowKeys((prevState) => prevState.filter((skuId) => skuIds.indexOf(skuId) === -1));
    }
    
    return (
        <ContentWrapper>
            <div className="bulkConfig-header">
                <div className="bulkConfig-title">Configure SKU</div>
                <div className="btn-group">
                    <Button className="resetAll-btn" id='configureSku-restlAll-btn' onClick={handleResetBtnClick}>
                        Reset All
                    </Button>
                    <Modal
                        title="Reset All"
                        open={openResetAllModal}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="cancel" id='configureSku-cancel-btn' onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="reset" type="primary" id='configureSku-modal-resetAll' onClick={handleConfirmReset}>
                                Reset All
                            </Button>
                        ]}>
                        {' '}
                        Are you sure you want to reset the current selected SKUs?
                    </Modal>
                    {/* <Button>Next-configure SKUs</Button>      */}

                    <Button className='back-btn' id='configureSku-back-btn' onClick={handleBackBtnClick}>
                        Back - Select SKUs
                    </Button>
                    <Modal
                        title="Back - Selected SKUs"
                        open={openBackModal}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="cancel" id='configureSku-modal-cancel' onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="reset" type="primary" id='configureSku-modal-confirm' onClick={handleConfirmBack}>
                                Confirm
                            </Button>
                        ]}>
                        {' '}
                        Going back to selected SKUs will Discard the Configurations. Are you Sure
                        you want to Go back?
                    </Modal>
                    <Button
                        type="primary"
                        className="applyConfig-btn"
                        loading={loading}
                        disabled={allEditedSkus.length === 0 ? true : false}
                        id='configureSku-apply-btn'
                        onClick={() => {
                            validateGuardrailValues();
                            setReload(!reload);
                        }}>
                        Apply Configuration
                    </Button>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className="table-container">
                <div className="inlineContainer">
                    <div className="inlineContainer-item">
                        <Input
                            className="petco-Data-Search responsive-Search-Bar"
                            id='configureSku-search'
                            onKeyDown={onPressEnterKeyHandler}
                            prefix={<SearchOutlined />}
                            placeholder="Search for SKU"
                            onChange={handleSearchChange}
                            suffix={
                                <span className="press-enter-style">Press enter to search</span>
                            }
                        />
                    </div>
                    <div className="inlineContainer-item">
                        <Button type="primary" onClick={selectAll} id='configureSku-selectAll-btn'>
                            Select All
                        </Button>
                    </div>
                    {selectedSkuList?.length > 0 && <div className="inlineContainer-item">
                        <Button type="secondary" onClick={resetConfigurations} id='configureSku-restlAllConfig-btn'>
                            Reset All Configuration
                        </Button>
                    </div>}
                    <div className="inlineContainer-item">
                        <div className="updateSelectedbtn">
                            <Dropdown menu={{ items }} trigger={['click']} disabled={!hasSelected}>
                                <Button className="updateSelectedSku-btn" id='configureSku-update-btn' type="primary">
                                    Update Selected <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                        <Drawer
                            open={showDrawerFor}
                            onClose={() => {
                                setIsDefaultguardMinEdited(false);
                                setIsDefaultguardMaxEdited(false);
                                setShowDrawerFor('');
                                setDataOfSkus(selectedRows);
                                setSelectedPriceRule('');
                                setIsEditingSingleProperty(false);
                            }}
                            title={
                                isEditingSingleProperty
                                    ? `${showDrawerFor?.skuId} - ${showDrawerFor?.levelType}`
                                    : `Configure Selected ${showDrawerFor?.levelType}`
                            }
                            onCancel={handleCancel}
                            width={950}>
                            {showDrawerFor.levelType === 'EADS' && (
                                <div>
                                    <Row className="row-1">
                                        <Col span={12}>
                                            <h3 className="eads-model-heading">EADS Model</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <Checkbox
                                                className="markdownCheckbox"
                                                id='configureSku-markdownPrice'
                                                onChange={(e) => {
                                                    markdownPriceHandler(e);
                                                }}>
                                                Markdown Price
                                            </Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <Checkbox
                                                id='configureSku-basePrice'
                                                className="basePriceCheckbox"
                                                onChange={(e) => {
                                                    basePriceHandler(e);
                                                }}>
                                                Base Price
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                id='configureSku-discard-btn'
                                                className="discard-change-btn"
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                id='configureSku-update-btn'
                                                className="update-configuration-btn"
                                                key="submit"
                                                type="primary"
                                                onClick={() => updatedTableData()}
                                                loading={drawerLoading}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for when single property is edited for a single Sku} */}
                            {/* { drawer for MarkDown Price} */}
                            {showDrawerFor.levelType === 'Single SKU Markdown Price' && (
                                <div>
                                    <Row className="row-1">
                                        <Col span={12}>
                                            <h3 className="eads-model-heading">EADS Model</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <Checkbox
                                                id='configureSku-restlAll-btn'
                                                defaultChecked={showDrawerFor?.value}
                                                className="markdownCheckbox"
                                                onChange={(e) => {
                                                    markdownPriceHandler(e, showDrawerFor);
                                                }}>
                                                Markdown Price
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='configureSku-drawer-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                id='configureSku-drawer-update'
                                                key="submit"
                                                type="primary"
                                                onClick={() => updatedTableData()}
                                                loading={drawerLoading}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for Base Price} */}
                            {showDrawerFor.levelType === 'Single SKU Base Price' && (
                                <div>
                                    <Row className="row-1">
                                        <Col span={12}>
                                            <h3 className="eads-model-heading">EADS Model</h3>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={6}>
                                            <Checkbox
                                                defaultChecked={showDrawerFor?.value}
                                                id='configureSku-modal-basePrice'
                                                className="basePriceCheckbox"
                                                onChange={(e) => {
                                                    basePriceHandler(e, showDrawerFor);
                                                }}>
                                                Base Price
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='configureSku-modal-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                type="primary"
                                                id='configureSku-modal-update'
                                                onClick={() => updatedTableData()}
                                                loading={drawerLoading}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showDrawerFor.levelType === 'Price Rules' && (
                                <div>
                                    <Row style={{ justifyContent: 'space-between' }}>
                                        <Col span={7}>
                                            <h3 className="price-rule-heading-bulk">Price Rule</h3>
                                            <label>Price Rule</label>
                                            <br />
                                            <Select
                                                className="priceRuleOptions"
                                                status={
                                                    submitClicked && !dataOfSkus[0]?.priceRule
                                                        ? 'error'
                                                        : ''
                                                }
                                                placeholder="Select"
                                                onChange={(value) => {
                                                    handlePriceRuleChange(value);
                                                }}
                                                style={{
                                                    width: '100%'
                                                }}
                                                options={priceRules.map((rule) => ({
                                                    key: rule.key,
                                                    label: rule.label,
                                                    value: rule.key
                                                }))}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Col>
                                        {selectedPriceRule ===
                                            price_rule_types?.family_rule?.key && (
                                            <Col span={4}>
                                                <br />
                                                <label>Master SKU</label>
                                                <Select
                                                    showSearch
                                                    showArrow={false}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => {
                                                        const newData = dataOfSkus.map((item) => {
                                                            return {
                                                                ...item,
                                                                masterSku: value,
                                                                HasSignal: true
                                                            };
                                                        });
                                                        setDataOfSkus(newData);
                                                    }}
                                                    onSearch={onSearchMasterSKUHandler}
                                                    status={
                                                        submitClicked &&
                                                        (!dataOfSkus[0]?.masterSku ||
                                                            dataOfSkus[0]?.masterSku ===
                                                                dataOfSkus[0]?.skuId)
                                                            ? 'error'
                                                            : ''
                                                    }>
                                                    {filteredSKUs.map((sku) => (
                                                        <Select.Option key={sku} value={sku}>
                                                            {sku}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        )}
                                        {selectedPriceRule ===
                                            price_rule_types?.family_rule?.key && (
                                            <Col span={4}>
                                                <br />
                                                <label>Price Modifier</label>
                                                <Select
                                                    onChange={(value) => {
                                                        const newData = dataOfSkus.map((item) => {
                                                            return {
                                                                ...item,
                                                                familyPriceModifier: value,
                                                                HasSignal: true
                                                            };
                                                        });
                                                        setDataOfSkus(newData);
                                                    }}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    options={priceModifierArray}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    status={
                                                        submitClicked &&
                                                        !dataOfSkus[0]?.familyPriceModifier
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                        {selectedPriceRule ===
                                            price_rule_types?.family_rule?.key && (
                                            <Col span={4}>
                                                <br />
                                                <label>Price</label>
                                                <InputNumber
                                                    id='configureSku-number'
                                                    min={0}
                                                    className="bulkConfig-inputs"
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => {
                                                        const newData = dataOfSkus.map((item) => {
                                                            return {
                                                                ...item,
                                                                familyPrice: value,
                                                                HasSignal: true
                                                            };
                                                        });
                                                        setDataOfSkus(newData);
                                                    }}
                                                    status={
                                                        submitClicked && !dataOfSkus[0]?.familyPrice
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                        {selectedPriceRule ===
                                            price_rule_types?.family_rule?.key && (
                                            <Col span={2}>
                                                <br />
                                                <label>Unit</label>
                                                <Select
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    options={[
                                                        {
                                                            key: '$',
                                                            label: '$',
                                                            value: '$'
                                                        },
                                                        {
                                                            key: '%',
                                                            label: '%',
                                                            value: '%'
                                                        }
                                                    ]}
                                                    onChange={(value) => {
                                                        const newData = dataOfSkus.map((item) => {
                                                            return {
                                                                ...item,
                                                                familyPriceSuffix: value,
                                                                HasSignal: true
                                                            };
                                                        });
                                                        setDataOfSkus(newData);
                                                    }}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    status={
                                                        submitClicked &&
                                                        !dataOfSkus[0]?.familyPriceSuffix
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                        {(selectedPriceRule ===
                                            price_rule_types?.manual_price?.key ||
                                            selectedPriceRule ===
                                                price_rule_types?.manual_price_with_market_tracking
                                                    ?.key) && (
                                            <Col span={8}>
                                                <br />
                                                <label>Manual Price</label>
                                                <InputNumber
                                                    prefix="$"
                                                    className='bulkConfig-inputs'
                                                    id='configureSku-manual-price'
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => {
                                                        const newData = dataOfSkus.map((item) => {
                                                            return {
                                                                ...item,
                                                                manualPrice: value,
                                                                HasSignal: true
                                                            };
                                                        });
                                                        setDataOfSkus(newData);
                                                    }}
                                                    status={
                                                        submitClicked && !dataOfSkus[0]?.manualPrice
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                        {(selectedPriceRule ===
                                            price_rule_types?.manual_price?.key ||
                                            selectedPriceRule ===
                                                price_rule_types?.manual_price_with_market_tracking
                                                    ?.key) && (
                                            <Col span={8}>
                                                <br />
                                                <label>Type</label>
                                                <Select
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    onChange={(value) => {
                                                        const newData = dataOfSkus.map((item) => {
                                                            return {
                                                                ...item,
                                                                manualPriceReason: value,
                                                                HasSignal: true
                                                            };
                                                        });
                                                        setDataOfSkus(newData);
                                                    }}
                                                    options={manualPriceTypes}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    status={
                                                        submitClicked &&
                                                        !dataOfSkus[0]?.manualPriceReason
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='configureSku-footer-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setSelectedPriceRule('');
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='configureSku-footer-update'
                                                type="primary"
                                                onClick={() => {
                                                    const valid = validateData();
                                                    if (valid) {
                                                        updatedTableData();
                                                    }
                                                }}
                                                loading={drawerLoading}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for single SKU Price Rule} */}
                            {showDrawerFor.levelType === 'Single SKU Price Rule' && (
                                <div>
                                    <Row style={{ justifyContent: 'space-between' }}>
                                        <Col span={7}>
                                            <h3 className="price-rule-heading-bulk">Price Rule</h3>
                                            <label>Price Rule</label>
                                            <br />
                                            <Select
                                                className="priceRuleOptions"
                                                status={
                                                    submitClicked && !dataOfSkus[0]?.priceRule
                                                        ? 'error'
                                                        : ''
                                                }
                                                placeholder="Select"
                                                onChange={(value) => {
                                                    handlePriceRuleChange(value);
                                                }}
                                                style={{
                                                    width: '100%'
                                                }}
                                                options={priceRules.map((rule) => ({
                                                    key: rule.key,
                                                    label: rule.label,
                                                    value: rule.key
                                                }))}
                                                defaultValue={showDrawerFor.value[0]}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Col>
                                        {selectedPriceRule ===
                                            price_rule_types?.family_rule?.key && (
                                            <Col span={4}>
                                                <br />
                                                <label>Master SKU</label>
                                                <Select
                                                    showSearch
                                                    showArrow={false}
                                                    defaultValue={showDrawerFor.record.masterSku}
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => {
                                                        if (isEditingSingleProperty === false) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        masterSku: value,
                                                                        HasSignal: true
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        } else if (
                                                            isEditingSingleProperty === true
                                                        ) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    if (
                                                                        item.skuId ===
                                                                        showDrawerFor.skuId
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            masterSku: value,
                                                                            HasSignal: true
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                }
                                                            );

                                                            setDataOfSkus(newData);
                                                        }
                                                    }}
                                                    onSearch={onSearchMasterSKUHandler}
                                                    status={
                                                        submitClicked &&
                                                        (!dataOfSkus[0]?.masterSku ||
                                                            dataOfSkus[0]?.masterSku ===
                                                                dataOfSkus[0]?.skuId)
                                                            ? 'error'
                                                            : ''
                                                    }>
                                                    {filteredSKUs.map((sku) => (
                                                        <Select.Option key={sku} value={sku}>
                                                            {sku}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        )}
                                        {selectedPriceRule ===
                                            price_rule_types?.family_rule?.key && (
                                            <Col span={4}>
                                                <br />
                                                <label>Price Modifier</label>
                                                <Select
                                                    onChange={(value) => {
                                                        if (isEditingSingleProperty === false) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        familyPriceModifier: value,
                                                                        HasSignal: true
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        } else if (
                                                            isEditingSingleProperty === true
                                                        ) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    if (
                                                                        item.skuId ===
                                                                        showDrawerFor.skuId
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            familyPriceModifier:
                                                                                value,
                                                                            HasSignal: true
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                }
                                                            );

                                                            setDataOfSkus(newData);
                                                        }
                                                    }}
                                                    defaultValue={
                                                        showDrawerFor.record.familyPriceModifier ===
                                                        '+'
                                                            ? priceModifierArray[1].label
                                                            : (showDrawerFor.record.familyPriceModifier === '-'? priceModifierArray[0].label : '')
                                                    }
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    options={priceModifierArray}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    status={
                                                        submitClicked &&
                                                        !dataOfSkus[0]?.familyPriceModifier
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                        {selectedPriceRule ===
                                            price_rule_types?.family_rule?.key && (
                                            <Col span={4}>
                                                <br />
                                                <label>Price</label>
                                                <InputNumber
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    id='configureSku-Price'
                                                    defaultValue={showDrawerFor.record.familyPrice}
                                                    onChange={(value) => {
                                                        if (isEditingSingleProperty === false) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        familyPrice: value,
                                                                        HasSignal: true
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        } else if (
                                                            isEditingSingleProperty === true
                                                        ) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    if (
                                                                        item.skuId ===
                                                                        showDrawerFor.skuId
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            familyPrice: value,
                                                                            HasSignal: true
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                }
                                                            );

                                                            setDataOfSkus(newData);
                                                        }
                                                    }}
                                                    status={
                                                        submitClicked && !dataOfSkus[0]?.familyPrice
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                        {selectedPriceRule ===
                                            price_rule_types?.family_rule?.key && (
                                            <Col span={2}>
                                                <br />
                                                <label>Unit</label>
                                                <Select
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    options={[
                                                        {
                                                            key: '$',
                                                            label: '$',
                                                            value: '$'
                                                        },
                                                        {
                                                            key: '%',
                                                            label: '%',
                                                            value: '%'
                                                        }
                                                    ]}
                                                    defaultValue={
                                                        showDrawerFor.record.familyPriceSuffix
                                                    }
                                                    onChange={(value) => {
                                                        if (isEditingSingleProperty === false) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        familyPriceSuffix: value,
                                                                        HasSignal: true
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        } else if (
                                                            isEditingSingleProperty === true
                                                        ) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    if (
                                                                        item.skuId ===
                                                                        showDrawerFor.skuId
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            familyPriceSuffix:
                                                                                value,
                                                                            HasSignal: true
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                }
                                                            );

                                                            setDataOfSkus(newData);
                                                        }
                                                    }}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    status={
                                                        submitClicked &&
                                                        !dataOfSkus[0]?.familyPriceSuffix
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                        {(selectedPriceRule ===
                                            price_rule_types?.manual_price?.key ||
                                            selectedPriceRule ===
                                                price_rule_types?.manual_price_with_market_tracking
                                                    ?.key) && (
                                            <Col span={8}>
                                                <br />
                                                <label>Manual Price</label>
                                                <InputNumber
                                                    prefix="$"
                                                    className='bulkConfig-inputs'
                                                    id='configureSku-manualPrice'
                                                    min={0}
                                                    style={{ width: '100%' }}
                                                    defaultValue={
                                                        showDrawerFor?.value[1]
                                                            ? showDrawerFor.value[1]
                                                            : null
                                                    }
                                                    onChange={(value) => {
                                                        if (isEditingSingleProperty === false) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        manualPrice: value,
                                                                        HasSignal: true
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        } else if (
                                                            isEditingSingleProperty === true
                                                        ) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    if (
                                                                        item.skuId ===
                                                                        showDrawerFor.skuId
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            manualPrice: value,
                                                                            HasSignal: true
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                }
                                                            );

                                                            setDataOfSkus(newData);
                                                        }
                                                    }}
                                                    status={
                                                        submitClicked && !dataOfSkus[0]?.manualPrice
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                        {(selectedPriceRule ===
                                            price_rule_types?.manual_price?.key ||
                                            selectedPriceRule ===
                                                price_rule_types?.manual_price_with_market_tracking
                                                    ?.key) && (
                                            <Col span={8}>
                                                <br />
                                                <label>Type</label>
                                                <Select
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    defaultValue={
                                                        showDrawerFor.value[2]
                                                            ? showDrawerFor.value[2]
                                                            : null
                                                    }
                                                    onChange={(value) => {
                                                        if (isEditingSingleProperty === false) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        manualPriceReason: value,
                                                                        HasSignal: true
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        } else if (
                                                            isEditingSingleProperty === true
                                                        ) {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    if (
                                                                        item.skuId ===
                                                                        showDrawerFor.skuId
                                                                    ) {
                                                                        return {
                                                                            ...item,
                                                                            manualPriceReason:
                                                                                value,
                                                                            HasSignal: true
                                                                        };
                                                                    } else {
                                                                        return item;
                                                                    }
                                                                }
                                                            );

                                                            setDataOfSkus(newData);
                                                        }
                                                    }}
                                                    options={manualPriceTypes}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    status={
                                                        submitClicked &&
                                                        !dataOfSkus[0]?.manualPriceReason
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='configureSku-bulk-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setSelectedPriceRule('');
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='configureSku-bulk-update'
                                                type="primary"
                                                onClick={() => {
                                                    onClickCheckValidations(showDrawerFor.record);
                                                }}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for Bulk SKU MAP Price & Schedule} */}
                            {showDrawerFor.levelType === 'MAP Schedule' && (
                                    <div>
                                        <Row>
                                            <Col span={20}>
                                                <Row className="row-map">
                                                    <Col span={9}>
                                                        <label>MAP</label>
                                                        <InputNumber
                                                            min={0}
                                                            id='map-number'
                                                            prefix="$"
                                                            style={{ width: '90%' }}
                                                            onChange={(value) => {
                                                                const newData = dataOfSkus.map(
                                                                    (item) => {
                                                                        return {
                                                                            ...item,
                                                                            tempMapPrice: value
                                                                        };
                                                                    }
                                                                );
                                                                setDataOfSkus(newData);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col span={15}>
                                                        <label>Schedule MAP</label>
                                                        <br/>
                                                        <RangePicker
                                                            id='map-date'
                                                            placement="topLeft"
                                                            onCalendarChange={e=> setMapCalendarDate(e)}
                                                            onOpenChange={e=> {onOpenChangeHandler('map', e)}}
                                                            onChange={(value,dateString) => {
                                                                const newData = dataOfSkus.map((item) => {
                                                                        return {
                                                                            ...item,
                                                                            mapStartDate: dateString[0],
                                                                            mapEndDate: dateString[1]
                                                                        };
                                                                    }
                                                                );
                                                                setDataOfSkus(newData);
                                                            }}
                                                            disabledDate={current => dateSkipper(current, 14, mapCalendarDate)}
                                                            value={mapCalendarDate || [
                                                                dataOfSkus[0]?.mapStartDate ? dayjs(dataOfSkus[0]?.mapStartDate) : null,
                                                                dataOfSkus[0]?.mapEndDate ? dayjs(dataOfSkus[0]?.mapEndDate) : null
                                                            ]}
                                                        />
                                                    </Col>
                                                </Row>
                                            <br />
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                id='map-discard'
                                                className="discard-change-btn"
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='map-update'
                                                type="primary"
                                                onClick={() => {
                                                    const valid = validateData();
                                                    if (valid) {
                                                        updatedTableData();
                                                    }
                                                }}
                                                loading={drawerLoading}
                                                >
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showDrawerFor.levelType === 'MSRP Schedule' && (
                                <div>
                                    <Row>
                                        <Col span={20}>
                                            <Row className="row-msrp">
                                                <Col span={9}>
                                                    <label>MSRP Price</label>
                                                    <InputNumber
                                                        id='msrp-price'
                                                        min={0}
                                                        prefix="$"
                                                        style={{ width: '90%' }}
                                                        onChange={(value) => {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        tempMsrpPrice: value
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={15}>
                                                    <label>Schedule MSRP</label>
                                                    <br/>
                                                    <RangePicker
                                                        id='msrp-date'
                                                        placement="topLeft"
                                                        onCalendarChange={e=> setMsrpCalendarDate(e)}
                                                        onOpenChange={e=> {onOpenChangeHandler('msrp', e)}}
                                                        onChange={(value,dateString) => {
                                                            const newData = dataOfSkus.map((item) => {
                                                                    return {
                                                                        ...item,
                                                                        msrpStartDate: dateString[0],
                                                                        msrpEndDate: dateString[1]
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        }}
                                                        disabledDate={current => dateSkipper(current, 14, msrpCalendarDate)}
                                                        value={msrpCalendarDate || [
                                                                dataOfSkus[0]?.msrpStartDate ? dayjs(dataOfSkus[0]?.msrpStartDate) : null,
                                                                dataOfSkus[0]?.msrpEndDate ? dayjs(dataOfSkus[0]?.msrpEndDate) : null
                                                            ]}
                                                        />
                                                </Col>
                                            </Row>
                                            <br />
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                id='msrp-discard'
                                                className="discard-change-btn"
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='msrp-update'
                                                type="primary"
                                                onClick={() => {
                                                    const valid = validateData();
                                                    if (valid) {
                                                        updatedTableData();
                                                    }
                                                }}
                                                loading={drawerLoading}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {showDrawerFor.levelType === 'SubRd Schedule' && (
                                <div>
                                    <Row>
                                        <Col span={20}>
                                            <Row className="row-msrp">
                                                <Col span={9}>
                                                    <label>SubRd Price</label>
                                                    <InputNumber
                                                        id='subRd-price'
                                                        min={subRdMinLimit}
                                                        max={subRdMaxLimit}
                                                        prefix="%"
                                                        style={{ width: '90%' }}
                                                        onChange={(value) => {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        subRdPrice: value
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={15}>
                                                    <label>Schedule SubRd</label>
                                                    <br />
                                                    <DatePicker
                                                        id='subRd-date'
                                                        placement="topLeft"
                                                        className='rd-date-picker'
                                                        onChange={(value,dateString) => {
                                                            const newData = dataOfSkus.map(
                                                                (item) => {
                                                                    return {
                                                                        ...item,
                                                                        subRdStartDate: dateString
                                                                    };
                                                                }
                                                            );
                                                            setDataOfSkus(newData);
                                                        }}
                                                        disabledDate={(current) =>
                                                            dateSkipper(
                                                                current,
                                                                14,
                                                                msrpCalendarDate,
                                                            )
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <br />
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='subRd-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='subRd-update'
                                                type="primary"
                                                onClick={() => {
                                                    const valid = validateData();
                                                    if (valid) {
                                                        updatedTableData();
                                                    }
                                                }}
                                                loading={drawerLoading}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for Single SKU MAP Price & Schedule} */}
                            {showDrawerFor.levelType === 'Single SKU MAP Schedule' && (
                                <div>
                                    <Row className="row-1">
                                        <Col span={12}>
                                            <h3 className="map-model-heading">MAP Schedule</h3>
                                        </Col>
                                    </Row>
                                    <Row className="row-MAPhOLIDAY">
                                        <Col span={9}>
                                            <label>MAP</label>
                                            <br />
                                            <InputNumber
                                                min={0}
                                                id='single-map-price'
                                                prefix="$"
                                                style={{ width: '90%' }}
                                                defaultValue={showDrawerFor?.value[0]}
                                                onChange={(value) => {
                                                    const newData = dataOfSkus.map((item) => {
                                                        if (item.skuId === showDrawerFor.skuId) {
                                                            return {
                                                                ...item,
                                                                tempMapPrice: value,
                                                                defaultGuardMinPrice: item.defaultGuardMin === 'MAP'? getMapPriceByConfiguration(item) : item?.defaultGuardMinPrice,
                                                                defaultGuardMaxPrice: item.defaultGuardMax === 'MAP'? getMapPriceByConfiguration(item) : item?.defaultGuardMaxPrice
                                                            };
                                                        } else {
                                                            return item;
                                                        }
                                                    });

                                                    setDataOfSkus(newData);
                                                }}
                                            />
                                        </Col>
                                        <Col span={15}>
                                            <label>Schedule MAP</label>
                                            <br />

                                            <RangePicker
                                                id='single-map-date'
                                                placement="topLeft"
                                                onCalendarChange={e=> setMapCalendarDate(e)}
                                                onOpenChange={e=> onOpenChangeHandler('map', e)}
                                                onChange={(value,dateString) => {
                                                    const newData = dataOfSkus.map((item) => {
                                                        if (item.skuId === showDrawerFor.skuId) {
                                                            return {
                                                                ...item,
                                                                mapStartDate: dateString[0],
                                                                mapEndDate: dateString[1]
                                                            };
                                                        } else {
                                                            return item;
                                                        }
                                                    });
                                                    setDataOfSkus(newData);
                                                }}
                                                value={mapCalendarDate || [
                                                    currentSku.mapStartDate ? dayjs(currentSku.mapStartDate) : null,
                                                    currentSku.mapEndDate ? dayjs(currentSku.mapEndDate) : null,
                                                ]}
                                                disabledDate={current => dateSkipper(current, 14, mapCalendarDate)}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='single-map-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='single-map-update'
                                                type="primary"
                                                onClick={() => {
                                                    onClickCheckValidations(showDrawerFor.record);
                                                }}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for Single SKU MSRP Price & Schedule} */}
                            {showDrawerFor.levelType === 'Single SKU MSRP Schedule' && (
                                <div>
                                    <Row className="row-1">
                                        <Col span={12}>
                                            <h3 className="msrp-model-heading">MSRP Schedule</h3>
                                        </Col>
                                    </Row>
                                    <Row className="row-MAPhOLIDAY">
                                        <Col span={9}>
                                            <label>MSRP</label>
                                            <br />
                                            <InputNumber
                                                id='single-msrp-price'
                                                min={0}
                                                prefix="$"
                                                style={{ width: '90%' }}
                                                defaultValue={showDrawerFor?.value[0]}
                                                onChange={(value) => {
                                                    const newData = dataOfSkus.map((item) => {
                                                        if (item.skuId === showDrawerFor.skuId) {
                                                            return {
                                                                ...item,
                                                                tempMsrpPrice: value,
                                                                defaultGuardMinPrice: item.defaultGuardMin === 'MSRP'? getMsrpPriceByConfiguration(item) : item?.defaultGuardMinPrice,
                                                                defaultGuardMaxPrice: item.defaultGuardMax === 'MSRP'? getMsrpPriceByConfiguration(item) : item?.defaultGuardMaxPrice
                                                            };
                                                        } else {
                                                            return item;
                                                        }
                                                    });

                                                    setDataOfSkus(newData);
                                                }}
                                            />
                                        </Col>
                                        <Col span={15}>
                                            <label>Schedule MSRP</label>
                                            <br />
                                            <RangePicker
                                                id='single-msrp-date'
                                                placement="topLeft"
                                                onCalendarChange={e=> setMsrpCalendarDate(e)}
                                                onOpenChange={e=> onOpenChangeHandler('msrp', e)}
                                                onChange={(value,dateString) => {
                                                    const newData = dataOfSkus.map((item) => {
                                                        if (item.skuId === showDrawerFor.skuId) {
                                                            return {
                                                                ...item,
                                                                msrpStartDate: dateString[0],
                                                                msrpEndDate: dateString[1]
                                                            };
                                                        } else {
                                                            return item;
                                                        }
                                                    });
                                                    setDataOfSkus(newData);
                                                }}
                                                value={msrpCalendarDate || [
                                                    currentSku.msrpStartDate ? dayjs(currentSku.msrpStartDate) : null,
                                                    currentSku.msrpEndDate ? dayjs(currentSku.msrpEndDate) : null,
                                                ]}
                                                disabledDate={current => dateSkipper(current, 14, msrpCalendarDate)}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                id='single-msrp-discard'
                                                className="discard-change-btn"
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='single-msrp-update'
                                                type="primary"
                                                onClick={() => {
                                                    onClickCheckValidations(showDrawerFor.record);
                                                }}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for Single SKU SubRd Price & Schedule} */}
                            {showDrawerFor.levelType === 'Single SKU SubRd Schedule' && (
                                <div>
                                    <Row className="row-1">
                                        <Col span={12}>
                                            <h3 className="subrd-model-heading">SubRd Schedule</h3>
                                        </Col>
                                    </Row>
                                    <Row className="row-MAPhOLIDAY">
                                        <Col span={9}>
                                            <label>SubRd</label>
                                            <br />
                                            <InputNumber
                                                id='single-subRd-price'
                                                min={subRdMinLimit}
                                                max={subRdMaxLimit}
                                                prefix="%"
                                                style={{ width: '90%' }}
                                                defaultValue={
                                                    showDrawerFor?.value[0] || showDrawerFor?.value[0] === 0
                                                        ? showDrawerFor?.value[0]
                                                        : subRdDefault
                                                }
                                                onChange={(value) => {
                                                    const newData = dataOfSkus.map((item) => {
                                                        if (item.skuId === showDrawerFor.skuId) {
                                                            return {
                                                                ...item,
                                                                subRdPrice: value
                                                            };
                                                        } else {
                                                            return item;
                                                        }
                                                    });
                                                    setDataOfSkus(newData);
                                                }}
                                            />
                                            {showDrawerFor.record?.lastRepricePrice ? (
                                                parseFloat(showDrawerFor.record?.subRdPrice) <=
                                                    50 &&
                                                parseFloat(showDrawerFor.record?.subRdPrice) >=
                                                    0 && (findEditedSkuIdData(showDrawerFor).subRdPrice || findEditedSkuIdData(showDrawerFor).subRdPrice===0)? (
                                                    <div>
                                                        Projected RD price:{' '}
                                                        <b>
                                                            $
                                                            {(
                                                                showDrawerFor.record?.lastRepricePrice
                                                                - (dataOfSkus.find(item => item?.skuId === showDrawerFor.skuId)?.subRdPrice / 100)
                                                                * showDrawerFor.record?.lastRepricePrice
                                                            ).toFixed(2)}
                                                        </b>
                                                    </div>
                                                ) : (
                                                    <div style={{ color: 'red' }}>
                                                        {'Enter valid percentage'}{' '}
                                                    </div>
                                                )
                                            ) : (
                                                <>
                                                    {showDrawerFor.record?.subRdPrice ? (
                                                        <div
                                                            className="error-mssge"
                                                            style={{ color: 'red' }}>
                                                            {'No recommended price'}
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            )}
                                        </Col>
                                        <Col span={15}>
                                            <label>Schedule SubRd</label>
                                            <br />
                                            <DatePicker
                                                id='single-subRd-date'
                                                placement="topLeft"
                                                className="rd-date-picker"
                                                onChange={(value, dateString) => {
                                                    const newData = dataOfSkus.map((item) => {
                                                        if (item.skuId === showDrawerFor.skuId) {
                                                            return {
                                                                ...item,
                                                                subRdStartDate: dateString
                                                            };
                                                        } else {
                                                            return item;
                                                        }
                                                    });

                                                    setDataOfSkus(newData);
                                                }}
                                                defaultValue={
                                                    showDrawerFor.value[1]
                                                        ? dayjs(showDrawerFor?.value[1])
                                                        : null
                                                }
                                                disabledDate={(current) =>
                                                    dateSkipper(current, 14, mapCalendarDate)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                id='single-subRd-discard'
                                                className="discard-change-btn"
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                id='single-subRd-update'
                                                className="update-configuration-btn"
                                                key="submit"
                                                type="primary"
                                                onClick={() => {
                                                    onClickCheckValidations(showDrawerFor.record);
                                                }}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for Bulk SKU Default Guardrail} */}
                            {showDrawerFor.levelType === 'Default Guardrail' && (
                                <div>
                                    <Row className="row-guardRail">
                                        <h3 className="default-guardrail">Default Guardrail</h3>
                                    </Row>
                                    <Row className="row-3">
                                        <Col span={6}>
                                            <label>Default Min</label>
                                            <br />
                                            <Select
                                                status={
                                                    submitClicked &&
                                                    dataOfSkus[0]?.defaultGuardMin === null
                                                        ? 'error'
                                                        : ''
                                                }
                                                id='default-guardrail-dropdown'
                                                onChange={(value) => {
                                                    const suffix = value === 'Margin' ? '%' : '$';
                                                    setIsDefaultguardMinEdited(true);
                                                    const newData = dataOfSkus.map((item) => {
                                                        if (value === 'MAP') {
                                                            return {
                                                                ...item,
                                                                defaultGuardMin: value,
                                                                defaultGuardMinPrice: null,
                                                                defaultGuardMinPriceSuffix: suffix
                                                            };
                                                        } else if (value === 'MSRP') {
                                                            return {
                                                                ...item,
                                                                defaultGuardMin: value,
                                                                defaultGuardMinPrice: null,
                                                                defaultGuardMinPriceSuffix: suffix
                                                            };
                                                        } else {
                                                            return {
                                                                ...item,
                                                                defaultGuardMin: value,
                                                                defaultGuardMinPrice: null,
                                                                defaultGuardMinPriceSuffix: suffix
                                                            };
                                                        }
                                                    });
                                                    setDataOfSkus(newData);
                                                }}
                                                style={{ width: '95%' }}
                                                options={effectiveDataArray.map((data) => ({
                                                    key: data,
                                                    label: data,
                                                    value: data
                                                }))}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <label>Value</label>
                                            <br />
                                            <InputNumber
                                                id='default-guardrail-value'
                                                max={
                                                    dataOfSkus[0]?.defaultGuardMin === 'Margin'
                                                        ? marginMaxLimit
                                                        : null
                                                }
                                                min={
                                                    dataOfSkus[0]?.defaultGuardMin === 'Margin'
                                                        ? marginMinLimit
                                                        : null
                                                }
                                                step={0.1}
                                                disabled={
                                                    dataOfSkus[0].defaultGuardMin === 'MAP' ||
                                                    dataOfSkus[0].defaultGuardMin === 'MSRP'
                                                        ? true
                                                        : false
                                                }
                                                prefix={
                                                    dataOfSkus[0].defaultGuardMin === 'Margin'
                                                        ? '%'
                                                        : '$'
                                                }
                                                style={{ width: '95%' }}
                                                value={isDefaultguardMinEdited? dataOfSkus[0].defaultGuardMinPrice : null}
                                                onChange={(value) => {
                                                    setIsDefaultguardMinEdited(true)
                                                    const newData = dataOfSkus.map((item) => {
                                                        return {
                                                            ...item,
                                                            defaultGuardMinPrice: value
                                                        };
                                                    });
                                                    setDataOfSkus(newData);
                                                }}
                                            />
                                        </Col>
                                        <br />
                                        <Col span={6}>
                                            <label>Default Max</label>
                                            <br />
                                            <Select
                                                status={
                                                    submitClicked &&
                                                    dataOfSkus[0]?.defaultGuardMax === null
                                                        ? 'error'
                                                        : ''
                                                }
                                                id='default-max-dropdown'
                                                onChange={(value) => {
                                                    const suffix = value === 'Margin' ? '%' : '$';
                                                    setIsDefaultguardMaxEdited(true);
                                                    const newData = dataOfSkus.map((item) => {
                                                        if (value === 'MAP' && (item.tempMapPrice || item.mapPrice)) {
                                                            return {
                                                                ...item,
                                                                defaultGuardMax: value,
                                                                defaultGuardMaxPrice: null,
                                                                defaultGuardMaxPriceSuffix: suffix
                                                            };
                                                        } else if (
                                                            value === 'MSRP' &&
                                                            (item?.tempMsrpPrice || item?.listPrice)
                                                        ) {
                                                            return {
                                                                ...item,
                                                                defaultGuardMax: value,
                                                                defaultGuardMaxPrice: null,
                                                                defaultGuardMaxPriceSuffix: suffix
                                                            };
                                                        } else {
                                                            return {
                                                                ...item,
                                                                defaultGuardMax: value,
                                                                defaultGuardMaxPrice: null,
                                                                defaultGuardMaxPriceSuffix: suffix
                                                            };
                                                        }
                                                    });
                                                    setDataOfSkus(newData);
                                                }}
                                                style={{ width: '95%' }}
                                                options={effectiveDataArray.map((data) => ({
                                                    key: data,
                                                    label: data,
                                                    value: data
                                                }))}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Col>
                                        <Col span={5}>
                                            <label>Value</label>
                                            <br />
                                            <InputNumber
                                                id='default-guardrail-value'
                                                max={
                                                    dataOfSkus[0]?.defaultGuardMax === 'Margin'
                                                        ? marginMaxLimit
                                                        : null
                                                }
                                                min={
                                                    dataOfSkus[0]?.defaultGuardMax === 'Margin'
                                                        ? marginMinLimit
                                                        : null
                                                }
                                                step={0.1}
                                                disabled={
                                                    dataOfSkus[0].defaultGuardMax === 'MAP' ||
                                                    dataOfSkus[0].defaultGuardMax === 'MSRP'
                                                        ? true
                                                        : false
                                                }
                                                prefix={
                                                    dataOfSkus[0].defaultGuardMax === 'Margin'
                                                        ? '%'
                                                        : '$'
                                                }
                                                style={{ width: '90%' }}
                                                value={isDefaultguardMaxEdited? dataOfSkus[0].defaultGuardMaxPrice : null}
                                                onChange={(value) => {
                                                    setIsDefaultguardMaxEdited(true);
                                                    const newData = dataOfSkus.map((item) => {
                                                        return {
                                                            ...item,
                                                            defaultGuardMaxPrice: value
                                                        };
                                                    });
                                                    setDataOfSkus(newData);
                                                }}
                                            />
                                        </Col>
                                        <br />
                                    </Row>

                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='default-guardrail-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setIsDefaultguardMinEdited(false);
                                                    setIsDefaultguardMaxEdited(false);
                                                    setDataOfSkus(selectedRows);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='default-guardrail-update'
                                                type="primary"
                                                onClick={() => {
                                                    const valid = validateData();
                                                    setIsDefaultguardMinEdited(false);
                                                    setIsDefaultguardMaxEdited(false);
                                                    if (valid) {
                                                        updatedTableData();
                                                    }
                                                }}
                                                loading={drawerLoading}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for Single SKU Default Guardrail Min} */}
                            {showDrawerFor.levelType === 'Single SKU default Guardrail Min' && (
                                <div>
                                    <Row className="row-guardRail">
                                        <h3 className="default-guardrail">Default Guardrail</h3>
                                    </Row>
                                    <Row className="row-3">
                                        <Col span={8}>
                                            <label>Default Min</label>
                                            <br />
                                            <Select
                                                id='default-guardrail-default-min'
                                                status={
                                                    submitClicked &&
                                                    (() => {
                                                        const editedSkuIdData = dataOfSkus.filter(
                                                            (item) =>
                                                                item.skuId === showDrawerFor.skuId
                                                        );
                                                        if (
                                                            editedSkuIdData?.defaultGuardMin ===
                                                            null
                                                        ) {
                                                            return 'error';
                                                        } else {
                                                            return '';
                                                        }
                                                    })
                                                }
                                                defaultValue={
                                                    showDrawerFor.value[1] &&
                                                    showDrawerFor.value[1] !== 'null'
                                                        ? showDrawerFor.value[1]
                                                        : 'MAP'
                                                }
                                                onChange={(value) => {
                                                    const suffix = value === 'Margin' ? '%' : '$';
                                                    if (isEditingSingleProperty === true) {
                                                        const newData = dataOfSkus.map((item) => {
                                                            if (
                                                                item.skuId === showDrawerFor.skuId
                                                            ) {
                                                                if (
                                                                    value === 'MAP' &&
                                                                    (showDrawerFor.record.tempMapPrice || showDrawerFor.record.mapPrice)
                                                                ) {
                                                                    return {
                                                                        ...item,
                                                                        defaultGuardMin: value,
                                                                        defaultGuardMinPrice: getMapPriceByConfiguration(showDrawerFor.record),
                                                                        defaultGuardMinPriceSuffix: suffix
                                                                    };
                                                                } else if (
                                                                    value === 'MSRP' &&
                                                                    (showDrawerFor.record.tempMsrpPrice || showDrawerFor.record.listPrice)
                                                                ) {
                                                                    return {
                                                                        ...item,
                                                                        defaultGuardMin: value,
                                                                        defaultGuardMinPrice: getMsrpPriceByConfiguration(showDrawerFor.record),
                                                                        defaultGuardMinPriceSuffix: suffix
                                                                    };
                                                                } else {
                                                                    return {
                                                                        ...item,
                                                                        defaultGuardMin: value,
                                                                        defaultGuardMinPrice: null,
                                                                        defaultGuardMinPriceSuffix:
                                                                            suffix
                                                                    };
                                                                }
                                                            } else {
                                                                return item;
                                                            }
                                                        });

                                                        setDataOfSkus(newData);
                                                    }
                                                }}
                                                style={{ width: '99%' }}
                                                options={effectiveDataArray.map((data) => ({
                                                    key: data,
                                                    label: data,
                                                    value: data
                                                }))}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <label>Value</label>
                                            <br />
                                            <InputNumber
                                                id='default-value'
                                                max={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    if (
                                                        editedSkuIdData[0]?.defaultGuardMin ===
                                                        'Margin'
                                                    ) {
                                                        return marginMaxLimit;
                                                    } else {
                                                        return null;
                                                    }
                                                })()}
                                                min={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    if (
                                                        editedSkuIdData[0]?.defaultGuardMin ===
                                                        'Margin'
                                                    ) {
                                                        return marginMinLimit;
                                                    } else {
                                                        return null;
                                                    }
                                                })()}
                                                step={0.1}
                                                disabled={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    if (
                                                        editedSkuIdData[0]?.defaultGuardMin ===
                                                            'MAP' ||
                                                        editedSkuIdData[0]?.defaultGuardMin ===
                                                            'MSRP'
                                                    ) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                })()}
                                                prefix={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    if (
                                                        editedSkuIdData[0]?.defaultGuardMin ===
                                                        'Margin'
                                                    ) {
                                                        return '%';
                                                    } else {
                                                        return '$';
                                                    }
                                                })()}
                                                style={{ width: '99%' }}
                                                value={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    return editedSkuIdData[0]?.defaultGuardMin === 'MSRP'? getMsrpPriceByConfiguration(editedSkuIdData[0]) : editedSkuIdData[0]?.defaultGuardMin ===
                                                          'MAP'
                                                        ? getMapPriceByConfiguration(editedSkuIdData[0])
                                                        : editedSkuIdData[0]?.defaultGuardMinPrice !== null
                                                        ? editedSkuIdData[0]?.defaultGuardMinPrice
                                                        : '';
                                                })()}
                                                onChange={(value) => {
                                                    if (isEditingSingleProperty === true) {
                                                        const newData = dataOfSkus.map((item) => {
                                                            if (
                                                                item.skuId === showDrawerFor.skuId
                                                            ) {
                                                                return {
                                                                    ...item,
                                                                    defaultGuardMinPrice: value
                                                                };
                                                            } else {
                                                                return item;
                                                            }
                                                        });

                                                        setDataOfSkus(newData);
                                                    }
                                                }}
                                            />
                                            {(() => {
                                                const editedSkuIdData = dataOfSkus.filter(
                                                    (item) => item.skuId === showDrawerFor.skuId
                                                );
                                                return editedSkuIdData[0]?.defaultGuardMin ===
                                                    'Margin' ? (
                                                    editedSkuIdData[0].costPrice ? (
                                                        parseFloat(
                                                            editedSkuIdData[0].defaultGuardMinPrice
                                                        ) <= marginMaxLimit &&
                                                        parseFloat(
                                                            editedSkuIdData[0].defaultGuardMinPrice
                                                        ) >= marginMinLimit ? (
                                                            <div>
                                                                Projected min price:{' '}
                                                                <b>
                                                                $ {calculateMargin(editedSkuIdData[0].costPrice, editedSkuIdData[0].defaultGuardMinPrice)}
                                                                </b>
                                                            </div>
                                                        ) : (
                                                            <div style={{ color: 'red' }}>
                                                                {'Enter valid percentage'}
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div style={{ color: 'red' }}>
                                                            {unit_cost_error}
                                                        </div>
                                                    )
                                                ) : null;
                                            })()}
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='default-guardrail-min-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='default-guardrail-min-update'
                                                type="primary"
                                                onClick={() => {
                                                    onClickCheckValidations(showDrawerFor.record);
                                                }}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* { drawer for Single SKU Default Guardrail Max} */}
                            {showDrawerFor.levelType === 'Single SKU default Guardrail Max' && (
                                <div>
                                    <Row className="row-guardRail">
                                        <h3 className="default-guardrail">Default Guardrail</h3>
                                    </Row>
                                    <Row className="row-3">
                                        <Col span={8}>
                                            <label>Default Max</label>
                                            <br />
                                            <Select
                                                id='default-guardrail-max-update'
                                                status={
                                                    submitClicked &&
                                                    (() => {
                                                        const editedSkuIdData = dataOfSkus.filter(
                                                            (item) =>
                                                                item.skuId === showDrawerFor.skuId
                                                        );
                                                        if (
                                                            editedSkuIdData?.defaultGuardMax ===
                                                            null
                                                        ) {
                                                            return 'error';
                                                        } else {
                                                            return '';
                                                        }
                                                    })
                                                }
                                                defaultValue={
                                                    showDrawerFor.value[1] &&
                                                    showDrawerFor.value[1] !== 'null'
                                                        ? showDrawerFor.value[1]
                                                        : 'MSRP'
                                                }
                                                onChange={(value) => {
                                                    const suffix = value === 'Margin' ? '%' : '$';
                                                    if (isEditingSingleProperty === true) {
                                                        const newData = dataOfSkus.map((item) => {
                                                            if (
                                                                item.skuId === showDrawerFor.skuId
                                                            ) {
                                                                if (
                                                                    value === 'MAP' &&
                                                                    (showDrawerFor.record.tempMapPrice || showDrawerFor.record.mapPrice)
                                                                ) {
                                                                    return {
                                                                        ...item,
                                                                        defaultGuardMax: value,
                                                                        defaultGuardMaxPrice: getMapPriceByConfiguration(showDrawerFor.record),
                                                                        defaultGuardMaxPriceSuffix: suffix
                                                                    };
                                                                } else if (
                                                                    value === 'MSRP' &&
                                                                    (showDrawerFor.record.tempMsrpPrice || showDrawerFor.record.listPrice)
                                                                ) {
                                                                    return {
                                                                        ...item,
                                                                        defaultGuardMax: value,
                                                                        defaultGuardMaxPrice: getMsrpPriceByConfiguration(showDrawerFor.record),
                                                                        defaultGuardMaxPriceSuffix: suffix
                                                                    };
                                                                } else {
                                                                    return {
                                                                        ...item,
                                                                        defaultGuardMax: value,
                                                                        defaultGuardMaxPrice: null,
                                                                        defaultGuardMaxPriceSuffix: suffix
                                                                    };
                                                                }
                                                            } else {
                                                                return item;
                                                            }
                                                        });

                                                        setDataOfSkus(newData);
                                                    }
                                                }}
                                                style={{ width: '99%' }}
                                                options={effectiveDataArray.map((data) => ({
                                                    key: data,
                                                    label: data,
                                                    value: data
                                                }))}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children
                                                        .toLowerCase()
                                                        .indexOf(input.toLowerCase()) >= 0
                                                }
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <label>Value</label>
                                            <br />
                                            <InputNumber
                                                id='default-guardrail-max-value'
                                                max={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    if (
                                                        editedSkuIdData[0]?.defaultGuardMax ===
                                                        'Margin'
                                                    ) {
                                                        return marginMaxLimit;
                                                    } else {
                                                        return null;
                                                    }
                                                })()}
                                                min={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    if (
                                                        editedSkuIdData[0]?.defaultGuardMax ===
                                                        'Margin'
                                                    ) {
                                                        return marginMinLimit;
                                                    } else {
                                                        return null;
                                                    }
                                                })()}
                                                step={0.1}
                                                disabled={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    if (
                                                        editedSkuIdData[0]?.defaultGuardMax ===
                                                            'MAP' ||
                                                        editedSkuIdData[0]?.defaultGuardMax ===
                                                            'MSRP'
                                                    ) {
                                                        return true;
                                                    } else {
                                                        return false;
                                                    }
                                                })()}
                                                prefix={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    if (
                                                        editedSkuIdData[0]?.defaultGuardMax ===
                                                        'Margin'
                                                    ) {
                                                        return '%';
                                                    } else {
                                                        return '$';
                                                    }
                                                })()}
                                                style={{ width: '99%' }}
                                                value={(() => {
                                                    const editedSkuIdData = dataOfSkus.filter(
                                                        (item) => item.skuId === showDrawerFor.skuId
                                                    );
                                                    return editedSkuIdData[0]?.defaultGuardMax === 'MSRP'? getMsrpPriceByConfiguration(editedSkuIdData[0])
                                                        : editedSkuIdData[0]?.defaultGuardMax === 'MAP'? getMapPriceByConfiguration(editedSkuIdData[0])
                                                        : editedSkuIdData[0]?.defaultGuardMaxPrice !== null
                                                        ? editedSkuIdData[0]?.defaultGuardMaxPrice : '';
                                                })()}
                                                onChange={(value) => {
                                                    if (isEditingSingleProperty === true) {
                                                        const newData = dataOfSkus.map((item) => {
                                                            if (
                                                                item.skuId === showDrawerFor.skuId
                                                            ) {
                                                                return {
                                                                    ...item,
                                                                    defaultGuardMaxPrice: value
                                                                };
                                                            } else {
                                                                return item;
                                                            }
                                                        });

                                                        setDataOfSkus(newData);
                                                    }
                                                }}
                                            />
                                            {(() => {
                                                const editedSkuIdData = dataOfSkus.filter(
                                                    (item) => item.skuId === showDrawerFor.skuId
                                                );
                                                return editedSkuIdData[0]?.defaultGuardMax ===
                                                    'Margin' ? (
                                                    editedSkuIdData[0].costPrice ? (
                                                        parseFloat(
                                                            editedSkuIdData[0].defaultGuardMaxPrice
                                                        ) <= marginMaxLimit &&
                                                        parseFloat(
                                                            editedSkuIdData[0].defaultGuardMaxPrice
                                                        ) >= marginMinLimit ? (
                                                            <div>
                                                                Projected max price:{' '}
                                                                <b>
                                                                    $ {calculateMargin(editedSkuIdData[0].costPrice, editedSkuIdData[0].defaultGuardMaxPrice)}
                                                                </b>
                                                            </div>
                                                        ) : (
                                                            <div style={{ color: 'red' }}>
                                                                {'Enter valid percentage'}
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div style={{ color: 'red' }}>
                                                            {unit_cost_error}
                                                        </div>
                                                    )
                                                ) : null;
                                            })()}
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                className="discard-change-btn"
                                                id='default-guardrail-max-discard'
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                className="update-configuration-btn"
                                                key="submit"
                                                id='default-guardrail-max-update'
                                                type="primary"
                                                onClick={() => {
                                                    onClickCheckValidations(showDrawerFor.record);
                                                }}>
                                                {`Update ${showDrawerFor?.levelType}`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Drawer Code for Temporary Guardrail */}
                            {(showDrawerFor.levelType === 'Temporary Guardrail' || showDrawerFor.levelType === 'Single SKU Temporary Guardrail') && (
                                <div>
                                    <Row className="row-1">
                                        <Col xs={24} sm={11} className="override-temporaryValue">
                                            <Row className="override-tempMin">
                                                <Col xs={10} sm={13}>
                                                    <label>{temp_min}</label>
                                                    <Select
                                                        defaultValue={showDrawerFor.levelType === 'Temporary Guardrail'? '' : showDrawerFor.record.tempGuardMin}
                                                        id='temporaryMin-guardrail-select'
                                                        onChange={(value) => {
                                                            const suffix = value === 'Margin' ? '%' : '$';
                                                            if (isEditingSingleProperty) {
                                                                const newData = dataOfSkus.map((item) => {
                                                                    if (item.skuId === showDrawerFor.skuId) {
                                                                            if (value === 'MAP' && (showDrawerFor.record.tempMapPrice || showDrawerFor.record.mapPrice)) {
                                                                                return {
                                                                                    ...item,
                                                                                    tempGuardMin: value,
                                                                                    tempGuardMinPrice: getMapPriceByConfiguration(showDrawerFor.record),
                                                                                    tempGuardMinPriceSuffix: suffix
                                                                                };
                                                                            } else if (value === 'MSRP' && (showDrawerFor.record.tempMsrpPrice || showDrawerFor.record.listPrice)) {
                                                                                return {
                                                                                    ...item,
                                                                                    tempGuardMin: value,
                                                                                    tempGuardMinPrice: getMsrpPriceByConfiguration(showDrawerFor.record),
                                                                                    tempGuardMinPriceSuffix: suffix
                                                                                };
                                                                            } else {
                                                                                return {
                                                                                    ...item,
                                                                                    tempGuardMin: value,
                                                                                    tempGuardMinPrice: null,
                                                                                    tempGuardMinPriceSuffix: suffix
                                                                                };
                                                                            }
                                                                        } else {
                                                                            return item;
                                                                        }
                                                                    }
                                                                );

                                                                setDataOfSkus(newData);
                                                            } else {
                                                                const newData = dataOfSkus.map((item) => {
                                                                        return {
                                                                            ...item,
                                                                            tempGuardMin: value,
                                                                            tempGuardMinPrice: null,
                                                                            tempGuardMinPriceSuffix: suffix
                                                                        };
                                                                    }
                                                                );
                                                                setDataOfSkus(newData);
                                                            }
                                                        }}
                                                        // value={fetchedPriceRuleData?.defaultGuardMin}
                                                        style={{
                                                            width: 'clamp(100px,100%,135px)'
                                                        }}
                                                        options={guardrailOptions}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.props.children
                                                                .toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    />
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col xs={8} sm={10}>
                                                <div className='tempGuardInput'>
                                                    <label>{value}</label>
                                                    <br />
                                                    <InputNumber
                                                        id='temporaryMin-guardrail-value'
                                                        max={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : dataOfSkus[0];
                                                            if (editedSkuIdData?.tempGuardMin === 'Margin') {
                                                                return marginMaxLimit;
                                                            } else {
                                                                return null;
                                                            }
                                                        })()}
                                                        min={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : dataOfSkus[0];
                                                            if (editedSkuIdData?.tempGuardMin === 'Margin'
                                                            ) {
                                                                return marginMinLimit;
                                                            } else {
                                                                return null;
                                                            }
                                                        })()}
                                                        step={0.1}
                                                        disabled={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : dataOfSkus[0];
                                                            if (editedSkuIdData?.tempGuardMin ==='MAP' || editedSkuIdData?.tempGuardMin === 'MSRP') {
                                                                return true;
                                                            } else {
                                                                return false;
                                                            }
                                                        })()}
                                                        prefix={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : dataOfSkus[0];
                                                            if (editedSkuIdData?.tempGuardMin ==='Margin') {
                                                                return '%';
                                                            } else {
                                                                return '$';
                                                            }
                                                        })()}
                                                        value={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : null;
                                                            if (editedSkuIdData) {
                                                                return editedSkuIdData?.tempGuardMin ==='MSRP'? (getMsrpPriceByConfiguration(editedSkuIdData)) : editedSkuIdData?.tempGuardMin ==='MAP'? (getMapPriceByConfiguration(editedSkuIdData)) : (editedSkuIdData?.tempGuardMinPrice || editedSkuIdData?.tempGuardMinPrice===0)? editedSkuIdData?.tempGuardMinPrice : '';
                                                            } else {
                                                                return dataOfSkus[0].tempGuardMinPrice;
                                                            }
                                                        })()}
                                                        style={{ width: 'clamp(100px,100%,135px)' }}
                                                        onChange={(value) => {
                                                            if (isEditingSingleProperty) {
                                                                const newData = dataOfSkus.map((item) => {
                                                                        if (item.skuId === showDrawerFor.skuId) {
                                                                            return {
                                                                                ...item,
                                                                                tempGuardMinPrice: value
                                                                            };
                                                                        } else {
                                                                            return item;
                                                                        }
                                                                    }
                                                                );

                                                                setDataOfSkus(newData);
                                                            } else {
                                                                const newData = dataOfSkus.map(
                                                                    (item) => {
                                                                        return {
                                                                            ...item,
                                                                            tempGuardMinPrice: value
                                                                        };
                                                                    }
                                                                );
                                                                setDataOfSkus(newData);
                                                            }
                                                        }}
                                                    />
                                                    {isEditingSingleProperty && 
                                                    (findEditedSkuIdData(showDrawerFor).tempGuardMin === 'Margin' ? 
                                                    (findEditedSkuIdData(showDrawerFor).costPrice ? 
                                                    (parseFloat(findEditedSkuIdData(showDrawerFor).tempGuardMinPrice) <= marginMaxLimit && 
                                                    parseFloat(findEditedSkuIdData(showDrawerFor).tempGuardMinPrice) >= marginMinLimit ? (
                                                                    <div>
                                                                        {project_min_price}
                                                                        <b>
                                                                            $
                                                                            {(findEditedSkuIdData(showDrawerFor)?.costPrice /(1 - 0.01 * findEditedSkuIdData(showDrawerFor).tempGuardMinPrice)).toFixed(2)}
                                                                        </b>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{ color: 'red' }}>
                                                                        {margin_warning}
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <div style={{ color: 'red' }}>
                                                                    {unit_cost_error}
                                                                </div>
                                                            )
                                                        ) : null)}
                                                    </div>    
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row className="override-tempMax">
                                                <Col xs={10} sm={13}>
                                                    <label>{temp_max}</label>
                                                    <Select
                                                        id='temporaryMax-guardrail-select'
                                                        defaultValue={showDrawerFor.levelType ==='Temporary Guardrail'? '': showDrawerFor.record.tempGuardMax}
                                                        onChange={(value) => {
                                                            const suffix =
                                                                value === 'Margin' ? '%' : '$';
                                                            if (isEditingSingleProperty === true) {
                                                                const newData = dataOfSkus.map((item) => {
                                                                        if (item.skuId === showDrawerFor.skuId) {
                                                                            if (value === 'MAP' && (showDrawerFor.record.tempMapPrice || showDrawerFor.record.mapPrice)) {
                                                                                return {
                                                                                    ...item,
                                                                                    tempGuardMax: value,
                                                                                    tempGuardMaxPrice: (getMapPriceByConfiguration(showDrawerFor.record)),
                                                                                    tempGuardMaxPriceSuffix: suffix
                                                                                };
                                                                            } else if (value === 'MSRP' && (showDrawerFor.record.tempMsrpPrice || showDrawerFor.record.listPrice)) {
                                                                                return {
                                                                                    ...item,
                                                                                    tempGuardMax: value,
                                                                                    tempGuardMaxPrice: (getMsrpPriceByConfiguration(showDrawerFor.record)),
                                                                                    tempGuardMaxPriceSuffix: suffix
                                                                                };
                                                                            } else {
                                                                                return {
                                                                                    ...item,
                                                                                    tempGuardMax: value,
                                                                                    tempGuardMaxPrice: null,
                                                                                    tempGuardMaxPriceSuffix: suffix
                                                                                };
                                                                            }
                                                                        } else {
                                                                            return item;
                                                                        }
                                                                    }
                                                                );

                                                                setDataOfSkus(newData);
                                                            } else if (showDrawerFor.levelType === 'Temporary Guardrail') {
                                                                const newData = dataOfSkus.map((item) => {
                                                                        return {
                                                                            ...item,
                                                                            tempGuardMax: value,
                                                                            tempGuardMaxPrice: null,
                                                                            tempGuardMaxPriceSuffix:suffix
                                                                        };
                                                                    }
                                                                );
                                                                setDataOfSkus(newData);
                                                            }
                                                        }}
                                                        style={{
                                                            width: 'clamp(100px,100%,135px)'
                                                        }}
                                                        options={guardrailOptions}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    />
                                                </Col>
                                                <Col span={1}></Col>
                                                <Col xs={8} sm={10}>
                                                <div className='tempGuardInput'>
                                                    <label>{value}</label>
                                                    <br />
                                                    <InputNumber
                                                        id='temporaryMax-guardrail-value'
                                                        max={(() => {
                                                            const editedSkuIdData =isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : dataOfSkus[0];
                                                            if (editedSkuIdData?.tempGuardMax === 'Margin') {
                                                                return marginMaxLimit;
                                                            } else {
                                                                return null;
                                                            }
                                                        })()}
                                                        min={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : dataOfSkus[0];
                                                            if (editedSkuIdData?.tempGuardMax === 'Margin'
                                                            ) {
                                                                return marginMinLimit;
                                                            } else {
                                                                return null;
                                                            }
                                                        })()}
                                                        step={0.1}
                                                        disabled={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : dataOfSkus[0];
                                                            if (editedSkuIdData?.tempGuardMax ==='MAP' || editedSkuIdData?.tempGuardMax ==='MSRP') {
                                                                return true;
                                                            } else {
                                                                return false;
                                                            }
                                                        })()}
                                                        prefix={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : dataOfSkus[0];
                                                            if (editedSkuIdData?.tempGuardMax === 'Margin') {
                                                                return '%';
                                                            } else {
                                                                return '$';
                                                            }
                                                        })()}
                                                        value={(() => {
                                                            const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : null;
                                                            if (editedSkuIdData) {
                                                                return editedSkuIdData?.tempGuardMax ==='MSRP'? (getMsrpPriceByConfiguration(editedSkuIdData)) : editedSkuIdData?.tempGuardMax ==='MAP'? (getMapPriceByConfiguration(editedSkuIdData)) : (editedSkuIdData?.tempGuardMaxPrice || editedSkuIdData?.tempGuardMaxPrice===0)? editedSkuIdData?.tempGuardMaxPrice : '';
                                                            } else {
                                                                return dataOfSkus[0].tempGuardMaxPrice;
                                                            }
                                                        })()}
                                                        style={{ width: 'clamp(100px,100%,135px)' }}
                                                        onChange={(value) => {
                                                            if (isEditingSingleProperty) {
                                                                const newData = dataOfSkus.map((item) => {
                                                                        if (item.skuId ===showDrawerFor.skuId) {
                                                                            return {
                                                                                ...item,
                                                                                tempGuardMaxPrice: value
                                                                            };
                                                                        } else {
                                                                            return item;
                                                                        }
                                                                    }
                                                                );

                                                                setDataOfSkus(newData);
                                                            } else {
                                                                const newData = dataOfSkus.map(
                                                                    (item) => {
                                                                        return {
                                                                            ...item,
                                                                            tempGuardMaxPrice: value
                                                                        };
                                                                    }
                                                                );
                                                                setDataOfSkus(newData);
                                                            }
                                                        }}
                                                    />
                                                    {isEditingSingleProperty && 
                                                    (findEditedSkuIdData(showDrawerFor).tempGuardMax === 'Margin' ? 
                                                    (findEditedSkuIdData(showDrawerFor).costPrice ? 
                                                    (parseFloat(findEditedSkuIdData(showDrawerFor).tempGuardMaxPrice) <= marginMaxLimit && 
                                                    parseFloat(findEditedSkuIdData(showDrawerFor).tempGuardMaxPrice) >= marginMinLimit ? (
                                                                    <div>
                                                                        {project_min_price}
                                                                        <b>$
                                                                            {(findEditedSkuIdData(showDrawerFor)?.costPrice /(1 - 0.01 * findEditedSkuIdData(showDrawerFor).tempGuardMaxPrice)).toFixed(2)}
                                                                        </b>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{ color: 'red' }}>
                                                                        {margin_warning}
                                                                    </div>
                                                                )
                                                            ) : (
                                                                <div style={{ color: 'red' }}>
                                                                    {unit_cost_error}
                                                                </div>
                                                            )
                                                        ) : null)}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col xs={24} sm={11} className="override-Date">
                                          <div className='tempGuardDate'>
                                            <label>{interval_text}</label>
                                            <br />
                                            <RangePicker
                                                id='temporary-guardrail-date'
                                                defaultValue={(() => {const editedSkuIdData = isEditingSingleProperty? findEditedSkuIdData(showDrawerFor) : null;
                                                    if (editedSkuIdData) {
                                                        return [
                                                            showDrawerFor.record.startTempGuardDate? dayjs(showDrawerFor.record.startTempGuardDate) : null,
                                                            showDrawerFor.record.endTempGuardDate? dayjs(showDrawerFor.record.endTempGuardDate) : null
                                                        ];
                                                    } else {
                                                        return null;
                                                    }
                                                })()}
                                                placement="topLeft"
                                                onChange={(value, dateString) => {
                                                    let newData;
                                                    if (isEditingSingleProperty) {
                                                        newData = dataOfSkus.map((item) => {
                                                            if (item.skuId === showDrawerFor.skuId) {
                                                                return {
                                                                    ...item,
                                                                    startTempGuardDate: dateString[0],
                                                                    endTempGuardDate: dateString[1]
                                                                };
                                                            } else {
                                                                return item;
                                                            }
                                                        });
                                                    } else {
                                                        newData = dataOfSkus.map((item) => {
                                                            return {
                                                                ...item,
                                                                startTempGuardDate: dateString[0],
                                                                endTempGuardDate: dateString[1]
                                                            };
                                                        });
                                                    }
                                                    setDataOfSkus(newData);
                                                }}
                                                style={{ width: '60%' }}
                                                disabledDate={(current) => dateSkipper(current, 14, mapCalendarDate)}
                                            />
                                          </div>
                                        </Col>
                                    </Row>
                                    <div className="price-configure-modal-footer">
                                        <div className="button-container">
                                            <Button
                                                id='discard-tempGuardrail-btn'
                                                className="discard-change-btn"
                                                onClick={() => {
                                                    setShowDrawerFor('');
                                                    setDataOfSkus(selectedRows);
                                                    setIsEditingSingleProperty(false);
                                                }}>
                                                Discard Change
                                            </Button>
                                            <Button
                                                 id='clear-tempGuardrail-btn'
                                                className="discard-change-btn"
                                                onClick={() => {
                                                    clearGuardrailConfigurations();
                                                }}>
                                               Clear Guardrail
                                            </Button>
                                            <Button
                                                 id='update-tempGuardrail-btn'
                                                className="update-configuration-btn"
                                                key="submit"
                                                type="primary"
                                                onClick={() => {
                                                    if(!isEditingSingleProperty){
                                                        if (validateData()) {
                                                        updatedTableData();
                                                        }
                                                    } else onClickCheckValidations(showDrawerFor.record)
                                                    // onClickCheckValidations(showDrawerFor.record);
                                                }}>
                                                {`Apply Temporary Guardrail`}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Drawer>
                    </div>
                    {selectedRowKeys.length ? (
                        <div
                            id='skuConfigure-clearSelection'
                            className="inlineContainer-item"
                            style={{
                                marginBottom: 16
                            }}>
                            <Button
                                type="secondary"
                                onClick={clearSelection}
                                disabled={!hasSelected}>
                                Clear Selection
                            </Button>
                            <span
                                style={{
                                    marginLeft: 10
                                }}>
                                {`Selected ${selectedRows.length} items`}
                            </span>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <Table
                    rowKey="skuId"
                    loading={loading}
                    rowSelection={rowSelection}
                    onRow={(row, rowIndex) => {
                        return {
                            onClick: (event) => {
                                onRowClick(row);
                            }
                        };
                    }}
                    dataSource={fetchedBulkPriceConfig}
                    columns={configureSkuColumns}
                    pagination={
                        fetchedBulkPriceConfig.length > 10
                            ? {
                                  position: ['', 'bottomCenter'],
                                  showSizeChanger: false,
                                  onChange: handlePageChange,
                              }
                            : false
                    }
                    scroll={{ x: 2000 }}
                    size="middle"
                    className="table-striped-rows clickable-table"
                />
            </div>
            <Modal
                title={viewInfomodaldata && viewInfomodaldata.skuId ? viewInfomodaldata.skuId : ''}
                key={viewInfomodaldata.skuId}
                open={skuInfoSummaryModal}
                onCancel={handleCancel}
                width={1400}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Close
                    </Button>
                ]}>
                <SkuViewBox loading={loading} imageUrl={imageUrl} defImage={defImage} viewInfomodaldata={viewInfomodaldata} skuDetails={skuDetails}/>
            </Modal>
            <Drawer
                open={configDataModal}
                destroyOnClose={true}
                onClose={() => setConfigDataModal(false)}
                title={`${editInfomodaldata.skuId} - SKU Configuration`}
                onCancel={handleCancel}
                width={dataConfigDrawerWidth}
                footer={[]}>
                <Spin spinning={loading}>
                    <PriceConfigForBulk
                        levelProps={{
                            configId: editInfomodaldata.skuId,
                            configdesc: editInfomodaldata.skuDesc,
                            configLevel: TAB_NAME.CONFIG_SKU,
                            handleOk: handleConfigDataUpdate,
                            priceConfigData: {
                                ...editInfomodaldata
                            },
                            callbackForEditedData: editSingleSkuData
                        }}
                        priceDetails={skuDetails}
                        parentName={TAB_NAME.SKU}
                        handleCancel={handleCancel}
                        setStagedDataSku={setStagedDataSku}
                        editedSkuState={editedSkuState}
                        skudetails={skuDetails}
                        isMaster={skuDetails?.isMaster}
                        handleOk={handleConfigDataUpdate}
                    />
                </Spin>
            </Drawer>
            <Modal
                title="Error"
                open={showModalForError}
                onCancel={handleCancel}
                width={700}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Close
                    </Button>
                ]}>
                <Result
                    status="error"
                    title="Selected SKU Error"
                    subTitle="Please deselect the Master SKU records before proceeding to the Bulk Price Rule Config">
                    <div className="MasterSkus">
                        <Paragraph>
                            <Text
                                strong
                                style={{
                                    fontSize: 16
                                }}>
                                The records you selected have following Master SKUs
                            </Text>
                        </Paragraph>
                        {isSelectedSkuMaster.map((item) => {
                            return (
                                <Paragraph>
                                    <CloseCircleOutlined className="site-result-demo-error-icon" />{' '}
                                    {item.skuId}
                                </Paragraph>
                            );
                        })}
                    </div>
                </Result>
            </Modal>
            {/* <Tabs animated defaultActiveKey={tabindex} items={items} onChange={onTabChange} /> */}
             <Drawer
                open={showErrorDrawer}
                destroyOnClose={true}
                title={"SKU Errors"}
                width={dataConfigDrawerWidth}
                footer={[]}
                onClose={() => setShowErrorDrawer(false)}>
                <Spin spinning={loading}>
                    <ErrorSkuList
                        errorSkusList={errorSkus}
                        removeSkusCallback={removeSkusCallback}
                        editSkuCallback={sku => {
                            handleSkuConfigEdit(fetchedBulkPriceConfig.find(item => item.skuId === sku));
                            setShowErrorDrawer(false);
                        }}
                        closeDrawerCallback={() => setShowErrorDrawer(false)}
                    />
                </Spin>
            </Drawer>

        </ContentWrapper>
    );
};

export default ConfigureSkus;
