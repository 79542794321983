import { Badge, Button, Tabs, Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import BulkUpload from '../../components/BulkUpload/BulkUpload';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import BulkConfigure from '../../components/Tables/BulkConfigure.table';
import SelectedSku from '../../components/Tables/SelectedSku.table';
import { getAllAssignedCountData, getAllCountData } from '../../graphql/queryExecuters';
import { AllCountActions } from '../../redux/slices/allCount.slice';
import { SelectedSkuActions } from '../../redux/slices/selectedSkus.slice';
import { Notification } from '../../utilities';
import './BulkConfig.scss';
import { merchantAccess, userTypes } from '../../constants/common';
import {count_list_error, sku_upload_success} from "../../constants/displayStrings";

const BulkConfig = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //getting all count of skus
    const { allCountList, publishedSkuCount } = useSelector((state) => state.allCount);
    //keeping track of which all sku has been selected to keep track of selected sku count
    const { selectedSkuCount } = useSelector((state) => state.selectedSku);
    const { userType, editAccess, id } = useSelector(state => state.auth);
    //state for capturing the uploaded csv data and passing to next component
    const [uploadedCsvData,setUploadedCsvData] = useState([]);
    // helper for checking which tab is currently user is on 
    const [tabindex, setTabindex] = useState(props.tabindex);
    // local state variable for keeping track of selected rows
    const [selectedData, setSelectedData] = useState([]);

    //Helper for open drawer
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => {
        if (allCountList.length === 0) {
            getAllCountTrigger();
        }
    }, [tabindex, userType]); //eslint-disable-line

    //Redirecting user if not a valid user
    useEffect(() => {
        if (userType === userTypes.MERCHANT && editAccess === merchantAccess.VIEW) {
            navigate('/');
        }
    }, [userType]) //eslint-disable-line

    const onTabChange = (key) => {
        setTabindex(key);
        if (key === '1') {
            navigate('/bulk_Configurations/allSku');
        } else if (key === '2') {
            navigate('/bulk_Configurations/selectedSku');
        }
    };
   
    const getAllCountTrigger = async () => {
        try {
            let allCountData = null;
            if(userType) {
                if (userType === userTypes.MERCHANT) {
                    allCountData = await getAllAssignedCountData(id);
                } else {
                    allCountData = await getAllCountData();
                }
                if (allCountData) {
                    dispatch(AllCountActions.setAllCountData({ allCountData: allCountData }));
                }
            }
        } catch (err) {
            console.log(err);
            Notification('error', count_list_error);
        }
    };
    // a callback function which will trigger when data is passed from child component to parent --> here from  selected SKU tab/component
    const callbackfunction = (selected) => {
        setSelectedData(selected);

    };
    // a callback to sync the removed sku from selected sku tab
    const removeAllCallback = () => {
        setSelectedData([]);
    };
    const removeSelectedCallback = (remainingSelectedCount) => {
        setSelectedData(remainingSelectedCount);
    };
    let newProps = {
        level: 'bulk config',
        id: '',
        count: publishedSkuCount,
        callback: callbackfunction,
        selectedData: selectedData
    };

    let selectedDataProps = {
        level: 'selected config',
        id: '',
        selectedData: selectedData,
        removeAllCallback: removeAllCallback,
        tabIndexChanged: tabindex,
        removeSelectedCallback: removeSelectedCallback
    };
    //navigation to next component on click of next-config button
    const handleNextBtn = () => {
        navigate('/bulk_Configurations/selectedSku/configSku',{state: {uploadedCsvData}});
    };

    const items = [
        {
            label: (
                <span id='bulk-config-allSku-tab'>
                    <span style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}>
                        All SKU{' '}
                    </span>
                    <Badge
                        className="sku-count-badge"
                        showZero
                        count={publishedSkuCount}
                        overflowCount={99999999}
                        style={{
                            marginLeft: 10,
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: <BulkConfigure {...newProps} />
        },
        {
            label: (
                <span id='bulk-config-selected-tab'>
                    <span style={{ color: tabindex === '2' ? '#001952' : '#96999F' }}>
                        Selected SKU{' '}
                    </span>
                    <Badge
                        className="sku-count-badge"
                        showZero
                        count={selectedSkuCount}
                        overflowCount={99999999}
                        style={{
                            marginLeft: 10,
                            backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                            color: tabindex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '2',
            children: <SelectedSku {...selectedDataProps} />
        }
    ];

    //Upload CSV drawer trigger
    const onClickUploadCSVHandler = () => {
        setOpenDrawer(true);
    };
    // a callback function to capture the correct uploaded csv data and use it accordingly
    const onSubmitDataHandler = (data, csvData) => {
        Notification('success', sku_upload_success)
        const skusWithoutError = csvData.filter(skuData => skuData.errors.length === 0);
        const filteredData = data.filter((item) => (skusWithoutError.map((sku) => sku.skuId)).includes(item.skuId))
        const uploadedKeys = filteredData.map((item) => item.skuId);
        setUploadedCsvData(skusWithoutError);
        dispatch(SelectedSkuActions.setSelectedSkuData({ selectedSkuData: filteredData }));
        dispatch(SelectedSkuActions.setSelectedRowKeysData({ selectedRowKeysData: uploadedKeys }));
        dispatch(SelectedSkuActions.setSelectedSkuCount({ selectedSkuCount: uploadedKeys.length }));
        setOpenDrawer(false);
        setTabindex('2')
        navigate('/bulk_Configurations/selectedSku');
    };

    return (
        <ContentWrapper>
            <div className="bulk-upload-header">
                <div className="bulkConfig-title">Bulk Configuration</div>
                <div className="upload-actions">
                    <Button className="uploadCsv-btn" id='bulk-config-upload-btn' onClick={onClickUploadCSVHandler}>
                        Upload CSV
                    </Button>
                    <Button
                        onClick={handleNextBtn}
                        id='bulk-config-next-btn'
                        type="primary"
                        className="next-btn"
                        disabled={selectedSkuCount === 0 ? true : false}>
                        Next-configure SKUs
                    </Button>
                </div>
            </div>
            <br />
            <div className="bulkConfigTabs">
                <Tabs animated defaultActiveKey={tabindex} items={items} onChange={onTabChange} activeKey={tabindex} />
            </div>
            <Drawer
                title="Upload CSV"
                open={openDrawer}
                onClose={() => {
                    setOpenDrawer(false);
                }}
                width={850}>
                <BulkUpload
                    onClose={() => {
                        setOpenDrawer(false);
                    }}
                    onSubmit={onSubmitDataHandler}
                />
            </Drawer>
        </ContentWrapper>
    );
};

export default BulkConfig;
