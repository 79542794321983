import { createSlice } from '@reduxjs/toolkit';
import { merchantAccessTypes } from '../../constants/common';

const initialState = {
    id: null,
    name: '',
    email: '',
    userType: '',
    accessRole: '',
    createdBy: '',
    editAccess: '',
    accessToken: ''
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        //Setting Auth state
        setAuth(state, action) {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.userType = action.payload.userType;
            state.accessRole = action.payload.accessRole;
            state.createdBy = action.payload.createdBy;
            state.editAccess = action.payload.editAccess ? action.payload.editAccess : merchantAccessTypes[0].value;
            state.accessToken = action.payload.accessToken;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const AuthActions = authSlice.actions;
export const AuthReducer = authSlice.reducer;
export default authSlice;
