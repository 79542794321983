import React, { useEffect, useState } from 'react';
import { Select, Checkbox, InputNumber, Space } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import './CompetitorConfig.form.scss';
import { corpLabel } from '../../constants/common';
import { competitors, adder, scraper_priority, incart, pdp } from '../../constants/displayStrings';

export const CompetitorConfig = (props) => {
    const adderOptions = ['Add (%)', 'Add ($)', 'Subtract (%)', 'Subtract ($)'];
    const [activeCompetitor, setActiveCompetitor] = useState(props.activeCompetitor); //eslint-disable-line
    const [activeScraper, setActiveScraper] = useState([]); //eslint-disable-line
    
    const [competitorData, setCompetitorData] = useState({
        competitor: props.data.competitor,
        adderText: props.data.adderText,
        adderValue: props.data.adderValue,
        priority: props.data.priority,
        incart: props.data.incart,
        pdp: props.data.pdp
    });

    useEffect(() => {
        setActiveScraper(props.activeScraper)
        setCompetitorData({
            competitor: props.data.competitor,
            adderText: props.data.adderText,
            adderValue: props.data.adderValue,
            priority: props.data.priority,
            incart: props.data.incart,
            pdp: props.data.pdp
        });
    }, [props]); //eslint-disable-line

    const updateCompetitorData = (value, index) => {
        let data = {
            competitor: index === 1 ? value : competitorData.competitor,
            adderText: index === 2 ? value : competitorData.adderText,
            adderValue: index === 3 ? value ? value : 0 : competitorData.adderValue,
            priority: index === 4 ? value : competitorData.priority,
            incart: index === 5 ? value : competitorData.incart,
            pdp: index === 6 ? value : competitorData.pdp
        };
        setCompetitorData(data);
        props.updateData(data, props.index);
    }
    
    return (
        <div className="reScrape-form" key={props.index}>
            <div className='flex-row'>
                <div className="row-item-config" id="competitor-item">
                    <Space direction="vertical" style={{width: "inherit"}}>
                    <label className="comp-config-label">{competitors}</label>
                    <Select
                        placeholder="Select"
                        value={corpLabel[competitorData.competitor]}
                        style={{width: "100%"}}
                        options={(activeCompetitor)?.map((item) => ({
                            value: item,
                            label: corpLabel[item]
                        }))}
                        onChange={(value) => updateCompetitorData(value, 1)}
                        status={props?.submitClicked && competitorData.competitor === "" ? "error": ""}
                    />
                    </Space>
                </div>
                <div className="row-item-config" id="adder-item">
                    <Space direction="vertical" style={{width: "min(180px, 100%)"}}>
                        <label className="comp-config-label">{adder}</label>
                        <div className="adder-container">
                            <Select
                                placeholder="Select"
                                value={competitorData.adderText}
                                className="adder"
                                options={adderOptions?.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                onChange={(value) => updateCompetitorData(value, 2)}
                                status={props?.submitClicked && competitorData.adderText === "" ? "error": ""}
                            />
                            <InputNumber 
                                value={competitorData.adderValue} 
                                min={0} 
                                className="adder-value"
                                onChange={(value) => updateCompetitorData(value, 3)}
                                controls = {false}
                            />
                        </div>
                    </Space>
                </div>
                <div className="row-item-config" id="scraper-item">
                    <Space direction="vertical" style={{width: "inherit"}}> 
                        <label className="comp-config-label">{scraper_priority}</label>
                        <Select
                            placeholder="Select"
                            value={corpLabel[competitorData.priority]}
                            style={{width: "100%"}}
                            options={(activeScraper)?.map((item) => ({
                                value: item,
                                label: corpLabel[item]
                            }))}
                            onChange={(value) => updateCompetitorData(value, 4)}
                            status={competitorData.priority === "" ? "error": ""}
                        />
                    </Space>
                </div>
                <div className="row-item-config">
                    <Checkbox 
                        style={{ marginTop: '33px', marginLeft: '4px' }} 
                        checked={competitorData.incart} 
                        onChange={(e) => updateCompetitorData(e.target.checked, 5)}>{incart}
                    </Checkbox>           
                </div>
                <div className="row-item-config">
                    <Checkbox 
                        style={{ marginTop: '33px', marginLeft: '4px' }} 
                        checked={competitorData.pdp} 
                        onChange={(e) => updateCompetitorData(e.target.checked, 6)}>{pdp}
                    </Checkbox>
                </div>
                <div className="row-item-config">
                    <CloseOutlined style={{ marginTop: '36px', marginLeft: '4px' }} onClick={() => props.handleDelete(props.index)}/>
                </div>
            </div>
        </div>
    );
};

export default CompetitorConfig;
