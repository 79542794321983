import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tabs, Modal, Input, Select, Badge, Breadcrumb } from 'antd';
import dayjs from 'dayjs';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ScraperDataTable from '../../components/Tables/ScraperData.table';

import './CompetitorDetails.scss';
import { Notification } from '../../utilities';
import {
    getCompetitorById,
    updateCompetitorDetails
} from '../../graphql/queryExecuters/competitors.executer';
import { CompetitorsActions } from '../../redux/slices/competitors.slice';
import { ScrapersActions } from '../../redux/slices/scrapers.slice';
import { getAllCompetitors, getAllScrapers } from '../../graphql/queryExecuters';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { userTypes } from '../../constants/common';
import {
    adder_update_error,
    adder_value_error,
    default_adder_update_success, proper_adder_error,
    update_failed_generic
} from "../../constants/displayStrings";
import { getCompetitorsBaseData } from '../../redux/actionCreators/competitors.actions';

const CompetitorDetails = () => {
    const adderNullState = {
        perValueAdder: null,
        perValueSubtracted: null,
        valueAdder: null,
        valueSubtracted: null
    };

    const settingsFieldsSQLMatch = [
        { field: 'perValueAdder', match: 'per_value_adder' },
        { field: 'perValueSubtracted', match: 'per_value_subtracted' },
        { field: 'valueAdder', match: 'value_adder' },
        { field: 'valueSubtracted', match: 'value_subtracted' }
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { competitor_id } = useParams();
    const { competitors, competitorsBaseDetails } = useSelector((state) => state.competitors);
    const { userType } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState('1');
    const [competitorDetails, setCompetitorDetails] = useState(null);
    const [showDefaultAdderModal, setShowDefaultAdderModal] = useState(false);
    const [currentDefaultAdder, setCurrentDefaultAdder] = useState('');
    const [currentDefaultAdderValue, setCurrentDefaultAdderValue] = useState(0);

    //Competitor Base Details
    const [competitorBaseData, setCompetitorBaseData] = useState({});

    useEffect(() => {
        getCompetitorDetails(competitor_id);
    }, [competitor_id]); //eslint-disable-line

    //Getting base details
    useEffect(() => {
        const compData = competitorsBaseDetails.find(data => data.competitorId === competitor_id);
        if (compData) {
            setCompetitorBaseData(compData);
        }
    }, [competitorsBaseDetails, competitor_id])

    //Redirecting user if not a valid user
    useEffect(() => {
        if (userType === userTypes.MERCHANT) {
            navigate('/');
        }
    }, [userType]) //eslint-disable-line

    const getCompetitorDetails = async (competitorId) => {
        try {
            setLoading(true);

            let competitorsList = [];
            if (competitors?.length === 0) {
                const competitorsData = await getAllCompetitors();
                if (competitorsData) {
                    dispatch(CompetitorsActions.setCompetitors({ competitors: competitorsData.data }));
                    competitorsList = competitorsData.data;
                }
                const scrapersData = await getAllScrapers();
                if (scrapersData) {
                    dispatch(ScrapersActions.setScrapers({ scrapers: scrapersData.data }));
                    const activeScraper = scrapersData.data.filter((scrap) => scrap.status);
                    if (activeScraper) {
                        dispatch(ScrapersActions.setActiveScrapers({ activeScrapers: activeScraper }));
                    }
                }
            } else {
                competitorsList = [...competitors];
            }
            const competitorData = competitorsList.find(
                (competitor) => competitor.compId === competitorId
            );
            if (competitorData) {
                setCompetitorDetails({ ...competitorData });
            } else {
                const competitor = await getCompetitorById(competitorId);
                setCompetitorDetails({ ...competitor });
                dispatch(CompetitorsActions.setCompetitors({ competitors: [...competitorsList, competitor] }));
            }

            dispatch(getCompetitorsBaseData());
            
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const getDefaultAdder = (competitor) => {
        let defaultSetting = '';
        if (competitorDetails.valueAdder) {
            defaultSetting = 'valueAdder';
            return defaultSetting;
        }
        if (competitorDetails.valueSubtracted) {
            defaultSetting = 'valueSubtracted';
            return defaultSetting;
        }
        if (competitorDetails.perValueAdder) {
            defaultSetting = 'perValueAdder';
            return defaultSetting;
        }
        if (competitorDetails.perValueSubtracted) {
            defaultSetting = 'perValueSubtracted';
            return defaultSetting;
        }
        return defaultSetting;
    };

    //Getting current default adder value
    const onClickShowSettingsModalHandler = () => {
        const defaultAdder = getDefaultAdder(competitorDetails);
        setCurrentDefaultAdder(defaultAdder);
        setCurrentDefaultAdderValue(competitorDetails[defaultAdder]);
        setShowDefaultAdderModal(true);
    };

    const onChangeValueHandler = (field, value) => {
        if (field === 'adder') {
            setCurrentDefaultAdder(value);
        }
        if (field === 'adderValue') {
            if (value >= 0) {
                setCurrentDefaultAdderValue(value);
            }
        }
    };

    //Initiator for update default adder
    const onClickUpdateDefaultAdderHandler = async () => {
        try {
            setLoading(true);
            if (currentDefaultAdderValue >= 0) {
                if (currentDefaultAdder) {
                    const adderValues = { ...adderNullState };
                    adderValues[currentDefaultAdder] = currentDefaultAdderValue;
                    //Creating Fields to update array
                    const fieldsToUpdate = settingsFieldsSQLMatch.map((sqlMatch) => {
                        return { field: sqlMatch.match, value: adderValues[sqlMatch.field] };
                    });
                    const response = await updateCompetitorDetails(
                        competitorDetails.compId,
                        fieldsToUpdate
                    );
                    if (response.data.status === 1) {
                        setShowDefaultAdderModal(false);
                        const index = competitors.findIndex(
                            (comp) => comp.compId === competitorDetails.compId
                        );
                        if (index >= 0) {
                            const newCompetitorsList = competitors.map((competitor) =>
                                Object.assign({}, competitor)
                            );
                            const newCompDetails = { ...newCompetitorsList[index], ...adderValues };
                            newCompetitorsList[index] = newCompDetails;
                            dispatch(
                                CompetitorsActions.setCompetitors({
                                    competitors: newCompetitorsList
                                })
                            );
                            setCompetitorDetails(newCompDetails);
                        }
                        Notification('success', default_adder_update_success);
                    } else {
                        Notification('error', update_failed_generic);
                    }
                } else {
                    Notification('warning', proper_adder_error);
                }
            } else {
                Notification('warning', adder_value_error);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err);
            Notification('error', adder_update_error);
        }
    };

    const props = {
        level: "competitor",
        name: competitor_id
    };

    const tabs = [
        {
            label: (
                <label id='scraped-data-comp'>
                    <span style={{ color: tabIndex === '1' ? '#001952' : '#96999F' }}>
                        Scraped Data{' '}
                    </span>
                    <Badge
                        showZero
                        overflowCount={10000000}
                        count={competitorBaseData.totalRecords ? competitorBaseData.totalRecords : 0}
                        style={{
                            backgroundColor: tabIndex === '1' ? '#001952' : '#EBF1FF',
                            color: tabIndex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </label>
            ),
            key: '1',
            children: (
                <ScraperDataTable {...props}/>
            )
        }
    ];

    return (
        <>
            <ContentWrapper>
                <div className="competitor-details-container">
                    <div className="competitor-base-details">
                        <div className="breadcrumn-container">
                            <Breadcrumb 
                            items ={[
                                {
                                    title:(
                                        <div style={{ display: 'inline-flex'}}>
                                        <div style={{color: '#001952', cursor:'pointer'}} onClick={() => {navigate(-1);}}>
                                            <ArrowLeftOutlined/>
                                        </div>
                                        <div style={{color: '#96999F', marginLeft: 10}}>Competitors</div>
                                    </div>

                                    )
                                },
                                {
                                    title:competitorDetails?.name,
                                    style:{color: '#001952'}
                                }
                            ]}
                                />
                           
                        </div>
                        <div className="name-and-actions">
                            <div className="name-container">{competitorDetails?.name}</div>
                            {userType === userTypes.ADMIN && <div className="actions-container">
                                <Button
                                    type="primary"
                                    id='comp-details-set-adder'
                                    onClick={onClickShowSettingsModalHandler}>
                                    Set Default Adder
                                </Button>
                            </div>}
                        </div>
                        <div className="detail-container">
                            <div className="detail last-scraped-date">
                                <div className="key">Last Scraped Date</div>
                                <div className="value">
                                    {competitorBaseData?.lastScrapedDate
                                        ? dayjs.tz(competitorBaseData?.lastScrapedDate).format(
                                                'MM/DD/YYYY HH:mm:ss'
                                            )
                                        : '-'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">Default Adder</div>
                                <div className="value">
                                    {competitorDetails?.valueAdder
                                        ? `+${competitorDetails?.valueAdder.toFixed(2)} $`
                                        : competitorDetails?.valueSubtracted
                                        ? `-${competitorDetails?.valueSubtracted.toFixed(2)} $`
                                        : competitorDetails?.perValueAdder
                                        ? `+${competitorDetails?.perValueAdder.toFixed(2)} %`
                                        : competitorDetails?.perValueSubtracted
                                        ? ` -${competitorDetails?.perValueSubtracted.toFixed(
                                                2
                                            )} %`
                                        : '-'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">Scrapers Linked</div>
                                <div className="value">
                                    {competitorDetails?.scrapers
                                        ? competitorDetails?.scrapers.split(',').length
                                        : '-'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">Total SKUs Scraped</div>
                                <div className="value">{competitorBaseData?.skuScraped ? competitorBaseData?.skuScraped : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="competitor-table-container">
                        <Tabs
                            defaultActiveKey={tabIndex}
                            items={tabs}
                            animated
                            onChange={(index) => {
                                setTabIndex(index);
                            }}
                        />
                    </div>
                </div>
            </ContentWrapper>
            <Modal
                open={showDefaultAdderModal}
                title="Set Default Adder"
                footer={null}
                onCancel={() => {
                    setShowDefaultAdderModal(false);
                }}>
                <div className="set-default-adder-container">
                    <div className="title">Adder</div>
                    <div className="input">
                        <Input.Group compact>
                            <Select
                                className="adder"
                                id='comp-details-adder'
                                placeholder="Select Default Adder"
                                value={currentDefaultAdder}
                                onChange={(e) => {
                                    onChangeValueHandler('adder', e);
                                }}>
                                <Select.Option key={'valueAdder'}>Add ($)</Select.Option>
                                <Select.Option key={'valueSubtracted'}>Subtract ($)</Select.Option>
                                <Select.Option key={'perValueAdder'}>Add (%)</Select.Option>
                                <Select.Option key={'perValueSubtracted'}>
                                    Subtract (%)
                                </Select.Option>
                            </Select>
                            <Input
                                id='comp-details-adder-value'
                                onChange={(e) => {
                                    onChangeValueHandler('adderValue', Number(e.target.value));
                                }}
                                type="number"
                                className="adder-value"
                                value={currentDefaultAdderValue}
                            />
                        </Input.Group>
                    </div>
                    <div className="actions">
                        <Button
                            type="default"
                            id='comp-details-close-btn'
                            onClick={() => {
                                setShowDefaultAdderModal(false);
                            }}>
                            Close
                        </Button>
                        <Button id='comp-details-apply-btn' type="primary" onClick={onClickUpdateDefaultAdderHandler} loading={loading}>
                            Apply Changes
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CompetitorDetails;
