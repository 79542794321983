import GraphQL from '../../configs/graphQL.config';

import * as QueryCreators from '../queryCreators';

export const getAllDeptData = async (index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllDeptDataQuery(index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllDepts;
    } catch (err) {
        console.log(err);
    }
};
export const getDeptDetails = async (deptId, deptDesc) => {
    try {
        const query = QueryCreators.createGetDeptDetailsQuery(deptId, deptDesc);
        const response = await GraphQL.request(query);
        return response.getLevelDetails;
    } catch (err) {
        console.log(err);
    }
};

export const getDeptAtSbuData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetDeptAtSbuDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllDeptsAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getDeptAtShopData = async (id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetDeptAtShopDataQuery(id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllDeptsAtShop;
    } catch (err) {
        console.log(err);
    }
};

export const getAllAssignDeptData = async (merchantId, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAllAssignDeptDataQuery(merchantId, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignDepts;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignDeptAtSbuData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignDeptAtSbuDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignDeptsAtSbu;
    } catch (err) {
        console.log(err);
    }
};

export const getAssignDeptAtShopData = async (merchantId, id, index, size, search, sortType) => {
    try {
        const query = QueryCreators.createGetAssignDeptAtShopDataQuery(merchantId, id, index, size, search, sortType);
        const response = await GraphQL.request(query);
        return response.getAllAssignDeptsAtShop;
    } catch (err) {
        console.log(err);
    }
};