import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    alertsCount: 0
};

const alertsCountSlice = createSlice({
    name: 'alertsCount',
    initialState,
    reducers: {
        setAlertsCountData(state, { payload }) {
            state.alertsCount = payload.alertsCountData;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const AlertsCountActions = alertsCountSlice.actions;
export const AlertsCountReducer = alertsCountSlice.reducer;
export default alertsCountSlice;
