import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'antd';

import Override from '../Forms/Override.form';
import './OverrideModal.scss';
import './CompTableReScrapeModal.scss';
import { merchantAccess, userTypes } from '../../constants/common';

const OverrideModal = ({levelProps, callback}) => {

    const [overrideModal, setOverrrideModal] = useState(false);

    const { userType, editAccess } = useSelector(state => state.auth);

    const handleOverrideCancel = () => {
        setOverrrideModal(false);
    };
    const handleOverrrideModal = () => {
        setOverrrideModal(true);
        
    };
    const handleOverrrideGuardrailbtn = async () => {
        setOverrrideModal(false);
        await callback();
    };
    return (
        <>
            <div className="sbu-temporaryGuardrail-btn">
                {!(userType === userTypes.MERCHANT && editAccess === merchantAccess.VIEW) && <Button
                    id='overide-btn'
                    className="sbu-temporaryGuardrail-override-btn"
                    onClick={handleOverrrideModal}>
                    Override
                </Button>}
            </div>
            <Modal
                open={overrideModal}
                title="Temporary Guardrail Override"
                onCancel={handleOverrideCancel}
                className="override-modal"
                width={650}
                footer={[]}
                >
                <Override levelProps={{
                        ...levelProps,
                        handleCancel: handleOverrideCancel,
                        handleOk: handleOverrrideGuardrailbtn,
                    }} />
            </Modal>
        </>
    );
};

export default OverrideModal;
