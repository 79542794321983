import { gql } from 'graphql-request';
import { prepareString } from '../../utilities';



export const createGetLevelAlertsQuery = (skuId, index, size, sortType = 'DESC') => {
    try {
        return gql`
            query {
                getAllAlertsBySku(skuId: "${skuId}", index: ${index}, size: ${size}, sortType: "${sortType}"){
                    dataCount
                    data {
                        id
                        alertName
                        alertDetails
                        createdAt
                        subscribedBy
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetUserAlertsQuery = (userId, index, size, searchText = '', sortType = 'DESC') => {
    try {
        return gql`
            query {
                getAllAlertsByUser(userId: "${userId}", index: ${index}, size: ${size}, search: ${prepareString(searchText)}, sortType: "${sortType}"){
                    dataCount
                    data {
                        id
                        skuId
                        alertName
                        alertDetails
                        createdAt
                        subscribedBy
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetAllAlertsQuery = (userId, index, size, searchText = '', sortType = 'DESC') => {
    try {
        return gql`
            query {
                getAllAlerts(index: ${index}, size: ${size}, search: ${prepareString(searchText)}, sortType: "${sortType}", userId: "${userId}"){
                    dataCount
                    data {
                        id
                        skuId
                        alertName
                        alertDetails
                        createdAt
                        subscribedBy
                    }
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createGetLiveAlertsCountQuery = () => {
    try {
        return gql`
            query {
                getUnreadAlerts{
                    count
                }
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUpdateLiveAlertsCountQuery = () => {
    try {
        return gql`
            query {
                markUnreadAlert
            }
        `;
    } catch (err) {
        console.log(err);
    }
};

export const createUnmatchTicketQuery = (skuId, ticketDetails) => {
    try {
        return gql`
            mutation { createUnmatchTicket (skuId: "${skuId}", ticketData: {ticketDetails: ${prepareString(prepareString(ticketDetails).replace('"', '\"'))}}) { message } } `; //eslint-disable-line
    } catch (err) {
        console.log(err);
    }
};