import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input, Pagination, Checkbox, Spin, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Notification, encodeString } from '../../utilities';
import { brandsTableColumns } from '../../constants/tableColumns';
import {
    getAllBrandsData,
    getBrandAtSbuData,
    getBrandAtShopData,
    getBrandAtDeptData,
    getBrandAtClassData,
    getBrandAtSubclassData,
    getBrandAtManufData,
    getAllAssignBrandData,
    getAssignBrandAtSbuData,
    getAssignBrandAtShopData,
    getAssignBrandAtDeptData,
    getAssignBrandAtClassData,
    getAssignBrandAtSubclassData,
    getAssignBrandAtManufData
} from '../../graphql/queryExecuters';
import { BrandsActions } from '../../redux/slices/brands.slice';
import './Tables.scss';

const BrandsTable = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const urlParamsObj = new URLSearchParams(location.search);
    const urlParams = urlParamsObj.toString();
    const [filterParams, setFilterParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [sortType, setSortType] = useState('ASC');
    const [rowCount, setRowCount] = useState(props.count.brandCount);
    const [tableData, setTableData] = useState([]);
    const [pageindex, setPageindex] = useState(filterParams.get('brandsIndex') ? filterParams.get('brandsIndex') : 1);
    const [searchText, setSearchText] = useState(filterParams.get('brandsSearch') ? filterParams.get('brandsSearch') : '');
    const [reloadData, setReloadData] = useState(false);
    const [userId, setUserId] = useState('');

    const { userType, id } = useSelector(state => state.auth);
    const { level,  index, search, sort, brandsList, count } = useSelector((state) => state.brandsData);

    const getIndexParam = () => parseInt(filterParams.get('brandsIndex')) || 1;
    
    useEffect(() => {
        setPageindex(filterParams.get('brandsIndex') || 1);
        checkSearchTextChange();
    }, [filterParams]); //eslint-disable-line

    useEffect(() => {
        if (props?.level && userType) {
            getAllBrandsDataTrigger();
        }
    }, [pageindex, sortType, props.reload, props.level, userType]); //eslint-disable-line

    const onRowClick = (row) => {
        navigate('/sku_data/brand/' + row.brandId + '_' + encodeString(row.brandDesc), { state: { searchParams: urlParams, row } });
    };
    
    //getting all brands Data
    const getAllBrandsDataTrigger = async (searchToApply = null) => {
        try {
            setLoading(true);
            const searchValue = searchToApply !== null ? searchToApply : searchText;
            if(level === props.level && index === pageindex && search === searchValue && sort === sortType && userId === props.merchantId && !(reloadData !== props.reload && props.reload)){
                setTableData(brandsList);
                setRowCount(count);
            } else {
                let brandsData = [];
                if (props.level === 'all' || props.level === 'bulkConfig') {
                    if(userType === 'MERCHANT'){
                        brandsData = await getAllAssignBrandData(id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    else {
                        brandsData = await getAllBrandsData(getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = brandsData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'sbu') {
                    if(userType === 'MERCHANT'){
                        brandsData = await getAssignBrandAtSbuData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    else {
                        brandsData = await getBrandAtSbuData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = brandsData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'shop') {
                    if(userType === 'MERCHANT'){
                        brandsData = await getAssignBrandAtShopData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    else {
                        brandsData = await getBrandAtShopData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = brandsData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'department') {
                    if(userType === 'MERCHANT'){
                        brandsData = await getAssignBrandAtDeptData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    else {
                        brandsData = await getBrandAtDeptData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = brandsData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'class') {
                    if(userType === 'MERCHANT'){
                        brandsData = await getAssignBrandAtClassData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    else {
                        brandsData = await getBrandAtClassData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = brandsData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'subclass') {
                    if(userType === 'MERCHANT'){
                        brandsData = await getAssignBrandAtSubclassData(id, props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    else {
                        brandsData = await getBrandAtSubclassData(props.id, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = brandsData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'manufacturer') {
                    if(userType === 'MERCHANT'){
                        brandsData = await getAssignBrandAtManufData(id, props.id, props.desc, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    else {
                        brandsData = await getBrandAtManufData(props.id, props.desc, getIndexParam() - 1, 10, searchValue, sortType);
                    }
                    const value = brandsData.dataCount;
                    onSearchInput(value);
                } else if (props.level === 'merchant') {
                    brandsData = await getAllAssignBrandData(props?.merchantId, getIndexParam() - 1, 10, searchValue, sortType);
                    const value = brandsData.dataCount;
                    onSearchInput(value);
                }
                dispatch(BrandsActions.setBrandsData({ 
                    level: props.level,
                    index: pageindex, 
                    search: searchValue, 
                    sort: sortType,
                    brandsList: brandsData.data,
                    count: brandsData.dataCount
                }));
                setTableData(brandsData.data);
                setRowCount(brandsData.dataCount);
                setUserId(props.merchantId);
            }
            setReloadData(props.reload);
            setLoading(false);
            props.setReload && props.setReload('brand', false);
        } catch (err) {
            console.log(err);
            Notification(
                'error',
                'Something went wrong while getting brand Data. Please try again later.'
            );
            setLoading(false);
        }
    };

    const checkSearchTextChange = () => {
        const searchParamText = filterParams.get('brandsSearch') || '';
        if(searchText !== searchParamText) {
            setSearchText(searchParamText);
            getAllBrandsDataTrigger(searchParamText);
        }
    }

    const onSearchInput = (value) => {
        if (value < (getIndexParam() - 1) * 10) {
            filterParams.set('brandsIndex', 1);
            filterParams.set('brandsSearch', searchText);
            setFilterParams(filterParams);
        }    
    }

    const handlePageChange = (event) => {
        filterParams.set('brandsIndex', event);
        setFilterParams(filterParams);
        setPageindex(event);
    };

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const handleCheckboxSelection = async (checkedValues) => {
        const status = {
            value: checkedValues.target.options[0].value,
            status: checkedValues.target.checked
        }

        props.updateTagValues(status)
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let sort = sorter.order === 'ascend' ? 'ASC' : 'DESC';
        setSortType(sort);
    };

    //Search when user press ENTER
    const onPressEnterKeyHandler = (event) => {
        try {
            if (event.key === 'Enter') {
                filterParams.set('brandsIndex', 1);
                filterParams.set('brandsSearch', event.target.value);
                setFilterParams(filterParams);
                setPageindex(1);
                getAllBrandsDataTrigger();
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div className="table-container">
            <Input
                className="petco-Data-Search responsive-Search-Bar"
                id="brands-search"
                prefix={<SearchOutlined />}
                onKeyDown={onPressEnterKeyHandler}
                onChange={handleSearchChange}
                value={searchText}
                placeholder={searchText ? searchText : "Search for Brand" }
                suffix={<span className='press-enter-style'>Press enter to search</span>}
                disabled={loading}
            />
            {props.level === 'bulkConfig' ? (
                <Spin spinning={loading}>
                    {tableData.map((item) => {
                        return (
                                <li
                                    key={`${item.brandDesc}+${item.brandId}`}
                                    style={{
                                        color: '#001952',
                                        font: '10px',
                                        marginBottom: '10px'
                                    }}>
                                    {
                                        <Checkbox
                                            key={`${item.brandDesc}+${item.brandId}`}
                                            checked={props.isTagChecked(item.brandDesc, item.brandId,'brand')}
                                            options={[{
                                                label: item.brandDesc,
                                                value: {desc: item.brandDesc,
                                                id: item.brandId,
                                                level: 'brand'
                                                }
                                            }]}
                                            onChange={handleCheckboxSelection}
                                        >{item.brandDesc}</Checkbox>
                                    }{' '}
                                    <Divider style={{margin: '15px'}} />
                                </li>
                        );
                    })}
                </Spin>
            ) : (
            <Table
                rowKey={(row) => row.brandId + row.brandDesc}
                id= "brands-table"
                columns={brandsTableColumns}
                dataSource={tableData}
                loading={loading}
                pagination={false}
                scroll={{ x: 2000 }}
                size="middle"
                onRow={(row) => {
                    return {
                        onClick: (event) => {
                            props.level !== 'merchant' && onRowClick(row);
                        }
                    };
                }}
                className="table-striped-rows clickable-table"
                rowSelection={props.level === 'merchant' ? props?.rowSelection : false}
                onChange={handleTableChange}
            />)}
            {rowCount > 10 && !loading ? <Pagination
                    className="petco-Data-pagination"
                    id="brands-bulk-filter"
                    defaultCurrent={getIndexParam()}
                    total={rowCount}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    size="small"
            />: <></>}
        </div>
    );
};

export default BrandsTable;
