import React, { useEffect, useState} from "react";
import { Row, Col, DatePicker } from 'antd';

import './Tables.scss'
import { disableFuture } from "../../utilities";
import dayjs from "dayjs";

const CreatedAtTable = ({ handleDateFilter, createdAtDate, setCreatedAtDate }) => {
    const { RangePicker } = DatePicker;
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: ''
    })
    const startDate = createdAtDate[0] ? dayjs(createdAtDate[0], 'YYYY/MM/DD 00:00:00') : null;
    const endDate = createdAtDate[1] ? dayjs(createdAtDate[1], 'YYYY/MM/DD 23:59:59') : null;
    const [tempStartDate, setTempStartDate] = useState(startDate);
    const [tempEndDate, setTempEndDate] = useState(endDate);

    useEffect(() => {
        if (createdAtDate[0] && createdAtDate[1]) {
            setTempStartDate(startDate)
            setTempEndDate(endDate)
        } else {
            setTempStartDate(null);
            setTempEndDate(null);
        }
    }, [createdAtDate]); //eslint-disable-line


    const handleDateChange = (value) => {
        if (value) {
            const tempDateRange = {
                startDate: value ? value[0].format('YYYY/MM/DD 00:00:00') : null,
                endDate: value ? value[1].format('YYYY/MM/DD 23:59:59') : null
            }
            handleDateFilter(tempDateRange)
            setDateRange(tempDateRange)
        } else {
            clearDates();
        }
    }

    const clearDates = () => {
        if (dateRange.startDate !== null || dateRange.endDate !== null) {
            setCreatedAtDate({ startDate: '', endDate: '' })
            setDateRange({startDate: '', endDate: ''})
            setTempStartDate(null)
            setTempEndDate(null)
        }
    }

    return (
        <div className="table-container">
            <Row>
                <Col span={12}>
                    <RangePicker
                        allowClear
                        style={{ width: '100%', height: '46px' }}
                        disabledDate={(current) => disableFuture(current)}
                        value={[
                            tempStartDate || null,
                            tempEndDate || null
                        ]}
                        onClick={() => clearDates()}
                        onChange={handleDateChange}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default CreatedAtTable;
