import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    logs: []
};

const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        //Setting logs state
        setLogsData(state, { payload }) {
            state.logs = payload.logsData;
        },
        //Resetting state
        reset(state) {
            state = {...initialState};
            return state;
        }
    }
});

export const LogsActions = logsSlice.actions;
export const LogsReducer = logsSlice.reducer;
export default logsSlice;