import { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Badge, Breadcrumb, Button, Input, Modal, Select, Spin, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useNavigationType } from 'react-router-dom';
import dayjs from 'dayjs';

import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import ScheduledAlertsTable from '../../components/Tables/ScheduledAlerts.table';
import UserLogsTable from '../../components/Tables/UserLogs.table';
import * as QueryExecuters from '../../graphql/queryExecuters';
import { Notification, validatePetcoEmail } from '../../utilities';

import './PetcoUserDetails.scss';
import AlertsTable from '../../components/Tables/Alerts.table';
import {
    alert_logs_data_error,
    generic_email_error,
    generic_name_error,
    user_update_success,
    user_update_warning
} from "../../constants/displayStrings";
import { userTypes } from '../../constants/common';

const PetcoUserDetails = () => {
    const { id } = useParams();
    const location = useLocation();
    const { state, pathname } = location;
    const navigationType = useNavigationType(); 
    const { userType } = useSelector((state) => state.auth);

    const [tabindex, setTabindex] = useState('1');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onTabChange = (key) => {
        setTabindex(key);
    };

    const [userDetails, setUserDetails] = useState({
        name: state?.row?.name || '',
        email: state?.row?.email || '',
        accessRole: state?.row?.accessRole || '',
        userType: state?.row?.userType || '',
        lastLogin: state?.row?.lastLogin || ''
    });

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        userType: '',
        accessRole: ''
    });

    const [showEditUserModal, setShowEditUserModal] = useState(false);

    const [userAlertsCount, setUserAlertsCount] = useState({});

    useEffect(() => {
        if (userType === 'MERCHANT') {
            navigate('/');
        }
        if(!state){
            getUserDetailsTrigger(id);
        }
    }, [id, userType]); //eslint-disable-line

    useEffect(() => {
        getAlertsCount();
    }, []); //eslint-disable-line
    

    const getUserDetailsTrigger = async (id) => {
        try {
            setLoading(true);
            const res = await QueryExecuters.getUserById(id);
            if (res.__typename === 'Error') {
                Notification('error', res.message);
            } else {
                setUserDetails((prevState) => {
                    const state = { ...prevState };
                    state.name = res.name;
                    state.email = res.email;
                    state.userType = res.userType;
                    state.accessRole = res.accessRole;
                    state.lastLogin = res.lastLogin;
                    return state;
                });
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onChangeValueHandler = (key, value) => {
        setFormData((prevState) => {
            const state = { ...prevState };
            state[key] = value;
            return state;
        });
    };

    const onClickEditUserHandler = () => {
        setShowEditUserModal(true);
        setFormData({
            name: userDetails.name,
            email: userDetails.email,
            userType: userDetails.userType,
            accessRole: userDetails.accessRole
        });
    };

    const onUpdateUserHandler = async () => {
        try {
            const petcoUserName = formData.name.trim()
            const petcoUserEmail = formData.email.trim()
            setLoading(true);
            if(!petcoUserName){
                Notification('error', generic_name_error);
                return;
            }
            const isValidEmail = validatePetcoEmail(petcoUserEmail);
            if (!isValidEmail) {
                Notification('error', generic_email_error);
                return;
            }
            let data = [];
            if(petcoUserName !== userDetails.name ){
                data.push({ field: 'name', value: petcoUserName })
            }
            if(petcoUserEmail.toLowerCase() !== userDetails.email.toLocaleLowerCase() ){
                data.push({ field: 'email', value: petcoUserEmail.toLowerCase() })
            }
            if(formData.userType !== userDetails.userType ){
                data.push({ field: 'user_type', value: formData.userType })
            }
            if(formData.accessRole !== userDetails.accessRole ){
                data.push({ field: 'access_role', value: formData.accessRole })
            }
            if(data.length > 0) {
                const res = await QueryExecuters.updateUser(id, data);
                if (res.__typename === 'Error') {
                    Notification('error', res.message);
                } else {
                    setUserDetails({ ...userDetails, ...formData });
                    navigate(pathname, { state: { row: {...userDetails, ...formData} }, replace: true });
                    setShowEditUserModal(false);
                    Notification('success', user_update_success);
                }
            } else {
                Notification('warning', user_update_warning);
                return;
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onBreadClickHandler = () => {
        if (navigationType === 'PUSH') navigate(-1 ,{state:{path:location.pathname , searchParams : state.searchParams}, replace: true});
        navigate('/teams_and_merchants/users');
    };

    //declaring localstate for sku Alerts
    const getAlertsCount = async () => {
        try {
            const alertsCount = await QueryExecuters.getAlertsCountByUser(id);
            setUserAlertsCount(alertsCount);
        } catch (err) {
            console.log(err);
            Notification('error', alert_logs_data_error);
        }
    };

    let level = 'user';

    const propsdata = {
        userId: id,
        userLogsCount: userAlertsCount?.logCount,
        userSubsCount: userAlertsCount?.subscriptionCount,
        level: level,
        alertsCount: userAlertsCount?.alertsCount
    };

    const tabs = [
        {
            label: (
                <span id='petUser-detail-userLog'>
                    <span style={{ color: tabindex === '1' ? '#001952' : '#96999F' }}>User Log</span>                    
                    <Badge
                        showZero
                        className="userLog-count-badge"
                        count={userAlertsCount?.logCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '1' ? '#001952' : '#EBF1FF',
                            color: tabindex === '1' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '1',
            children: <UserLogsTable {...propsdata}/>
        },
        {
            label: (
                <span id='petUser-detail-schedulealerts'>                    
                    <span style={{ color: tabindex === '2' ? '#001952' : '#96999F'}}>Scheduled Alerts</span>
                    <Badge
                        showZero
                        className="userLog-count-badge"
                        count={userAlertsCount?.subscriptionCount}
                        overflowCount={99999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '2' ? '#001952' : '#EBF1FF',
                            color: tabindex === '2' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '2',
            children: <ScheduledAlertsTable {...propsdata}/>
        },
        {
            label: (
                <span id='petUser-detail-triggeredalerts'>
                    <span style={{ color: tabindex === '3' ? '#001952' : '#96999F'}}>Triggered Alerts</span>
                    <Badge
                        showZero
                        className="userLog-count-badge"
                        count={userAlertsCount?.alertCount}
                        overflowCount={9999999}
                        style={{
                            marginLeft: 8,
                            backgroundColor: tabindex === '3' ? '#001952' : '#EBF1FF',
                            color: tabindex === '3' ? '#FFFFFF' : '#001952'
                        }}
                    />
                </span>
            ),
            key: '3',
            children: <AlertsTable {...propsdata} />
        }
    ];

    return (
        <Spin spinning={loading}>
            <ContentWrapper>
                <div className="petco-user-details-container">
                    <div className="breadcrumb-container">
                        <Breadcrumb 
                            items={[
                                {
                                    title:(
                                        <div style={{ display: 'inline-flex'}}>
                                        <div style={{color: '#001952', cursor:'pointer'}} onClick={onBreadClickHandler}>
                                            <ArrowLeftOutlined/>
                                        </div>
                                        <div style={{color: '#96999F', marginLeft: 10}}>Petco Team Member</div>
                                    </div>
                                    )
                                },
                                {
                                    title:userDetails.name,
                                    style:{color: '#001952'}
                                }
                            ]}
                            />
                    </div>
                    <div className="user-base-details">
                        <div className="name-and-actions">
                            <div className="name-container">{userDetails.name}</div>
                            <div className="actions-container">
                                {userType === userTypes.ADMIN && <Button
                                    style={{ marginRight: '16px' }}
                                    id='petUser-detail-edit-btn'
                                    type="default"
                                    onClick={onClickEditUserHandler}>
                                    Edit/Update
                                </Button>}
                            </div>
                        </div>
                        <div className="detail-container">
                            <div className="detail">
                                <div className="key">Assigned Role</div>
                                <div className="value">
                                    {userDetails.accessRole === 'PRICING-TEAM'
                                        ? 'Pricing Team'
                                        : 'EADS Team'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">Access Type</div>
                                <div className="value">
                                    {userDetails.userType === 'ADMIN'
                                        ? 'Admin Access'
                                        : 'Petco User'}
                                </div>
                            </div>
                            <div className="detail">
                                <div className="key">Email Address</div>
                                <div className="value">{userDetails.email}</div>
                            </div>
                            <div className="detail">
                                <div className="key">Last Logged In</div>
                                <div className="value">{userDetails.lastLogin ? dayjs.tz(userDetails.lastLogin).format('MM/DD/YYYY HH:mm:ss') : '-'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="user-table-container">
                        <Tabs defaultActiveKey="1" items={tabs} onChange={onTabChange} />
                    </div>
                </div>
                <Modal
                    open={showEditUserModal}
                    title="Edit User"
                    okText="Update"
                    cancelText="Close"
                    onCancel={() => {
                        setShowEditUserModal(false);
                    }}
                    onOk={onUpdateUserHandler}>
                    <div style={{ marginTop: 24 }}>Name</div>
                    <Input
                        value={formData.name}
                        id='edit-name'
                        onChange={(e) => {
                            onChangeValueHandler('name', e.target.value);
                        }}
                    />
                    <div style={{ marginTop: '16px' }}>Email Address</div>
                    <Input 
                        value={formData.email} 
                        id='edit-email'
                        onChange={(e) => {
                            onChangeValueHandler('email', e.target.value);
                        }}
                    />
                    <div style={{ marginTop: '16px' }}>User Type</div>
                    <Select
                        style={{ width: '100%' }}
                        id='edit-user-type'
                        value={formData.userType}
                        onChange={(e) => {
                            onChangeValueHandler('userType', e);
                        }}>
                        {userType === 'ADMIN' && (
                            <Select.Option value="ADMIN" id='edit-type-admin' key="ADMIN">
                                Admin
                            </Select.Option>
                        )}
                        <Select.Option value="PETCO-USER" id='edit-type-staff' key="PETCO-USER">
                            Staff
                        </Select.Option>
                    </Select>
                    <div style={{ marginTop: '16px' }}>Access Role</div>
                    <Select
                        disabled
                        style={{ width: '100%', marginBottom: '24px' }}
                        value={formData.accessRole}
                        id='edit-role'
                        onChange={(e) => {
                            onChangeValueHandler('accessRole', e);
                        }}>
                        <Select.Option value="PRICING-TEAM" id='edit-role-pticing-team' key="PRICING-TEAM">
                            Pricing Team
                        </Select.Option>
                        {/* <Select.Option value="EADS-TEAM" key="EADS-TEAM">
                            EADS Team
                        </Select.Option> */}
                    </Select>
                </Modal>
            </ContentWrapper>
        </Spin>
    );
};

export default PetcoUserDetails;
